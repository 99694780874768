import { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { CssEditor } from '../../components/sidebar/ExportHtml/CssEditor'
import { HtmlEditor } from '../../components/sidebar/ExportHtml/HtmlEditor'
import { useEditor } from '@craftjs/core'
import { jsonToHtmlParser } from '../../utils/jsonToHtmlParser'
import { parseStyle } from '../../utils/parseStyle'

export const ExportCode = () => {
  const [openDialogue, setOpenDialogue] = useState('none')
  const [exportedhtml, setExportedhtml] = useState('')
  const [exportedcss, setExportedcss] = useState('')
  const { query } = useEditor()
  const { scripts } = useSelector(state => state.scripts)
  const { customCssCode } = useSelector(state => state.styles)
  const { styles } = useSelector(state => state)
  const showDialogue = () => {
    const lincxTemplateJson = query.serialize()
    const parsedHtml = jsonToHtmlParser(lincxTemplateJson)
    setExportedhtml(parsedHtml)
    const parsedCss = parseStyle(styles)
    setExportedcss(parsedCss + customCssCode)
    setOpenDialogue('flex')
  }
  const closeDialogue = () => {
    setOpenDialogue('none')
  }

  return (
    <>
      <ExportButton onClick={showDialogue} data-cy='export__code__button'>
        <img
          src='https://img.icons8.com/ios/50/000000/export.png'
          alt='export-icon'
          width='40px'
        />
      </ExportButton>
      <ExportModal openDialogue={openDialogue}>
        <ModalContainer>
          <DialogueCloseButton onClick={closeDialogue} data-cy='exported_code__editor__close__button'>
            <img
              src='https://img.icons8.com/material-outlined/24/1A1A1A/delete-sign.png'
              alt='close-icon'
            />
          </DialogueCloseButton>
          <EditorContainer>
            <HtmlEditor exportedhtml={exportedhtml} exportedJavascript={scripts} />
            <CssEditor exportedcss={exportedcss} />
          </EditorContainer>
          <SaveButtonContainer>
            <button>Save</button>
          </SaveButtonContainer>
        </ModalContainer>
      </ExportModal>
    </>
  )
}

const ExportButton = styled.div`
  position: fixed;
  marigin: auto;
  height: 50px;
  width: 50px;
  left: 50px;
  bottom: 50px;
  &: hover {
    cursor: pointer;
  }
`
const ExportModal = styled.div`
  display: ${props => props.openDialogue};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: #0000009e;
`
const ModalContainer = styled.div`
  position: relative;
  padding: 10px;
  background: #ffff;
  z-index: 1000;
  margin: auto;
  border-radius: 10px;
  width: 65%;
`
const DialogueCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  img {
    width: 15px;
  }
`
const EditorContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`
const SaveButtonContainer = styled.div`
  display: felx;
  justify-content: flex-end;
  padding: 10px 0;
  button {
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    background: #28a745;
    &: hover {
      background: #1e7b34;
    }
  }
`
