export const homeAddressCapturePredefinedPage = {
  name: 'homeAddress',
  className: '',
  elements: [
    {
      kind: 'TEXT',
      type: 'TITLE',
      className: 'description home__address__description',
      value: 'What is your home address?'
    },
    {
      kind: 'CONTAINER',
      className: 'phone__form__container',
      keys: [],
      children: [
        {
          kind: 'TEXT',
          type: 'PARAGRAPH',
          className: 'label home__address__label',
          value: 'Home Address'
        },
        {
          kind: 'FORM',
          type: 'TEXT',
          className: 'address__input',
          placeholder: 'Please enter your home address',
          key: 'address',
          validations: {
            regex: {
              value: true,
              regex: '/([A-Z][a-z]+\\s?)+,\\s[A-Z]{2}\\s\\d{5}-?\\d{4}?/'
            },
            required: {
              value: true,
              message: 'Please enter a valid Home Address'
            }
          }
        }
      ]
    },
    {
      kind: 'CONTAINER',
      className: 'navigation__container',
      keys: [],
      children: [
        {
          kind: 'BUTTON',
          className: 'next__button',
          value: 'NEXT',
          role: 'NEXT'
        },
        {
          kind: 'BUTTON',
          className: 'back__button',
          value: 'BACK',
          role: 'BACK'
        }
      ]
    }
  ],
  nextPage: {},
  layouts: {
    beforeElements: '',
    afterElements: ''
  }
}
