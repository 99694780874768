import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  scripts: ''
}
export const scriptSlice = createSlice({
  name: 'scripts',
  initialState,
  reducers: {
    updateScript: (state, action) => {
      state.scripts = action.payload
    }
  }
})
export const { updateScript } = scriptSlice.actions
export default scriptSlice.reducer
