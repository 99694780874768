import { useDispatch } from 'react-redux'
import {
  changeValidationValues,
  useFormsBuilderState
} from 'state/slices/formsGenerator/builderSlice'

export const useValidations = () => {
  const { selectedElement } = useFormsBuilderState()
  const dispatch = useDispatch()

  const changeValidation = ({ validationName, key, value }) => {
    dispatch(changeValidationValues({ validationName, key, value }))
  }

  const getValidations = (validationName) => {
    if (selectedElement.validations) {
      return selectedElement.validations[validationName]
    }
    return null
  }

  return {
    haveValidations: !!selectedElement.validations,
    getValidations,
    changeValidation
  }
}
