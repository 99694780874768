import { TextField } from '@mui/material'
import { useDefaultValue } from 'modules/forms-generator/hooks/useDefaultValue'

export default function DefaultValueByValue ({ placeHolder }) {
  const { changeValue, value } = useDefaultValue()

  return (
    <TextField
      sx={{ flex: 1 }}
      label={placeHolder}
      value={value}
      onChange={({ target }) => changeValue(target.value)}
    />
  )
}
