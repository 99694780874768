import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import useLayouts from 'modules/forms-generator/hooks/useLayouts'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

export default function LayoutList ({ onSelectLayoutToEdit }) {
  const { layouts, removeSpecificLayout } = useLayouts()
  return (
    <TableContainer component={Paper} sx={{ m: '10px', width: '50%' }}>
      <Table sx={{ width: 650 }} aria-label='Layouts Table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Layout Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(layouts).map((layout, idx) => (
            <TableRow
              key={layout + idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {layout}
              </TableCell>
              <TableCell align='right'>
                <IconButton
                  aria-label='edit'
                  onClick={() => {
                    onSelectLayoutToEdit(layout)
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label='delete'
                  onClick={() => {
                    removeSpecificLayout(layout)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
