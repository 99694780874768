import { keyBy } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  onConnectState,
  onEdgesChangeState,
  onNodesChangesState,
  setDynamicConnections,
  setNodes,
  usePagesFlowState
} from 'state/slices/formsGenerator/pagesFlowSlice'
import { usePagesConfig } from './usePagesConfig'
import { usePagesConnections } from './usePagesConnections'
import usePages from './usePagesEditor'

export const usePagesFlow = () => {
  const dispatch = useDispatch()
  const { pages, selectPage } = usePages()
  const { nodes, edges, openConnectionDialogue, connectionParams } =
    usePagesFlowState()
  const [, setCurrentPageNextPageName] = usePagesConfig({
    keyPath: 'nextPage.name',
    defaultValue: ''
  })
  const {
    handleToggleOpenDialogue,
    setCurrentConnectionParams,
    getPagesConnection
  } = usePagesConnections()

  const selectedNode = useMemo(() => {
    return nodes.find((node) => node.selected)
    // eslint-disable-next-line
  }, [nodes])

  useEffect(() => {
    if (selectedNode) {
      selectPage(pages[selectedNode.id], selectedNode.id)
    }
    // eslint-disable-next-line
  }, [selectedNode, pages])

  const pagesToNode = useMemo(() => {
    const currentNodes = keyBy(nodes, 'id')
    return pages.reduce((acc, page, index) => {
      return [
        ...acc,
        {
          id: `${index}`,
          position: { x: 100, y: (index + 1) * 10 },
          data: { ...page },
          type: 'pageNode',
          ...currentNodes[index]
        }
      ]
    }, [])
    // eslint-disable-next-line
  }, [pages])

  useEffect(() => {
    dispatch(setNodes(pagesToNode))
  // eslint-disable-next-line
  }, [pagesToNode])

  const onNodesChange = useCallback((changes) => {
    dispatch(onNodesChangesState(changes))
    // eslint-disable-next-line
  }, [])

  const onEdgesChange = useCallback((changes) => {
    dispatch(onEdgesChangeState(changes))
    // eslint-disable-next-line
  }, [])

  const onConnect = (params) => {
    const pagesConnection = getPagesConnection()
    const { source, target } = params
    if (pagesConnection[source].targets.length > 0) {
      handleToggleOpenDialogue()
      setCurrentConnectionParams(params)
    } else {
      setCurrentPageNextPageName(pagesConnection[target].name, Number(source))
      dispatch(
        onConnectState({ ...params, id: `specific__${source}->${target}` })
      )
    }
    // eslint-disable-next-line
  }

  const changeDynamicConnections = (dynamicConnections) => {
    dispatch(setDynamicConnections(dynamicConnections))
  }

  const removeNode = (id) => {
    setNodes(nodes.filter((node) => node.id !== id))
  }

  return {
    nodes,
    edges,
    openConnectionDialogue,
    connectionParams,
    onNodesChange,
    onEdgesChange,
    onConnect,
    changeDynamicConnections,
    removeNode
  }
}
