import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html'
import jsBeautify from 'js-beautify'
import copy from 'copy-to-clipboard'
import {
  CodeMirrorContainer,
  EditorContainer,
  CopyMessage,
  CopyButton,
  EditorHeader
} from '../../styles-elements/styled-elements'

export const HtmlEditor = ({ exportedhtml, exportedJavascript }) => {
  const [htmlValue, setHtmlValue] = useState(exportedhtml)
  const [copyAnimation, setCopyAnimation] = useState(false)
  const handleChange = React.useCallback(value => {
    setHtmlValue(value)
  }, [])

  const handleCopy = () => {
    setCopyAnimation(true)
    copy(htmlValue)
  }

  useEffect(() => {
    if (exportedJavascript) {
      const javascript = `<script>${exportedJavascript}</script>`
      const codeToExport = javascript + exportedhtml
      const formatedHtmlCode = jsBeautify.html(codeToExport, { indent_size: 2 })
      setHtmlValue(formatedHtmlCode)
    } else {
      const formatedHtmlCode = jsBeautify.html(exportedhtml, { indent_size: 2 })
      setHtmlValue(formatedHtmlCode)
    }
  }, [exportedhtml, exportedJavascript])

  useEffect(() => {
    setTimeout(() => {
      if (copyAnimation) setCopyAnimation(false)
    }, 2000)
  }, [copyAnimation])

  return (
    <EditorContainer>
      <EditorHeader>
        <Label>HTML</Label>
        <CopyButton onClick={handleCopy}>
          <CopyMessage copied={copyAnimation}>
            HTML Copied to clipboard
          </CopyMessage>
          <img
            src='https://img.icons8.com/material-rounded/24/000000/copy.png'
            alt='copy-icon'
          />
        </CopyButton>
      </EditorHeader>
      <CodeMirrorContainer>
        <CodeMirror
          value={htmlValue}
          height='100%'
          width='100%'
          extensions={[html()]}
          onChange={handleChange}
          data-cy='html__editor'
        />
      </CodeMirrorContainer>
    </EditorContainer>
  )
}

const Label = styled.div`
  font-weight: bold;
  padding: 5px;
`
