import { FormControl, TextField } from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useEffect, useState } from 'react'

export default function ElementCustomEventHandler ({ label, eventKey }) {
  const { changeElementCustomEvent, customEvents } = useSelectedElements()
  const [customEventValue, setCustomEventValue] = useState('')

  const handleChange = (ev) => {
    setCustomEventValue(ev.target.value)
    changeElementCustomEvent({ key: eventKey, eventName: ev.target.value })
  }

  useEffect(() => {
    if (!customEvents || !customEvents[eventKey]) return setCustomEventValue('')
    setCustomEventValue(customEvents[eventKey])
    // eslint-disable-next-line
  }, [customEvents])
  return (
    <FormControl fullWidth>
      <TextField
        required
        id={eventKey}
        label={label}
        value={customEventValue}
        onChange={handleChange}
      />
    </FormControl>
  )
}
