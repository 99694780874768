import { KeysForTypes } from '..'
import ButtonRoles from '../buttonRoles'
import DefaultValueSelector from '../defaultValueSelector'
import DefaultValueHandler from '../defaultValue'
import GoNextHandler from '../goNext'
import OptionsHandler from '../options'
import Suggestions from '../suggestions'
import TextValue from '../textValue'
import ElementCustomEvents from '../elementCustomEvent'
import ElementCustomFunctions from '../elementCustomFunctions'
import InputTypes from '../inputTypes'

export const renderHandler = (setting, idx) => {
  switch (setting) {
    case KeysForTypes.CLASSNAME:
      return (
        <TextValue key={idx} elementKey='className' placeHolder='ClassNames' />
      )
    case KeysForTypes.CONTENT:
      return <TextValue key={idx} elementKey='content' placeHolder='Content' />
    case KeysForTypes.TEXT_VALUE:
      return <TextValue key={idx} elementKey='value' placeHolder='Text Value' />
    case KeysForTypes.BUTTON_ROLE:
      return <ButtonRoles key={idx} />
    case KeysForTypes.KEY:
      return <TextValue key={idx} elementKey='key' placeHolder='Key' />
    case KeysForTypes.GO_NEXT:
      return <GoNextHandler key={idx} />
    case KeysForTypes.SUGGESTIONS:
      return <Suggestions key={idx} />
    case KeysForTypes.OPTIONS:
      return <OptionsHandler key={idx} />
    case KeysForTypes.MIN_LABEL:
      return (
        <TextValue
          key={idx}
          elementKey='minLabel'
          placeHolder='Min Value Label'
        />
      )
    case KeysForTypes.MAX_LABEL:
      return (
        <TextValue
          key={idx}
          elementKey='maxLabel'
          placeHolder='Max Value Label'
        />
      )
    case KeysForTypes.INITIAL_INDEX:
      return (
        <DefaultValueHandler
          key={idx}
          elementKey='initialIndex'
          placeHolder='Initial Index'
          elementOptionsKey='suggestions'
        />
      )
    case KeysForTypes.PLACEHOLDER:
      return (
        <TextValue
          key={idx}
          elementKey='placeholder'
          placeHolder='Enter Placeholder value'
        />
      )
    case KeysForTypes.DEFAULT_VALUE:
      return <DefaultValueSelector key={idx} />

    case KeysForTypes.CUSTOM_EVENTS:
      return <ElementCustomEvents key={idx} />

    case KeysForTypes.CUSTOM_FUNCTIONS_ON_CLICK:
      return <ElementCustomFunctions key={idx} handlerKey='onClick' />
    case KeysForTypes.INPUT_TYPE:
      return <InputTypes key={idx} />
    default:
      return null
  }
}
