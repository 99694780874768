export const has = (obj, key, value) => {
  if (typeof key === 'string') {
    return findPaths(obj, key, value) !== undefined
  } else {
    return findPaths(obj, key.key, key.value) !== undefined
  }
}
export const findObjectPaths = (obj, { key, value }) => {
  return findPaths(obj, key, value)
}
export const findObjectPathsByKey = (obj, key) => {
  return findPaths(obj, key)
}
export const findObjectPathsByValue = (obj, value) => {
  return findPaths(obj, undefined, value)
}
export const findObjectPathsByKeyValue = (obj, key, value) => {
  return findPaths(obj, key, value)
}
export const findPaths = (obj, key, value) => {
  const results = []
  const find = (data, searchKey, searchValue, pathToData) => {
    if (
      typeof data === 'string' ||
      typeof data === 'boolean' ||
      typeof data === 'number'
    ) {
      const keyTmp = pathToData.split('.').pop()
      if (searchKey && searchValue !== undefined && data === searchValue) {
        if (
          keyTmp &&
          (keyTmp === searchKey || keyTmp.endsWith(`[${searchKey}]`))
        ) {
          results.push(pathToData)
        }
      } else if (searchKey && searchValue === undefined) {
        if (
          keyTmp &&
          (keyTmp === searchKey || keyTmp.endsWith(`[${searchKey}]`))
        ) {
          results.push(pathToData)
        }
      } else if (
        searchKey === undefined &&
        searchValue !== undefined &&
        data === searchValue
      ) {
        if (keyTmp) {
          results.push(pathToData)
        }
      }
    } else if (Array.isArray(data)) {
      for (let j = 0; j < data.length; j++) {
        find(data[j], searchKey, searchValue, `${pathToData}[${j}]`)
      }
    } else if (typeof data === 'object') {
      for (const k in data) {
        // eslint-disable-next-line
        if (data.hasOwnProperty(k)) {
          find(
            data[k],
            searchKey,
            searchValue,
            `${pathToData ? pathToData + '.' : ''}${k}`
          )
        }
      }
    }
  }
  find(obj, key, value, '')
  if (results.length > 1) {
    return results
  } else if (results.length === 1) {
    return results[0]
  }
}
