import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const SelectComponentSetting = () => {
  return <Settings typography dimensions colors border />
}

export const SelectContainer = ({ children, className }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <div
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {children}
    </div>
  )
}
SelectContainer.craft = {
  displayName: 'Select Container',
  related: {
    toolbar: SelectComponentSetting
  }
}
