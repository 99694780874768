import { Alert, Box, IconButton, Snackbar } from '@mui/material'
import { useToast } from 'state/slices/formsGenerator/toastSlice'
import { useFormsBuilderState } from '../../state/slices/formsGenerator/builderSlice'
import JsManagement from './components/jsManagement'
import BuilderLayout from './components/layouts/builder'
import PreviewLayout from './components/layouts/preview'
import LayoutManagementsMode from './components/layoutsManagement'
import ModeTabs from './components/tabs/mode-tabs'
import CloseIcon from '@mui/icons-material/Close'

export default function FormsGeneratorApp () {
  const { selectedMode } = useFormsBuilderState()
  const { closeToast, horizontal, message, open, severity, vertical } =
    useToast()

  const action = (
    <>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={closeToast}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  )
  return (
    <>
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <ModeTabs />
        {selectedMode === 'builder' && <BuilderLayout />}
        {selectedMode === 'preview' && <PreviewLayout />}
        {selectedMode === 'layouts' && <LayoutManagementsMode />}
        {selectedMode === 'functions' && <JsManagement />}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={closeToast}
        action={action}
        key={vertical + horizontal}
      >
        <Alert onClose={closeToast} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}
