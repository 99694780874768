import { SvgIcon } from '@mui/material'

export default function Logo (className) {
  return (
    <SvgIcon
      version='1.1'
      id='Shape_1_copy_7_3_'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='388px'
      height='300px'
      viewBox='0 0 388 300'
      enableBackground='new 0 0 388 300'
      xmlSpace='preserve'
      className={className}
      sx={
        className
          ? {}
          : {
              display: 'block',
              width: 24,
              marginRight: 'auto',
              marginLeft: 'auto',
              height: 'auto'
            }
      }
    >
      <path
        fill='#383838'
        d={`
          M385.467,0h-35.445c-2.335,0-5.465,1.473-6.955,3.273l-117.058,141.49l-16.207-15.369L314.147,3.273
          c1.488-1.8,0.796-3.273-1.54-3.273h-61.875c-2.336,0-5.466,1.473-6.956,3.273l-73.407,88.729L76.43,2.922
          C74.735,1.315,71.437,0,69.101,0H4.525C2.19,0,1.666,1.315,3.36,2.922L138.246,130.83L0.992,296.728
          c-1.488,1.8-0.796,3.272,1.54,3.272h61.876c2.336,0,5.465-1.473,6.955-3.272l106.315-128.504l16.207,15.367l-93.601,113.137
          c-1.49,1.8-0.797,3.272,1.54,3.272h35.444c2.336,0,5.466-1.473,6.955-3.272l74.284-89.788l95.055,90.139
          c1.696,1.607,4.993,2.922,7.33,2.922h64.574c2.337,0,2.86-1.314,1.167-2.922L250.632,168.111L387.007,3.273
          C388.496,1.473,387.804,0,385.467,0z
        `}
      />
    </SvgIcon>
  )
}
