import { Box } from '@mui/system'
import { ELEMENTS_VALIDATIONS } from './validations/elementsValidations'
import { renderValidation } from './validations/render-validations'

export default function ValidationsHandler ({ elementType }) {
  return (
    <Box>
      {ELEMENTS_VALIDATIONS[elementType] &&
        ELEMENTS_VALIDATIONS[elementType].map((validations, idx) =>
          renderValidation(validations, idx)
        )}
    </Box>
  )
}
