import { usePagesConnections } from 'modules/forms-generator/hooks/usePagesConnections'
import { useEffect, useRef, useState } from 'react'
import { getBezierPath } from 'reactflow'
import styled from 'styled-components'

export default function NextPageEdge ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
  label
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })
  const { removeSpecificEdge, removeDynamicEdge } = usePagesConnections()

  const onEdgeClick = (evt, id) => {
    evt.stopPropagation()
    if (label) {
      removeDynamicEdge(id)
    } else {
      removeSpecificEdge(id)
    }
  }

  const foreignObjectSize = 40

  const edgeRef = useRef(null)
  const [edgeTextBbox, setEdgeTextBbox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  })

  useEffect(() => {
    if (edgeRef.current) {
      const textBbox = edgeRef.current.getBBox()

      setEdgeTextBbox({
        x: textBbox.x,
        y: textBbox.y,
        width: textBbox.width,
        height: textBbox.height
      })
    }
  }, [label])

  return (
    <>
      <path
        id={id}
        style={style}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
        width={3}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={
          label
            ? labelX - foreignObjectSize / 2 + 1
            : labelX - foreignObjectSize / 2 + 1
        }
        y={
          label
            ? labelY - foreignObjectSize / 2 - 30
            : labelY - foreignObjectSize / 2 + 1
        }
        className='edgebutton-foreignobject'
        requiredExtensions='http://www.w3.org/1999/xhtml'
      >
        <body>
          <RemoveEdgeButton onClick={(event) => onEdgeClick(event, id)}>
            ×
          </RemoveEdgeButton>
        </body>
      </foreignObject>
      <g
        transform={`translate(${labelX - edgeTextBbox.width / 2} ${
          labelY - edgeTextBbox.height / 2
        })`}
        visibility={edgeTextBbox.width ? 'visible' : 'hidden'}
      >
        {label
          ? (
            <rect
              width={edgeTextBbox.width + 2 * 10}
              x={-10}
              y={-10}
              height={edgeTextBbox.height + 2 * 10}
              className='react-flow__edge-textbg'
              rx={5}
              ry={5}
            />
            )
          : null}

        {label
          ? (
            <EdgeLabel
              className='react-flow__edge-text'
              y={edgeTextBbox.height / 2}
              dy='0.3em'
              ref={edgeRef}
            >
              {label}
            </EdgeLabel>
            )
          : null}
      </g>
    </>
  )
}

const RemoveEdgeButton = styled.button`
  border-radius: 100%;
  border: none;
  background-color: red;
  color: #ffffff;
  height: 30px;
  width: 30px;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const EdgeLabel = styled.text`
  font-weight: bold;
`
