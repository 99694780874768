import { Box, Typography } from '@mui/material'
import ElementCustomFunctionHandler from './handler'

export default function ElementCustomFunctions ({ handlerKey = 'onClick' }) {
  return (
    <Box>
      <Typography sx={{ mb: 1 }}>Custom Functions</Typography>
      <ElementCustomFunctionHandler handlerKey={handlerKey} label='onClick' />
    </Box>
  )
}
