import { ROOT_NODE, useEditor } from '@craftjs/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useStylesHelpers } from '../../../hooks/use-styles-helpers'
import MinPlusUi from './min-plus-ui'
import { keyWords } from './style-keywords'
import { useStyles } from 'hooks/use-styles'

export default function MinPlusInput (props) {
  const [count, setCount] = useState(0)
  const [unity, setUnity] = useState(props.unity ?? 'px')
  const [isLaoded, setIsLoaded] = useState(false)
  const [inputValue, setInputValue] = useState(count + '' + unity)
  const [selectedUnity, setSelectedUnity] = useState('px')
  const { changestyle } = useStylesHelpers()
  const unities = [
    'px',
    'pt',
    'pc',
    'cm',
    'mm',
    'in',
    'em',
    'rem',
    'vh',
    'vw',
    'vmin',
    '%',
    'vmax'
  ]
  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const { getDefaultValueByClassName } = useStyles()
  useEffect(() => {
    if (selectedClassName) {
      getDefaultValueByClassName({ selectedClassName, selectedDevice, tabletStyle, desktopStyle, classes, props, unities, setSelectedUnity, setCount })
    }
  // eslint-disable-next-line
  }, [selectedClassName])
  const { active, classes } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      selectedNode: state.nodes[currentlySelectedNodeId]
        ? query.node(currentlySelectedNodeId)
        : undefined,
      related:
        currentlySelectedNodeId && state.nodes[currentlySelectedNodeId].related,
      elementProps: state.nodes[currentlySelectedNodeId]
        ? state.nodes[currentlySelectedNodeId].data.props
        : undefined,
      dom: state.nodes[currentlySelectedNodeId]
        ? state.nodes[currentlySelectedNodeId].dom
        : undefined,
      parent: state.nodes[currentlySelectedNodeId]
        ? state.nodes[currentlySelectedNodeId].data.parent
        : undefined,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName ||
        [],
      rootProps: query.node(ROOT_NODE).get()?.data?.props,
      isRoot: ROOT_NODE === currentlySelectedNodeId
    }
  })

  useEffect(() => {
    let className
    classes.forEach((element, index) => {
      if (element.substring(0, 3) === 'lx_') {
        className = element
      } else {
        if (index === classes.length - 1) {
          className = element
        }
      }
    })
    let initialInputValue
    if (selectedDevice === 'tablet') {
      initialInputValue =
        tabletStyle[className]?.[props.cssKey] ||
        styles[className]?.[props.cssKey] ||
        props.defaultValue
    } else if (selectedDevice === 'desktop') {
      initialInputValue =
        desktopStyle[className]?.[props.cssKey] ||
        tabletStyle[className]?.[props.cssKey] ||
        styles[className]?.[props.cssKey] ||
        props.defaultValue
    } else {
      initialInputValue =
        styles[className]?.[props.cssKey] || props.defaultValue
    }

    const getNumberValue = () => {
      if (selectedDevice === 'tablet') {
        return parseInt(
          tabletStyle[className]?.[props.cssKey] || props.defaultValue
        )
      } else if (selectedDevice === 'desktop') {
        return parseInt(
          desktopStyle[className]?.[props.cssKey] || props.defaultValue
        )
      } else {
        return parseInt(styles[className]?.[props.cssKey] || props.defaultValue)
      }
    }

    if (styles[className]?.[props.cssKey] !== undefined) {
      if (
        unities.includes(styles[className]?.[props.cssKey].replace(/\d+/g, ''))
      ) {
        setSelectedUnity(styles[className]?.[props.cssKey].replace(/\d+/g, ''))
      }
    } else {
      setSelectedUnity(initialInputValue.includes('%') ? '%' : 'px')
    }
    if (isNaN(getNumberValue())) {
      setCount(initialInputValue)
    } else {
      setCount(getNumberValue())
    }
    // eslint-disable-next-line
  }, [active, selectedDevice])

  function incrementCount () {
    if (!isNaN(count)) {
      setCount(count + 1)
    }
  }

  function decrementCount () {
    if (count !== 0 && !isNaN(count)) {
      setCount(count - 1)
    }
  }

  const setStylesValue = (cssKey, value) => {
    if (classes) {
      changestyle({ classes, styles, cssKey, value, selectedClassName })
    }
  }

  const handleChange = e => {
    if (e.target.value) {
      if (e.target.value.replace(/[0-9]/g, '')) {
        setUnity(e.target.value.replace(/[0-9]/g, ''))
      }
      setCount(Number(e.target.value.match(/(\d+)/)[0]))
    } else {
      setInputValue(e.target.value)
    }
  }

  useEffect(
    () => {
      const currentStyles = {}
      currentStyles[props.cssKey] = inputValue + '' + selectedUnity
      if (inputValue && isLaoded) {
        setStylesValue(props.cssKey, inputValue + '' + selectedUnity)
      }
      setIsLoaded(true)
    },
    // eslint-disable-next-line
    [inputValue, selectedUnity]
  )

  const handleUnitSlection = e => {
    setUnity(e.target.value)
    setSelectedUnity(e.target.value)
  }

  useEffect(() => {
    if (!isNaN(count)) {
      setInputValue(count)
    } else if (keyWords.includes(count)) {
      setCount(0)
      setInputValue(0)
    } else if (typeof count === 'string') {
      setInputValue(count)
    }
  }, [count, unity])

  return (
    <Container justify='space-around' data-cy={props.cssKey}>
      <MinPlusUi
        title={props.title}
        handleDecrementCount={decrementCount}
        cssKey={props.cssKey}
        inputValue={inputValue}
        handleChange={handleChange}
        handleUnitSlection={handleUnitSlection}
        unity={unity}
        handleIncrementCount={incrementCount}
      />
    </Container>
  )
}
const Container = styled.div`
  display: flex;
  height: 40px;
  margin: 0;
  flex-direction: column;
  margin: 0px 10px 24px 10px;
`
