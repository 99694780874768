import DynamicSelectInput from './dynamicSelectInput'

export default function DynamicConnectionHandler ({
  sourceName,
  targetName,
  suggestions,
  connectedValue,
  onChange
}) {
  return (
    <div className='pagesContainer'>
      <div className='pageName'>{sourceName}</div>
      <DynamicSelectInput
        label='Connection Value'
        id='connectionValue'
        value={connectedValue}
        onChange={onChange}
        options={suggestions}
      />
      <div className='pageName'>{targetName}</div>
    </div>
  )
}
