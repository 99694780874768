import { useEditor } from '@craftjs/core'
import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useStylesHelpers } from '../../../hooks/use-styles-helpers'
import { ColorContainer } from '../../styles-elements/styled-elements'
import { Container, Wrapper } from './dimensions-styled'
export default function BorderStyleSection () {
  const defaultSelectedStyle = ''
  const [slectedBorderStyle, setSelectedBorderStyle] = useState('')
  const [color, setColor] = useState('')
  const { changestyle } = useStylesHelpers()
  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const { active, classes } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName ||
        []
    }
  })
  const stylesOptions = [
    { key: 'none', value: 'none' },
    { key: 'dotted', value: 'dotted' },
    { key: 'dashed', value: 'dashed' },
    { key: 'solid', value: 'solid' },
    { key: 'double', value: 'double' },
    { key: 'groove', value: 'groove' },
    { key: 'ridge', value: 'ridge' },
    { key: 'inset', value: 'inset' },
    { key: 'outset', value: 'outset' },
    { key: 'hidden', value: 'hidden' }
  ]

  const styleChangeHandler = e => {
    setSelectedBorderStyle(e.target.value)
  }

  const setStylesValue = (cssKey, value) => {
    if (classes) {
      changestyle({ classes, styles, cssKey, value, selectedClassName })
    }
  }

  const colorChangeHandler = e => {
    setColor(e.target.value)
  }

  useEffect(() => {
    setStylesValue('borderStyle', slectedBorderStyle)
    // eslint-disable-next-line
  }, [slectedBorderStyle])

  useEffect(() => {
    setStylesValue('borderColor', color)
    // eslint-disable-next-line
  }, [color])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setSelectedBorderStyle(tabletStyle?.[classes?.[0]]?.borderStyle || '')
      setColor(tabletStyle?.[classes?.[0]]?.borderColor || '')
    } else if (selectedDevice === 'desktop') {
      setSelectedBorderStyle(
        desktopStyle?.[classes?.[0]]?.borderStyle || ''
      )
      setColor(desktopStyle?.[classes?.[0]]?.borderColor || '')
    } else {
      setSelectedBorderStyle(styles?.[classes?.[0]]?.borderStyle || '')
      setColor(styles?.[classes?.[0]]?.borderColor || '')
    }
    // eslint-disable-next-line
  }, [active, selectedDevice])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setSelectedBorderStyle(tabletStyle?.[selectedClassName]?.borderStyle || 'none')
      setColor(tabletStyle?.[selectedClassName]?.borderColor || 'none')
    } else if (selectedDevice === 'desktop') {
      setSelectedBorderStyle(
        desktopStyle?.[selectedClassName]?.borderStyle || 'none'
      )
      setColor(desktopStyle?.[selectedClassName]?.borderColor || 'none')
    } else {
      setSelectedBorderStyle(styles?.[selectedClassName]?.borderStyle || 'none')
      setColor(styles?.[selectedClassName]?.borderColor || 'none')
    }
    // eslint-disable-next-line
  }, [selectedClassName])
  return (
    <Wrapper>
      <Container center='center'>
        <FormControl sx={{ m: 0, minWidth: 200 }}>
          <Select
            onChange={styleChangeHandler}
            value={slectedBorderStyle}
            fullWidth
          >
            {stylesOptions.map((item, index) => {
              return (
                <MenuItem value={item.key} key={index}>
                  {item.value}
                  {item.value === defaultSelectedStyle}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <ColorContainer>
          <InputColor
            type='color'
            value={color}
            onChange={colorChangeHandler}
          />
          <ColorCodeInput
            type='text'
            value={color}
            onChange={colorChangeHandler}
          />
        </ColorContainer>
      </Container>
    </Wrapper>
  )
}
const InputColor = styled.input`
  width: 60px;
`
const ColorCodeInput = styled.input`
  width: 100px;
`
