import { useEditor } from '@craftjs/core'
import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useStylesHelpers } from '../../../hooks/use-styles-helpers'
import {
  HeaderContainer
} from './dimensions-styled'

export default function FontStyle () {
  const stylesOptions = [
    { key: 'normal', value: 'normal' },
    { key: 'italic', value: 'italic' },
    { key: 'oblique', value: 'oblique' }
  ]
  const [slectedFontStyle, setSelectedFontStyle] = useState('')
  const { changestyle } = useStylesHelpers()
  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const { active, classes } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName ||
        []
    }
  })

  const styleChangeHandler = e => {
    setSelectedFontStyle(e.target.value)
  }

  const setStylesValue = (cssKey, value) => {
    if (classes) {
      changestyle({ classes, styles, cssKey, value, selectedClassName })
    }
  }

  useEffect(() => {
    setStylesValue('fontStyle', slectedFontStyle)
    // eslint-disable-next-line
  }, [slectedFontStyle])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setSelectedFontStyle(tabletStyle?.[classes?.[0]]?.fontStyle || '')
    } else if (selectedDevice === 'desktop') {
      setSelectedFontStyle(desktopStyle?.[classes?.[0]]?.fontStyle || '')
    } else {
      setSelectedFontStyle(styles?.[classes?.[0]]?.fontStyle || '')
    }
    // eslint-disable-next-line
  }, [active, selectedDevice])
  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setSelectedFontStyle(tabletStyle?.[selectedClassName]?.fontStyle || 'normal')
    } else if (selectedDevice === 'desktop') {
      setSelectedFontStyle(desktopStyle?.[selectedClassName]?.fontStyle || 'normal')
    } else {
      setSelectedFontStyle(styles?.[selectedClassName]?.fontStyle || 'normal')
    }
    // eslint-disable-next-line
  },[selectedClassName])
  return (
    <Wrapper>
      <HeaderContainer data-cy='font__select'>
        <Title>Font style</Title>
      </HeaderContainer>
      <FormControl sx={{ m: 3, minWidth: 200 }}>
        <Select
          onChange={styleChangeHandler} value={slectedFontStyle}
          data-cy='font__style__select'
        >
          {stylesOptions.map((item, index) => {
            return (
              <MenuItem value={item.key} key={index}>
                {item.value}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Wrapper>
  )
}
const Wrapper = styled.div`
 
`
const Title = styled.div`
  color: #636e72;
  margin: 12px 0px 0px 0px;
`
