import { Box, InputAdornment, TextField } from '@mui/material'
import styled from 'styled-components'

export default function BorderRadiusSideInput ({
  startIcon,
  className,
  value,
  onChange
}) {
  return (
    <BorderRadiusSideInputContainer className={className}>
      <TextField
        size='small'
        value={value}
        type='number'
        onChange={onChange}
        min='0'
        InputProps={{
          sx: {
            pr: '0px'
          },
          inputMode: 'numeric',
          pattern: '[0-9]*',
          startAdornment: (
            <InputAdornment position='start'>{startIcon}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
              <select>
                <option value='px'>px</option>
                <option value='%'>%</option>
                <option value='em'>em</option>
                <option value='rem'>rem</option>
              </select>
            </InputAdornment>
          )
        }}
        variant='outlined'
      />
    </BorderRadiusSideInputContainer>
  )
}

const BorderRadiusSideInputContainer = styled(Box)`
  margin: 10px 0;
  display: flex;
  align-items: center;
  svg {
    font-size: 1.3em;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    font-size: 1em;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    opacity: 0.5;
    padding: 10px 5px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
