import { Image } from '../Image'
import { Bullets } from '../Bullets'
import { Cta } from '../Cta'
import { Element } from '@craftjs/core'
import { AdContainer } from '../AdContainer'
import { ElementContainer } from '../ElementContainer'

const layout3DefaultStyles = {
  adContainer: {
    gap: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imageStyle: {
    height: '200px',
    width: 'auto'
  },
  headlineStyle: {
    fontSize: '26px',
    width: 'fit-content'
  },
  bullesStyle: {
    fontSize: '16px'
  },
  ctaStyle: {
    display: 'flex',
    justifyContent: 'center'
  },
  elementContainer1: {
    display: 'flex',
    flexDirection: 'column'
  },
  elementContainer2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export const Layout3 = () => {
  return (
    <Element id='ad-container' styles={layout3DefaultStyles.layout3Style} is={AdContainer} canvas>
      <ElementContainer styles={layout3DefaultStyles.elementContainer1}>
        <Image
          imgSource=''
          imgName='image-icon'
          style={layout3DefaultStyles.imageStyle}
        />
        <ElementContainer styles={layout3DefaultStyles.elementContainer2}>
          <Bullets bullets={['bullet-1', 'bullet-2', 'bullet-3', 'bullet-4']} styles={layout3DefaultStyles.bullesStyle} />
          <Cta styles={layout3DefaultStyles.ctaStyle} />
        </ElementContainer>
      </ElementContainer>
    </Element>
  )
}
