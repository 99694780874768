import styled from 'styled-components'
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 1000;
`
export const CollapseContainer = styled.div`
  cursor: pointer;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${props => props.center ?? 'space-between'};
  margin-top: -18px;
  margin: 0;
`
export const Select = styled.select`
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #ddd;
  width: ${props => (props.large ? '200px' : props.med ? '100px' : '100%')};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 12px;
`
