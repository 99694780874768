import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const RangeInputLabelsSetting = () => {
  return <Settings typography dimensions colors border />
}

export const RangeInputLabels = ({ minLabel, maxLabel }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <div
      className='range__input--labels'
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {minLabel && <span>{minLabel}</span>}
      {maxLabel && <span>{maxLabel}</span>}
    </div>
  )
}

RangeInputLabels.craft = {
  displayName: 'Range Input Labels',
  related: {
    toolbar: RangeInputLabelsSetting
  }
}
