import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Modal,
  TextField
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'
import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html'
import SaveIcon from '@mui/icons-material/Save'
import useLayouts from 'modules/forms-generator/hooks/useLayouts'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const filter = createFilterOptions()

export default function LayoutHandler ({ label, position }) {
  const { selectedPage } = useFormsBuilderState()
  const {
    layouts,
    addEditManageLayout,
    attachLayoutToBeforeElements,
    attachLayoutToAfterElements
  } = useLayouts()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [layoutName, setLayoutName] = useState('')
  const [layoutContent, setLayoutContent] = useState('')

  useEffect(() => {
    if (
      selectedPage &&
      selectedPage.layouts[
        position === 'top' ? 'beforeElements' : 'afterElements'
      ]
    ) {
      setLayoutName(
        selectedPage.layouts[
          position === 'top' ? 'beforeElements' : 'afterElements'
        ]
      )
      setLayoutContent(
        layouts[
          selectedPage.layouts[
            position === 'top' ? 'beforeElements' : 'afterElements'
          ]
        ]
      )
    } else {
      setLayoutName('')
      setLayoutContent('')
    }
    // eslint-disable-next-line
  }, [])

  const addEditLayout = () => {
    addEditManageLayout(layoutName, layoutContent)
    if (position === 'top') {
      attachLayoutToBeforeElements(layoutName)
    } else {
      attachLayoutToAfterElements(layoutName)
    }
    handleClose()
  }

  if (!selectedPage) return null
  return (
    <>
      <Box
        sx={{
          border: '3px dashed #90caf9',
          py: '20px',
          px: '90px',
          mt: '10px',
          w: '100%',
          textTransform: 'uppercase',
          cursor: 'pointer',
          fontWeight: 'bold',
          color: '#1e88e5'
        }}
        onClick={handleOpen}
      >
        {selectedPage.layouts[
          position === 'top' ? 'beforeElements' : 'afterElements'
        ]
          ? selectedPage.layouts[
              position === 'top' ? 'beforeElements' : 'afterElements'
            ]
          : label}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Autocomplete
            sx={{ width: '100%', my: '20px' }}
            size='small'
            label='Layout Name'
            options={Object.keys(layouts)}
            variant='outlined'
            value={layoutName}
            onChange={(_, newValue) => {
              const isExisting = Object.keys(layouts).some(
                (option) => newValue === option
              )
              if (isExisting) {
                setLayoutContent(layouts[newValue])
              } else {
                setLayoutContent('')
              }
              setLayoutName(newValue)
            }}
            renderInput={(params) => (
              <TextField {...params} label='Layout Name' />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option)
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue)
              }
              if (isExisting) {
                setLayoutContent(layouts[inputValue])
                console.log('should update')
              }

              return filtered
            }}
            getOptionLabel={(option) => {
              return option
            }}
          />
          <Box
            sx={{
              width: '100%',
              height: '500px',
              overflowY: 'scroll',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              mb: '20px'
            }}
          >
            <CodeMirror
              width='100%'
              extensions={[html()]}
              value={layoutContent}
              onChange={(value) => setLayoutContent(value)}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='outlined'
              size='small'
              startIcon={<SaveIcon />}
              onClick={addEditLayout}
            >
              Save The Layout
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
