/* eslint-disable no-unused-vars */
import { useEditor } from '@craftjs/core'
import React, { useEffect, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import CodeIcon from '@mui/icons-material/Code'
import TitleIcon from '@mui/icons-material/Title'
import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { WRAPPER_ID } from '../wrapper'

const IndicatorDiv = styled.div``
const getElementPosition = (element) => {
  const left = element.offsetLeft - element.scrollLeft
  const top = element.offsetTop - element.scrollTop
  return { left, top }
}
export const RenderNode = ({ render }) => {
  const { id, isHover, dom, name } = useCraftNode()
  const { isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id)
  }))

  const currentRef = useRef()

  useEffect(() => {
    if (dom) {
      dom.dataset.elementId = id
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (dom) {
      isHover
        ? dom.classList.add('is__hovered')
        : dom.classList.remove('is__hovered')
      isActive
        ? dom.classList.add('is__active')
        : dom.classList.remove('is__active')
    }
  }, [dom, isActive, isHover])

  const getPos = useCallback((dom) => {
    const { top, left } = dom
      ? getElementPosition(
          document
            .getElementById(WRAPPER_ID)
            .contentWindow.document.querySelector(
              `[data-element-id="${dom.dataset.elementId}"]`
            )
        )
      : { top: 0, left: 0 }
    return {
      top: `${top}px`,
      left: `${left}px`
    }
  }, [])

  return (
    <>
      {/* {isActive
        ? ReactDOM.createPortal(
            <div
              className="indicator"
              ref={currentRef}
              style={{
                left: getPos(dom).left,
                top: getPos(dom).top,
                zIndex: 9999
              }}
            >
              <Typography sx={{ fontSize: '10px' }}>{name}</Typography>
              <CodeIcon onClick={openChangeClassName} />
              <TitleIcon onClick={openChangeTextValue} />
            </div>,
            document
              .getElementById(WRAPPER_ID)
              .contentWindow.document.querySelector('.EditorContainer')
          )
        : null} */}
      {render}
    </>
  )
}
