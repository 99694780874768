import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useEffect } from 'react'

export default function ButtonRoles () {
  const { selectedElement, changeElement } = useSelectedElements()

  useEffect(() => {
    if (!selectedElement.role) {
      changeElement('role', 'NEXT')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <FormControl sx={{ my: '10px' }}>
      <FormLabel id='button__role'>Button Role</FormLabel>
      <RadioGroup
        row
        aria-labelledby='button__role'
        defaultValue='NEXT'
        name='radio-buttons-group'
        value={selectedElement?.role}
        onChange={(e) => changeElement('role', e.target.value)}
      >
        <FormControlLabel value='NEXT' control={<Radio />} label='Next' />
        <FormControlLabel value='BACK' control={<Radio />} label='Back' />
        <FormControlLabel value='SUBMIT' control={<Radio />} label='Submit' />
      </RadioGroup>
    </FormControl>
  )
}
