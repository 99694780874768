import React, { useEffect, useState } from 'react'
import { useEditor } from '@craftjs/core'
import MinPlusInput from './min-plus-input'
import styled from 'styled-components'
import FontStyle from './font-style'
import FontWeight from './font-weight'
import FontColor from './fontColor'
export default function FontSection () {
  const [showFontSection, setShowFontSection] = useState(true)

  const { selected } = useEditor(state => {
    const currentNodeId = [...state?.events?.selected][0]
    let selected
    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name
      }
    }
    return {
      selected
    }
  })
  useEffect(() => {
    if (selected?.name === 'Image') {
      setShowFontSection(false)
    } else {
      setShowFontSection(true)
    }
  }, [selected])
  return (
    <Wrapper show={showFontSection}>
      <Container center='space-around'>
        <MinPlusInput title='Font Size' cssKey='fontSize' defaultValue='14' />
        <FontColor />
        <FontStyle />
        <FontWeight />
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  width: 90%;
  margin-top: 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${props => props.center ?? 'space-between'};
  margin-top: -18px;
  margin: 0;
`
