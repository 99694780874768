import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import { useFontFamily } from './useFontFamily'
import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'

export default function TypographySetting () {
  const { AutoCompleteFontFamily } = useFontFamily()

  const [fontSizeValue, changeFontSize] = useCssKeyValue({
    key: 'font-size',
    value: 0,
    unit: 'px'
  })
  const [fontWeightValue, changeFontWeight] = useCssKeyValue({
    key: 'font-weight',
    value: '400'
  })
  const [textAlignValue, changeTextAlign] = useCssKeyValue({
    key: 'text-align',
    value: 'left'
  })

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Typography</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <AutoCompleteFontFamily />
        </div>
        <div>
          <label htmlFor='fontSize'>Font Size:</label>
          <Slider
            size='small'
            defaultValue={fontSizeValue}
            value={fontSizeValue}
            aria-label='Small'
            valueLabelDisplay='auto'
            min={0}
            max={100}
            onChange={(_, value) => changeFontSize({ value })}
          />
        </div>
        <div>
          <InputLabel id='fontWightLabel'>Font Weight:</InputLabel>
          <Select
            labelId='fontWightLabel'
            id='fontWight'
            value={fontWeightValue}
            label='Font Weight:'
            onChange={({ target }, _) =>
              changeFontWeight({ value: target.value })}
          >
            <MenuItem value='100'>100 (Thin)</MenuItem>
            <MenuItem value='200'>200 (Extra Light)</MenuItem>
            <MenuItem value='300'>300 (Light)</MenuItem>
            <MenuItem value='400'>400 (Normal)</MenuItem>
            <MenuItem value='500'>500 (Medium)</MenuItem>
            <MenuItem value='600'>600 (Semi Bold)</MenuItem>
            <MenuItem value='700'>700 (Bold)</MenuItem>
            <MenuItem value='800'>800 (Extra Bold)</MenuItem>
          </Select>
        </div>
        <div>
          <InputLabel>Text Align</InputLabel>
          <ButtonGroup variant='outlined' aria-label='outlined button group'>
            <IconButton
              disabled={textAlignValue === 'left'}
              aria-label='left'
              onClick={() => changeTextAlign({ value: 'left' })}
            >
              <FormatAlignLeftIcon />
            </IconButton>
            <IconButton
              disabled={textAlignValue === 'center'}
              aria-label='center'
              onClick={() => changeTextAlign({ value: 'center' })}
            >
              <FormatAlignCenterIcon />
            </IconButton>
            <IconButton
              disabled={textAlignValue === 'right'}
              aria-label='right'
              onClick={() => changeTextAlign({ value: 'right' })}
            >
              <FormatAlignRightIcon />
            </IconButton>
          </ButtonGroup>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
