import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useEffect, useState } from 'react'

export default function InputTypes () {
  const { selectedElement, changeElement } = useSelectedElements()

  const [inputType, setInputType] = useState('text')

  useEffect(() => {
    if (selectedElement.inputType) {
      setInputType(selectedElement.inputType)
    } else {
      changeElement('inputType', 'text')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <FormControl size='small'>
      <InputLabel id='input-type'>Type</InputLabel>
      <Select
        labelId='input-type'
        id='input-type'
        value={inputType}
        label='Input Type'
        onChange={({ target }) =>
          setInputType((_) => {
            changeElement('inputType', target.value)
            return target.value
          })}
      >
        <MenuItem value='text'>Text</MenuItem>
        <MenuItem value='number'>Number</MenuItem>
        <MenuItem value='tel'>Tel</MenuItem>
        <MenuItem value='email'>Email</MenuItem>
        <MenuItem value='password'>Password</MenuItem>
        <MenuItem value='datetime-local'>Date Time</MenuItem>
        <MenuItem value='date'>Date</MenuItem>
        <MenuItem value='time'>Time</MenuItem>
        <MenuItem value='month'>Month</MenuItem>
      </Select>
    </FormControl>
  )
}
