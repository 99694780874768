import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export default function DynamicSelectInput ({
  id,
  value,
  onChange,
  label,
  options,
  optionsKey = 'key',
  optionsValue = 'value'
}) {
  return (
    <FormControl fullWidth sx={{ mx: '5px' }} size='small'>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        id={id}
        value={value}
        label={label}
        fullWidth
        onChange={({ target }) => onChange(target.value)}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option[optionsKey]} key={option[optionsKey]}>
            {option[optionsValue]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
