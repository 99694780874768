const errorMessages = {
  validationError: {
    email: 'Email is not valid',
    password: 'Password is not valid'
  },
  missingValue: {
    email: 'Email can not be left empty',
    password: 'Password can not be left empty'
  }
}

const EMAIL_REGEX =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const useAuthValidation = () => {
  const validateEmail = (email) => {
    return !EMAIL_REGEX.test(email)
  }

  const validate = (email, password) => {
    const errors = {}
    const hasError = { email: false, password: false }
    if (!email) {
      errors.email = [errorMessages.missingValue.email]
      hasError.email = true
    } else if (validateEmail(email)) {
      errors.email = [errorMessages.validationError.email]
      hasError.email = true
    }
    if (!password) {
      errors.password = [errorMessages.missingValue.password]
      hasError.password = true
    }
    return {
      hasError,
      messages: Object.keys(errors).reduce((messages, key) => {
        return [...messages, ...errors[key]]
      }, [])
    }
  }

  return { validate }
}
