import React from 'react'
import styled from 'styled-components'
import BackgroundColor from './backgroundColor'
import BorderStyleSection from './border-style'
import BorderRadiusSection from './BorderRadiusSection'
import BorderSection from './borderSection'
import ClassNamesSection from './classNameSection'
import DisplayStyleSection from './displayelement'
import FontSection from './fontSection'
import HeightSection from './height'
import MarginSection from './margin'
import PaddingSection from './padding'
import WidthSection from './widthSection'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ShadowSection from './shadowSection'

export default function DimensionsSection () {
  return (
    <DimensionsContainer data-cy='style__section'>
      <ClassNamesSection />
      <Accordion sx={{ width: 320 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          data-cy='padding__section__accordion'
        >
          <Typography>Padding</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PaddingSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: 320 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>shadow</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ShadowSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Background Color</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BackgroundColor />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          data-cy='margin__section__accordion'
        >
          <Typography>Margin</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarginSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          data-cy='height__section__accordion'
        >
          <Typography>Height</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HeightSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          data-cy='width__section__accordion'
        >
          <Typography>Width</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WidthSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          data-cy='border__radius__expand'
        >
          <Typography>Border Radius</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BorderRadiusSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Border Style</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BorderStyleSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Border Width</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BorderSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          data-cy='collapse__font__container'
        >
          <Typography>Font</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FontSection />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          data-cy='display__section__accordion'
        >
          <Typography>Display Style</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DisplayStyleSection />
        </AccordionDetails>
      </Accordion>
    </DimensionsContainer>
  )
}
const DimensionsContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
