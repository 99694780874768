import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material'
import { usePagesConnections } from 'modules/forms-generator/hooks/usePagesConnections'
import { useState } from 'react'
import NorthIcon from '@mui/icons-material/North'
import ForkRightIcon from '@mui/icons-material/ForkRight'
import SpecificPageConnection from './specificPageConnection'
import DynamicPagesConnection from './dynamicPagesConnection'

export default function ConnectionDialogue () {
  const {
    connectionParams,
    openConnectionDialogue,
    handleToggleOpenDialogue,
    setCurrentConnectionParams,
    changeSpecificPageConnection,
    changeDynamicPagesConnection,
    pagesConnection
  } = usePagesConnections()
  const [connectionType, setConnectionType] = useState('static')

  const handleClose = () => {
    handleToggleOpenDialogue()
    setCurrentConnectionParams(null)
  }
  if (connectionParams === null) return null

  const handleChangeCurrentConnection = () => {
    if (connectionType === 'static') {
      changeSpecificPageConnection()
      handleClose()
    }

    if (connectionType === 'dynamic') {
      if (changeDynamicPagesConnection()) {
        handleToggleOpenDialogue()
        setCurrentConnectionParams(null)
      }
    }
  }
  return (
    <Dialog open={openConnectionDialogue} onClose={handleClose}>
      <DialogTitle>Connection Conflicts</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The source page is already connected to another page, the below form
          will help you either change the current connection or make it dynamic
          connection
        </DialogContentText>
        <Divider sx={{ my: '10px' }} />
        <ButtonGroup
          variant='outlined'
          aria-label='Connections Types'
          sx={{ mb: '10px' }}
        >
          <Button
            onClick={() => setConnectionType('static')}
            disabled={connectionType === 'static'}
          >
            <NorthIcon />
            Specific Page
          </Button>
          <Button
            onClick={() => setConnectionType('dynamic')}
            disabled={connectionType === 'dynamic'}
          >
            <ForkRightIcon />
            Dynamic Page
          </Button>
        </ButtonGroup>

        {connectionType === 'static' && (
          <SpecificPageConnection
            sourceName={pagesConnection[connectionParams.source].name}
            currentTargetName={
              pagesConnection[connectionParams.source].targets[0].name
            }
            newTargetName={pagesConnection[connectionParams.target].name}
          />
        )}
        {connectionType === 'dynamic' && (
          <DynamicPagesConnection
            sourceName={pagesConnection[connectionParams.source].name}
            currentTargetsNames={
              pagesConnection[connectionParams.source].targets
            }
            newTargetName={pagesConnection[connectionParams.target].name}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleChangeCurrentConnection}>
          Change Current Connection
        </Button>
      </DialogActions>
    </Dialog>
  )
}
