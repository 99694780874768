import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Resizable } from 're-resizable'
import { useNode, useEditor } from '@craftjs/core'
import useEditorHelpers from '../../hooks/useEditorHelpers'
import { Toolbar } from './Toolbar'
import { useStyles } from '../../hooks/use-styles'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'

export const ElementContainer = ({ children, classname, mustacheTag }) => {
  const currentComponent = 'ElementContainer'
  const defaultTargets = ['visibility', 'click link']
  const [isElementSelected, setIsElementSelected] = useState(false)
  const [elementHeight, setElementHeight] = useState()
  const [elementWidth, setElementWidth] = useState()
  const { ActiveSideBarElements } = useEditorHelpers()
  const { changestyle } = useStylesHelpers()
  const { elementClassName } = useStyles()
  const {
    connectors: { connect, drag },
    actions: { setProp }
  } = useNode()
  const { selectedDevice, styles, tabletStyle, desktopStyle } = useSelector(
    state => state.styles
  )
  const { selected, Selectedstyles } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name
      }
    }

    return {
      selected,
      classes: state.nodes[currentNodeId]?.dom?.classList,
      Selectedstyles: state.nodes[currentNodeId]?.data?.props?.styles
    }
  })

  const setStylesValue = (cssKey, value) => {
    if (elementClassName) {
      changestyle({
        classes: [elementClassName],
        Selectedstyles,
        cssKey,
        value
      })
    }
  }

  const handleClick = () => {
    ActiveSideBarElements('Styles')
    if (selected?.name === currentComponent) {
      setIsElementSelected(true)
    }
  }

  const handleResize = (e, direction, ref, d) => {
    setStylesValue('height', ref.style.height)
    setStylesValue('width', ref.style.width)
    setElementHeight(ref.style.height)
    setElementWidth(ref.style.width)
    setProp(props => (props.elementClassName = [elementClassName]))
  }

  useEffect(() => {
    if (selected?.name === currentComponent) {
      setIsElementSelected(true)
    } else {
      setIsElementSelected(false)
    }
    // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    setProp(props => (props.elementClassName = [elementClassName]))
  })

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setElementHeight(tabletStyle[elementClassName]?.height)
      setElementWidth(tabletStyle[elementClassName]?.width)
    } else if (selectedDevice === 'desktop') {
      setElementHeight(desktopStyle[elementClassName]?.height)
      setElementWidth(desktopStyle[elementClassName]?.width)
    } else {
      setElementHeight(styles[elementClassName]?.height)
      setElementWidth(styles[elementClassName]?.width)
    }
    // eslint-disable-next-line
  }, [selectedDevice])

  useEffect(() => {

  }, [children])

  return (
    <ContainerWrapper
      ref={connect}
      style={{
        width: 'fit-content',
        height: 'fit-content'
      }}
      onClick={handleClick}
      data-cy='container__component'
      isElementSelected={isElementSelected}
      className={classname + ' container_component'}
    >
      <Toolbar
        isElementSelected={isElementSelected}
        defaultTargets={defaultTargets}
        drag={drag}
        mustacheTag={mustacheTag}
      />
      <Resizable
        size={{
          width: elementWidth,
          height: elementHeight
        }}
        onResizeStop={handleResize}
      >
        <Container
          haschildren={children}
          draggable='false'
          data-cy='container'
          className={`${elementClassName} ${classname}`}
          style={styles}
        >
          {children}
        </Container>
      </Resizable>
    </ContainerWrapper>
  )
}

const ContainerWrapper = styled.div`
  outline: ${props =>
    props.isElementSelected
      ? '1px dashed #1a76d2'
      : 'none'};
  &:hover {
    outline: 1px dashed #1a76d2;
  }
`
const Container = styled.div`
  ${props => props.haschildren ? '' : 'height: 100px;'}
  ${props => props.haschildren ? '' : 'width: 100px;'}
`
