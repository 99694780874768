import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const ButtonSetting = () => {
  return <Settings typography dimensions colors border display />
}

export const ButtonComponent = ({ text, className = '' }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  return (
    <button
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {text}
    </button>
  )
}

ButtonComponent.craft = {
  displayName: 'Button',
  props: {
    text: 'Text'
  },
  related: {
    toolbar: ButtonSetting
  }
}
