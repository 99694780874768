import { Box, Button, FormControlLabel, Switch, TextField } from '@mui/material'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import { Handle, Position } from 'reactflow'
import styled from 'styled-components'
import { usePageNode } from 'modules/forms-generator/hooks/usePageNode'

export default function PageNode ({ data, selected, id, ...rest }) {
  const {
    isChanged,
    toggleEditPageSetting,
    isNodeConnected,
    handleDeleteNode,
    handleSaveClick,
    openEdit,
    formState,
    isAutoMode,
    setIsAutoMove,
    handleChange
  } = usePageNode({ id, selected })

  const { className, onLoad, animation, autoMoveNext } = formState

  return (
    <>
      {id !== '0' && <Handle type='target' position={Position.Top} />}
      <PageWrapper isSelected={selected} isChanged={isChanged}>
        <div className='infos'>
          <DisplaySettingsIcon />
          <div className='page__info'>
            <div className='headline'>Page Name:</div>
            <div className='content'>{data.name}</div>
          </div>
          <Button
            variant='text'
            className='edit__button'
            onClick={toggleEditPageSetting}
          >
            Edit
          </Button>
          <Button
            variant='text'
            className='delete__button'
            disabled={isNodeConnected}
            onClick={handleDeleteNode}
          >
            Delete
          </Button>
        </div>
        {selected && openEdit && (
          <PageSettingWrapper>
            <TextField
              label='ClassName'
              id='outlined-size-small'
              value={className}
              onChange={({ target }) =>
                handleChange({ key: 'className', value: target.value })}
              size='small'
            />
            <TextField
              label='on load custom event'
              id='outlined-size-small'
              value={onLoad}
              onChange={({ target }) =>
                handleChange({ key: 'onLoad', value: target.value })}
              size='small'
            />
            <select
              id='animation-select'
              value={animation}
              onChange={({ target }) =>
                handleChange({ key: 'animation', value: target.value })}
            >
              <option value=''>Without Animation</option>
              <option value='slide'>Slide Animation</option>
              <option value='fade'>Fade Animation</option>
            </select>
            <div className='auto__move'>
              <FormControlLabel
                control={
                  <Switch
                    value={isAutoMode}
                    onChange={(_, checked) => {
                      setIsAutoMove(checked)
                      if (!checked) { handleChange({ key: 'autoMoveNext', value: 0 }) }
                    }}
                  />
                }
                label='Auto Move'
              />
              {isAutoMode && (
                <TextField
                  className='auto__move__field'
                  label='Seconds before moving'
                  id='auto-move'
                  type='number'
                  value={autoMoveNext}
                  onChange={({ target }) =>
                    handleChange({ key: 'autoMoveNext', value: target.value })}
                  size='small'
                />
              )}
            </div>

            <Button
              variant='contained'
              fullWidth
              sx={{ mt: '10px' }}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </PageSettingWrapper>
        )}
      </PageWrapper>
      <Handle type='source' position={Position.Bottom} id='a' />
    </>
  )
}

const PageWrapper = styled(Box)`
  min-width: 450px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px 10px;
  transition: all 0.2s ease;
  box-shadow: ${(props) =>
    props.isSelected
      ? 'none'
      : `rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px`};
  box-sizing: border-box;
  outline: ${(props) =>
    props.isChanged
      ? '2px dashed #e74c3c'
      : props.isSelected
      ? '2px dashed #badc58'
      : 'none'};
  .infos {
    display: flex;
    align-items: center;
  }
  .page__info {
    margin-left: 10px;
    flex: 1;
  }

  .headline {
    color: #c3c7cb;
    text-transform: uppercase;
    font-size: x-small;
  }
  .content {
    font-weight: bold;
  }
  .edit__button,
  .delete__button {
    text-transform: capitalize;
    margin-left: 10px;
  }

  .delete__button {
    color: #e74c3c;
  }
  .delete__button:disabled {
    color: #95a5a6;
  }
`

const PageSettingWrapper = styled(Box)`
  width: 100%;
  margin-top: 5px;
  padding: 20px 0;
  border-top: 1px dashed #badc58;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
  .auto__move {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .auto__move__field {
      flex: 1;
      width: 100%;
    }
  }
`
