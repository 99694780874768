import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  css: {},
  tabletCss: {},
  desktopCss: {},
  plainCss: '',
  selectedSelector: undefined,
  googleFonts: [],
  device: 'mobile'
}

export const formCssSlice = createSlice({
  name: 'formCss',
  initialState,
  reducers: {
    selectSelector: (state, { payload }) => {
      state.selectedSelector = payload
      if (!state.css[payload]) state.css[payload] = {}
      if (!state.tabletCss[payload]) state.tabletCss[payload] = {}
      if (!state.desktopCss[payload]) state.desktopCss[payload] = {}
    },
    updateKey: (state, { payload }) => {
      const { key, value, unit } = payload
      if (!state.css[state.selectedSelector][key]) {
        state.css[state.selectedSelector][key] = {}
      }
      if (!state.tabletCss[state.selectedSelector][key]) {
        state.tabletCss[state.selectedSelector][key] = {}
      }
      if (!state.desktopCss[state.selectedSelector][key]) {
        state.desktopCss[state.selectedSelector][key] = {}
      }
      switch (state.device) {
        case 'mobile':
          state.css[state.selectedSelector][key] = { value, unit }
          break
        case 'tablet':
          state.tabletCss[state.selectedSelector][key] = { value, unit }
          break
        case 'desktop':
          state.desktopCss[state.selectedSelector][key] = { value, unit }
          break

        default:
          break
      }
    },
    changePlainCss: (state, { payload }) => {
      state.plainCss = payload
    },
    addNewGoogleFont: (state, { payload }) => {
      const exist = state.googleFonts.find(
        (font) => font.family === payload.family
      )
      if (!exist) {
        state.googleFonts.push(payload)
      }
    },
    loadCssConfig: (state, { payload }) => {
      console.log({ payload })
      state.css = payload.formCss ? payload.formCss : {}
      state.css = payload.css ? payload.css : {}
      state.plainCss = payload.plainCss ? payload.plainCss : ''
      state.desktopCss = payload.desktopCss ? payload.desktopCss : {}
      state.tabletCss = payload.tabletCss ? payload.tabletCss : {}
      state.googleFonts = payload.googleFonts ? payload.googleFonts : []
    },
    changeDevice: (state, { payload }) => {
      state.device = payload
    },
    pushNewStyles: (state, { payload }) => {
      state.css = { ...state.css, ...payload.css }
      state.tabletCss = { ...state.tabletCss, ...payload.tabletCss }
      state.desktopCss = { ...state.desktopCss, ...payload.desktopCss }
    }
  }
})

export const {
  selectSelector,
  updateKey,
  changePlainCss,
  addNewGoogleFont,
  loadCssConfig,
  changeDevice,
  pushNewStyles
} = formCssSlice.actions
export const useFormCssState = () => useSelector((state) => state.formCss)
export default formCssSlice.reducer
