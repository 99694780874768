import { Box } from '@mui/material'
import { usePagesFlow } from 'modules/forms-generator/hooks/usePagesFlow'
import ReactFlow, { Background, Controls, MiniMap } from 'reactflow'
import 'reactflow/dist/style.css'
import NextPageEdge from './nextPageEdge'
import PageNode from './pageNode'

const nodeTypes = { pageNode: PageNode }
const edgeTypes = { nextPageEdge: NextPageEdge }

export default function PagesFlow () {
  const { nodes, edges, onNodesChange, onConnect, onEdgesChange } =
    usePagesFlow()

  return (
    <Box sx={{ height: '100%', flex: '0 0 50%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      >
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </Box>
  )
}
