import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import ContentEditable from 'react-contenteditable'
import { Settings } from '../../settings'
import { useDispatch } from 'react-redux'
import { changeValue } from 'state/slices/formsGenerator/builderSlice'
import { useState } from 'react'

const TitleSetting = () => {
  return <Settings border colors dimensions display typography />
}
export const TitleComponent = ({ text, className = '', customId }) => {
  const [textValue, setTextValue] = useState(text)
  const dispatch = useDispatch()
  const {
    id,
    connectors: { connect, drag }
  } = useCraftNode()

  const handleChangeElement = ({ target }) => {
    setTextValue(target.value)
    dispatch(changeValue({ id: customId, key: 'value', value: target.value }))
  }

  return (
    <ContentEditable
      innerRef={(ref) => connect(drag(ref))}
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      html={textValue}
      data-element-id={id}
      data-custom-id={customId}
      tagName='h2'
      onChange={handleChangeElement}
    />
  )
}

TitleComponent.craft = {
  displayName: 'Title',
  props: {
    text: 'Text'
  },
  related: {
    toolbar: TitleSetting
  }
}
