import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const RangeInputSetting = () => {
  return <Settings typography dimensions colors border />
}

export const RangeInput = ({ value, handleChange, suggestions }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <input
      readOnly
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      type='range'
      min={0}
      max={suggestions.length - 1}
      step={1}
      value={value}
      onChange={handleChange}
    />
  )
}

RangeInput.craft = {
  displayName: 'Range Input',
  related: {
    toolbar: RangeInputSetting
  }
}
