import { Box } from '@mui/material'
import { useState } from 'react'
import Editor from './editor'
import LayoutList from './layoutsList'

export default function LayoutManagementsMode () {
  const [selectedLayout, setSelectedLayout] = useState(null)
  return (
    <Box
      sx={{
        borderTop: '1px solid #dedede',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }}
    >
      <Box
        sx={{
          height: '100%',
          backgroundColor: '#eeeeee',
          overflowY: 'scroll',
          position: 'relative',
          display: 'flex',
          p: '5px'
        }}
      >
        <LayoutList onSelectLayoutToEdit={setSelectedLayout} />
        <Editor
          layoutToEdit={selectedLayout}
          mode={selectedLayout ? 'edit' : 'add'}
          onCancel={() => setSelectedLayout(null)}
        />
      </Box>
    </Box>
  )
}
