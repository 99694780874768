import { useEditor } from '@craftjs/core'
import { ROOT_NODE } from '@craftjs/utils'
import { useFormCss } from 'modules/forms-generator/hooks/useFormCss'
import React, { useEffect, useState } from 'react'
import { Layers } from '@craftjs/layers'
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import CodeMirror from '@uiw/react-codemirror'
import { css } from '@codemirror/lang-css'
import { useFormCssState } from 'state/slices/formsGenerator/formCssSlice'
import { WRAPPER_ID } from '../wrapper'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `side-bar-tab-${index}`,
    'aria-controls': `side-bar-tabpanel-${index}`
  }
}

export default function Toolbar () {
  const { active, related, props, dom, parentDom } = useEditor(
    (state, query) => {
      const currentlySelectedNodeId = query.getEvent('selected').first()
      return {
        active: currentlySelectedNodeId,
        selectedNode: state.nodes[currentlySelectedNodeId]
          ? query.node(currentlySelectedNodeId)
          : undefined,
        related:
          currentlySelectedNodeId &&
          state.nodes[currentlySelectedNodeId].related,
        props: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].data.props
          : undefined,
        dom: state.nodes[currentlySelectedNodeId]
          ? document
              .getElementById(WRAPPER_ID)
              .contentWindow.document.querySelector(
                `[data-element-id="${currentlySelectedNodeId}"]`
              )
          : undefined,

        parent: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].data.parent
          : undefined,
        parentDom:
          state.nodes[currentlySelectedNodeId] &&
          state.nodes[currentlySelectedNodeId].data.parent
            ? state.nodes[state.nodes[currentlySelectedNodeId].data.parent].dom
            : undefined,
        rootProps: query.node(ROOT_NODE).get()?.data?.props,
        isRoot: ROOT_NODE === currentlySelectedNodeId
      }
    }
  )
  const { elementSelected, changeCss } = useFormCss()
  const { plainCss } = useFormCssState()
  const [value, setValue] = React.useState(1)
  const [currentClassName, setCurrentClassName] = useState('')
  const [allClassNames, setAllClassnames] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (props && dom) {
      let splittedClassnames
      if (props.className) {
        splittedClassnames = props.className
          .split(' ')
          .map((className) => `.${className}`)
      } else {
        splittedClassnames = [
          `${parentDom ? `.${parentDom.className}` : ''} ${dom.localName}`
        ]
      }
      setCurrentClassName(splittedClassnames[0])
      setAllClassnames(splittedClassnames)
      elementSelected({
        className: splittedClassnames[0],
        elementTag: dom?.localName,
        parentHasError: parentDom
          ? parentDom.classList.contains('with__error')
          : false,
        parentClassName: parentDom ? parentDom.className : ''
      })
    }
    // eslint-disable-next-line
  }, [active, related, props, dom, parentDom])

  useEffect(() => {
    if (currentClassName && allClassNames.length > 0) {
      elementSelected({
        className: currentClassName,
        elementTag: dom?.localName,
        parentHasError: parentDom
          ? parentDom.classList.contains('with__error')
          : false,
        parentClassName: parentDom ? parentDom.className : ''
      })
    }
    // eslint-disable-next-line
  }, [currentClassName, allClassNames])

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Layers' {...a11yProps(0)} />
          <Tab label='Styles' {...a11yProps(1)} />
          <Tab label='CSS Editor' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
        <TabPanel value={value} index={1}>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
          >
            {active && related.toolbar && (
              <>
                <ButtonGroup
                  variant='outlined'
                  aria-label='outlined button group'
                >
                  {allClassNames.map((classname, idx) => (
                    <Button
                      key={idx}
                      sx={{ textTransform: 'none' }}
                      variant={
                        currentClassName === classname
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() => {
                        setCurrentClassName(classname)
                      }}
                    >
                      {classname}
                    </Button>
                  ))}
                </ButtonGroup>
                <Divider sx={{ my: 2 }} />
                {React.createElement(related.toolbar)}
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={0}>
          <Box>
            <Layers expandRootOnLoad={false} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <CodeMirror
              value={plainCss}
              onChange={(value) => changeCss(value)}
              height='100%'
              width='100%'
              extensions={[css()]}
            />
          </Box>
        </TabPanel>
      </Box>
    </>
  )
}
