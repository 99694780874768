import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Sidebar from './sidebar'
import { Text } from '../../components/User/Text'
import { Container } from '../../components/User/Container'
import { Image } from '../../components/User/Image'
import { ZoneContainer } from '../../components/User/ZoneContainer'
import { AdContainer } from '../../components/User/AdContainer'
import { Editor as EditorWrapper, Frame, Element } from '@craftjs/core'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'
import { Bullets } from '../../components/User/Bullets'
import { Headline } from '../../components/User/Headline'
import { Cta } from '../../components/User/Cta'
import { ElementContainer } from 'components/User/ElementContainer'
import { ListingLayout } from 'components/User/layouts/listing-layouts/ListingLayout'
import { Option1Numbered } from 'components/User/layouts/listing-layouts/0ption1Numbered'
import { Option2ListingLayout } from 'components/User/layouts/listing-layouts/Option2ListingLayout'
import { Option2Numbered } from 'components/User/layouts/listing-layouts/Option2Numbered'
import { Option3ListingLayout } from 'components/User/layouts/listing-layouts/Option3ListingLayout'
import { Option3Numbered } from 'components/User/layouts/listing-layouts/Option3Numbered'
import { Layout1 } from '../../components/User/layouts/Layout1'
import { ListicleLayout } from '../../components/User/layouts/listicle/listicleLayout'
import { Layout2 } from '../../components/User/layouts/Layout2'
import { Layout3 } from '../../components/User/layouts/Layout3'
import { Layout4 } from '../../components/User/layouts/Layout4'
import { EmptyLayout } from '../../components/User/layouts/EmptyLayout'
import { ExportCode } from './ExportCode'
import { Cssmodal } from './cssModal'
import { JsModal } from './jsModal'
import TabletIcon from '@mui/icons-material/Tablet'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import DesktopMacIcon from '@mui/icons-material/DesktopMac'

import { Link } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import useAuth from 'modules/lincx-auth/use-auth'

const zoneContainerStyle = {
  boxSizing: 'border-box',
  padding: '10px',
  margin: 'auto'
}

export default function BuilderZone () {
  const [device, setDevice] = useState('desktop')
  const [deviceWidth, setDeviceWidth] = useState(1024)
  const { changedevice } = useStylesHelpers()
  const { navigateIfNotLoggedIn } = useAuth()

  const handleResponsiveChange = (value) => {
    setDevice(value)
    switch (value) {
      case 'mobile':
        setDeviceWidth(400)
        break
      case 'tablet':
        setDeviceWidth(768)
        break
      default:
        setDeviceWidth(1024)
        break
    }
  }

  useEffect(() => {
    changedevice(device)
  }, [device, changedevice])

  useEffect(() => {
    navigateIfNotLoggedIn('/login')
  // eslint-disable-next-line
  }, [])

  return (
    <>
      <div style={{ position: 'absolute' }}>
        <Link to='/forms'>Forms</Link>
      </div>
      <ViewSelector>
        <BottomNavigation
          sx={{ borderColor: '#4285f4', border: 0.01 }}
          showLabels
          value={device}
          onChange={(event, newValue) => {
            handleResponsiveChange(newValue)
          }}
        >
          <BottomNavigationAction
            label='mobile'
            value='mobile'
            icon={<PhoneIphoneIcon />}
            data-cy='mobile__device'
          />
          <BottomNavigationAction
            label='tablet'
            value='tablet'
            icon={<TabletIcon />}
            data-cy='tablet__device'
          />
          <BottomNavigationAction
            label='desktop'
            value='desktop'
            icon={<DesktopMacIcon />}
            data-cy='desktop__device'
          />
        </BottomNavigation>
      </ViewSelector>
      <EditorWrapper
        resolver={{
          ZoneContainer,
          AdContainer,
          Text,
          Container,
          Image,
          Bullets,
          Headline,
          Cta,
          ElementContainer,
          ListicleLayout,
          ListingLayout,
          Option1Numbered,
          Option2ListingLayout,
          Option2Numbered,
          Option3ListingLayout,
          Option3Numbered,
          Layout1,
          Layout2,
          Layout3,
          Layout4,
          EmptyLayout
        }}
        canvas
      >
        <FrameContainer>
          <EditorContainer>
            <div
              data-cy='template__editor'
              style={{
                width: deviceWidth,
                position: 'relative',
                overflow: 'scroll',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: '4px solid #dedede',
                marginTop: '64px',
                background: '#4444',
                marginBottom: '64px'
              }}
            >
              <Frame>
                <Element is={ZoneContainer} canvas styles={zoneContainerStyle}>
                  <Element is={Layout1} canvas />
                </Element>
              </Frame>
            </div>
          </EditorContainer>
          <Sidebar />
          <ExportCode />
          <Cssmodal />
          <JsModal />
        </FrameContainer>
      </EditorWrapper>
    </>
  )
}
const FrameContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`
const EditorContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background: #f7f7f7;
`
const ViewSelector = styled.div`
  position: absolute;
  top: 2px;
  right: 52%;
  display: flex;
`
