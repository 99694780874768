import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

export default function ElementCustomFunctionForm ({
  functionsList,
  currentValue,
  idx,
  onChange,
  keysArray,
  onRemove
}) {
  return (
    <Box sx={{ display: 'flex', gap: 3, my: 2 }}>
      <FormControl sx={{ m: 1, flex: 1 }} size='small'>
        <InputLabel id={`custom_function_label_${idx}`}>Function</InputLabel>
        <Select
          labelId={`custom_function_label_${idx}`}
          id={`custom_function_label_${idx}`}
          label='Function Name'
          value={currentValue.name}
          onChange={({ target: { value } }) => onChange(idx, value, 'name')}
        >
          {functionsList.map(({ name }, idx) => (
            <MenuItem value={name} key={idx}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Autocomplete
        disablePortal
        multiple
        value={currentValue.params}
        options={keysArray}
        sx={{ ml: '5px', flex: 1 }}
        onChange={(_, values) => onChange(idx, values, 'params')}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Choose keys to be passed to the above function in order'
          />
        )}
      />

      <FormControlLabel
        control={<Switch />}
        label='is Async'
        checked={currentValue.shouldBeAwaited}
        onChange={(_, checked) => onChange(idx, checked, 'shouldBeAwaited')}
      />
      <IconButton aria-label='delete' size='small' onClick={onRemove}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    </Box>
  )
}
