import { ELEMENTS_TYPES } from 'modules/utils/elementsTypes'

export const ValidationsOptions = {
  REQUIRED: 'REQUIRED',
  USA_ZIP_CODE: 'USA_ZIP_CODE',
  REGEX: 'REGEX',
  MORE_THAN: 'MORE_THAN',
  LESS_THAN: 'MORE_THAN'
}

export const ELEMENTS_VALIDATIONS = {
  [ELEMENTS_TYPES.RADIO_BUTTONS]: [ValidationsOptions.REQUIRED],
  [ELEMENTS_TYPES.RANGE_SLIDER]: [
    ValidationsOptions.REQUIRED,
    ValidationsOptions.MORE_THAN
  ],
  [ELEMENTS_TYPES.DROPDOWN_SELECT]: [ValidationsOptions.REQUIRED],
  [ELEMENTS_TYPES.FORM_TEXT]: [
    ValidationsOptions.REQUIRED,
    ValidationsOptions.REGEX,
    ValidationsOptions.USA_ZIP_CODE
  ],
  [ELEMENTS_TYPES.FORM_PHONE]: [
    ValidationsOptions.REQUIRED,
    ValidationsOptions.REGEX
  ],
  [ELEMENTS_TYPES.FORM_ERN_ADDRESS]: [
    ValidationsOptions.REQUIRED,
    ValidationsOptions.REGEX
  ]
}
