import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const InputContainerSetting = () => {
  return <Settings typography dimensions colors border display />
}

export const InputContainerComponent = ({ children, className = '' }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  return (
    <div
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {children}
    </div>
  )
}

InputContainerComponent.craft = {
  displayName: 'RadioInputContainer',
  props: {
    text: 'Text'
  },
  rules: {
    canDrag: () => true
  },
  related: {
    toolbar: InputContainerSetting
  }
}
