import { Box, Chip } from '@mui/material'

const Filters = [
  { id: 1, label: 'All', key: 'all' },
  { id: 2, label: 'Contact Form', key: 'contact' },
  { id: 3, label: 'Real Estate Forms', key: 'realEstate' },
  { id: 4, label: 'Loan Forms', key: 'loan' },
  { id: 5, label: 'Insurance Forms', key: 'insurance' }
]

export default function FilterChips ({ onChange, currentSelectedFilter }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px'
      }}
    >
      {Filters.map(({ id, label, key }) => (
        <Chip
          key={id}
          label={label}
          onClick={() => onChange(key)}
          sx={{ bgcolor: currentSelectedFilter === key && 'AppWorkspace' }}
        />
      ))}
    </Box>
  )
}
