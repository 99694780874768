import { Tab, Tabs } from '@mui/material'
import HandymanIcon from '@mui/icons-material/Handyman'
import CodeIcon from '@mui/icons-material/Code'
import DnsIcon from '@mui/icons-material/Dns'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'
import useFormsLayout from '../../hooks/useFormsLayouts'

export default function ModeTabs () {
  const { selectedMode, pages } = useFormsBuilderState()
  const { changeBuilderMode } = useFormsLayout()

  const handleModeChange = (_, newValue) => {
    changeBuilderMode(newValue)
  }
  return (
    <Tabs
      value={selectedMode}
      onChange={handleModeChange}
      aria-label='icon label tabs'
      centered
    >
      <Tab value='builder' icon={<HandymanIcon />} label='FORM BUILDER' />
      <Tab
        value='layouts'
        icon={<CodeIcon />}
        label='LAYOUTS MANAGEMENTS'
      />
      <Tab
        value='functions'
        icon={<CodeIcon />}
        label='JAVASCRIPT FUNCTIONS'
      />
      <Tab
        value='preview'
        icon={<DnsIcon />}
        label='PREVIEW'
        disabled={pages.length === 0}
      />
    </Tabs>
  )
}
