import { useCraftNode } from 'hooks/useCraftNode'
import { Settings } from '../../settings'

export const CheckBoxComponentSetting = () => {
  return <Settings typography dimensions colors border display withError />
}

export const CheckBoxComponent = ({ className = '', content }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <div
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
    >
      <label>
        <input type='checkbox' />
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </label>
    </div>
  )
}

CheckBoxComponent.craft = {
  displayName: 'CheckBox',
  props: {
    className: '',
    content: ''
  },
  related: {
    toolbar: CheckBoxComponentSetting
  }
}
