import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'
import { ContainerWrapper } from './ContainerWrapper'
import SpacingInput from './input'
import PaddingHandlers from './padding'

export default function MarginHandlers () {
  const [marginTop, setMarginTop] = useCssKeyValue({
    key: 'margin-top',
    value: '0',
    unit: 'px'
  })
  const [marginLeft, setMarginLeft] = useCssKeyValue({
    key: 'margin-left',
    value: '0',
    unit: 'px'
  })
  const [marginRight, setMarginRight] = useCssKeyValue({
    key: 'margin-right',
    value: '0',
    unit: 'px'
  })
  const [marginBottom, setMarginBottom] = useCssKeyValue({
    key: 'margin-bottom',
    value: '0',
    unit: 'px'
  })
  return (
    <ContainerWrapper className='margin'>
      <div className='top'>
        <SpacingInput
          value={marginTop}
          onChange={({ target }) => setMarginTop({ value: target.value })}
          onAutoClick={() => setMarginTop({ value: 'auto' })}
          className='fullwidth'
        />
      </div>
      <div className='left-right'>
        <SpacingInput
          value={marginLeft}
          onChange={({ target }) => setMarginLeft({ value: target.value })}
          onAutoClick={() => setMarginLeft({ value: 'auto' })}
        />
        <PaddingHandlers />
        <SpacingInput
          value={marginRight}
          onChange={({ target }) => setMarginRight({ value: target.value })}
          onAutoClick={() => setMarginRight({ value: 'auto' })}
        />
      </div>
      <div className='bottom'>
        <SpacingInput
          value={marginBottom}
          onChange={({ target }) => setMarginBottom({ value: target.value })}
          onAutoClick={() => setMarginBottom({ value: 'auto' })}
          className='fullwidth'
        />
      </div>
      <div className='margin-label'>Margin</div>
    </ContainerWrapper>
  )
}
