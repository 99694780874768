import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const RangeInputSlideSetting = () => {
  return <Settings typography dimensions colors border />
}

export const RangeInputSlide = ({ rangePercent }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <span
      style={{ width: `${rangePercent}%` }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      className='range__input--slide'
    />
  )
}

RangeInputSlide.craft = {
  displayName: 'Range Input Slide',
  related: {
    toolbar: RangeInputSlideSetting
  }
}
