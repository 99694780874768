import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import Logo from 'components/logo'
import { useAuthValidation } from './use-auth-validation'
import useAuth from './use-auth'
import { useNavigate } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'

export default function LincxSignIn ({ coverSrc }) {
  const { validate } = useAuthValidation()
  const { login, isLoggedIn } = useAuth()
  const [email, setEmail] = useState('')
  const [fieldErrors, setFieldError] = useState({})
  const [password, setPassword] = useState('')
  const [open, setOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/forms')
    }
  // eslint-disable-next-line
  }, [isLoggedIn])

  const onSubmit = (e) => {
    e.preventDefault()
    const { hasError, messages } = validate(email, password)
    if (messages.length) {
      setSnackbarMessage(messages.join(', '))
      setOpen(true)
      setFieldError(hasError)
      return
    }
    login({ email, password }, (err) => {
      if (err) {
        setSnackbarMessage(err.error)
        setOpen(true)
        return
      }
      navigate('/forms')
    })
  }
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0} display='flex'>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  backgroundImage: `url(${coverSrc})`,
                  height: '100vh',
                  backgroundSize: 'cover',
                  backgroundPosition: 'bottom center',
                  position: 'relative',
                  boxSizing: 'border-box'
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ backgroundColor: '#ffffff', position: 'relative' }}
            >
              <FormContainer onSubmit={onSubmit}>
                <Logo />
                <FormControl
                  fullWidth
                  sx={{ mt: '20px' }}
                  error={fieldErrors.email}
                >
                  <TextField
                    error={fieldErrors.email}
                    size='small'
                    required={false}
                    label='Email'
                    placeholder='Enter email'
                    name='email'
                    type='text'
                    margin='normal'
                    fullWidth
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ mt: '20px' }}
                  error={fieldErrors.password}
                >
                  <TextField
                    error={fieldErrors.password}
                    size='small'
                    placeholder='Enter password'
                    name='password'
                    fullWidth
                    label='Password'
                    type='password'
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                  />
                </FormControl>
                <FormGroup
                  sx={{
                    mt: '20px',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <Button
                    type='submit'
                    name='signIn'
                    variant='contained'
                    color='primary'
                    onClick={onSubmit}
                  >
                    Sign In
                  </Button>
                </FormGroup>
              </FormContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarMessage('')
          setOpen(false)
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarMessage('')
            setOpen(false)
          }}
          severity='error'
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
`
