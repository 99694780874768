import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const RadioInputSetting = () => {
  return <Settings typography dimensions colors border display />
}

export const RadioInputComponent = ({ className = '', questionKey, value, validations }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  return (
    <input
      type='radio'
      name={questionKey}
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      id={`${questionKey}_${value}`}
    />
  )
}

RadioInputComponent.craft = {
  displayName: 'RadioInput',
  props: {
    text: 'Text'
  },
  related: {
    toolbar: RadioInputSetting
  }
}
