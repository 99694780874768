import React, { useEffect, useRef } from 'react'
import { Image } from '../../Image'
import { Cta } from '../../Cta'
import { Element } from '@craftjs/core'
import { AdContainer } from '../../AdContainer'
import { ElementContainer } from 'components/User/ElementContainer'
import { Bullets } from 'components/User/Bullets'
import useEditorHelpers from 'hooks/useEditorHelpers'
import { Headline } from 'components/User/Headline'

export const Option3Numbered = () => {
  const ref = useRef(true)
  const offerFeatures = [
    'Cut My House Payment - Saving Homeowners Money Every Month',
    'Breaking: HARP Replaced with New Program',
    'Take this 60 Second Quiz to Calculate Your Savings',
    'See if you qualify before time runs out. We only need a few pice of information to ley you know'
  ]

  const listingOfferCAG = {
    headline: [{ name: 'headline', target: 'content' }],
    image_CAG: [{ name: '{{ src }}', target: 'src' }],
    bullets_CAG: [{ name: 'bulletpoints', target: 'list items' }],
    cta_CAG: [
      { name: '{{ cta_text }}', target: 'content' },
      { name: '{{ href }}', target: 'href' }
    ],
    phone_CAG: [{ name: '{{ phone }}', target: 'content' }]
  }

  const { createListOfBullets } = useEditorHelpers()

  useEffect(() => {
    const firstRender = ref.current
    if (firstRender) {
      ref.current = false
      createListOfBullets(offerFeatures)
    }
  })

  return (
    <Element
      id='ad-container'
      is={AdContainer}
      canvas
      classname='main__offer__container'
    >
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          marginLeft: '-25px',
          width: '25px',
          height: '25px',
          background: '#808080',
          color: '#ffffff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        className={{ name: ['offer__counter'] }}
      >
        1
      </div>
      <ElementContainer classname='listing__offer__container'>
        <Image
          imgSource='https://mma.prnewswire.com/media/1880066/SimpliSafe_logo.jpg?p=facebook'
          imgName='image-icon'
          classname='listing__offer__image'
          mustacheTag={listingOfferCAG.image_CAG}
        />
        <ElementContainer classname='listing__offer__headline__container'>
          <Headline
            classname='listing__offer__headline'
            text='SimpliSafe Security'
            mustacheTag={listingOfferCAG.headline}
          />
          <Bullets
            classname='listing__offer__features'
            mustacheTag={listingOfferCAG.bullets_CAG}
          />
        </ElementContainer>
        <ElementContainer classname='listing__offer__cta__container'>
          <Cta
            classname='listing__offer__phone'
            ctaText='(866) 358-9898'
            mustacheTag={listingOfferCAG.phone_CAG}
          />
          <Cta
            classname='listing__offer__cta'
            ctaText='View Offer'
            mustacheTag={listingOfferCAG.cta_CAG}
          />
        </ElementContainer>
      </ElementContainer>
    </Element>
  )
}
