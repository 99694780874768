import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useValidations } from 'modules/forms-generator/hooks/useValidations'
import { useEffect, useState } from 'react'

export default function RequiredValidation () {
  const {
    errors,
    setErrorMessage: setSelectedElementErrorMessage,
    clearError
  } = useSelectedElements()
  const { changeValidation, getValidations } = useValidations()

  const [required, setRequired] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const handleChange = ({ validationName, key, value }) => {
    changeValidation({ validationName, key, value })
  }

  useEffect(() => {
    const alreadyRequiredData = getValidations('required')
    if (alreadyRequiredData) {
      setRequired(alreadyRequiredData.value)
      setErrorMessage(alreadyRequiredData.message)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (required && !errorMessage) {
      setSelectedElementErrorMessage({
        key: 'Required',
        message: 'Required Validation'
      })
    } else {
      clearError('Required')
    }

    return () => {
      clearError('Required')
    }
    // eslint-disable-next-line
  }, [errorMessage, required])
  return (
    <Box sx={{ display: 'flex', my: '10px', width: '100%' }}>
      <FormControlLabel
        onChange={(_, checked) => {
          setRequired(checked)
          handleChange({
            validationName: 'required',
            key: 'value',
            value: checked
          })
        }}
        checked={required}
        control={<Checkbox />}
        label='Add Required Validation'
      />
      {required && (
        <TextField
          label='Validation error message'
          sx={{ flex: 1 }}
          value={errorMessage}
          helperText={errors.Required}
          error={errors.Required !== true}
          onChange={({ target }) => {
            setErrorMessage(target.value)
            handleChange({
              validationName: 'required',
              key: 'message',
              value: target.value
            })
          }}
        />
      )}
    </Box>
  )
}
