import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider
} from '@mui/material'

export default function SliderConfig ({
  value,
  onChange,
  label,
  withAuto = false,
  max = 300,
  withUnit = 'px',
  onChangeUnit
}) {
  return (
    <div>
      <label htmlFor='marginTop'>{label}</label>
      <Slider
        size='small'
        defaultValue={value}
        value={value !== 'auto' ? value : 0}
        aria-label='Small'
        valueLabelDisplay='auto'
        min={0}
        max={withUnit === '%' ? 100 : max}
        onChange={(_, currentValue) => onChange(currentValue)}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {withAuto && (
          <Button
            variant='outlined'
            onClick={() => onChange('auto')}
            disabled={value === 'auto'}
          >
            auto
          </Button>
        )}
        {withUnit && (
          <FormControl size='small'>
            <InputLabel id='unit-label'>Unit</InputLabel>
            <Select
              labelId='unit-label'
              id='demo-simple-select'
              value={withUnit}
              label='Age'
              onChange={({ target }) => onChangeUnit(target.value)}
            >
              <MenuItem value='px'>px</MenuItem>
              <MenuItem value='%'>%</MenuItem>
              <MenuItem value='em'>em</MenuItem>
              <MenuItem value='rem'>rem</MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
    </div>
  )
}
