import './App.css'
import BuilderZone from './modules/builder-zone'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import FormsGenerator from './pages/forms-generator'
import LincxSignIn from 'modules/lincx-auth'

function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<BuilderZone />}
        />
        <Route
          path='forms'
          element={<FormsGenerator />}
        />
        <Route
          path='login'
          element={
            <LincxSignIn coverSrc='https://catalog.adverse.lincx.la/static/media/Sign-In.df14184e.jpg' />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
