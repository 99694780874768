import styled from 'styled-components'
import { useEditor } from '@craftjs/core'

export const DeleteElement = () => {
  const { actions, selected, nextNodeId } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    const listOfNodesId = Object.keys(state.nodes).filter(node => (node !== 'ROOT'))
    const nextNodeId = listOfNodesId[0]
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected,
      nextNodeId
    }
  })
  const handleClick = () => {
    actions.selectNode(nextNodeId)
    actions.delete(selected.id)
  }
  return (
    <DeleteElementContainer onClick={handleClick} data-cy='delete__button'>
      <img
        src='https://img.icons8.com/carbon-copy/100/000000/filled-trash.png'
        alt='icon-delete'
      />
    </DeleteElementContainer>
  )
}

const DeleteElementContainer = styled.span`
  font-size: 23px;
  padding: 5px;
  display: flex;
`
