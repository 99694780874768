import { v4 } from 'uuid'

export const EmailElementData = {
  kind: 'CONTAINER',
  className: 'email__container form__container',
  keys: ['email'],
  id: v4(),
  children: [
    {
      id: v4(),
      kind: 'TEXT',
      type: 'PARAGRAPH',
      className: 'email__label form__label',
      value: 'Email'
    },
    {
      id: v4(),
      kind: 'FORM',
      type: 'TEXT',
      className: 'email__input',
      placeholder: 'Enter your email address',
      key: 'email',
      validations: {
        required: {
          value: true,
          message: 'Email address is required'
        },
        regex: {
          regex: '/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/',
          message: 'Please Enter a valid email address'
        }
      }
    }
  ]
}
export const EmailElementStyles = {
  css: {
    'div.email__input': {
      'margin-left': {
        value: '10',
        unit: 'px'
      },
      'margin-right': {
        value: '10',
        unit: 'px'
      },
      'margin-bottom': {
        value: '10',
        unit: 'px'
      }
    },
    'p.email__label': {
      'margin-left': {
        value: '10',
        unit: 'px'
      },
      'margin-right': {
        value: '10',
        unit: 'px'
      },
      'margin-bottom': {
        value: '10',
        unit: 'px'
      }
    },
    'div.email__container': {
      'margin-left': {
        value: '10',
        unit: 'px'
      },
      'margin-right': {
        value: '10',
        unit: 'px'
      },
      'margin-top': {
        value: '10',
        unit: 'px'
      },
      'margin-bottom': {
        value: '10',
        unit: 'px'
      },
      'font-family': {
        value: 'Roboto'
      },
      'font-size': {
        value: 12,
        unit: 'px'
      }
    }
  },
  tabletCss: {
    'div.email__input': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'p.email__label': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'div.email__container': {
      'margin-left': {},
      'margin-right': {},
      'margin-top': {},
      'margin-bottom': {},
      'font-family': {},
      'font-size': {}
    }
  },
  desktopCss: {
    'div.email__input': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'p.email__label': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'div.email__container': {
      'margin-left': {},
      'margin-right': {},
      'margin-top': {},
      'margin-bottom': {},
      'font-family': {},
      'font-size': {}
    }
  }
}
