import { zipCodePredefinedPage } from './zipCodepages'
import { emailCapturePredefinedPage } from './emailCapturePage'
import { phoneNumberCapturePredefinedPage } from './phoneNumberCapturePage'
import { firstLastNameCapturePreDefinedPage } from './firstLastNamesCapturePage'
import { homeAddressCapturePredefinedPage } from './homeAddressCapturePage'
export const PREDEFINED_PAGES = {
  ZIPCODE_PAGE: 'ZIPCODE_PAGE',
  EMAIL_CAPTURE_PAGE: 'EMAIL_CAPTURE_PAGE',
  PHONE_NUMBER_CAPTURE_PAGE: 'PHONE_NUMBER_CAPTURE_PAGE',
  FULL_NAME_CAPTURE_PAGE: 'FULL_NAME_CAPTURE_PAGE',
  HOME_ADDRESS_CAPTURE_PAGE: 'HOME_ADDRESS_CAPTURE_PAGE'
}
export const preDefinedPages = {
  [PREDEFINED_PAGES.ZIPCODE_PAGE]: zipCodePredefinedPage,
  [PREDEFINED_PAGES.EMAIL_CAPTURE_PAGE]: emailCapturePredefinedPage,
  [PREDEFINED_PAGES.PHONE_NUMBER_CAPTURE_PAGE]:
    phoneNumberCapturePredefinedPage,
  [PREDEFINED_PAGES.FULL_NAME_CAPTURE_PAGE]: firstLastNameCapturePreDefinedPage,
  [PREDEFINED_PAGES.HOME_ADDRESS_CAPTURE_PAGE]: homeAddressCapturePredefinedPage
}
