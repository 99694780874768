import { ELEMENTS_TYPES } from 'modules/utils/elementsTypes'

export const KeysForTypes = {
  CLASSNAME: 'CLASSNAME',
  TEXT_VALUE: 'TEXT_VALUE',
  BUTTON_ROLE: 'BUTTON_ROLE',
  SUGGESTIONS: 'SUGGESTIONS',
  OPTIONS: 'OPTIONS',
  CHILDREN: 'CHILDREN',
  KEY: 'KEY',
  KEYS: 'KEYS',
  GO_NEXT: 'GO_NEXT',
  MIN_LABEL: 'MIN_LABEL',
  MAX_LABEL: 'MAX_LABEL',
  INITIAL_INDEX: 'INITIAL_INDEX',
  PLACEHOLDER: 'PLACEHOLDER',
  INPUT_TYPE: 'INPUT_TYPE',
  DEFAULT_VALUE: 'DEFAULT_VALUE',
  CUSTOM_EVENTS: 'CUSTOM_EVENTS',
  CUSTOM_FUNCTIONS_ON_CLICK: 'CUSTOM_FUNCTIONS_ON_CLICK',
  CONTENT: 'CONTENT',
  CHECKED_UNCHECKED_VALUES: 'CHECKED_UNCHECKED_VALUES'
}

export const ELEMENTS_SETTINGS = {
  [ELEMENTS_TYPES.HEADLINE]: [KeysForTypes.CLASSNAME, KeysForTypes.TEXT_VALUE],
  [ELEMENTS_TYPES.PARAGRAPH]: [KeysForTypes.CLASSNAME, KeysForTypes.TEXT_VALUE],
  [ELEMENTS_TYPES.BUTTON]: [
    KeysForTypes.CLASSNAME,
    KeysForTypes.TEXT_VALUE,
    KeysForTypes.BUTTON_ROLE,
    KeysForTypes.CUSTOM_EVENTS,
    KeysForTypes.CUSTOM_FUNCTIONS_ON_CLICK
  ],
  [ELEMENTS_TYPES.CONTAINER]: [KeysForTypes.CLASSNAME],

  [ELEMENTS_TYPES.RADIO_BUTTONS]: [
    KeysForTypes.KEY,
    KeysForTypes.CLASSNAME,
    KeysForTypes.GO_NEXT,
    KeysForTypes.SUGGESTIONS
  ],
  [ELEMENTS_TYPES.DROPDOWN_SELECT]: [
    KeysForTypes.KEY,
    KeysForTypes.CLASSNAME,
    KeysForTypes.OPTIONS
  ],
  [ELEMENTS_TYPES.RANGE_SLIDER]: [
    KeysForTypes.KEY,
    KeysForTypes.CLASSNAME,
    KeysForTypes.MIN_LABEL,
    KeysForTypes.MAX_LABEL,
    KeysForTypes.INITIAL_INDEX,
    KeysForTypes.OPTIONS
  ],
  [ELEMENTS_TYPES.FORM_TEXT]: [
    KeysForTypes.KEY,
    KeysForTypes.INPUT_TYPE,
    KeysForTypes.CLASSNAME,
    KeysForTypes.PLACEHOLDER,
    KeysForTypes.DEFAULT_VALUE
  ],
  [ELEMENTS_TYPES.FORM_PHONE]: [
    KeysForTypes.KEY,
    KeysForTypes.CLASSNAME,
    KeysForTypes.PLACEHOLDER
  ],
  [ELEMENTS_TYPES.FORM_ERN_ADDRESS]: [
    KeysForTypes.KEY,
    KeysForTypes.CLASSNAME,
    KeysForTypes.PLACEHOLDER
  ],
  [ELEMENTS_TYPES.CHECKBOX]: [
    KeysForTypes.KEY,
    KeysForTypes.CLASSNAME,
    KeysForTypes.CONTENT,
    KeysForTypes.CHECKED_UNCHECKED_VALUES
  ]
}
