import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'
import { ContainerWrapper } from './ContainerWrapper'
import SpacingInput from './input'

export default function PaddingHandlers () {
  const [paddingTop, setPaddingTop] = useCssKeyValue({
    key: 'padding-top',
    value: '0',
    unit: 'px'
  })
  const [paddingLeft, setPaddingLeft] = useCssKeyValue({
    key: 'padding-left',
    value: '0',
    unit: 'px'
  })
  const [paddingRight, setPaddingRight] = useCssKeyValue({
    key: 'padding-right',
    value: '0',
    unit: 'px'
  })
  const [paddingBottom, setPaddingBottom] = useCssKeyValue({
    key: 'padding-bottom',
    value: '0',
    unit: 'px'
  })
  return (
    <ContainerWrapper className='padding'>
      <div className='top'>
        <SpacingInput
          value={paddingTop}
          onChange={({ target }) => setPaddingTop({ value: target.value })}
          onAutoClick={() => setPaddingTop({ value: 'auto' })}
          className='fullwidth'
        />
      </div>
      <div className='left-right'>
        <SpacingInput
          value={paddingLeft}
          onChange={({ target }) => setPaddingLeft({ value: target.value })}
          onAutoClick={() => setPaddingLeft({ value: 'auto' })}
        />
        <SpacingInput
          value={paddingRight}
          onChange={({ target }) => setPaddingRight({ value: target.value })}
          onAutoClick={() => setPaddingRight({ value: 'auto' })}
        />
      </div>
      <div className='bottom'>
        <SpacingInput
          value={paddingBottom}
          onChange={({ target }) => setPaddingBottom({ value: target.value })}
          onAutoClick={() => setPaddingBottom({ value: 'auto' })}
          className='fullwidth'
        />
      </div>
      <div className='padding-label'>Padding</div>
      <div className='point point-top' />
      <div className='point point-left' />
      <div className='point point-right' />
      <div className='point point-bottom' />
    </ContainerWrapper>
  )
}
