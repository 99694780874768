import { Box, Typography } from '@mui/material'
import ElementCustomEventHandler from './handler'

export default function ElementCustomEvents () {
  return (
    <Box>
      <Typography sx={{ mb: 1 }}>Custom Events</Typography>
      <ElementCustomEventHandler label='Click' eventKey='onClick' />
    </Box>
  )
}
