import styled from '@emotion/styled'
import { Box, Button, Typography } from '@mui/material'
import { useTemplates } from 'modules/forms-generator/hooks/useTemplates'

export default function TemplateItem ({ item }) {
  const { name, type, data } = item
  const { importTemplate } = useTemplates()

  const handleImportTemplate = () => {
    importTemplate(data)
  }
  return (
    <TemplateItemContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '300px'
      }}
    >
      <Typography variant='h3'>{name}</Typography>
      <img src={`/forms/${name}.png`} alt={`${name} - ${type}`} />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Button variant='contained' size='small'>
          Preview Template
        </Button>
        <Button variant='contained' size='small' onClick={handleImportTemplate}>
          Use Template
        </Button>
      </Box>
    </TemplateItemContainer>
  )
}

const TemplateItemContainer = styled(Box)`
  border: 1px solid #e2e2e2;
  h3 {
    text-transform: uppercase;
    width: 100%;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    padding: 20px 0;
    background-color: azure;
  }
  img {
    width: 100%;
    height: auto;
  }
  button {
    margin-top: 10px;
  }
`
