
import { ROOT_NODE, useEditor } from '@craftjs/core'
import { ButtonGroup } from '@mui/material'
import ChunkedArray from 'components/helpers-components/chunkedArray'
import { useStyles } from 'hooks/use-styles'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  HeaderContainer, Wrapper
} from './dimensions-styled'

export default function ClassNamesSection () {
  const { getEditorElementFromTheElementSelected, getClassesFromElement } = useStyles()
  const [classNames, setClassNames] = useState()
  const { dom, classList } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName || [],
      dom: state.nodes[currentlySelectedNodeId]
        ? state.nodes[currentlySelectedNodeId].dom
        : undefined,
      classList: state.nodes[currentlySelectedNodeId]
        ? state.nodes[currentlySelectedNodeId].dom.classList
        : undefined,
      rootProps: query.node(ROOT_NODE).get()?.data?.props,
      isRoot: ROOT_NODE === currentlySelectedNodeId
    }
  })
  useEffect(() => {
    const selectedElementDom = getEditorElementFromTheElementSelected(dom)
    const selectedElementClasses = getClassesFromElement(selectedElementDom)
    setClassNames(selectedElementClasses)
    // eslint-disable-next-line
  }, [classList])
  return (
    <Wrapper>
      <HeaderContainer>
        <Title>class names</Title>
      </HeaderContainer>
      <ButtonGroup
        variant='outlined'
        aria-label='outlined button group'
      >
        <ChunkedArray items={classNames} />
      </ButtonGroup>
    </Wrapper>
  )
}
const Title = styled.div`
color: #636e72;
font-weight: bold;
padding: 10px;
`
