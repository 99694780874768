import styled from 'styled-components'

export const ElementContainer = styled.div`
  padding: 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`
export const TitleElement = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 5px;
  color: #5f6368;
  font-weight: bold;
`
export const ContentInput = styled.input``
export const Select = styled.select`
  width: ${props => (props.small ? '75%' : '150px')};
  color: #5f6368;
`
export const FontSize = styled.input``
export const FormContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 4px;
  width: 100%;
  margin: 10px 0px;
  position: relative;
  flex-direction: ${props => (props.column ? 'column' : 'rows')};
`
export const Label = styled.label`
  display: inline-block;
  width: ${props => (props.large ? '320px' : '120px')};
  text-align: left;
  span {
    color: #5f6368;
    font-size: 15px;
    font-weight: 400;
  }
`
export const InputContainer = styled.div`
  width: 150px;
`
export const IconsContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: 150px;
`
export const Icon = styled.div``
export const Input = styled.input`
  width: 90%;
  width: ${props => (props.small ? '90%' : '150px')};
  color: #5f6368;
`
export const ColorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-top: 15px
`
export const TitleContainer = styled.div`
  justify-content: space-between;
  display: flex;
`
export const CollapseContainer = styled.div`
  cursor: pointer;
`
export const SaveButton = styled.button`
  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
  color: rgb(244, 244, 244);
  background-color: #212121;
  font-size: 18px;
  min-width: 120px;
  min-height: 39px;
  border-radius: 2px;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;
  border-color: #212121;
`
export const DateInput = styled.input.attrs({ type: 'date' })`
  padding: 5px;
  width: 205px;
  color: #5f6368;
  cursor: pointer;
`
export const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked + span {
    background: #212121;
  }
  &:checked + span :after {
    display: block;
  }
`
export const IconContainer = styled.div`
  cursor: pointer;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const CodeMirrorContainer = styled.div`
  background: #fff;
  height: calc(100% - 30px);
  border: 1px solid #ddd;
  border-left: none;
  overflow: scroll;
`
export const EditorContainer = styled.div`
  height: 500px;
  width: 600px;
`
export const CopyButton = styled.div`
  display: flex;
  gap: 5px;
  img {
    width: 20px;
  }
`
export const CopyMessage = styled.div`
  display: ${(props) => (props.copied ? 'flex' : 'none')};
  transition: visibility 0s, opacity 0.5s linear;
  z-index: 2;
  justify-content: center;
  align-items: center;
  background: #69676738;
  padding: 0 5px;
  border-radius: 3px;
`
export const EditorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`
