import { useEditor } from '@craftjs/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft'
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter'
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight'
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop'
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter'
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import ViewStreamIcon from '@mui/icons-material/ViewStream'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {
  WrapIcon,
  NoWrapIcon,
  AlignCenterIcon,
  AlignTopIcon,
  AlignBottomIcon,
  SpaceAroundIcon,
  SpaceBetweenIcon,
  AlignStretchIcon,
  JustifyCenterIcon,
  JustifyFlexStartIcon,
  JustifyFlexEndIcon,
  JustifySpaceBetweenIcon,
  JustifySpaceAroundIcon,
  JustifySpaceEvenlyIcon
} from './svgIcons'
import { useStylesHelpers } from '../../../hooks/use-styles-helpers'

export default function DisplayStyleSection () {
  const [display, setDisplay] = useState('block')
  const [flexStylingOptions, setFlexStylingOptions] = useState({})
  const [isFlexDirectionHorizontal, setIsFlexDirectionHorizontal] =
    useState(true)
  const { changestyle } = useStylesHelpers()

  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const { active, classes } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName || []
    }
  })
  const handleDisplayStyleChange = (e) => {
    const displayStyleValue = e.target.value
    setStylesValue('display', displayStyleValue)
    setDisplay(displayStyleValue)
  }

  const setStylesValue = (cssKey, value) => {
    if (classes) {
      changestyle({ classes, styles, cssKey, value, selectedClassName })
    }
  }

  const handleStyleChange = (e, cssKey, styleValue) => {
    setFlexStylingOptions({ ...flexStylingOptions, [cssKey]: styleValue })
    const styleOption = {}
    styleOption[cssKey] = styleValue
    if (styleOption?.flexDirection === 'column' || styleOption?.flexDirection === 'column-reverse') {
      setIsFlexDirectionHorizontal(false)
    } else {
      setIsFlexDirectionHorizontal(true)
    }
    Object.keys(styleOption || []).forEach((csskey) => {
      setStylesValue(csskey, styleOption[csskey])
    })
  }

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setDisplay(tabletStyle[classes]?.display || 'block')
    } else if (selectedDevice === 'desktop') {
      setDisplay(desktopStyle[classes]?.display || 'block')
    } else {
      setDisplay(styles[classes]?.display || 'block')
    }
    // eslint-disable-next-line
  }, [active, selectedDevice])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setDisplay(tabletStyle[selectedClassName]?.display)
    } else if (selectedDevice === 'desktop') {
      setDisplay(desktopStyle[selectedClassName]?.display)
    } else {
      setDisplay(styles[selectedClassName]?.display)
    }
    // eslint-disable-next-line
  },[selectedClassName])
  return (
    <Wrapper>
      <Container center='center'>
        <FormControl sx={{ m: 1, minWidth: 200 }} size='small' data-cy='flex__styling__section'>
          <Select
            value={display}
            onChange={handleDisplayStyleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            data-cy='display__style__select'
          >
            <MenuItem value='none'>none</MenuItem>
            <MenuItem value='block'>block</MenuItem>
            <MenuItem value='inline'>inline</MenuItem>
            <MenuItem value='inline-block'>inline-block</MenuItem>
            <MenuItem value='flex'>flex</MenuItem>
          </Select>
        </FormControl>
        {display === 'flex' && (
          <FlexSection data-cy='flex__section__container'>
            <FlexOptions>
              <FlexTitle>Flex Direction</FlexTitle>
              <ImageButtons>
                <FlexButton
                  onClick={e => handleStyleChange(e, 'flexDirection', 'row')}
                  data-cy='flex__direction__row'
                  selected={flexStylingOptions.flexDirection === 'row'}
                >
                  <HorizontalDirection>
                    <ViewStreamIcon />
                    <ArrowRightAltIcon />
                  </HorizontalDirection>
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'flexDirection', 'column')}
                  data-cy='flex__direction__column'
                  selected={flexStylingOptions.flexDirection === 'column'}
                >
                  <VerticalDirection>
                    <ViewStreamIcon />
                    <ArrowRightAltIcon />
                  </VerticalDirection>
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'flexDirection', 'row-reverse')}
                  data-cy='flex__direction__row__reverse'
                  selected={flexStylingOptions.flexDirection === 'row-reverse'}
                >
                  <HorizontalDirectionReverse>
                    <ViewStreamIcon />
                    <ArrowRightAltIcon />
                  </HorizontalDirectionReverse>
                </FlexButton>
                <FlexButton
                  onClick={e =>
                    handleStyleChange(e, 'flexDirection', 'column-reverse')}
                  data-cy='flex__direction__column__reverse'
                  selected={
                    flexStylingOptions.flexDirection === 'column-reverse'
                  }
                >
                  <VerticalDirectionReverse>
                    <ViewStreamIcon />
                    <ArrowRightAltIcon />
                  </VerticalDirectionReverse>
                </FlexButton>
              </ImageButtons>
            </FlexOptions>
            <FlexOptions>
              <FlexTitle>Flex Wrap</FlexTitle>
              <ImageButtons>
                <FlexButton
                  onClick={e => handleStyleChange(e, 'flexWrap', 'wrap')}
                  selected={flexStylingOptions.flexWrap === 'wrap'}
                  data-cy='flex__wrap__wrap'
                >
                  <WrapIcon />
                </FlexButton>
                <FlexButton
                  onClick={e => handleStyleChange(e, 'flexWrap', 'nowrap')}
                  selected={flexStylingOptions.flexWrap === 'nowrap'}
                  data-cy='flex__wrap__nowrap'
                >
                  <NoWrapIcon />
                </FlexButton>
              </ImageButtons>
            </FlexOptions>
            <FlexOptions>
              <FlexTitle>Align Content</FlexTitle>
              <ImageButtons>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'alignContent', 'center')}
                  data-cy='align__content__center'
                  selected={flexStylingOptions.alignContent === 'center'}
                >
                  <AlignCenterIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'alignContent', 'flex-start')}
                  data-cy='align__content__flex__start'
                  selected={flexStylingOptions.alignContent === 'flex-start'}
                >
                  <AlignTopIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'alignContent', 'flex-end')}
                  data-cy='align__content__flex__end'
                  selected={flexStylingOptions.alignContent === 'flex-end'}
                >
                  <AlignBottomIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'alignContent', 'space-around')}
                  data-cy='align__content__space__around'
                  selected={flexStylingOptions.alignContent === 'space-around'}
                >
                  <SpaceAroundIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'alignContent', 'space-between')}
                  data-cy='align__content__space__between'
                  selected={flexStylingOptions.alignContent === 'space-between'}
                >
                  <SpaceBetweenIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'alignContent', 'stretch')}
                  data-cy='align__content__stretch'
                  selected={flexStylingOptions.alignContent === 'stretch'}
                >
                  <AlignStretchIcon />
                </FlexButton>
              </ImageButtons>
            </FlexOptions>
            <FlexOptions>
              <FlexTitle>Justify Content</FlexTitle>
              <ImageButtons>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'justifyContent', 'center')}
                  data-cy='justify__content__center'
                  selected={flexStylingOptions.justifyContent === 'center'}
                >
                  <JustifyCenterIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'justifyContent', 'flex-start')}
                  data-cy='justify__content__flex__start'
                  selected={flexStylingOptions.justifyContent === 'flex-start'}
                >
                  <JustifyFlexStartIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'justifyContent', 'flex-end')}
                  data-cy='justify__content__flex__end'
                  selected={flexStylingOptions.justifyContent === 'flex-end'}
                >
                  <JustifyFlexEndIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'justifyContent', 'space-between')}
                  data-cy='justify__content__space__between'
                  selected={flexStylingOptions.justifyContent === 'flex-start'}
                >
                  <JustifySpaceBetweenIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'justifyContent', 'space-around')}
                  data-cy='justify__content__space__around'
                  selected={flexStylingOptions.justifyContent === 'space-around'}
                >
                  <JustifySpaceAroundIcon />
                </FlexButton>
                <FlexButton
                  onClick={(e) =>
                    handleStyleChange(e, 'justifyContent', 'space-evenly')}
                  data-cy='justify__content__space__evenly'
                  selected={flexStylingOptions.justifyContent === 'space-evenly'}
                >
                  <JustifySpaceEvenlyIcon />
                </FlexButton>
              </ImageButtons>
            </FlexOptions>
            <FlexOptions>
              <FlexTitle>Align Items</FlexTitle>
              {isFlexDirectionHorizontal
                ? (
                  <ImageButtons data-cy='for__flex__direction__horizontal'>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'flex-start')}
                      selected={flexStylingOptions.alignItems === 'flex-start'}
                      data-cy='align__items__flex__start'
                    >
                      <AlignHorizontalLeftIcon />
                    </FlexButton>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'center')}
                      selected={flexStylingOptions.alignItems === 'center'}
                      data-cy='align__items__center'
                    >
                      <AlignHorizontalCenterIcon />
                    </FlexButton>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'flex-end')}
                      selected={flexStylingOptions.alignItems === 'flex-end'}
                      data-cy='align__items__flex__end'
                    >
                      <AlignHorizontalRightIcon />
                    </FlexButton>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'baseline')}
                      selected={flexStylingOptions.alignItems === 'baseline'}
                      data-cy='align__items__baseline'
                    >
                      <TextFormatIcon />
                    </FlexButton>
                  </ImageButtons>
                  )
                : (
                  <ImageButtons data-cy='for__flex__direction__vertical'>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'flex-start')}
                      selected={flexStylingOptions.alignItems === 'flex-start'}
                      data-cy='align__items__flex__start'
                    >
                      <AlignVerticalTopIcon />
                    </FlexButton>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'center')}
                      selected={flexStylingOptions.alignItems === 'center'}
                      data-cy='align__items__center'
                    >
                      <AlignVerticalCenterIcon />
                    </FlexButton>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'flex-end')}
                      selected={flexStylingOptions.alignItems === 'flex-end'}
                      data-cy='align__items__flex__end'
                    >
                      <AlignVerticalBottomIcon />
                    </FlexButton>
                    <FlexButton
                      onClick={(e) =>
                        handleStyleChange(e, 'alignItems', 'baseline')}
                      selected={flexStylingOptions.alignItems === 'baseline'}
                      data-cy='align__items__baseline'
                    >
                      <TextFormatIcon />
                    </FlexButton>
                  </ImageButtons>
                  )}
            </FlexOptions>
          </FlexSection>
        )}
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 12px;
`

const FlexButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.selected ? '#343434' : '#d0d0d0')};
  box-shadow: ${(props) => (props.selected ? '0 0 11px rgba(33, 33, 33, 0.2)' : '')};
  padding: 5px;
  height: 32px;
  width: 32px;
  :hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
  padding: 20px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  width: 100%;
  margin-top: -18px;
  padding: 12px 0px;
`
const FlexSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
const FlexOptions = styled.div``
const ImageButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  padding: 10px;
`
const HorizontalDirection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  svg:nth-child(1) {
    transform: rotate(90deg);
  }
  svg:nth-child(2) {
    margin-top: -10px;
  }
`
const HorizontalDirectionReverse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  svg:nth-child(1) {
    transform: rotate(90deg);
  }
  svg:nth-child(2) {
    margin-top: -10px;
    transform: rotate(180deg);
  }
`
const VerticalDirection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  svg:nth-child(1) {
    transform: rotate(90deg);
  }
  svg:nth-child(2) {
    margin-top: -10px;
    transform: rotate(180deg);
  }
  transform: rotate(-90deg);
`
const VerticalDirectionReverse = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  svg:nth-child(1) {
    transform: rotate(90deg);
  }
  svg:nth-child(2) {
    margin-top: -10px;
  }
  transform: rotate(-90deg);
`
const FlexTitle = styled.div`
  font-size: 16px;
  color: #636e72;
  padding: 5px 10px;
`
