export const emailCapturePredefinedPage = {
  name: 'emailCapturePage',
  className: '',
  elements: [
    {
      kind: 'TEXT',
      type: 'TITLE',
      className: 'description email__description',
      value: 'What email address can we send your results to?'
    },
    {
      kind: 'CONTAINER',
      className: 'email__form__container',
      keys: [],
      children: [
        {
          kind: 'TEXT',
          type: 'PARAGRAPH',
          className: 'label email__label',
          value: 'Email Address'
        },
        {
          kind: 'FORM',
          type: 'TEXT',
          className: 'email__input',
          placeholder: 'Please enter your email address',
          key: 'email',
          validations: {
            required: {
              value: true,
              message: 'Email address is not correct'
            },
            regex: {
              value: true,
              regex: '/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/'
            }
          }
        }
      ]
    },
    {
      kind: 'CONTAINER',
      className: 'navigation__container',
      keys: [],
      children: [
        {
          kind: 'BUTTON',
          className: 'next__button',
          value: 'NEXT',
          role: 'NEXT'
        },
        {
          kind: 'BUTTON',
          className: 'back__button',
          value: 'BACK',
          role: 'BACK'
        }
      ]
    }
  ],
  nextPage: {},
  layouts: {
    beforeElements: '',
    afterElements: ''
  }
}
