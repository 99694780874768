import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'

export default function ColorsSettings () {
  const [color, setColor] = useCssKeyValue({ key: 'color', value: '#000000' })
  const [bgColor, setBgColor] = useCssKeyValue({
    key: 'background-color',
    value: 'transparent'
  })
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Colors</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          mb='10px'
        >
          <Typography sx={{ width: '120px', fontSize: '12px' }}>
            Color
          </Typography>
          <TextField
            size='small'
            type='text'
            value={color}
            onChange={({ target }) => setColor({ value: target.value })}
            sx={{
              borderColor: color,
              borderWidth: '5px',
              borderStyle: 'solid'
            }}
          />
          <IconButton
            variant='outlined'
            onClick={() =>
              setColor({
                value: '#000000'
              })}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography sx={{ width: '120px', fontSize: '12px' }}>
            Background Color
          </Typography>
          <TextField
            type='text'
            size='small'
            value={bgColor}
            onChange={({ target }) =>
              setBgColor({
                value: target.value
              })}
            sx={{
              borderColor: color,
              borderWidth: '5px',
              borderStyle: 'solid'
            }}
          />
          <IconButton
            variant='outlined'
            onClick={() =>
              setBgColor({
                value: 'transparent'
              })}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
