import { Image } from '../../Image'
import { Headline } from '../../Headline'
import { Cta } from '../../Cta'
import { Element } from '@craftjs/core'
import { AdContainer } from '../../AdContainer'
import { ElementContainer } from '../../ElementContainer'
import { Text } from '../../Text'

const layout4DefaultStyles = {
  adContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imageStyle: {
    height: '200px',
    width: 'auto'
  },
  headlineStyle: {
    fontSize: '26px',
    width: 'fit-content'
  },
  bullesStyle: {
    fontSize: '16px'
  },
  ctaStyle: {
    display: 'flex',
    justifyContent: 'center'
  },
  elementContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}

export const ListicleLayout = () => {
  return (
    <Element
      id='ad-container'
      is={AdContainer}
      canvas
      styles={layout4DefaultStyles.adContainer}
    >
      <ElementContainer styles={layout4DefaultStyles.elementContainer} classname='listicle__container'>
        <Headline text='LowerMyBills - Listicle ' classname='listicle__headline' />
        <Image
          imgSource=''
          imgName='image-icon'
          styles={layout4DefaultStyles.imageStyle}
          classname='listicle__img'
        />
        <Text classname='listicle__offer--description' text='Along with low debt-to-income-ratio and a strong financial history, you’ll need a high credit score for the lowest mortgage rates. Most homeowners improve their credit health over time and might be able to get a better rate than when they first bought their home. If you haven’t missed a mortgage payment in the last year, don’t wait another month to learn more about refinancing.' />

        <Cta
          styles={layout4DefaultStyles.ctaStyle}
          ctaText='Click Here yo Customize you Canvas'
          classname='listicle__cta'
        />
      </ElementContainer>
    </Element>
  )
}
