import React, { useState } from 'react'
import { useEditor } from '@craftjs/core'
import styled from 'styled-components'
import { styled as MUIStyled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { ListingLayout } from 'components/User/layouts/listing-layouts/ListingLayout'
import { Option1Numbered } from 'components/User/layouts/listing-layouts/0ption1Numbered'
import { Option2ListingLayout } from 'components/User/layouts/listing-layouts/Option2ListingLayout'
import { Option2Numbered } from 'components/User/layouts/listing-layouts/Option2Numbered'
import { Option3ListingLayout } from 'components/User/layouts/listing-layouts/Option3ListingLayout'
import { Option3Numbered } from 'components/User/layouts/listing-layouts/Option3Numbered'
import { useStylesHelpers } from 'hooks/use-styles-helpers'
import listingStyleData from 'components/User/layouts/listing-layouts/ListingStyle.json'
import Option1NumberedStyle from 'components/User/layouts/listing-layouts/Option1NumberedStyle.json'
import Option2ListingLayoutStyles from 'components/User/layouts/listing-layouts//Option2ListingLayoutStyle.json'
import Option2NumberedStyle from 'components/User/layouts/listing-layouts/Option2NumberedStyle.json'
import Option3ListingLayoutStyles from 'components/User/layouts/listing-layouts//Option3ListingLayoutStyle.json'
import Option3NumberedStyle from 'components/User/layouts/listing-layouts/Option3NumberedStyle.json'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

const Accordion = MUIStyled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = MUIStyled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

export const ListingLayoutFolder = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  const [expanded, setExpanded] = useState(false)
  const { pushLayoutStyle } = useStylesHelpers()
  const { query, actions, adContainerId } = useEditor(state => {
    const userElements = state.nodes
    const layouts = [
      'Layout1',
      'Layout2',
      'Layout3',
      'Layout4',
      'EmptyLayout',
      'ListingLayout',
      'Option1Numbered',
      'Option2ListingLayout'
    ]
    const adContainerId = Object.keys(userElements).filter(elementId =>
      layouts.includes(userElements[elementId].data.name)
    )
    return {
      adContainerId
    }
  })

  const handleChange = panel => (event, isExpanded) => {
    console.log('Ad container Id', adContainerId)
    setExpanded(isExpanded ? panel : false)
  }

  const createLayout1 = (event) => {
    const layout1NodeTree = query
      .parseReactElement(<ListingLayout />)
      .toNodeTree()
    actions.addNodeTree(layout1NodeTree, 'ROOT')
    pushLayoutStyle(listingStyleData)
    handleListItemClick(event, 1)
  }

  const createOption1NumberedLayout = (event) => {
    const layout1NumberedNodeTree = query
      .parseReactElement(<Option1Numbered />)
      .toNodeTree()
    actions.addNodeTree(layout1NumberedNodeTree, 'ROOT')
    pushLayoutStyle(Option1NumberedStyle)
    handleListItemClick(event, 2)
  }
  const creatOption2ListingLayout = (event) => {
    const option2ListingLayout = query
      .parseReactElement(<Option2ListingLayout />)
      .toNodeTree()
    actions.addNodeTree(option2ListingLayout, 'ROOT')
    pushLayoutStyle(Option2ListingLayoutStyles)
    handleListItemClick(event, 3)
  }

  const createOption2NumberedLayout = (event) => {
    const layout2NumberedNodeTree = query
      .parseReactElement(<Option2Numbered />)
      .toNodeTree()
    actions.addNodeTree(layout2NumberedNodeTree, 'ROOT')
    pushLayoutStyle(Option2NumberedStyle)
    handleListItemClick(event, 4)
  }

  const creatOption3ListingLayout = (event) => {
    const option3ListingLayout = query
      .parseReactElement(<Option3ListingLayout />)
      .toNodeTree()
    actions.addNodeTree(option3ListingLayout, 'ROOT')
    pushLayoutStyle(Option3ListingLayoutStyles)
    handleListItemClick(event, 5)
  }

  const createOption3NumberedLayout = (event) => {
    const layout3NumberedNodeTree = query
      .parseReactElement(<Option3Numbered />)
      .toNodeTree()
    actions.addNodeTree(layout3NumberedNodeTree, 'ROOT')
    pushLayoutStyle(Option3NumberedStyle)
    handleListItemClick(event, 6)
  }

  return (
    <ListingFolder>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <FolderOutlinedIcon sx={{ color: '#5e5e5e', paddingRight: '10px' }} />
          <Typography sx={{ color: 'text.secondary' }}>Listing</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0 10px' }}>
          <List sx={{ padding: '0' }}>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={event => createLayout1(event)}
            >
              <ListItemText primary='Option-1' />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={event => createOption1NumberedLayout(event)}
            >
              <ListItemText primary='Option-1 "Numberd"' />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={event => creatOption2ListingLayout(event)}
            >
              <ListItemText primary='Option-2' />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 4}
              onClick={event => createOption2NumberedLayout(event)}
            >
              <ListItemText primary='Option-2 "Numberd"' />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 5}
              onClick={event => creatOption3ListingLayout(event)}
            >
              <ListItemText primary='Option-3' />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 6}
              onClick={event => createOption3NumberedLayout(event)}
            >
              <ListItemText primary='Option-3 "Numberd"' />
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </ListingFolder>
  )
}
const ListingFolder = styled.div`
  width: 100%;
`
