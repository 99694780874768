import React from 'react'
import MinPlusInput from './min-plus-input'
import styled from 'styled-components'
import { Container } from './dimensions-styled'
export default function BorderSection () {
  return (
    <Wrapper>
      <Container center='space-around'>
        <MinPlusInput
          title='All slides'
          cssKey='borderWidth'
          defaultValue='0'
        />
        <MinPlusInput title='top' cssKey='borderTopWidth' defaultValue='0' />
        <MinPlusInput
          title='right'
          cssKey='borderRightWidth'
          defaultValue='0'
        />
        <MinPlusInput title='left' cssKey='borderLeftWidth' defaultValue='0' />
        <MinPlusInput
          title='bottom'
          cssKey='borderBottomWidth'
          defaultValue='0'
        />
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 12px;
`
