import { useState } from 'react'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import PostAddIcon from '@mui/icons-material/PostAdd'
import LayersIcon from '@mui/icons-material/Layers'
import SaveIcon from '@mui/icons-material/Save'
import PublishIcon from '@mui/icons-material/Publish'
import NewPageModal from './newPageModal'
import ExportedFormModal from './exportedFormModal'
import ImportFormModal from '../import'

const actions = [
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PublishIcon />, name: 'Import' },
  { icon: <PostAddIcon />, name: 'Page' },
  { icon: <LayersIcon />, name: 'Layout' }
]

export default function SpeedDials () {
  const [open, setOpen] = useState(false)
  const [openNewPageModal, setOpenNewPageModal] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)
  const [openImportModal, setOpenImportModal] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleOpenImportModal = () => setOpenImportModal(true)
  const handleCloseImportModal = () => setOpenImportModal(false)

  const openModal = () => {
    setOpenNewPageModal(true)
    handleClose()
  }
  const closeModal = () => {
    setOpenNewPageModal(false)
    handleClose()
  }

  const handleExport = () => {
    setOpenExportModal(true)
  }

  return (
    <>
      <Box
        sx={{
          transform: 'translateZ(0px)',
          flexGrow: 1,
          position: 'absolute',
          width: '100%',
          height: 'fit-content',
          bottom: '10px'
        }}
      >
        <Backdrop open={open} />
        <SpeedDial
          ariaLabel='SpeedDial tooltip'
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => {
                if (action.name === 'Page') openModal()
                if (action.name === 'Save') handleExport()
                if (action.name === 'Import') handleOpenImportModal()
                else handleClose()
              }}
            />
          ))}
        </SpeedDial>
      </Box>
      <NewPageModal open={openNewPageModal} onClose={closeModal} />
      <ImportFormModal
        open={openImportModal}
        onClose={handleCloseImportModal}
      />
      <ExportedFormModal
        open={openExportModal}
        onClose={() => setOpenExportModal(false)}
      />
    </>
  )
}
