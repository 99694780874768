import React from 'react'
import MinPlusInput from './min-plus-input'
import { Container, Wrapper } from './dimensions-styled'
export default function MarginSection () {
  return (
    <Wrapper>
      <Container center='space-around' data-cy='margin__styling__section'>
        <MinPlusInput title='All Slides' defaultValue='0' cssKey='margin' />
        <MinPlusInput title='Top' defaultValue='0' cssKey='marginTop' />
        <MinPlusInput title='Bottom' defaultValue='0' cssKey='marginBottom' />
        <MinPlusInput
          title='Left'
          defaultValue='0'
          cssKey='marginLeft'
          style={{ border: '1px solid red' }}
        />
        <MinPlusInput title='Right' defaultValue='0' cssKey='marginRight' />
      </Container>
    </Wrapper>
  )
}
