import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import BordersHandlers from './bordersHandler'

export default function BorderSetting () {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Borders</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BordersHandlers />
      </AccordionDetails>
    </Accordion>
  )
}
