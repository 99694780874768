import { useEditor } from '@craftjs/core'
import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useStylesHelpers } from '../../../hooks/use-styles-helpers'
import {
  HeaderContainer
} from './dimensions-styled'

export default function FontWeight () {
  const stylesOptions = [
    { key: 'normal', value: 'normal' },
    { key: 'bold', value: 'bold' },
    { key: 'bolder', value: 'bolder' },
    { key: 'lighter', value: 'lighter' },
    { key: '100', value: '100' },
    { key: '200', value: '200' },
    { key: '300', value: '300' },
    { key: '400', value: '400' },
    { key: '500', value: '500' },
    { key: '600', value: '600' },
    { key: '700', value: '700' },
    { key: '800', value: '800' },
    { key: '900', value: '900' }
  ]
  const [slectedFontWeight, setSelectedFontWeight] = useState('')
  const { changestyle } = useStylesHelpers()
  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const { active, classes } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName ||
        []
    }
  })

  const styleChangeHandler = e => {
    setSelectedFontWeight(e.target.value)
  }

  const setStylesValue = (cssKey, value) => {
    if (classes) {
      changestyle({ classes, styles, cssKey, value, selectedClassName })
    }
  }
  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setSelectedFontWeight(tabletStyle?.[selectedClassName]?.fontWeight || 'normal')
    } else if (selectedDevice === 'desktop') {
      setSelectedFontWeight(desktopStyle?.[selectedClassName]?.fontWeight || 'normal')
    } else {
      setSelectedFontWeight(styles?.[selectedClassName]?.fontWeight || 'normal')
    }
    // eslint-disable-next-line
  },[selectedClassName])
  useEffect(() => {
    setStylesValue('fontWeight', slectedFontWeight)
    // eslint-disable-next-line
  }, [slectedFontWeight])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setSelectedFontWeight(tabletStyle?.[classes?.[0]]?.fontWeight || '')
    } else if (selectedDevice === 'desktop') {
      setSelectedFontWeight(desktopStyle?.[classes?.[0]]?.fontWeight || '')
    } else {
      setSelectedFontWeight(styles?.[classes?.[0]]?.fontWeight || '')
    }
    // eslint-disable-next-line
  }, [active, selectedDevice])

  return (
    <Wrapper>
      <HeaderContainer>
        <Title>Font Weight</Title>
      </HeaderContainer>
      <FormControl sx={{ m: 3, minWidth: 200 }}>
        <Select
          onChange={styleChangeHandler} value={slectedFontWeight}
          data-cy='font__weight__select'
        >
          {stylesOptions.map((item, index) => {
            return (
              <MenuItem value={item.key} key={item.key}>
                {item.value}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  color: #636e72;
`
