import { useState } from 'react'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import { Box } from '@mui/system'
import { useValidations } from 'modules/forms-generator/hooks/useValidations'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useFormData } from 'modules/forms-generator/hooks/useFormData'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export default function MoreThanValidation () {
  const { getKeysArray } = useFormData()
  const { changeValidation } = useValidations()
  const [hasMoreThanValidation, setHasMoreThanValidation] = useState(false)
  const [validationType, setValidationType] = useState('')
  const [functionName, setFunctionName] = useState('')
  const [functionParams, setFunctionParams] = useState([])
  const [specificValue, setSpecificValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const handleChange = ({ validationName, key, value }) => {
    changeValidation({ validationName, key, value })
  }
  return (
    <Box
      sx={{
        display: 'flex',
        my: '10px',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <FormControlLabel
        onChange={(_, checked) => {
          setHasMoreThanValidation(checked)
          handleChange({
            validationName: 'moreThan',
            key: 'value',
            value: checked
          })
        }}
        checked={hasMoreThanValidation}
        control={<Checkbox />}
        label='Should be More than'
      />
      {hasMoreThanValidation && (
        <Box sx={{ flex: 1, width: '100%' }}>
          <TextField
            label='Validation error message'
            sx={{ flex: 1, width: '100%' }}
            value={errorMessage}
            onChange={({ target }) => {
              setErrorMessage(target.value)
              handleChange({
                validationName: 'moreThan',
                key: 'message',
                value: target.value
              })
            }}
          />
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <FormControl>
              <FormLabel id='type-value-radio-buttons-group'>
                Type of value
              </FormLabel>
              <RadioGroup
                aria-labelledby='type-value-radio-buttons-group'
                name='value-radio-buttons-group'
                value={validationType}
                onChange={(_, value) => {
                  setValidationType(value)
                  handleChange({
                    validationName: 'moreThan',
                    key: 'type',
                    value: value
                  })
                }}
              >
                <FormControlLabel
                  value='value'
                  control={<Radio />}
                  label='Specific Value'
                />
                <FormControlLabel
                  value='function'
                  control={<Radio />}
                  label='Run Function to get the value'
                />
                <FormControlLabel
                  value='fromKey'
                  control={<Radio />}
                  label='Get the value from previous key entered by client'
                />
              </RadioGroup>
            </FormControl>
            {validationType === 'value' && (
              <Box sx={{ flex: 1, width: '100%' }}>
                <TextField
                  label='Specific Value'
                  value={specificValue}
                  sx={{ flex: 1, width: '100%' }}
                  onChange={({ target }) => {
                    setSpecificValue(target.value)
                    handleChange({
                      validationName: 'moreThan',
                      key: 'value',
                      value: target.value
                    })
                  }}
                />
              </Box>
            )}
            {validationType === 'function' && (
              <Box sx={{ flex: 1, width: '100%' }}>
                <TextField
                  label='Function Name'
                  value={functionName}
                  sx={{ flex: 1, width: '100%', my: '10px' }}
                  onChange={({ target }) => {
                    setFunctionName(target.value)
                    handleChange({
                      validationName: 'moreThan',
                      key: 'functionName',
                      value: target.value
                    })
                  }}
                />
                <Autocomplete
                  multiple
                  sx={{ flex: 1, width: '100%', my: '10px' }}
                  options={getKeysArray()}
                  disableCloseOnSelect
                  value={functionParams}
                  onChange={(_, values) => {
                    setFunctionParams(values)
                    handleChange({
                      validationName: 'moreThan',
                      key: 'params',
                      value: values
                    })
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Function Params List'
                      placeholder='Select Params from Previous Entered Keys'
                    />
                  )}
                />
              </Box>
            )}
            {validationType === 'fromKey' && (
              <Box sx={{ flex: 1, width: '100%' }}>
                <Autocomplete
                  disablePortal
                  onChange={(_, value) => {
                    setFunctionParams(value)
                    handleChange({
                      validationName: 'moreThan',
                      key: 'key',
                      value: value
                    })
                  }}
                  options={getKeysArray()}
                  sx={{ flex: 1, width: '100%', my: '10px' }}
                  renderInput={(params) => (
                    <TextField {...params} label='Select a Key' />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
