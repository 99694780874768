import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  setConnectionParams,
  setEdges,
  toggleConnectionDialogue,
  usePagesFlowState
} from 'state/slices/formsGenerator/pagesFlowSlice'
import { usePagesConfig } from './usePagesConfig'
import usePages from './usePagesEditor'

export const usePagesConnections = () => {
  const dispatch = useDispatch()
  const {
    connectionParams,
    openConnectionDialogue,
    edges,
    dynamicConnections
  } = usePagesFlowState()
  const { pages } = usePages()
  const [, setCurrentPageNextPageName] = usePagesConfig({
    keyPath: 'nextPage.name',
    defaultValue: ''
  })

  const [, setCurrentPageNextLookupKey] = usePagesConfig({
    keyPath: 'nextPage.lookupKey',
    defaultValue: ''
  })
  const [getCurrentPages, setCurrentPageNextPages] = usePagesConfig({
    keyPath: 'nextPage.pages',
    defaultValue: []
  })

  const handleToggleOpenDialogue = () => {
    dispatch(toggleConnectionDialogue())
  }

  const setCurrentConnectionParams = (params) => {
    dispatch(setConnectionParams(params))
  }

  const getTargetPageNameIndexByName = useCallback(
    (pageName) => {
      const targetPageIndex = pages.findIndex((page) => page.name === pageName)
      if (targetPageIndex >= 0) { return { name: pages[targetPageIndex].name, index: targetPageIndex } }
    },
    [pages]
  )

  const getTargetPageNameIndexByNextPage = (nextPage) => {
    if (nextPage.name) return [getTargetPageNameIndexByName(nextPage.name)]
    if (nextPage.lookupKey) {
      return nextPage.pages.map((entry) => ({
        ...getTargetPageNameIndexByName(entry.page),
        value: entry.value,
        lookupKey: nextPage.lookupKey
      }))
    }
    return []
  }

  const getPagesConnection = () =>
    pages.map((entry, index) => ({
      name: entry.name,
      index: index,
      targets: getTargetPageNameIndexByNextPage(entry.nextPage)
    }))

  const pagesConnection = getPagesConnection()

  const getEdgeId = (source, target) => `specific__${source}->${target}`

  const changeSpecificPageConnection = () => {
    const updatedEdges = edges.map((edge) => {
      if (
        edge.id !==
        getEdgeId(
          connectionParams.source,
          pagesConnection[connectionParams.source].targets[0].index
        )
      ) { return edge } else {
        return {
          ...edge,
          id: getEdgeId(connectionParams.source, connectionParams.target),
          target: `${connectionParams.target}`
        }
      }
    })
    setCurrentPageNextPageName(
      pagesConnection[connectionParams.target].name,
      Number(connectionParams.source)
    )
    setCurrentPageNextLookupKey('', Number(connectionParams.source))
    setCurrentPageNextPages([], Number(connectionParams.source))
    dispatch(setEdges(updatedEdges))
  }

  const isConnectionExist = (connectionEntry) => {
    if (edges.find((entry) => entry.id === connectionEntry.id)) return true
    return false
  }

  const getExistedConnections = () => {
    return dynamicConnections.filter((entry) => isConnectionExist(entry))
  }

  const getNonExistedConnections = () => {
    return dynamicConnections.filter((entry) => !isConnectionExist(entry))
  }

  const getUpdatedEdgesWithNewDynamicConnections = () => {
    return edges.map((edge) => {
      const existedConnection = getExistedConnections().find(
        (connection) => connection.id === edge.id
      )
      if (existedConnection) return existedConnection
      return edge
    })
  }

  const updatePagesConnections = () => {
    const pages = dynamicConnections.map(({ data }) => ({
      value: data.selectedValue,
      page: data.pageName
    }))
    setCurrentPageNextLookupKey(
      dynamicConnections[0].data.key,
      Number(connectionParams.source)
    )
    setCurrentPageNextPages(pages, Number(connectionParams.source))
    setCurrentPageNextPageName('', Number(connectionParams.source))
  }

  const changeDynamicPagesConnection = () => {
    const updatedEdges = [
      ...getUpdatedEdgesWithNewDynamicConnections(),
      ...getNonExistedConnections()
    ]
    dispatch(setEdges(updatedEdges))
    updatePagesConnections()
    return true
  }

  const isEdgeDynamic = (edgeId) => {
    const selectedEdge = edges.find((entry) => entry.id === edgeId)
    if (selectedEdge) {
      return !!selectedEdge.label
    }
    return false
  }

  const removeSpecificEdge = (edgeId) => {
    const { source } = edges.find((entry) => entry.id === edgeId)
    if (source) {
      const updatedEdges = edges.filter((entry) => entry.id !== edgeId)
      setCurrentPageNextPageName('', Number(source))
      dispatch(setEdges(updatedEdges))
    }
  }

  const removeDynamicEdge = (edgeId) => {
    const {
      source,
      data: { pageName }
    } = edges.find((entry) => entry.id === edgeId)
    const allTargets = edges.filter((entry) => entry.source === source)
    let updatedEdges
    if (allTargets.length === 2) {
      updatedEdges = edges
        .map((entry) => {
          if (entry.source === source) {
            const { data, ...rest } = entry
            return { ...rest, label: '' }
          }
          return entry
        })
        .filter((entry) => entry.id !== edgeId)
      const connectedPageName =
        allTargets[0].id !== edgeId
          ? pages[allTargets[0].target].name
          : pages[allTargets[1].target].name
      setCurrentPageNextPages([], Number(source))
      setCurrentPageNextLookupKey('', Number(source))
      setCurrentPageNextPageName(connectedPageName, Number(source))
    } else {
      const selectedConnectionPages = getCurrentPages(source)
      updatedEdges = edges.filter((entry) => entry.id !== edgeId)
      setCurrentPageNextPages(
        selectedConnectionPages.filter((page) => page.page !== pageName),
        Number(source)
      )
    }
    dispatch(setEdges(updatedEdges))
  }

  return {
    connectionParams,
    openConnectionDialogue,
    pagesConnection,
    handleToggleOpenDialogue,
    setCurrentConnectionParams,
    changeSpecificPageConnection,
    changeDynamicPagesConnection,
    isEdgeDynamic,
    removeSpecificEdge,
    removeDynamicEdge,
    getPagesConnection
  }
}
