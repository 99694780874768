import { useEditor, useNode } from '@craftjs/core'
import { WRAPPER_ID } from '../components/design/wrapper'

export const useCraftNode = () => {
  const { query } = useEditor()
  const {
    connectors,
    id,
    isActive,
    isHover,
    dom,
    name,
    deletable,
    parent,
    hasTile,
    moveable,
    props
  } = useNode((node) => ({
    node: node,
    events: node.events,
    isActive: node.events.selected,
    isHover: node.events.hovered,
    name: node.data.custom.displayName || node.data.displayName,
    dom:
      document
        .getElementById(WRAPPER_ID)
        .contentWindow.document.querySelector(
          `[data-element-id="${node.id}"]`
        ) || undefined,
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    hasTile: Boolean(node.related.tileSettings),
    moveable: query.node(node.id).isDraggable(),
    props: node.data.props
  }))

  return {
    id,
    isActive,
    isHover,
    dom,
    name,
    deletable,
    parent,
    hasTile,
    moveable,
    connectors,
    props
  }
}
