import { createSlice } from '@reduxjs/toolkit'
import { set } from 'lodash'
import { findObjectPaths } from 'modules/utils/getElementPath'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'

const initialState = {
  selectedMode: 'builder',
  builderTabs: 'edit',
  pages: [],
  selectedPageIndex: undefined,
  selectedPage: {},
  showElementsDrawer: false,
  showElementModal: false,
  containerIndex: null,
  selectedType: '',
  selectedElement: {},
  selectedElementIndex: null,
  layouts: {},
  errors: {}
}

const newPage = {
  name: '',
  className: '',
  elements: [],
  nextPage: {},
  layouts: {
    beforeElements: '',
    afterElements: ''
  }
}

export const formsBuilderSlice = createSlice({
  name: 'formsBuilder',
  initialState,
  reducers: {
    updateError: (state, { payload }) => {
      // Error must be like: { key: message | true }
      state.errors = { ...state.errors, ...payload }
    },
    changeMode: (state, { payload }) => {
      state.selectedMode = payload
    },
    changeBuilderTab: (state, { payload }) => {
      state.builderTabs = payload
    },
    createNewPage: (state, { payload }) => {
      state.pages.push({ ...newPage, name: payload, id: v4() })
    },
    setSelectedPage: (state, { payload }) => {
      const { page, index } = payload
      state.selectedPage = page
      state.selectedPageIndex = index
    },
    toggleElementsDrawer: (state, { payload }) => {
      state.showElementsDrawer = !state.showElementsDrawer
      state.containerIndex = payload
      if (state.showElementsDrawer) {
        state.selectedType = ''
        state.selectedElementIndex = null
      }
    },
    openElementModal: (state, { payload }) => {
      state.showElementModal = true
      state.selectedType = payload
    },
    closeElementModal: (state) => {
      state.showElementModal = false
    },
    changeSelectedElement: (state, { payload }) => {
      const { elementIndex, containerIndex } = payload
      state.showElementModal = true
      state.selectedElementIndex = elementIndex
      state.containerIndex = containerIndex

      state.selectedElement =
        containerIndex !== null && containerIndex !== undefined
          ? state.selectedPage.elements[containerIndex].children[elementIndex]
          : state.selectedPage.elements[elementIndex]
      state.selectedType =
        state.selectedElement.type || state.selectedElement.kind
    },
    addKeysToSelectedElement: (state, { payload }) => {
      state.selectedElement = { ...payload, id: v4() }
    },
    changeSelectedElementValue: (state, { payload }) => {
      const { key, value } = payload
      state.selectedElement = { ...state.selectedElement, [key]: value }
    },
    addElementToSelectedPage: (state) => {
      if (state.containerIndex === null || state.containerIndex === undefined) {
        if (state.selectedElementIndex === null) {
          state.selectedPage.elements.push(state.selectedElement)
        } else {
          state.selectedPage.elements[state.selectedElementIndex] =
            state.selectedElement
        }
      } else {
        if (state.selectedElementIndex === null) {
          state.selectedPage.elements[state.containerIndex].children.push(
            state.selectedElement
          )
        } else {
          state.selectedPage.elements[state.containerIndex].children[
            state.selectedElementIndex
          ] = state.selectedElement
        }
        state.selectedPage.elements[state.containerIndex].keys =
          state.selectedPage.elements[state.containerIndex].children.reduce(
            (prev, curr) => {
              if (curr.key) prev.push(curr.key)
              return prev
            },
            []
          )
      }
      state.pages = state.pages.map((page, idx) => {
        if (`${idx}` === `${state.selectedPageIndex}`) {
          return state.selectedPage
        }
        return page
      })
      state.selectedElement = {}
      state.selectedType = ''
      state.selectedElementIndex = null
    },
    addSuggestionToSelectedElement: (state, { payload }) => {
      if (!state.selectedElement.suggestions) {
        state.selectedElement.suggestions = []
      }
      state.selectedElement.suggestions.push(payload)
    },
    removeSuggestionsFromSelectedElement: (state, { payload }) => {
      const updatedSuggestions = state.selectedElement.suggestions.filter(
        (option, index) => index !== payload && option
      )
      state.selectedElement.suggestions = updatedSuggestions
    },
    updateSuggestionValueInSelectedElement: (state, { payload }) => {
      const { index, value } = payload
      const updatedSuggestions = state.selectedElement.suggestions.map(
        (option, idx) => (idx === index ? value : option)
      )
      state.selectedElement.suggestions = updatedSuggestions
    },
    changeValidationValues: (state, { payload }) => {
      const { validationName, key, value } = payload
      state.selectedElement.validations = {
        ...state.selectedElement.validations,
        [validationName]: {
          ...state.selectedElement.validations[validationName],
          [key]: value
        }
      }
    },
    // NEXT PAGE ACTIONS
    changeTypeOfNextPage: (state, { payload }) => {
      if (payload === 'name') {
        state.selectedPage.nextPage = {
          ...state.selectedPage.nextPage,
          lookupKey: ''
        }
      } else {
        state.selectedPage.nextPage = {
          ...state.selectedPage.nextPage,
          name: ''
        }
      }
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    changeNameOfNextPage: (state, { payload }) => {
      state.selectedPage.nextPage = {
        ...state.selectedPage.nextPage,
        name: payload
      }
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    changeLookupKeyOfNextPage: (state, { payload }) => {
      state.selectedPage.nextPage = {
        ...state.selectedPage.nextPage,
        lookupKey: payload
      }
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    addNewConditionForKey: (state, { payload }) => {
      if (!state.selectedPage.nextPage.pages) {
        state.selectedPage.nextPage.pages = []
      }
      state.selectedPage.nextPage.pages.push(payload)
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    changeConditionForKey: (state, { payload }) => {
      const { index, value, page } = payload
      state.selectedPage.nextPage.pages[index] = {
        ...state.selectedPage.nextPage.pages[index],
        value,
        page
      }
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    removeConditionForKey: (state, { payload }) => {
      const updatedPages = state.selectedPage.nextPage.pages.filter(
        (_, idx) => idx !== payload
      )
      state.selectedPage.nextPage.pages = updatedPages
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    addRemoveAutoNext: (state, { payload }) => {
      if (payload) state.selectedPage.autoMoveNext = 0
      else delete state.selectedPage.autoMoveNext
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    changeAutoMoveNextValue: (state, { payload }) => {
      state.selectedPage.autoMoveNext = payload
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    changePageClassNameValue: (state, { payload }) => {
      state.selectedPage.className = payload
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    changeAnimatePageValue: (state, { payload }) => {
      state.selectedPage.animatePage = payload
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    addEditLayout: (state, { payload }) => {
      const { name, content } = payload
      state.layouts[name] = content
    },
    deleteLayout: (state, { payload }) => {
      delete state.layouts[payload]
    },
    LayoutToBefore: (state, { payload }) => {
      state.selectedPage.layouts.beforeElements = payload
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    LayoutToAfter: (state, { payload }) => {
      state.selectedPage.layouts.afterElements = payload
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    changeDefaultValueValue: (state, { payload }) => {
      state.selectedElement.defaultValue = {
        value: payload
      }
    },
    changeDefaultValueKey: (state, { payload }) => {
      state.selectedElement.defaultValue = {
        key: payload
      }
    },
    changeDefaultValueFunction: (state, { payload }) => {
      const { functionName, params } = payload
      state.selectedElement.defaultValue = {
        functionName:
          functionName ||
          state.selectedElement?.defaultValue?.functionName ||
          '',
        params: params || state.selectedElement?.defaultValue?.params || []
      }
    },
    updateElementsOrderWithinPage: (state, { payload }) => {
      state.selectedPage.elements = payload
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    removeElementFromPage: (state, { payload }) => {
      const { index, childIndex } = payload
      if (childIndex !== undefined) {
        const updatedChildren = state.selectedPage.elements[
          index
        ].children.filter((_, idx) => idx !== childIndex)
        state.selectedPage.elements[index].children = updatedChildren
      } else {
        const updatedElements = state.selectedPage.elements.filter(
          (_, idx) => idx !== index
        )
        state.selectedPage.elements = updatedElements
      }
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    loadConfig: (state, { payload }) => {
      const { layouts, pages } = payload
      state.layouts = layouts
      state.pages = pages
    },
    loadPage: (state, { payload }) => {
      const page = payload
      state.pages.push(page)
    },
    updateCustomEvent: (state, { payload }) => {
      const { key, eventName } = payload
      if (!key || !eventName) return
      if (!state.selectedPage.customEvents) state.selectedPage.customEvents = {}
      state.selectedPage.customEvents[key] = eventName
      state.pages[state.selectedPageIndex] = state.selectedPage
    },
    updateSelectedElementCustomEvent: (state, { payload }) => {
      const { key, eventName } = payload
      if (!key || !eventName) return
      if (!state.selectedElement.customEvents) {
        state.selectedElement.customEvents = {}
      }
      state.selectedElement.customEvents[key] = eventName
    },
    updateSelectedElementOnClickFunctions: (state, { payload }) => {
      if (!state.selectedElement.onClick) {
        state.selectedElement.onClick = []
      }
      state.selectedElement.onClick = payload
    },
    removePageByIndex: (state, { payload }) => {
      state.pages = state.pages.filter((_, index) => index !== payload)
      state.selectedPage = null
      state.selectedPageIndex = null
      state.builderTabs = 'edit'
    },
    setPageConfig: (state, { payload }) => {
      const { pageIndex, keyPath, value } = payload
      set(state.pages[pageIndex], keyPath, value)
    },
    setPageKeysChange: (state, { payload }) => {
      const { pageIndex, values } = payload
      state.pages[pageIndex] = { ...state.pages[pageIndex], ...values }
    },
    pushComplexElement: (state, { payload }) => {
      state.pages[state.selectedPageIndex].elements.push(payload)
    },
    changeValue: (state, { payload }) => {
      const { id, key, value } = payload
      const idPath = findObjectPaths(
        state.pages[state.selectedPageIndex].elements,
        { value: id }
      )
      set(
        state.pages[state.selectedPageIndex].elements,
        idPath.replace('id', key),
        value
      )
      set(state.selectedPage.elements, idPath.replace('id', key), value)
    }
  }
})
export const {
  updateError,
  changeMode,
  changeBuilderTab,
  createNewPage,
  setSelectedPage,
  toggleElementsDrawer,
  openElementModal,
  closeElementModal,
  addKeysToSelectedElement,
  changeSelectedElementValue,
  addElementToSelectedPage,
  addSuggestionToSelectedElement,
  changeValidationValues,
  changeTypeOfNextPage,
  changeNameOfNextPage,
  addNewConditionForKey,
  changeConditionForKey,
  changeLookupKeyOfNextPage,
  removeConditionForKey,
  addRemoveAutoNext,
  changeAutoMoveNextValue,
  changePageClassNameValue,
  changeSelectedElement,
  removeSuggestionsFromSelectedElement,
  updateSuggestionValueInSelectedElement,
  addEditLayout,
  deleteLayout,
  LayoutToBefore,
  LayoutToAfter,
  changeDefaultValueValue,
  changeDefaultValueKey,
  changeDefaultValueFunction,
  updateElementsOrderWithinPage,
  removeElementFromPage,
  changeAnimatePageValue,
  loadPage,
  loadConfig,
  updateCustomEvent,
  updateSelectedElementCustomEvent,
  updateSelectedElementOnClickFunctions,
  removePageByIndex,
  setPageConfig,
  setPageKeysChange,
  pushComplexElement,
  changeValue
} = formsBuilderSlice.actions
export const useFormsBuilderState = () =>
  useSelector((state) => state.formsBuilder)
export default formsBuilderSlice.reducer
