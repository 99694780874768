import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const InputElementSetting = () => {
  return <Settings typography dimensions colors border display />
}

export const ContainerComponent = ({ className, children }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <div
      className={className}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {children}
    </div>
  )
}

ContainerComponent.craft = {
  displayName: 'Container',
  props: {
    text: 'Text'
  },
  related: {
    toolbar: InputElementSetting
  }
}
