import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useValidations } from 'modules/forms-generator/hooks/useValidations'
import { useEffect, useState } from 'react'

export default function USAZipCodeValidation () {
  const { changeValidation, getValidations } = useValidations()

  const {
    errors,
    setErrorMessage: setSelectedElementErrorMessage,
    clearError
  } = useSelectedElements()

  const [shouldBeUSAZipCode, setShouldBeUSAZipCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const handleChange = ({ validationName, key, value }) => {
    changeValidation({ validationName, key, value })
  }

  useEffect(() => {
    const alreadyRequiredData = getValidations('USAZipCode')
    if (alreadyRequiredData) {
      setShouldBeUSAZipCode(alreadyRequiredData.value)
      setErrorMessage(alreadyRequiredData.message)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (shouldBeUSAZipCode && !errorMessage) {
      setSelectedElementErrorMessage({
        key: 'useUSAZipCode',
        message: 'shouldBeUSAZipCode error message is required'
      })
    } else {
      clearError('useUSAZipCode')
    }

    return () => {
      clearError('useUSAZipCode')
    }
    // eslint-disable-next-line
  }, [errorMessage, shouldBeUSAZipCode])
  return (
    <Box sx={{ display: 'flex', my: '10px', width: '100%' }}>
      <FormControlLabel
        onChange={(_, checked) => {
          setShouldBeUSAZipCode(checked)
          handleChange({
            validationName: 'USAZipCode',
            key: 'value',
            value: checked
          })
        }}
        checked={shouldBeUSAZipCode}
        control={<Checkbox />}
        label='Should be USA ZipCode'
      />
      {shouldBeUSAZipCode && (
        <TextField
          label='Validation error message'
          sx={{ flex: 1 }}
          value={errorMessage}
          helperText={errors.useUSAZipCode}
          error={errors.useUSAZipCode !== true}
          onChange={({ target }) => {
            setErrorMessage(target.value)
            handleChange({
              validationName: 'USAZipCode',
              key: 'message',
              value: target.value
            })
          }}
        />
      )}
    </Box>
  )
}
