import { Button, ButtonGroup } from '@mui/material'
import { useStylesHelpers } from 'hooks/use-styles-helpers'
import React, { useEffect, useState } from 'react'

function ChunkedArray ({ items }) {
  const chunkedItems = []
  const chunkSize = 3
  const [currentClassName, setCurrentClassName] = useState('')
  const { changeselectedclassname } = useStylesHelpers()
  if (items) {
    for (let i = 0; i < items.length; i += chunkSize) {
      chunkedItems.push(items.slice(i, i + chunkSize))
    }
  }
  useEffect(() => {
    if ((items && currentClassName === '') || (!items?.includes(currentClassName) && items)) {
      setCurrentClassName(items[0])
      changeselectedclassname(items[0])
    }
  // eslint-disable-next-line
  }, [chunkedItems])
  return (
    <div>
      {chunkedItems.map((chunk, index) => (
        <ButtonGroup
          variant='outlined'
          aria-label='outlined button group'
          key={index}
          sx={{ mb: 1 }}
        >
          {chunk.map((item, subIndex) => (
            <Button
              key={subIndex}
              sx={{ textTransform: 'none' }}
              variant={
                currentClassName === item
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => {
                setCurrentClassName(item)
                changeselectedclassname(item)
              }}
            >
              {item}{' '}
            </Button>
          ))}
        </ButtonGroup>
      ))}
    </div>
  )
}

export default ChunkedArray
