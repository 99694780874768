import React from 'react'
import FirstElements from './firstelements'
import Layouts from './insert-section/layouts'
export default function InsertComponent () {
  return (
    <div>
      <FirstElements />
      <Layouts />
      {/* <Elements /> */}
    </div>
  )
}
