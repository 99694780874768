import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const SelectInputSetting = () => {
  return <Settings typography dimensions colors border />
}

export const SelectInput = ({ suggestions }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <select
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {suggestions.map(({ text, value }, idx) => (
        <option value={value} key={idx}>
          {text}
        </option>
      ))}
    </select>
  )
}

SelectInput.craft = {
  displayName: 'Select',
  related: {
    toolbar: SelectInputSetting
  }
}
