import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import FormsGeneratorApp from '../modules/forms-generator'
import { useEffect } from 'react'
import useAuth from 'modules/lincx-auth/use-auth'

const FormsGenerator = () => {
  const { navigateIfNotLoggedIn } = useAuth()
  useEffect(() => {
    navigateIfNotLoggedIn('/login')
  // eslint-disable-next-line
  }, [])
  return (
    <DndProvider backend={HTML5Backend}>
      <FormsGeneratorApp />
    </DndProvider>
  )
}
export default FormsGenerator
