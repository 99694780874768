export const IframeSchema = `
<!DOCTYPE html>
<html>
  <head>
    <style>
      *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      .is__hovered {
        outline: 1px dashed red;
      }
      .is__active {
        outline: 1px dashed green;
      }
      .indicator{
        position: absolute;
        height: auto;
        margin-top: -25px;
        font-size: 12px;
        line-height: 12px;
        background-color: #3498db;
        color: #ffffff;
        padding: 5px 0px;
        font-weight: bold;
        display: flex;
      }
      .indicator p {
        padding: 0 5px;
      }
      .indicator svg {
        fill: #ffffff;
        width: 15px;
        height: 15px;
        cursor: pointer;
        padding: 0 5px;
        border-left: 1px solid #ffffff;
      }
      .EditorContainer {
        position: relative;
      }
    </style>
  </head>
  <body
    data-new-gr-c-s-check-loaded="14.1085.0"
    data-gr-ext-installed=""
  ></body>
</html>

`
