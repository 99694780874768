import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MarginHandlers from './margin'

export default function SpacingSettings () {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Spacing</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MarginHandlers />
      </AccordionDetails>
    </Accordion>
  )
}
