import { Box, Tab, Tabs } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import OpacityIcon from '@mui/icons-material/Opacity'
import StyleIcon from '@mui/icons-material/Style'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'
import useFormsLayout from 'modules/forms-generator/hooks/useFormsLayouts'

export default function BuilderTabs () {
  const { builderTabs, selectedPageIndex } = useFormsBuilderState()
  const { changeBuilderSelectedTab } = useFormsLayout()
  const handleTabChange = (_, newValue) => {
    changeBuilderSelectedTab(newValue)
  }
  return (
    <Box
      borderTop='1px solid #dedede'
      boxShadow='1px 1px 9px 0 rgb(0 0 0 / 4%)'
    >
      <Tabs
        value={builderTabs}
        onChange={handleTabChange}
        aria-label='icon label tabs'
        centered
      >
        <Tab value='edit' icon={<EditIcon />} label='Edit' />
        <Tab
          value='design'
          icon={<OpacityIcon />}
          label='Design'
          disabled={
            selectedPageIndex === undefined || selectedPageIndex === null
          }
        />
        <Tab value='templates' icon={<StyleIcon />} label='Templates' />
      </Tabs>
    </Box>
  )
}
