import { useDispatch } from 'react-redux'
import {
  addEditLayout,
  deleteLayout,
  LayoutToAfter,
  LayoutToBefore,
  useFormsBuilderState
} from 'state/slices/formsGenerator/builderSlice'

export default function useLayouts () {
  const { layouts, selectedPage } = useFormsBuilderState()
  const dispatch = useDispatch()

  const addEditManageLayout = (name, content) => {
    dispatch(addEditLayout({ name, content }))
  }

  const removeSpecificLayout = (name) => {
    dispatch(deleteLayout(name))
  }

  const attachLayoutToBeforeElements = (name) => {
    dispatch(LayoutToBefore(name))
  }

  const attachLayoutToAfterElements = (name) => {
    dispatch(LayoutToAfter(name))
  }

  return {
    layouts,
    beforeElements: selectedPage?.layouts?.beforeElements,
    afterElements: selectedPage?.layouts?.afterElements,
    addEditManageLayout,
    removeSpecificLayout,
    attachLayoutToBeforeElements,
    attachLayoutToAfterElements
  }
}
