import { useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import usePages from '../../hooks/usePagesEditor'
import { TextField, FormLabel } from '@mui/material'
import { Add } from '@mui/icons-material'
import { usePredefinedPage } from 'modules/forms-generator/hooks/usePredefinedPage'
import { PREDEFINED_PAGES } from '../pageSetting/predefinedPage'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #dedede',
  boxShadow: 24,
  p: 4
}

export default function NewPageModal ({ open, onClose }) {
  const { addPage, isPageNameExist } = usePages()
  const { importPredefinedPage } = usePredefinedPage()
  const [pageName, setPageName] = useState('')
  const [pageNameHaveError, setPageNameHaveError] = useState(false)
  const [pageNameErrorMessage, setPageNameErrorMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handlePageNameChange = ({ target }) => {
    setPageName(target.value)
    if (target.value.indexOf(' ') >= 0) {
      setPageNameHaveError(true)
      return setPageNameErrorMessage('No Spaces are allowed')
    } else {
      setPageNameHaveError(false)
      setPageNameErrorMessage('')
    }
    if (isPageNameExist(target.value)) {
      setPageNameHaveError(true)
      setPageNameErrorMessage('Page Name Already Exist')
    } else {
      setPageNameHaveError(false)
      setPageNameErrorMessage('')
    }
  }

  const handleAddPageClick = () => {
    if (pageNameHaveError) return
    addPage(pageName)
    setPageName('')
    onClose()
  }

  useEffect(() => {
    return () => {
      setPageName('')
      setPageNameHaveError(false)
      setPageNameErrorMessage('')
    }
  }, [])

  const handleAddPredefinedPageClick = (pageIndex) => {
    if (pageName.length === 0) {
      setErrorMessage('page name is required')
    } else {
      setErrorMessage('')
      importPredefinedPage(pageIndex, pageName)
      setPageName('')
      onClose()
    }
  }

  return (
    <Modal
      aria-labelledby='new-page-modal-title'
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id='new-page-modal-title' variant='h6' component='h2'>
            Create New Page
          </Typography>
          <TextField
            fullWidth
            required
            error={pageNameHaveError}
            sx={{ marginTop: '10px' }}
            placeholder='Please Enter Page Name'
            value={pageName}
            helperText={pageNameErrorMessage}
            onChange={handlePageNameChange}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleAddPageClick()
              }
            }}
          />
          <FormLabel color='error'>{errorMessage}</FormLabel>
          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant='outlined'
              disabled={pageNameHaveError}
              startIcon={<AddIcon />}
              onClick={handleAddPageClick}
            >
              Add Page
            </Button>
          </Box>
          <Box>
            <Typography id='new-page-modal-title' variant='h6' component='h2'>
              predefined pages
            </Typography>
            <Button
              variant='outlined'
              startIcon={<Add />}
              onClick={() =>
                handleAddPredefinedPageClick(PREDEFINED_PAGES.ZIPCODE_PAGE)}
            >
              ZipCode Page
            </Button>
            <Button
              variant='outlined'
              startIcon={<Add />}
              onClick={() =>
                handleAddPredefinedPageClick(
                  PREDEFINED_PAGES.EMAIL_CAPTURE_PAGE
                )}
            >
              Email Capture Page
            </Button>
            <Button
              variant='outlined'
              startIcon={<Add />}
              onClick={() =>
                handleAddPredefinedPageClick(
                  PREDEFINED_PAGES.PHONE_NUMBER_CAPTURE_PAGE
                )}
            >
              Phone Number Capture Page
            </Button>
            <Button
              variant='outlined'
              startIcon={<Add />}
              onClick={() =>
                handleAddPredefinedPageClick(
                  PREDEFINED_PAGES.FULL_NAME_CAPTURE_PAGE
                )}
            >
              Full Name Capture Page
            </Button>
            <Button
              variant='outlined'
              startIcon={<Add />}
              onClick={() =>
                handleAddPredefinedPageClick(
                  PREDEFINED_PAGES.HOME_ADDRESS_CAPTURE_PAGE
                )}
            >
              Home Address Capture Page
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}
