import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  functionsList: [],
  currentFunction: {
    name: '',
    content: ''
  },
  selectedIndex: null
}

export const formJsSlice = createSlice({
  name: 'formJs',
  initialState,
  reducers: {
    selectFunction: (state, { payload }) => {
      state.selectedIndex = payload
      state.currentFunction = state.functionsList[payload]
    },
    addNewFunction: (state) => {
      state.functionsList.push(state.currentFunction)
      state.currentFunction = {
        name: '',
        content: ''
      }
      state.selectedIndex = null
    },
    updateFunction: (state) => {
      state.functionsList[state.selectedIndex] = state.currentFunction
      state.selectedIndex = null
      state.currentFunction = {
        name: '',
        content: ''
      }
    },
    changeCurrentFunction: (state, { payload }) => {
      const { key, value } = payload
      state.currentFunction = { ...state.currentFunction, [key]: value }
    },
    removeFunction: (state, { payload }) => {
      const updatedFunctions = state.functionsList.filter(
        (_, idx) => idx !== payload
      )
      state.functionsList = updatedFunctions
      state.selectedIndex = null
    },
    loadFunctionsList: (state, { payload }) => {
      state.functionsList = payload
    }
  }
})

export const {
  addNewFunction,
  updateFunction,
  changeCurrentFunction,
  selectFunction,
  removeFunction,
  loadFunctionsList
} = formJsSlice.actions

export const useFormJsState = () => useSelector((state) => state.formJs)
export default formJsSlice.reducer
