import { useDispatch } from 'react-redux'
import { loadConfig } from 'state/slices/formsGenerator/builderSlice'
import { loadCssConfig } from 'state/slices/formsGenerator/formCssSlice'
import { loadFunctionsList } from 'state/slices/formsGenerator/formJsSlice'
import { importConfig } from 'state/slices/formsGenerator/pagesFlowSlice'

export const useTemplates = () => {
  const dispatch = useDispatch()
  const importTemplate = (templateData) => {
    dispatch(importConfig({ ...templateData.pagesFlow }))
    dispatch(
      loadConfig({
        pages: templateData.pages || [],
        layouts: templateData.layouts || {}
      })
    )
    dispatch(loadCssConfig({ ...templateData.formCss }))
    dispatch(loadFunctionsList(templateData.formJs.functionsList || []))
  }

  return { importTemplate }
}
