import { configureStore } from '@reduxjs/toolkit'
import editorReducer from './slices/editorSlice'
import stylesReducer from './slices/stylesSlice'
import formsBuilderReducer from './slices/formsGenerator/builderSlice'
import scriptReducer from './slices/scriptSlice'
import formCssReducer from './slices/formsGenerator/formCssSlice'
import formJsReducer from './slices/formsGenerator/formJsSlice'
import pagesFlowReducer from './slices/formsGenerator/pagesFlowSlice'
import toastReducer from './slices/formsGenerator/toastSlice'

export const store = configureStore({
  reducer: {
    editor: editorReducer,
    styles: stylesReducer,
    formsBuilder: formsBuilderReducer,
    scripts: scriptReducer,
    formCss: formCssReducer,
    formJs: formJsReducer,
    pagesFlow: pagesFlowReducer,
    toast: toastReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
})
