import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CodeMirror from '@uiw/react-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import jsBeautify from 'js-beautify'
import useScriptHelpers from '../../../hooks/useJsHelper'

export const JsEditor = ({ jsCode }) => {
  const [jsValue, setJsValue] = useState(jsCode)
  const { addJavascript } = useScriptHelpers()
  const handleChange = React.useCallback((value) => {
    addJavascript(value)
    setJsValue(value)
  }, [addJavascript])

  useEffect(() => {
    const formatedJsCode = jsBeautify.js(jsCode, { indent_size: 2 })
    setJsValue(formatedJsCode)
  }, [jsCode])

  return (
    <JsEditorContainer>
      <Label>Javascript</Label>
      <CodeMirrorContainer>
        <CodeMirror
          value={jsValue}
          height='100%'
          width='100%'
          extensions={[javascript()]}
          onChange={handleChange}
          data-cy='Js__editor'
        />
      </CodeMirrorContainer>
    </JsEditorContainer>
  )
}

const JsEditorContainer = styled.div`
  height: 500px;
  width: 800px;
`
const CodeMirrorContainer = styled.div`
  background: #fff;
  height: calc(100% - 30px);
  overflow: scroll;
  border: 1px solid #ddd;
`
const Label = styled.div`
  font-weight: bold;
  padding: 5px;
`
