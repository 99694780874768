import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'

export const useFormData = () => {
  const { pages, selectedPage } = useFormsBuilderState()

  const getAllFormsElements = () => {
    if (!pages || pages.length === 0) return []
    return pages.reduce((formsElements, page) => {
      const forms = page.elements.filter((element) => element.kind === 'FORM')
      return [...formsElements, ...forms]
    }, [])
  }

  const getAllPagesNames = () => {
    if (!pages || pages.length === 0) return []
    return pages.map((page) => page.name)
  }

  const getKeysArray = () => {
    const allFormsElements = getAllFormsElements()
    if (allFormsElements.length === 0) return []
    const keys = allFormsElements.map((element) => element.key)
    return keys
  }

  const generateForExport = ({ functionsData, cssData, pageFlow }) => {
    const formData = { layouts: {}, pages: pages }
    const exportedCode = `
<div>
  <div id="__lincx__form__" data-zone-id="{{zoneId}}" data-google-apikey=""></div>
  <script>
    window.leadFormValues = ${JSON.stringify(formData)}
  </script>
  <script id="_LINCX_FORM_DATA_" type="application/json">${JSON.stringify(
    formData
  )}</script>
  ${
    cssData
      ? `<script id="_LINCX_CSS_DATA_" type="application/json">${JSON.stringify(
          cssData
        )}</script>`
      : ''
  }
  ${
    functionsData
      ? `<script id="_LINCX_FUNCTIONS_DATA_">${functionsData}</script>
        <script>
          var scriptTag = document.getElementById("_LINCX_FUNCTIONS_DATA_");
          eval(scriptTag.innerHTML);
        </script>`
      : ''
  }
  ${
    pageFlow
      ? `<script id="_LINCX_PAGES_FLOW_DATA_" type="application/json">${JSON.stringify(pageFlow)}</script>`
      : ''
  }
  {{#ads}} {{/ads}}
  <script src="https://lincx-controls.netlify.app/formsGenerator/formsGenerator.js"></script>
</div>`
    return exportedCode
  }

  const getAllOtherPagesNames = () => {
    if (!pages || pages.length === 0) return []
    return pages.reduce((pagesNames, page) => {
      if (page.name !== selectedPage.name) return [...pagesNames, page.name]
      return [...pagesNames]
    }, [])
  }

  const getAllKeysOptions = (keyToFilter = '') => {
    const allFormsData = getAllFormsElements()
    if (allFormsData.length === 0) return []
    const result = allFormsData.reduce((keysOptions, formElement) => {
      if (formElement.suggestions) {
        return {
          ...keysOptions,
          [formElement.key]: formElement.suggestions.map(
            (suggestion) => suggestion.value
          )
        }
      }
      return { ...keysOptions, [formElement.key]: [] }
    }, {})
    if (keyToFilter) return result[keyToFilter]
    return result
  }

  const getOptionsForKey = (selectedKey) => {
    if (selectedKey) return getAllKeysOptions(selectedKey)
    return []
  }

  return {
    getKeysArray,
    generateForExport,
    getAllOtherPagesNames,
    getAllKeysOptions,
    getAllPagesNames,
    getOptionsForKey
  }
}
