import { getFunctionName } from '../components/jsManagement/functionEditor'
import { useLoadConfig } from './useLoadConfig'

export const useImportConfig = () => {
  const { loadFormData, loadFunctions, loadCssData, loadPageFlowData } =
    useLoadConfig()
  const getSelectorInnerContent = (parsedTemplate, selector) => {
    if (!parsedTemplate) return
    const formDataContainer = parsedTemplate.querySelector(selector)
    if (!formDataContainer) return
    return formDataContainer.innerHTML
  }

  const getJSONData = (parsedTemplate, selector) => {
    const data = getSelectorInnerContent(parsedTemplate, selector)
    if (!data) return
    return JSON.parse(data)
  }

  const generateFunctions = (parsedTemplate) => {
    const script = getSelectorInnerContent(
      parsedTemplate,
      '#_LINCX_FUNCTIONS_DATA_'
    )
    const regex =
      /function\s*([A-z0-9]+)?\s*\((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*\)\s*\{(?:[^}{]+|\{(?:[^}{]+|\{[^}{]*\})*\})*\}/gm
    const functions = []
    let currentResult
    while ((currentResult = regex.exec(script)) !== null) {
      if (currentResult.index === regex.lastIndex) regex.lastIndex++
      currentResult.forEach((matchedValue) => {
        if (matchedValue.startsWith('function')) {
          functions.push({
            name: getFunctionName(matchedValue),
            content: matchedValue
          })
        }
      })
    }
    return functions
  }

  const importConfig = (importedHTML) => {
    // eslint-disable-next-line
    const template = new DOMParser().parseFromString(importedHTML, 'text/html')
    const formData = getJSONData(template, '#_LINCX_FORM_DATA_')
    const cssData = getJSONData(template, '#_LINCX_CSS_DATA_')
    const pagesFlow = getJSONData(template, '#_LINCX_PAGES_FLOW_DATA_')
    const functionsData = generateFunctions(template)
    if (
      formData &&
      formData.pages !== undefined &&
      formData.layouts !== undefined
    ) {
      loadFormData({ ...formData })
    }
    if (functionsData !== undefined) {
      loadFunctions(functionsData)
    }
    if (cssData !== undefined) {
      loadCssData(cssData)
    }
    if (pagesFlow) {
      loadPageFlowData(pagesFlow)
    }
  }

  return { importConfig }
}
