import { Autocomplete, Box, TextField } from '@mui/material'
import { useDefaultValue } from 'modules/forms-generator/hooks/useDefaultValue'
import { useFormData } from 'modules/forms-generator/hooks/useFormData'
import { useFormJs } from 'modules/forms-generator/hooks/useFormJs'
import { useMemo } from 'react'

export default function DefaultWithFunction () {
  const { getKeysArray } = useFormData()
  const { changeFunctionNameParams, functionName, params } = useDefaultValue()
  const { functionsList } = useFormJs()

  const functionsNames = useMemo(() => {
    return functionsList.reduce((acc, curr) => {
      if (curr.name !== functionName) acc.push(curr.name)
      return acc
    }, [])
  }, [functionsList, functionName])

  return (
    <Box sx={{ flex: 1 }}>
      <Autocomplete
        disablePortal
        options={functionsNames}
        sx={{ ml: '5px', my: '5px', flex: 1 }}
        onChange={(_, value) => {
          changeFunctionNameParams(value, params)
        }}
        value={functionName}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Choose keys to be passed to the above function in order'
          />
        )}
      />
      <Autocomplete
        disablePortal
        multiple
        options={getKeysArray()}
        sx={{ ml: '5px', flex: 1 }}
        onChange={(_, values) => {
          changeFunctionNameParams(functionName, values)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Choose keys to be passed to the above function in order'
          />
        )}
      />
    </Box>
  )
}
