import ReactDOM from 'react-dom'
import { useEffect, useState, useRef } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import styled from 'styled-components'
import { IframeSchema } from './iframeSchema'
import { DevicesWidth } from './devicesWidth'
import { useFormCss } from 'modules/forms-generator/hooks/useFormCss'

export const WRAPPER_ID = '__LINCX_WRAPPER_EDITOR_IFRAME_'

export default function EditorWrapper ({ children }) {
  const iframeRef = useRef()
  const [iframeRoot, setIframeRoot] = useState(null)

  useEffect(() => {
    const containerDiv = document.createElement('div')
    containerDiv.className = 'EditorContainer'
    setIframeRoot(containerDiv)
  }, [])

  const renderChildrenWithinIframe = () => {
    if (iframeRoot && iframeRef.current.contentDocument.body) {
      iframeRef.current.contentDocument.body.appendChild(iframeRoot)
      injectCss()
    }
  }

  const { changeCurrentDevice, device, injectCss } = useFormCss()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <FormControl>
        <FormLabel id='device-type'>Devices</FormLabel>
        <RadioGroup
          row
          aria-labelledby='device-type'
          name='row-radio-buttons-group'
          value={device}
          onChange={(_, value) => changeCurrentDevice(value)}
        >
          {Object.keys(DevicesWidth).map((key) => (
            <FormControlLabel
              value={key}
              key={key}
              control={<Radio />}
              label={key}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <StyledEditorWrapper
        className='page-container'
        marginX='10px'
        width={DevicesWidth[device]}
        maxHeight='80vh'
        overflow='scroll'
      >
        <Iframe
          srcDoc={IframeSchema}
          ref={iframeRef}
          title='editorWrapper'
          onLoad={renderChildrenWithinIframe}
          id={WRAPPER_ID}
        >
          {iframeRoot && ReactDOM.createPortal(children, iframeRoot)}
        </Iframe>
      </StyledEditorWrapper>
    </Box>
  )
}

const StyledEditorWrapper = styled(Box)`
  font-family: none;
  margin: 0 auto;
  flex: 1;
`

const Iframe = styled.iframe`
  width: 100%;
  border: none;
  height: 100%;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`
