import { Checkbox, Chip, FormControlLabel, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useValidations } from 'modules/forms-generator/hooks/useValidations'
import { useEffect, useState } from 'react'

const RegexValidationsList = {
  email: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
  zipCode: '(^\\d{5}$)|(^\\d{5}-\\d{4}$)',
  address: '([A-Z][a-z]+\\s?)+,\\s[A-Z]{2}\\s\\d{5}-?\\d{4}?',
  date: '^\\d{2}\\/\\d{2}\\/\\d{4}$'
}

export default function RegexValidations () {
  const { changeValidation, getValidations } = useValidations()
  const {
    errors,
    setErrorMessage: setSelectedElementErrorMessage,
    clearError
  } = useSelectedElements()
  const [hasRegexValidation, setHasRegexValidation] = useState(false)
  const [regex, setRegex] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const handleChange = ({ validationName, key, value }) => {
    changeValidation({ validationName, key, value })
  }

  useEffect(() => {
    const alreadyRequiredData = getValidations('regex')
    if (alreadyRequiredData) {
      setHasRegexValidation(alreadyRequiredData.value)
      setRegex(alreadyRequiredData.regex)
      setErrorMessage(alreadyRequiredData.message)
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (hasRegexValidation && (!errorMessage || !regex)) {
      if (!errorMessage) {
        setSelectedElementErrorMessage({
          key: 'regexErrorMessage',
          message: 'regex error message is required'
        })
      }
      if (!regex) {
        setSelectedElementErrorMessage({
          key: 'regexPattern',
          message: 'regex pattern is required'
        })
      }
    } else {
      clearError('regexErrorMessage')
      clearError('regexPattern')
    }

    return () => {
      clearError('regexErrorMessage')
      clearError('regexPattern')
    }
    // eslint-disable-next-line
  }, [errorMessage, hasRegexValidation, regex])

  const changeRegexValueExample = (key) => {
    setRegex(RegexValidationsList[key])
    handleChange({
      validationName: 'regex',
      key: 'regex',
      value: RegexValidationsList[key]
    })
  }
  return (
    <Box
      sx={{
        display: 'flex',
        my: '10px',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '10px' }}>
        <FormControlLabel
          onChange={(_, checked) => {
            setHasRegexValidation(checked)
            handleChange({
              validationName: 'regex',
              key: 'value',
              value: checked
            })
          }}
          checked={hasRegexValidation}
          control={<Checkbox />}
          label='Should Follow a Regex Format'
        />
        {hasRegexValidation && (
          <>
            <Chip
              label='Email Regex'
              sx={{ mr: '5px', cursor: 'pointer' }}
              onClick={() => changeRegexValueExample('email')}
            />
            <Chip
              label='Zip Code Regex'
              sx={{ mr: '5px', cursor: 'pointer' }}
              onClick={() => changeRegexValueExample('zipCode')}
            />
            <Chip
              label='Address Regex'
              sx={{ mr: '5px', cursor: 'pointer' }}
              onClick={() => changeRegexValueExample('address')}
            />
            <Chip
              label='Date Regex'
              sx={{ mr: '5px', cursor: 'pointer' }}
              onClick={() => changeRegexValueExample('date')}
            />
          </>
        )}
      </Box>
      {hasRegexValidation && (
        <Box sx={{ display: 'flex' }}>
          <TextField
            label='Regex String'
            sx={{ flex: 1, mr: '5px' }}
            value={regex}
            helperText={errors.regexPattern}
            error={errors.regexPattern !== true}
            onChange={({ target }) => {
              setRegex(target.value)
              handleChange({
                validationName: 'regex',
                key: 'regex',
                value: target.value
              })
            }}
          />
          <TextField
            label='Validation error message'
            sx={{ flex: 1 }}
            value={errorMessage}
            helperText={errors.regexErrorMessage}
            error={errors.regexErrorMessage !== true}
            onChange={({ target }) => {
              setErrorMessage(target.value)
              handleChange({
                validationName: 'regex',
                key: 'message',
                value: target.value
              })
            }}
          />
        </Box>
      )}
    </Box>
  )
}
