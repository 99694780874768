import { useDispatch } from 'react-redux'
import {
  addNewFunction,
  changeCurrentFunction,
  removeFunction,
  selectFunction,
  updateFunction,
  useFormJsState
} from 'state/slices/formsGenerator/formJsSlice'

export const useFormJs = () => {
  const dispatch = useDispatch()

  const { currentFunction, selectedIndex, functionsList } = useFormJsState()

  const mode = selectedIndex === null ? 'add' : 'edit'

  const editFunction = ({ key, value }) => {
    dispatch(changeCurrentFunction({ key, value }))
  }

  const saveCurrentFunction = () => {
    if (selectedIndex !== null) {
      return dispatch(updateFunction())
    }
    return dispatch(addNewFunction())
  }

  const selectFunctionIndex = (index) => {
    dispatch(selectFunction(index))
  }

  const removeSelectedFunction = (index) => {
    dispatch(removeFunction(index))
  }

  const clearSelectedFunction = () => {
    dispatch(selectFunction(null))
  }

  const isFunctionNameExist = (name) => {
    return functionsList.find((currentFunc) => currentFunc.name === name)
  }

  const generateExportedFunctions = () => {
    const functionsString = functionsList.reduce((accum, current) => {
      return `${accum} ${current.content} \n\n`
    }, '')

    return functionsString
  }

  return {
    currentFunction,
    functionsList,
    mode,
    editFunction,
    saveCurrentFunction,
    selectFunctionIndex,
    clearSelectedFunction,
    isFunctionNameExist,
    removeSelectedFunction,
    generateExportedFunctions
  }
}
