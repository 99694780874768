import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Resizable } from 're-resizable'
import { useNode, useEditor } from '@craftjs/core'
import useEditorHelpers from '../../hooks/useEditorHelpers'
import { Toolbar } from './Toolbar'
import ContentEditable from 'react-contenteditable'
import { useStyles } from '../../hooks/use-styles'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'

export const Headline = ({
  text = 'Example Headline',
  className,
  customEvenet,
  classname,
  dataattribute,
  mustacheTag
}) => {
  const currentComponent = 'Headline'
  const defaultTargets = ['content', 'click link', 'visibility']
  const [isElementSelected, setIsElementSelected] = useState(false)
  const [headlineText, setHeadlineText] = useState(text)
  const [elementHeight, setElementHeight] = useState()
  const [elementWidth, setElementWidth] = useState()
  const {
    connectors: { connect, drag },
    actions: { setProp }
  } = useNode()
  const { ActiveSideBarElements } = useEditorHelpers()
  const { changestyle } = useStylesHelpers()
  const { elementClassName } = useStyles()
  const { selectedDevice, styles, tabletStyle, desktopStyle } = useSelector(
    state => state.styles
  )
  const { selected, Selectedstyles } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected,
      classes: state.nodes[currentNodeId]?.dom?.classList,
      Selectedstyles: state.nodes[currentNodeId]?.data?.props?.styles
    }
  })

  const setStylesValue = (cssKey, value) => {
    if (elementClassName) {
      changestyle({
        classes: [elementClassName],
        Selectedstyles,
        cssKey,
        value
      })
    }
  }

  const handleClick = () => {
    if (selected?.name === currentComponent) {
      setIsElementSelected(true)
    }
    ActiveSideBarElements('Styles')
  }

  const handleContentChange = e => {
    setHeadlineText(e.target.value.replace(/<\/?[^>]+(>|$)/g, ''))
  }

  const handleResize = (e, direction, ref, d) => {
    setStylesValue('height', ref.style.height)
    setStylesValue('width', ref.style.width)
    setElementHeight(ref.style.height)
    setElementWidth(ref.style.width)
    setProp(props => (props.elementClassName = [elementClassName]))
  }

  useEffect(() => {
    if (selected?.name === currentComponent) {
      setIsElementSelected(true)
    } else {
      setIsElementSelected(false)
    }
    // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    setProp(props => (props.elementClassName = [classname !== undefined ? classname : elementClassName]))
  })

  useEffect(() => {
    setProp(props => (props.text = headlineText))
    // eslint-disable-next-line
  }, [headlineText, setProp])

  useEffect(() => {
    if (headlineText === '' && selected?.name !== currentComponent) {
      setProp(props => (props.text = 'Example Headline'))
    }
  }, [selected, setProp, headlineText])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setElementHeight(tabletStyle[elementClassName]?.height)
      setElementWidth(tabletStyle[elementClassName]?.width)
    } else if (selectedDevice === 'desktop') {
      setElementHeight(desktopStyle[elementClassName]?.height)
      setElementWidth(desktopStyle[elementClassName]?.width)
    } else {
      setElementHeight(styles[elementClassName]?.height)
      setElementWidth(styles[elementClassName]?.width)
    }
    // eslint-disable-next-line
  }, [selectedDevice])

  useEffect(() => {
    setProp(
      props =>
        (props.styles = {
          ...props.styles,
          height: elementHeight,
          width: elementWidth
        })
    )
  }, [elementHeight, elementWidth, setProp])

  return (
    <HeadlineWrapper
      ref={connect}
      onClick={handleClick}
      isElementSelected={isElementSelected}
      className={`${classname}--container `}
      data-ce-lincx-type={customEvenet?.type}
      style={{
        width: 'fit-content',
        height: 'fit-content'
      }}
      data-cy='Text__component'
    >
      <Toolbar
        isElementSelected={isElementSelected}
        defaultTargets={defaultTargets}
        drag={drag}
        mustacheTag={mustacheTag}
      />
      <Resizable
        data-cy='Text__element__content'
        style={{ height: elementHeight, width: elementWidth }}
        size={{ elementWidth, elementHeight }}
        onResizeStop={handleResize}
      >
        <ContentEditable
          draggable='false'
          html={text}
          className={`${elementClassName}  ${classname}`}
          dataattribute={dataattribute}
          onChange={handleContentChange}
          customevent={customEvenet?.eventType}
          style={styles}
          data-is-editor-element
        />
      </Resizable>
    </HeadlineWrapper>
  )
}
const HeadlineWrapper = styled.div`
  font-size: 26px;
  outline: ${props => (props.isElementSelected ? '1px dashed #5e5e5e' : 'none')};
  &:hover {
    outline: 1px dashed #5e5e5e;
  }
`
