import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const InputElementSetting = () => {
  return <Settings typography dimensions colors border />
}

export const InputElement = ({
  questionKey,
  type = 'text',
  placeholder = ''
}) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <input
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      name={questionKey}
      type={type}
      readOnly
      placeholder={placeholder}
    />
  )
}

InputElement.craft = {
  displayName: 'Input',
  related: {
    toolbar: InputElementSetting
  }
}
