import { Box, Stack } from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'
import AddElementButton from './addNewElementButton'
import ElementItem from './elementItem'
import LayoutHandler from './layoutHandler'

const SwapElements = (data, from, to) => {
  if (!data || data.length === 0) return false
  const copiedData = [...data]
  const temp = copiedData[from]
  copiedData[from] = copiedData[to]
  copiedData[to] = temp
  return copiedData
}

export default function ElementsList () {
  const { selectedPage, selectedPageIndex } = useFormsBuilderState()
  const { updateElementsOrder } = useSelectedElements()
  const moveCard = (dragIndex, hoverIndex) => {
    const updatedElements = SwapElements(
      selectedPage.elements,
      dragIndex,
      hoverIndex
    )
    if (updatedElements) {
      updateElementsOrder(updatedElements)
    }
  }

  const renderItem = (element, index) => {
    return (
      <ElementItem
        element={element}
        key={index}
        idx={index}
        moveCard={moveCard}
      />
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '0 0 50%',
        borderLeft: '1px solid #c3c7cb',
        height: '100%',
        overflowY: 'scroll'
      }}
    >
      {selectedPageIndex !== undefined && selectedPageIndex !== null && (
        <>
          <LayoutHandler
            label='Layout Before the Form Elements'
            position='top'
          />
          <Stack spacing={2}>
            {selectedPage &&
              selectedPage.elements &&
              selectedPage.elements.map((element, idx) =>
                renderItem(element, idx)
              )}
          </Stack>
          <AddElementButton />
          <LayoutHandler
            label='Layout After the Form Elements'
            position='bottom'
          />
        </>
      )}
    </Box>
  )
}
