import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export const SelectComponentOptions = ({ label, value, onChange, options }) => {
  return (
    <FormControl fullWidth sx={{ my: '10px' }} size='small'>
      <InputLabel id='display-settings'>{label}</InputLabel>
      <Select
        labelId='display-settings'
        id='display-settings-select'
        value={value}
        label={label}
        onChange={onChange}
      >
        {options.map((value) => (
          <MenuItem value={value} key={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
