import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CssEditor } from '../../components/sidebar/ExportHtml/CssEditor'
import useEditorHelpers from '../../hooks/useEditorHelpers'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'
import { appendStyleFromEditor } from '../../utils/styles-utils'

export const Cssmodal = () => {
  const { showCssModal } = useSelector(state => state.editor)
  const { customCssCode } = useSelector(state => state.styles)
  const { showCssModalonEditor } = useEditorHelpers()
  const { addCssCode } = useStylesHelpers()
  const [cssOnEditor, setCssOnEditor] = useState(customCssCode)
  const [cssText, setCssText] = useState()
  const saveCssHandler = () => {
    appendStyleFromEditor(cssText)
    showCssModalonEditor()
    addCssCode(cssOnEditor)
  }
  const closeDialogue = () => {
    showCssModalonEditor()
  }
  const setCurrentCss = (value) => {
    setCssOnEditor(value)
    setCssText(value)
  }

  return (
    <>
      <ExportModal openDialogue={showCssModal}>
        <ModalContainer>
          <DialogueCloseButton onClick={closeDialogue}>
            <img
              src='https://img.icons8.com/material-outlined/24/1A1A1A/delete-sign.png'
              alt='close-icon'
            />
          </DialogueCloseButton>
          <EditorContainer>
            <CssEditor setCurrentCss={setCurrentCss} exportedcss={customCssCode} />
          </EditorContainer>
          <SaveButtonContainer>
            <SaveButton onClick={saveCssHandler}>Save</SaveButton>
          </SaveButtonContainer>
        </ModalContainer>
      </ExportModal>
    </>
  )
}
const ExportModal = styled.div`
  display: ${props => (props.openDialogue ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: #0000009e;
`
const ModalContainer = styled.div`
  position: relative;
  padding: 10px;
  background: #ffff;
  z-index: 1000;
  margin: auto;
  border-radius: 10px;
`
const DialogueCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  img {
    width: 15px;
  }
`
const EditorContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`
const SaveButtonContainer = styled.div`
  display: felx;
  justify-content: flex-end;
  padding: 10px 0;
`
const SaveButton = styled.div`
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  color: #fff;
  background: #28a745;
  &: hover {
    background: #1e7b34;
  button {
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    background: #28a745;
    &: hover {
      background: #1e7b34;
    }
  }
`
