import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNode, useEditor } from '@craftjs/core'
import useEditorHelpers from '../../hooks/useEditorHelpers'

export const ZoneContainer = ({ children, className }) => {
  const {
    connectors: { connect, drag }
  } = useNode()

  const { selectElementOfEditor } = useEditorHelpers()

  const { selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected,
      classes: state.nodes[currentNodeId]?.dom?.classList,
      Selectedstyles: state.nodes[currentNodeId]?.data?.props?.styles
    }
  })

  useEffect(() => {
    selectElementOfEditor(selected?.id)
    // eslint-disable-next-line
  }, [selected])

  return (
    <ContainerWrapper ref={dom => connect(drag(dom))} data-cy='zone__container' className={className?.name}>
      <Label>Zone</Label>
      {children}
    </ContainerWrapper>
  )
}
const ContainerWrapper = styled.div`
  border: 1px dashed #5e5e5e;
  min-height: 300px;
  height: fit-content;
  box-sizing: border-box;
  padding: 10px;
  margin: 50px 15px 0px 15px;
  background: #ffffff;
`
const Label = styled.span`
  color: #ffffff;
  background: #000000;
  align-self: flex-start;
  position: absolute;
  margin: -30px -10px;
  padding: 0 10px;
`
