import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addClassName } from '../state/slices/stylesSlice'

export function useStyles () {
  const dispatch = useDispatch()
  const { styles } = useSelector(state => state.styles)
  const [elementClassName, setElementClassName] = useState()

  useEffect(() => {
    generateClassName(setElementClassName)
    // eslint-disable-next-line
  }, [])
  const generateClassName = callBack => {
    const className = `lx_${(Math.random() + 1).toString(36).substring(7)}`
    if (!styles[className]) {
      dispatch(addClassName(className))
      callBack(className)
    } else {
      generateClassName(callBack)
    }
  }
  const getEditorElementFromTheElementSelected = (node) => {
    const elementConatinerSelectedDom = node
    const SelectedElementsDescendants = elementConatinerSelectedDom?.querySelectorAll('*')
    let editorElementDom
    if (SelectedElementsDescendants?.length === 1) { return elementConatinerSelectedDom }
    SelectedElementsDescendants?.forEach(descendant => {
      if (descendant.hasAttribute('data-is-editor-element')) { editorElementDom = descendant }
    })
    return editorElementDom
  }
  const getClassesFromElement = (element) => {
    if (element) {
      let elementClassList = Array.from(element.classList)
      elementClassList = elementClassList.filter((el) =>
        el !== undefined && el !== 'undefined'
      )
      return elementClassList
    }
    return []
  }
  const getDefaultValueByClassName = (params) => {
    let initialInputValue
    const { selectedClassName, selectedDevice, tabletStyle, desktopStyle, props, unities, setSelectedUnity, setCount } = params
    if (selectedDevice === 'tablet') {
      initialInputValue =
      tabletStyle[selectedClassName]?.[props.cssKey] ||
      styles[selectedClassName]?.[props.cssKey] ||
      props.defaultValue
    } else if (selectedDevice === 'desktop') {
      initialInputValue =
      desktopStyle[selectedClassName]?.[props.cssKey] ||
      tabletStyle[selectedClassName]?.[props.cssKey] ||
      styles[selectedClassName]?.[props.cssKey] ||
      props.defaultValue
    } else {
      initialInputValue =
      styles[selectedClassName]?.[props.cssKey] || props.defaultValue
    }

    const getNumberValue = () => {
      if (selectedDevice === 'tablet') {
        return parseInt(
          tabletStyle[selectedClassName]?.[props.cssKey] || props.defaultValue
        )
      } else if (selectedDevice === 'desktop') {
        return parseInt(
          desktopStyle[selectedClassName]?.[props.cssKey] || props.defaultValue
        )
      } else {
        return parseInt(
          styles[selectedClassName]?.[props.cssKey] || props.defaultValue
        )
      }
    }

    if (styles[selectedClassName]?.[props.cssKey] !== undefined) {
      if (
        unities.includes(styles[selectedClassName]?.[props.cssKey].replace(/\d+/g, ''))
      ) {
        setSelectedUnity(styles[selectedClassName]?.[props.cssKey].replace(/\d+/g, ''))
      }
    } else {
      setSelectedUnity('px')
    }
    if (isNaN(getNumberValue())) {
      setCount(initialInputValue)
    } else {
      setCount(getNumberValue())
    }
  }
  return {
    elementClassName,
    getEditorElementFromTheElementSelected,
    getClassesFromElement,
    getDefaultValueByClassName
  }
}
