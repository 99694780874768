import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useState } from 'react'
import { useToast } from 'state/slices/formsGenerator/toastSlice'

const TableCellWithInput = ({
  edited = false,
  component = 'input',
  cellVal,
  onChange
}) => {
  const [cellValue, setCellValue] = useState(cellVal)
  if (edited) {
    return component === 'input'
      ? (
        <TableCell align='center'>
          <TextField
            id={`for__${cellVal}`}
            defaultValue={cellValue}
            size='small'
            value={cellValue}
            onChange={({ target: { value } }) => {
              onChange(value)
              setCellValue(value)
            }}
          />
        </TableCell>
        )
      : (
        <TableCell align='center'>
          <FormControlLabel
            control={<Checkbox defaultChecked={cellValue} />}
            label='Should Be Submitted'
            value={cellValue}
            onChange={(_, checked) => {
              onChange(checked)
              setCellValue(checked)
            }}
          />
        </TableCell>
        )
  }
  return (
    <TableCell align='center'>
      {typeof cellVal === 'string' ? cellVal : cellVal ? 'Yes' : 'No'}
    </TableCell>
  )
}

const checkSuggestionExistance = (suggestion, list = [], index) => {
  console.log({ suggestion, list })
  if (!('text' in suggestion) || !('value' in suggestion)) return true
  for (const [idx, currentSuggestion] of list.entries()) {
    if (!('text' in currentSuggestion) || !('value' in currentSuggestion)) { return true }
    if (idx === index) return false
    if (
      currentSuggestion.text.toLowerCase() ===
        suggestion.text.toLocaleLowerCase() ||
      currentSuggestion.value.toLowerCase() ===
        suggestion.value.toLocaleLowerCase()
    ) {
      return true
    }
  }
  return false
}

export default function RadioSuggestionsList ({ suggestions = [] }) {
  const { openToast } = useToast()
  const [editedIndex, setEditedIndex] = useState(null)
  const [updatedSuggestion, setUpdatedSuggestion] = useState({})
  const { removeSuggestion, updateSuggestion } = useSelectedElements()

  const handleUpdateSuggestion = (key, value) => {
    setUpdatedSuggestion((prev) => ({ ...prev, [key]: value }))
  }

  const saveChangedValue = (index) => {
    if (checkSuggestionExistance(updatedSuggestion, suggestions, index)) {
      openToast("Suggestion shouldn't be duplicated on the same element")
    } else {
      updateSuggestion(index, updatedSuggestion)
      setEditedIndex((prev) => (prev === index ? null : index))
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='Suggestions List'>
        <TableHead>
          <TableRow>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>
              Text
            </TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>
              Value
            </TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>
              ClassName
            </TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>
              Should be submitted
            </TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suggestions.map(
            ({ text, value, className, shouldBeSubmitted }, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCellWithInput
                  edited={editedIndex === idx}
                  cellVal={text}
                  onChange={(changedValue) => {
                    handleUpdateSuggestion('text', changedValue)
                  }}
                />
                <TableCellWithInput
                  edited={editedIndex === idx}
                  cellVal={value}
                  onChange={(changedValue) => {
                    handleUpdateSuggestion('value', changedValue)
                  }}
                />
                <TableCellWithInput
                  edited={editedIndex === idx}
                  cellVal={className}
                  onChange={(changedValue) => {
                    handleUpdateSuggestion('className', changedValue)
                  }}
                />
                <TableCellWithInput
                  edited={editedIndex === idx}
                  cellVal={shouldBeSubmitted}
                  component='checkBox'
                  onChange={(changedValue) => {
                    handleUpdateSuggestion('shouldBeSubmitted', changedValue)
                  }}
                />
                <TableCell align='center'>
                  {editedIndex === idx
                    ? (
                      <IconButton
                        aria-label='edit'
                        size='small'
                        onClick={() => {
                          saveChangedValue(idx)
                        }}
                      >
                        <SaveIcon fontSize='small' color='red' />
                      </IconButton>
                      )
                    : (
                      <IconButton
                        aria-label='save'
                        size='small'
                        onClick={() => {
                          setEditedIndex((prev) => (prev === idx ? null : idx))
                          setUpdatedSuggestion({
                            text,
                            value,
                            className,
                            shouldBeSubmitted
                          })
                        }}
                      >
                        <EditIcon fontSize='small' color='red' />
                      </IconButton>
                      )}
                  <IconButton
                    aria-label='delete'
                    size='small'
                    onClick={() => removeSuggestion(idx)}
                  >
                    <DeleteIcon fontSize='small' color='red' />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
