import React from 'react'
import styled from 'styled-components'
import { useNode } from '@craftjs/core'

export const Container = ({ background, padding = 0, children }) => {
  const {
    connectors: { connect, drag }
  } = useNode()
  return (
    <ContainerWrapper
      style={{ margin: '5px 0', background, padding: `${padding}px` }}
      ref={ref => connect(drag(ref))}
    >
      {children}
    </ContainerWrapper>
  )
}
const ContainerWrapper = styled.div`
  border: 1px solid;
  width: 80vw;
`
