import { Image } from '../Image'
import { Headline } from '../Headline'
import { Bullets } from '../Bullets'
import { Cta } from '../Cta'
import { Element } from '@craftjs/core'
import { AdContainer } from '../AdContainer'
import { ElementContainer } from '../ElementContainer'

const layout1DefaultStyles = {
  adContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imageStyle: {
    height: '200',
    width: '200'
  },
  headlineStyle: {
    fontSize: '26',
    width: 'fit-content'
  },
  bullesStyle: {
    fontSize: '16'
  },
  ctaStyle: {
    display: 'flex',
    justifyContent: 'center'
  },
  ctaContainer1: {
  }
}

export const Layout1 = () => {
  return (
    <Element id='ad-container' is={AdContainer} styles={layout1DefaultStyles.adContainer} canvas>
      <Image imgSource='' imgName='image-icon' styles={layout1DefaultStyles.imageStyle} />
      <ElementContainer styles={layout1DefaultStyles.ctaContainer1}>
        <Headline styles={layout1DefaultStyles.headlineStyle} />
        <Bullets bullets={['bullet-1', 'bullet-2', 'bullet-3', 'bullet-4']} styles={layout1DefaultStyles.bullesStyle} />
      </ElementContainer>
      <Cta styles={layout1DefaultStyles.ctaStyle} />
    </Element>
  )
}
