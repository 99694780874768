import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'

export default function GoNextHandler () {
  const { selectedElement, changeElement } = useSelectedElements()
  return (
    <FormGroup sx={{ my: '10px' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedElement?.role === 'NEXT'}
            onChange={(_, checked) =>
              checked
                ? changeElement('role', 'NEXT')
                : changeElement('role', '')}
          />
        }
        label='Go Next Page (When Client select an option we move to the next Page)'
      />
    </FormGroup>
  )
}
