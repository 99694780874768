import { Box, Button, TextField } from '@mui/material'
import { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { range } from 'lodash'

export default function OptionsGenerator ({ show }) {
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(10)
  const [step, setStep] = useState(1)
  const [labelLayout, setLabelLayout] = useState('[VALUE]')
  const { addSuggestion } = useSelectedElements()

  const generateAndSave = () => {
    range(minValue, Number(maxValue) + 1, step).map((value) =>
      addSuggestion({
        text: labelLayout.replace('[VALUE]', value),
        value: value.toString()
      })
    )
  }

  if (!show) return null
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <TextField
        sx={{ my: '10px' }}
        required
        type='number'
        label='Min Value'
        value={minValue}
        onChange={({ target }) => setMinValue(target.value)}
      />
      <TextField
        sx={{ my: '10px' }}
        required
        type='number'
        label='Value'
        value={maxValue}
        onChange={({ target }) => setMaxValue(target.value)}
      />
      <TextField
        sx={{ my: '10px' }}
        required
        type='number'
        step='any'
        label='Step'
        value={step}
        onChange={({ target }) => setStep(target.value)}
      />
      <TextField
        sx={{ my: '10px' }}
        required
        label='Text Value Layout'
        value={labelLayout}
        onChange={({ target }) => setLabelLayout(target.value)}
      />
      <Button
        variant='outlined'
        startIcon={<AddIcon />}
        onClick={generateAndSave}
      >
        Generate and Save
      </Button>
    </Box>
  )
}
