import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { LabelComponent } from '../label'
import { RadioInputComponent } from '../radioInput'
import { InputContainerComponent } from './inputContainer'
import { Element, useEditor } from '@craftjs/core'
import { Settings } from '../../settings'
import { useEffect, useState } from 'react'
import { ErrorSpanComponent } from '../errorSpan'
import { getFirstValidationErrorMessage } from 'modules/utils/getFirstValidationErrorMessage'

export const InputContainerSetting = () => {
  return <Settings typography dimensions colors border display withError />
}

export default function RadioButtonComponent ({
  className = '',
  questionKey,
  suggestions = [],
  validations
}) {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const { withError } = useEditor((state, _) => ({
    withError:
      state.nodes[id] && state.nodes[id].dom
        ? state.nodes[id].dom.classList.contains('with__error')
        : false
  }))

  useEffect(() => {
    setShowErrorMessage(withError)
  }, [withError])

  return (
    <div
      className={`${className} radio__buttons--container`}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {suggestions.map((suggestion, idx) => (
        <Element
          canvas
          is={InputContainerComponent}
          className={suggestion.className}
          key={`${suggestion.value}_${idx}`}
          id={`element_radio_input_container_choice_${suggestion.value}`}
        >
          <Element
            canvas
            id={`element_radio_input_choice_${suggestion.value}`}
            is={RadioInputComponent}
            className={`input__${suggestion.className}`}
            value={suggestion.value}
            questionKey={questionKey}
          />
          <Element
            canvas
            is={LabelComponent}
            id={`element_radio_label_choice_${suggestion.value}`}
            text={suggestion.text}
            value={suggestion.value}
            questionKey={questionKey}
            className={`label__${suggestion.className}`}
          />
        </Element>
      ))}
      {showErrorMessage && (
        <Element
          canvas
          is={ErrorSpanComponent}
          text={getFirstValidationErrorMessage(validations)}
          id={`error__span__${questionKey}`}
          className='error__span'
        />
      )}
    </div>
  )
}

RadioButtonComponent.craft = {
  displayName: 'RadioButton',
  props: {
    text: 'RadioButton'
  },
  related: {
    toolbar: InputContainerSetting
  }
}
