import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useEditor } from '@craftjs/core'

export const AddMustacheTag = ({ defaultTargets, mustacheTag, setActiveToolOption, isActive }) => {
  const [isAddMustacheTagSelected, setIsAddMustacheTagSelected] = useState(
    false
  )
  const [listOfTags, setListOfTags] = useState(mustacheTag || [])
  const [attributeSpecificTags, setAttributeSpecificTags] = useState([])
  const [attributeSpecificTag, setAttributeSpecificTag] = useState({
    tagName: '',
    attributeName: ''
  })
  const [target, setTarget] = useState(defaultTargets?.[0])
  const [hasError, setError] = useState({
    tagName: false,
    target: false,
    dataAttribute: false,
    tagToAttribute: false
  })
  const [tagName, setTagName] = useState('')
  const mustacheTagIcon = '{ }'
  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected
    }
  })

  const handleClick = () => {
    setActiveToolOption('AddMustacheTagOption')
    setIsAddMustacheTagSelected(!isAddMustacheTagSelected)
  }

  const handleInputChange = e => {
    setTagName(e.target.value)
    setError({ ...hasError, tagName: false })
  }

  useEffect(() => {
    actions.setProp(selected?.id, props => {
      props.mustacheTag = listOfTags
    })
    // eslint-disable-next-line
  }, [listOfTags])

  useEffect(() => {
    actions.setProp(selected?.id, props => {
      props.dataattribute = attributeSpecificTags
    })
    // eslint-disable-next-line
  }, [attributeSpecificTags, selected?.id])

  useEffect(() => {
    if (isActive === 'AddMustacheTagOption') {
      setIsAddMustacheTagSelected(true)
    } else {
      setIsAddMustacheTagSelected(false)
    }
  }, [isActive])

  const handleAddMustacheTag = e => {
    if (!tagName) {
      setError({ ...hasError, tagName: true })
      return
    }
    const targetAlredyExist = listOfTags.filter(tag => tag.target === target)
    if (targetAlredyExist[0]) {
      setError({ ...hasError, target: true })
      return
    }

    if (!hasError.tagName && !hasError.target) {
      setListOfTags([...listOfTags, { name: tagName, target: target }])
      setTagName('')
    }
  }

  const handleTargetChange = e => {
    setTarget(e.target.value)
    setError({ ...hasError, target: false })
  }

  const handleRemoveTag = e => {
    const tagName = e.target.id
    setListOfTags(listOfTags.filter(tag => tag.name !== tagName))
  }

  const handleRemoveAttributeSpecificTag = e => {
    const attributeName = e.target.id
    setAttributeSpecificTags(
      attributeSpecificTags.filter(tag => tag.attributeName !== attributeName)
    )
  }

  const handleAddSpecificTag = () => {
    if (!attributeSpecificTag.attributeName) {
      setError({ ...hasError, dataAttribute: true })
      return
    }
    if (!attributeSpecificTag.tagName) {
      setError({ ...hasError, tagToAttribute: true })
      return
    }
    const targetAlredyExist = attributeSpecificTags.filter(
      tag => tag.attributeName === attributeSpecificTag.attributeName
    )
    if (targetAlredyExist[0]) {
      setError({ ...hasError, dataAttribute: true })
      return
    }

    if (!hasError.dataAttribute && !hasError.tagToAttribute) {
      setAttributeSpecificTags([...attributeSpecificTags, attributeSpecificTag])
      setAttributeSpecificTag({ tagName: '', attributeName: '' })
    }
  }

  const handleMustacheTagChange = e => {
    setAttributeSpecificTag({
      ...attributeSpecificTag,
      tagName: e.target.value
    })
    setError({ ...hasError, tagToAttribute: false })
  }

  const handleDataAttributeChange = e => {
    setAttributeSpecificTag({
      ...attributeSpecificTag,
      attributeName: e.target.value
    })
    setError({ ...hasError, dataAttribute: false })
  }

  return (
    <AddMustacheTagContainer>
      <span data-cy='mustache__tag__button' onClick={handleClick}>{mustacheTagIcon}</span>
      <MustacheTagInputContainer
        isAddMustacheTagSelected={isAddMustacheTagSelected}
      >
        <AddTagContainer>
          <MustacheTagIcon>{mustacheTagIcon}</MustacheTagIcon>
          <TagNameInput
            onChange={handleInputChange}
            placeholder='Add Mustache Tag'
            value={tagName}
            hasError={hasError.tagName}
            data-cy='mustache__tag__input'
          />
          <TargetContainer
            value={target}
            onChange={handleTargetChange}
            hasError={hasError.target}
            data-cy='data__attribute__select'
          >
            {defaultTargets?.map((target, index) => (
              <option key={index} value={target}>
                {target}
              </option>
            ))}
          </TargetContainer>
          <AddMustacheTagField onClick={handleAddMustacheTag} data-cy='Add__mustache__tag__button'>
            <img
              src='https://img.icons8.com/ios-filled/50/000000/plus-math.png'
              alt='add-icon'
            />
          </AddMustacheTagField>
        </AddTagContainer>
        <ListOfMustacheTag data-cy='List__of__Added__mustache__tags'>
          {listOfTags.map((tag, index) => (
            <MustacheTagItem key={index}>
              <div>{tag?.name}</div>
              <div>{tag?.target}</div>
              <RemoveButton
                onClick={handleRemoveTag}
                id={tag?.name}
                src='https://img.icons8.com/external-becris-lineal-becris/64/000000/external-cancel-mintab-for-ios-becris-lineal-becris.png'
                alt='remove-tag-icon'
              />
            </MustacheTagItem>
          ))}
        </ListOfMustacheTag>
        <AddTagContainer>
          <MustacheTagIcon>{mustacheTagIcon}</MustacheTagIcon>
          <TagNameInput
            placeholder='mustache-tag'
            value={attributeSpecificTag.tagName}
            hasError={hasError.tagToAttribute}
            onChange={handleMustacheTagChange}
            data-cy='custom__mustache__tag__input'
          />
          <TargetDataAttributInput
            placeholder='data-attribute'
            value={attributeSpecificTag.attributeName}
            hasError={hasError.dataAttribute}
            onChange={handleDataAttributeChange}
            data-cy='data__attribute__input'
          />
          <AddMustacheTagField onClick={handleAddSpecificTag} data-cy='Add__custom__mustache__tag__button'>
            <img
              src='https://img.icons8.com/ios-filled/50/000000/plus-math.png'
              alt='add-icon'
            />
          </AddMustacheTagField>
        </AddTagContainer>
        <ListOfMustacheTag data-cy='List__of__Added__custom__mustache__tags'>
          {attributeSpecificTags.map((dataAttribute, index) => (
            <MustacheTagItem key={index}>
              <div>{dataAttribute?.tagName}</div>
              <div>{dataAttribute?.attributeName}</div>
              <RemoveButton
                onClick={handleRemoveAttributeSpecificTag}
                id={dataAttribute?.attributeName}
                src='https://img.icons8.com/external-becris-lineal-becris/64/000000/external-cancel-mintab-for-ios-becris-lineal-becris.png'
                alt='remove-tag-icon'
              />
            </MustacheTagItem>
          ))}
        </ListOfMustacheTag>
      </MustacheTagInputContainer>
    </AddMustacheTagContainer>
  )
}

const AddMustacheTagContainer = styled.div`
  font-size: 23px;
  padding: 5px 8px;
  display: flex;
  border-right: 1px solid #aeaeae;
  z-index: 100;
  span {
    white-space: nowrap;
  }
`
const MustacheTagInputContainer = styled.div`
  margin-top: 35px;
  position: absolute;
  display: ${props => (props?.isAddMustacheTagSelected ? 'flex' : 'none')};
  flex-direction: column;
  margin-left: -50px;
  padding: 3px;
  gap: 10px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 5px 5px #707070;
  padding: 10px;
`
const AddTagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 5px 5px #e4e4e4;
  padding: 5px;
`
const MustacheTagIcon = styled.div`
  white-space: nowrap;
`
const TagNameInput = styled.input`
  height: 30px;
  ${props => props?.hasError && 'border: 1px solid red'};
`
const TargetContainer = styled.select`
  height: 36px;
  ${props => props?.hasError && 'border: 1px solid red'};
`
const AddMustacheTagField = styled.div``
const ListOfMustacheTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`
const MustacheTagItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px;
  box-shadow: 0px 5px 5px #e4e4e4;
`
const RemoveButton = styled.img`
  width: 15px !important;
  height: 15px;
`

const TargetDataAttributInput = styled.input`
  height: 30px;
  ${props => props?.hasError && 'border: 1px solid red'};
`
