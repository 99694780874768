import React from 'react'
import MinPlusInput from './min-plus-input'
import styled from 'styled-components'
import { Container } from './dimensions-styled'
export default function BorderRadiusSection () {
  return (
    <Wrapper>
      <Container
        center='space-around'
        data-cy='border__radius__styling__section'
      >
        <MinPlusInput
          title='All slides'
          cssKey='borderRadius'
          defaultValue='0'
        />
        <MinPlusInput
          title='top left'
          cssKey='borderTopLeftRadius'
          defaultValue='0'
        />
        <MinPlusInput
          title='top Right'
          cssKey='borderTopRightRadius'
          defaultValue='0'
        />
        <MinPlusInput
          title='bottom left'
          cssKey='borderBottomLeftRadius'
          defaultValue='0'
        />
        <MinPlusInput
          title='bottom Right'
          cssKey='borderBottomRightRadius'
          defaultValue='0'
        />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 12px;
`
