export const ELEMENTS_TYPES = {
  CONTAINER: 'CONTAINER',
  HEADLINE: 'TITLE',
  PARAGRAPH: 'PARAGRAPH',
  BUTTON: 'BUTTON',
  RADIO_BUTTONS: 'RADIO_BUTTONS',
  DROPDOWN_SELECT: 'DROPDOWN_SELECT',
  RANGE_SLIDER: 'RANGE_SLIDER',
  FORM_TEXT: 'TEXT',
  FORM_PHONE: 'PHONE',
  FORM_ERN_ADDRESS: 'ERN_ADDRESS',
  CHECKBOX: 'CHECKBOX'
}
