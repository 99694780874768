import axios from 'axios'

const GOOGLE_FONTS_LOCAL_STORAGE_KEY = 'GOOGLE_FONTS_LOCAL_STORAGE_KEY'

export const getGoogleFonts = async () => {
  const localGoogleFonts = window.localStorage.getItem(GOOGLE_FONTS_LOCAL_STORAGE_KEY)
  if (localGoogleFonts) return JSON.parse(localGoogleFonts)
  return await fetchGoogleFonts()
}

const fetchGoogleFonts = async () => {
  const API_URL = 'https://content-webfonts.googleapis.com/v1/webfonts?sort=POPULARITY&key=AIzaSyBtxsdywfUwS8CMG2Ufc7TPYlyx8YvSRXw'
  try {
    const { data } = await axios.get(API_URL)
    window.localStorage.setItem(
      GOOGLE_FONTS_LOCAL_STORAGE_KEY,
      JSON.stringify(data.items)
    )
    return data.items
  } catch (error) {
    console.error('Google Fonts Error:', error)
    return []
  }
}
