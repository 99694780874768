import {
  convertCssObjectToString,
  injectGoogleFonts,
  injectStylesToHead
} from 'modules/utils/convertCssObjectToString'
import { useDispatch } from 'react-redux'
import {
  addNewGoogleFont,
  changeDevice,
  changePlainCss,
  selectSelector,
  updateKey,
  useFormCssState
} from 'state/slices/formsGenerator/formCssSlice'
import { DevicesWidth } from '../components/design/devicesWidth'

const getTrimedClassName = (className, tagName = '') => {
  if (!className) {
    if (tagName === 'div') return
    return tagName
  }
  return `${className
    .trim()
    .split(' .')
    .join('.')
    .replace('is__hovered', '')
    .replace('is__active', '')}`
}

const getSelector = ({
  className = '',
  elementTag = '',
  parentHasError = false,
  parentClassName = ''
}) => {
  if (!parentHasError) return getTrimedClassName(className, elementTag)
  if (parentClassName) {
    return `${getTrimedClassName(parentClassName)} ${getTrimedClassName(
      className,
      elementTag
    )}`
  }
  return `${getTrimedClassName(className, elementTag)}`
}

export const useFormCss = () => {
  const { css, tabletCss, desktopCss, device, googleFonts, plainCss } =
    useFormCssState()
  const dispatch = useDispatch()

  const elementSelected = ({
    className = '',
    elementTag = '',
    parentHasError = false,
    parentClassName = ''
  }) => {
    const selector = getSelector({
      className,
      elementTag,
      parentHasError,
      parentClassName
    })
    dispatch(selectSelector(selector))
  }

  const changeSelectorKeyValue = ({ key, value, unit }) => {
    dispatch(updateKey({ key, value, unit }))
  }

  const changeCss = (cssValue) => {
    dispatch(changePlainCss(cssValue))
  }

  const newGoogleFont = (font) => {
    dispatch(addNewGoogleFont(font))
  }

  const changeCurrentDevice = (device = 'mobile') => {
    dispatch(changeDevice(device))
  }

  const getCurrentUsedCssObject = () => {
    switch (device) {
      case 'mobile':
        return css
      case 'tablet':
        return tabletCss
      case 'desktop':
        return desktopCss
      default:
        break
    }
  }

  const injectCss = () => {
    injectStylesToHead(
      `${injectGoogleFonts(googleFonts)} \n \n
      ${convertCssObjectToString(css)}\n\n
      @media only screen and (min-width: ${DevicesWidth.tablet}) {
        ${convertCssObjectToString(tabletCss)}
      }
      @media only screen and (min-width: ${DevicesWidth.desktop}) {
        ${convertCssObjectToString(desktopCss)}
      }
      \n\n
      ${plainCss}`
    )
  }

  return {
    elementSelected,
    changeSelectorKeyValue,
    changeCss,
    newGoogleFont,
    changeCurrentDevice,
    getCurrentUsedCssObject,
    injectCss,
    device
  }
}
