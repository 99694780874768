import { Element } from '@craftjs/core'
import { AdContainer } from '../AdContainer'
import { ElementContainer } from '../ElementContainer'
import { Headline } from '../Headline'

const layout1DefaultStyles = {
  adContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export const EmptyLayout = () => {
  return (
    <Element
      id='ad-container'
      is={AdContainer}
      styles={layout1DefaultStyles.adContainer}
      canvas
    >
      <ElementContainer>
        <Headline text='start by editing here' />
      </ElementContainer>
    </Element>
  )
}
