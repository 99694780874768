import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import CodeMirror from '@uiw/react-codemirror'
import { useState } from 'react'
import { html } from '@codemirror/lang-html'
import { useImportConfig } from 'modules/forms-generator/hooks/useImportConfig'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #dedede',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch'
}

export default function ImportFormModal ({ open, onClose }) {
  const { importConfig } = useImportConfig()
  const [htmlImport, setHtmlImport] = useState('')

  const handleImportFormConfig = () => {
    importConfig(htmlImport)
    setHtmlImport('')
    onClose()
  }
  return (
    <Modal
      aria-labelledby='import-form'
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id='import-form' variant='h6' component='h2'>
            Create New Page
          </Typography>
          <Box sx={{ flex: 1, overflow: 'scroll' }}>
            <CodeMirror
              value={htmlImport}
              height='100%'
              extensions={[html()]}
              onChange={(value) => setHtmlImport(value)}
            />
          </Box>

          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant='outlined'
              startIcon={<Add />}
              onClick={handleImportFormConfig}
            >
              Import Form
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}
