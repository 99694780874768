import { Autocomplete, TextField } from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'

export default function DefaultValueHandler ({
  elementKey,
  elementOptionsKey,
  placeHolder
}) {
  const { selectedElement, changeElement } = useSelectedElements()
  const handleChange = (e, selectedValue) => {
    const selectedIndex = selectedElement[elementOptionsKey].findIndex(
      (elem) => elem.value === selectedValue.value
    )
    changeElement(elementKey, selectedIndex)
  }
  return (
    <Autocomplete
      disablePortal
      getOptionLabel={(option) => option.text}
      options={selectedElement[elementOptionsKey]}
      sx={{ mt: '5px' }}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={placeHolder} />}
    />
  )
}
