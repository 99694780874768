import React from 'react'
import MinPlusInput from './min-plus-input'
import styled from 'styled-components'
import { Container } from './dimensions-styled'
export default function PaddingSection () {
  return (
    <Wrapper>
      <Container center='space-around' data-cy='padding__styling__section'>
        <MinPlusInput title='top' cssKey='paddingTop' defaultValue='0' />
        <MinPlusInput
          title='right slides'
          cssKey='paddingRight'
          defaultValue='0'
        />
        <MinPlusInput title='bottom' cssKey='paddingBottom' defaultValue='0' />
        <MinPlusInput title='left' defaultValue='0' cssKey='paddingLeft' />
        <MinPlusInput title='All slides' cssKey='padding' defaultValue='0' />
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div``
