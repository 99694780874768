import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

export default function SuggestionsList ({ data, onDelete, onEdit }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ my: '10px', mx: 'auto', width: '90%' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Text Value</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Option Value</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope='row'>{row.text}</TableCell>
              <TableCell scope='row'>{row.value}</TableCell>
              <TableCell scope='row' align='right'>
                <IconButton aria-label='edit' onClick={() => onEdit(row, idx)}>
                  <EditIcon />
                </IconButton>
                <IconButton aria-label='delete' onClick={() => onDelete(idx)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
