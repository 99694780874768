import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Resizable } from 're-resizable'
import { useNode, useEditor } from '@craftjs/core'
import { Toolbar } from './Toolbar'
import useEditorHelpers from '../../hooks/useEditorHelpers'
import ContentEditable from 'react-contenteditable'
import { useStyles } from '../../hooks/use-styles'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'

export const Cta = ({
  ctaText = 'Example CTA',
  className,
  mustacheTag,
  customEvenet,
  href = '#',
  classname,
  dataattribute
}) => {
  const currentElement = 'Cta'
  const defaultTargets = ['href', 'content', 'visibility', 'target']
  const [isElementSelected, setIsElementSelected] = useState(false)
  const [elementHeight, setElementHeight] = useState()
  const [elementWidth, setElementWidth] = useState()
  const [ctaContent, setCtaContent] = useState(ctaText)
  const { ActiveSideBarElements } = useEditorHelpers()
  const { elementClassName } = useStyles()
  const { changestyle } = useStylesHelpers()
  const {
    connectors: { connect, drag },
    actions: { setProp }
  } = useNode()
  const { selectedDevice, styles, tabletStyle, desktopStyle } = useSelector(
    state => state.styles
  )
  const { selected, Selectedstyles } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected,
      classes: state.nodes[currentNodeId]?.dom?.classList,
      Selectedstyles: state.nodes[currentNodeId]?.data?.props?.styles
    }
  })

  const setStylesValue = (cssKey, value) => {
    if (elementClassName) {
      changestyle({
        classes: [elementClassName],
        Selectedstyles,
        cssKey,
        value
      })
    }
  }

  const handleClick = () => {
    ActiveSideBarElements('Styles')
    if (selected.name === currentElement) {
      setIsElementSelected(true)
    }
  }

  const handleContentChange = e => {
    const updatedCtaContent = e.target.value
    setCtaContent(updatedCtaContent)
    setProp(prop => (prop.ctaText = updatedCtaContent))
  }

  const handleResize = (e, direction, ref, d) => {
    setStylesValue('height', ref.style.height)
    setStylesValue('width', ref.style.width)
    setElementHeight(ref.style.height)
    setElementWidth(ref.style.width)
    setProp(props => (props.elementClassName = [elementClassName]))
  }

  useEffect(() => {
    if (selected?.name === currentElement) {
      setIsElementSelected(true)
    } else {
      setIsElementSelected(false)
    }
    // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    setProp(props => (props.elementClassName = [elementClassName]))
  })

  useEffect(() => {
    if (ctaContent === '' && selected?.name !== currentElement) {
      setProp(props => (props.ctaText = 'Example CTA'))
    }
  }, [selected, setProp, ctaContent])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setElementHeight(tabletStyle[elementClassName]?.height)
      setElementWidth(tabletStyle[elementClassName]?.width)
    } else if (selectedDevice === 'desktop') {
      setElementHeight(desktopStyle[elementClassName]?.height)
      setElementWidth(desktopStyle[elementClassName]?.width)
    } else {
      setElementHeight(styles[elementClassName]?.height)
      setElementWidth(styles[elementClassName]?.width)
    }
    // eslint-disable-next-line
  }, [selectedDevice])

  return (
    <CtaWrapper
      ref={connect}
      onClick={handleClick}
      style={
        {
          width: 'fit-content',
          height: 'fit-content'
        }
      }
      isElementSelected={isElementSelected}
      className={`${classname}--container`}
      data-cy='cta__component'
    >
      <Toolbar isElementSelected={isElementSelected} defaultTargets={defaultTargets} drag={drag} mustacheTag={mustacheTag} />
      <Resizable style={{ height: elementHeight, width: elementWidth }} size={{ elementWidth, elementHeight }} data-cy='cta__component__resize' onResizeStop={handleResize}>
        <ContentEditable
          html={ctaText}
          tagName='a'
          href={href}
          className={`${elementClassName} ${classname}`}
          data-ce-lincx-type={customEvenet?.type}
          data-lincx-cta
          data-cy='cta__component__content'
          target='_blank'
          dataattribute={dataattribute}
          onChange={handleContentChange}
          customevent={customEvenet?.eventType}
          style={styles}
          data-is-editor-element
        />
      </Resizable>
    </CtaWrapper>
  )
}
const CtaWrapper = styled.div`
  display: flex;
  justify-content: center;
  outline: ${props => (props.isElementSelected ? '1px dashed #5e5e5e' : 'none')};
  &:hover {
    outline: 1px dashed #5e5e5e;
  }
`
