import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SelectComponentOptions } from '../components/Select'
import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'

export default function OverflowHandler () {
  const [overflowValue, setOverflowValue] = useCssKeyValue({
    key: 'overflow',
    value: 'visible'
  })
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Overflow</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SelectComponentOptions
          label='Overflow'
          value={overflowValue}
          onChange={({ target }) => setOverflowValue({ value: target.value })}
          options={['visible', 'hidden', 'scroll', 'auto']}
        />
      </AccordionDetails>
    </Accordion>
  )
}
