import styled from 'styled-components'

export const ListCustomEvents = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const RemoveButton = styled.img`
  width: 15px !important;
  height: 15px;
`
