import BorderSetting from './border'
import BorderRadius from './borderRadius'
import ColorsSettings from './colors'
import DimensionsSetting from './dimensions'
import DisplaySetting from './display'
import OverflowHandler from './overflow'
import SpacingSettings from './spacing'
import TypographySetting from './typography'
import WithErrorState from './withError'

export const Settings = ({
  typography,
  dimensions,
  colors,
  border,
  display,
  withError,
  borderRadius = true,
  overflow = true
}) => {
  return (
    <div>
      {withError && <WithErrorState />}
      {typography && <TypographySetting />}
      {dimensions && <DimensionsSetting />}
      {dimensions && <SpacingSettings />}
      {overflow && <OverflowHandler />}
      {colors && <ColorsSettings />}
      {border && <BorderRadius />}
      {borderRadius && <BorderSetting />}
      {display && <DisplaySetting />}
    </div>
  )
}
