import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button, TextField } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import FormLabel from '@mui/material/FormLabel'
import styled from 'styled-components'

export default function MinPlusUi (props) {
  return (
    <>
      <FormLabel>{props.title}</FormLabel>
      <InputContainer>
        <Button
          variant='outlined'
          style={{
            maxWidth: '30px',
            maxHeight: '30px',
            minWidth: '30px',
            minHeight: '40px'
          }}
          data-testid='decrement-plus-min'
          onClick={props.handleDecrementCount}
          data-cy={props.cssKey + '__decrement'}
        >
          <RemoveIcon />
        </Button>
        <TextField
          size='small'
          id='filled-hidden-label-small'
          value={props.inputValue}
          onChange={props.handleChange}
          style={{ padding: 0, minWidth: '80px', maxWidth: '30px' }}
          disabled={props.inputDisabled}
          inputProps={{ 'data-testid': 'input-plus-min' }}
          data-cy='min__plus__input'
        />
        <UnitSelector onChange={props.handleUnitSlection} value={props.unity} data-cy={props.cssKey + '__unity__select'}>
          <option value='px'>px</option>
          <option value='pt'>pt</option>
          <option value='pc'>pc</option>
          <option value='cm'>cm</option>
          <option value='mm'>mm</option>
          <option value='in'>in</option>
          <option value='em'>em</option>
          <option value='rem'>rem</option>
          <option value='vh'>vh</option>
          <option value='vw'>vw</option>
          <option value='vmin'>vmin</option>
          <option value='vmax'>vmax</option>
        </UnitSelector>
        <Button
          variant='outlined'
          style={{
            maxWidth: '30px',
            maxHeight: '30px',
            minWidth: '30px',
            minHeight: '40px'
          }}
          data-testid='increment-plus-min'
          onClick={props.handleIncrementCount}
          data-cy={props.cssKey + '__increment'}
        >
          <AddIcon />
        </Button>
      </InputContainer>
    </>
  )
}
const InputContainer = styled.div`
  display: flex;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
const UnitSelector = styled.select`
  border: 1px solid #ddd;
  font-size: 16px;
`
