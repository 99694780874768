export const parseStyle = (styles) => {
  return `${convertObjectToCss(styles?.styles)}
  ${
    hasClass(styles?.tabletStyle)
    ? `
    @media only screen and (min-width: 481px)  {
      ${convertObjectToCss(styles?.tabletStyle)}
    }
    `
: ''
  }

  @media only screen and (min-width: 769px)  {
    ${convertObjectToCss(styles?.desktopStyle)}
  }
  `
}

const hasClass = (styles) => {
  return Object.keys(styles).length > 0
}

const convertObjectToCss = (obj) => {
  const styleWithElement = `${Object.keys(obj).map((className) => (`.${className}{
    ${Object.keys(obj[className]).map((styleKey) => (`${camelCaseToDashed(styleKey)}: ${obj[className][styleKey]};`)).join('')}
  }`)).join('')}`
  return styleWithElement
}

const camelCaseToDashed = (string) => {
  return string.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
}
