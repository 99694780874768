import { Box } from '@mui/system'
import ElementsDrawer from '../elements-drawer'
import { useElementsDrawer } from 'modules/forms-generator/hooks/useElementsDrawer'
import ElementModal from '../elementSettings/modals'
import PagesFlow from './pages-flow'
import ElementsList from './elementsList'

export default function ElementsBuilder () {
  const { openDrawer, toggleDrawer } = useElementsDrawer()
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '100%'
        }}
      >
        <PagesFlow />
        <ElementsList />
      </Box>
      <ElementsDrawer isOpen={openDrawer} onClose={toggleDrawer} />
      <ElementModal />
    </>
  )
}
