import { WRAPPER_ID } from 'modules/forms-generator/components/design/wrapper'

export const convertCssObjectToString = (css) => {
  if (!css) return ''
  return Object.keys(css).reduce((accum, currentSelector) => {
    accum = `${accum} ${currentSelector} {\n ${convertSelectorStylesObject(
      css[currentSelector]
    )} }\n`
    return accum
  }, '')
}

const convertSelectorStylesObject = (selector) => {
  return Object.keys(selector).reduce((preValue, currValue) => {
    if (selector[currValue].value === undefined) return preValue
    preValue = `${preValue} ${currValue}: ${selector[currValue].value}${
      selector[currValue].unit
        ? selector[currValue].value === 'auto' ||
          selector[currValue].value === 'none'
          ? ''
          : selector[currValue].unit
        : ''
    }; \n`
    return preValue
  }, '')
}

export const injectGoogleFonts = (googleFonts = []) => {
  return googleFonts.reduce((accum, currValue) => {
    const { family, variants } = currValue
    const updatedFamily = `${family.replace(/ /g, '+')}`
    const updatedVariants =
      variants.length > 0
        ? `wght@${variants.filter((variant) => !isNaN(variant)).join(';')}`
        : ''
    return `${accum} @import url('https://fonts.googleapis.com/css2?family=${updatedFamily}:${updatedVariants}&display=swap'); \n`
  }, '')
}

export const injectStylesToHead = (styles) => {
  const stylesQuerySelector = '#lincx__form__style'
  const editorDocument =
    document.getElementById(WRAPPER_ID).contentWindow.document
  const head =
    editorDocument.head || editorDocument.getElementsByTagName('head')[0]
  const style =
    editorDocument.querySelector(stylesQuerySelector) ||
    editorDocument.createElement('style')
  style.id = 'lincx__form__style'

  head.appendChild(style)

  style.type = 'text/css'
  if (style.styleSheet) {
    style.styleSheet.cssText = ''
    style.styleSheet.cssText = styles
  } else {
    style.innerHTML = ''
    style.appendChild(editorDocument.createTextNode(styles))
  }
}
