import React from 'react'
import styled from 'styled-components'
import { useEditor, Element } from '@craftjs/core'
import { Headline } from '../User/Headline'
import { Image } from '../User/Image'
import { Bullets } from '../User/Bullets'
import { Cta } from '../User/Cta'
import useEditorHelpers from '../../hooks/useEditorHelpers'
import { ElementContainer } from 'components/User/ElementContainer'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded'
import JavascriptSharpIcon from '@mui/icons-material/JavascriptSharp'
import CssSharpIcon from '@mui/icons-material/CssSharp'
import TabUnselectedSharpIcon from '@mui/icons-material/TabUnselectedSharp'

export default function FirstElements () {
  const { connectors } = useEditor()
  const { showCssModalonEditor, showJsModalonEditor } = useEditorHelpers()
  const handleCreatingBullets = (ref) => {
    connectors.create(
      ref,
      <Bullets bullets={['bullet-1', 'bullet-2', 'bullet-3', 'bullet-4']} />
    )
  }
  const handleCreatingCta = (ref) => {
    connectors.create(ref, <Cta />)
  }
  const handleCssButtonClick = () => {
    showCssModalonEditor()
  }
  const hanldeCreateContainer = (ref) => {
    connectors.create(ref, <Element canvas is={ElementContainer} />)
  }
  const openJsEditor = () => {
    showJsModalonEditor()
  }
  return (
    <Container data-cy='sidebar__components__section'>
      <LayoutTitle>
        <Title>Components</Title>
      </LayoutTitle>
      <Wrapper>
        <ElemetContainer
          data-cy='Text__element'
          ref={ref => connectors.create(ref, <Headline />)}
        >
          <TextFieldsIcon fontSize='large' color='primary' />
        </ElemetContainer>
        <ElemetContainer ref={handleCreatingCta} data-cy='Link__element'>
          <AddLinkRoundedIcon fontSize='large' color='primary' />
        </ElemetContainer>
        <ElemetContainer data-cy='List__element' ref={handleCreatingBullets}>
          <ListAltRoundedIcon fontSize='large' color='primary' />
        </ElemetContainer>
        <ElemetContainer
          data-cy='Image__element'
          ref={ref => connectors.create(ref, <Image />)}
        >
          <AddPhotoAlternateRoundedIcon fontSize='large' color='primary' />
        </ElemetContainer>
        <ElemetContainer data-cy='JS__element' onClick={openJsEditor}>
          <JavascriptSharpIcon fontSize='large' color='primary' />
        </ElemetContainer>
        <ElemetContainer data-cy='CSS__element' onClick={handleCssButtonClick}>
          <CssSharpIcon fontSize='large' color='primary' />
        </ElemetContainer>
        <ElemetContainer ref={hanldeCreateContainer}>
          <TabUnselectedSharpIcon fontSize='large' color='primary' />
        </ElemetContainer>
      </Wrapper>
    </Container>
  )
}
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    flex-direction:column;
}`
const Title = styled.div`
  margin: 6px 0px;
  display: flex;
  width: 60%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #5f6368;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  &:hover {
    color: #202124;
  }
`
const ElemetContainer = styled.div`
  margin: 15px 10px;
  display: flex;
  width: 20%;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #d0d0d0;
  border-radius: 12px;
  padding: 3px;
  max-height: 60px;
  height: 55px;
  align-items:center;
  :hover{
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
`
const LayoutTitle = styled.div`
  display: flex;
  justify-content: space-around;
  color: #5f6368;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
  text-align: left;
`
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  width: 100%;
`
