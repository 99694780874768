import { useDispatch } from 'react-redux'
import { updateScript } from '../state/slices/scriptSlice'

export default function useScriptHelpers () {
  const dispatch = useDispatch()

  const addJavascript = (params) => {
    dispatch(updateScript(params))
  }
  return {
    addJavascript
  }
}
