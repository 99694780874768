import {
  convertCssObjectToString,
  injectGoogleFonts
} from 'modules/utils/convertCssObjectToString'
import { useEffect, useState } from 'react'
import { useFormCssState } from 'state/slices/formsGenerator/formCssSlice'
import { usePagesFlowState } from 'state/slices/formsGenerator/pagesFlowSlice'
import { DevicesWidth } from '../components/design/devicesWidth'
import { useFormData } from './useFormData'
import { useFormJs } from './useFormJs'

export const useExport = () => {
  const { generateForExport } = useFormData()
  const { css, plainCss, googleFonts, tabletCss, desktopCss } =
    useFormCssState()
  const { nodes, edges } = usePagesFlowState()
  const [exportedCode, setExportedCode] = useState('')
  const [exportedStyles, setExportedStyles] = useState('')
  const { generateExportedFunctions } = useFormJs()
  useEffect(() => {
    setExportedCode(
      `${generateForExport({
        functionsData: generateExportedFunctions(),
        cssData: { css, plainCss },
        pageFlow: { nodes, edges }
      })}`
    )
  }, [
    generateForExport,
    generateExportedFunctions,
    css,
    plainCss,
    desktopCss,
    tabletCss,
    googleFonts,
    nodes,
    edges
  ])

  useEffect(() => {
    setExportedStyles(`${injectGoogleFonts(googleFonts)} \n \n
    ${convertCssObjectToString(css)}\n\n
    @media only screen and (min-width: ${DevicesWidth.tablet}) {
      ${convertCssObjectToString(tabletCss)}
    }
    @media only screen and (min-width: ${DevicesWidth.desktop}) {
      ${convertCssObjectToString(desktopCss)}
    }
    \n\n
    ${plainCss}`)
  }, [css, plainCss, desktopCss, tabletCss, googleFonts])

  return { exportedCode, exportedStyles }
}
