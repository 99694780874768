import React, { useState } from 'react'
import styled from 'styled-components'
import { DeleteElement } from './DeleteElement'
import { ClassListAdder } from './ClassListAdder'
import { AddCustomEvent } from './AddCustomEvent'
import { AddMustacheTag } from './AddMustacheTag'
import useEditorHelpers from '../../hooks/useEditorHelpers'
export const Toolbar = (props) => {
  const { ActiveSideBarElements } = useEditorHelpers()
  const [activeToolOption, setActiveToolOption] = useState(false)
  return (
    <ToolbarContainer isElementSelected={props.isElementSelected} data-cy='toolbar__util'>
      <DraggingTool ref={props?.drag}>
        <img src='https://img.icons8.com/material-outlined/24/000000/resize-four-directions.png' alt='drag-icon' />
      </DraggingTool>
      <ClassListAdder isActive={activeToolOption} setActiveToolOption={setActiveToolOption} />
      <AddMustacheTag defaultTargets={props.defaultTargets} mustacheTag={props.mustacheTag} isActive={activeToolOption} setActiveToolOption={setActiveToolOption} />
      <Tools onClick={() => ActiveSideBarElements('Styles')}>
        <img src='https://img.icons8.com/material-sharp/24/000000/edit--v1.png' alt='icon-edit' />
      </Tools>
      <AddCustomEvent isActive={activeToolOption} setActiveToolOption={setActiveToolOption} />
      <DeleteElement />
    </ToolbarContainer>
  )
}

const ToolbarContainer = styled.div`
  display: ${props => (props.isElementSelected ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  margin-top: -41px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 5px 5px #e4e4e4;
  img {
    width: 27px;
  }
`
const Tools = styled.div`
  font-size: 23px;
  padding: 5px;
  display: flex;
  border-right: 1px solid #aeaeae;
`
const DraggingTool = styled.div`
  font-size: 23px;
  padding: 5px;
  display: flex;
  border-right: 1px solid #aeaeae;
`
