import { v4 } from 'uuid'

export const LabelInputElementData = {
  kind: 'CONTAINER',
  className: 'form__container',
  keys: ['firstName'],
  id: v4(),
  children: [
    {
      id: v4(),
      kind: 'TEXT',
      type: 'PARAGRAPH',
      className: 'form__label',
      value: 'First Name'
    },
    {
      id: v4(),
      kind: 'FORM',
      type: 'TEXT',
      className: 'form__input',
      placeholder: 'Enter your first name',
      key: 'firstName',
      validations: {
        required: {
          value: true,
          message: 'First name is required'
        }
      }
    }
  ]
}
export const LabelInputElementStyles = {
  css: {
    '.form__input': {
      'margin-left': {
        value: '10',
        unit: 'px'
      },
      'margin-right': {
        value: '10',
        unit: 'px'
      },
      'margin-bottom': {
        value: '10',
        unit: 'px'
      }
    },
    'p.form__label': {
      'margin-left': {
        value: '10',
        unit: 'px'
      },
      'margin-right': {
        value: '10',
        unit: 'px'
      },
      'margin-bottom': {
        value: '10',
        unit: 'px'
      }
    },
    'div.form__container': {
      'margin-left': {
        value: '10',
        unit: 'px'
      },
      'margin-right': {
        value: '10',
        unit: 'px'
      },
      'margin-top': {
        value: '10',
        unit: 'px'
      },
      'margin-bottom': {
        value: '10',
        unit: 'px'
      },
      'font-family': {
        value: 'Roboto'
      },
      'font-size': {
        value: 12,
        unit: 'px'
      }
    }
  },
  tabletCss: {
    '.form__input': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'p.form__label': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'div.form__container': {
      'margin-left': {},
      'margin-right': {},
      'margin-top': {},
      'margin-bottom': {},
      'font-family': {},
      'font-size': {}
    }
  },
  desktopCss: {
    '.form__input': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'p.form__label': {
      'margin-left': {},
      'margin-right': {},
      'margin-bottom': {}
    },
    'div.form__container': {
      'margin-left': {},
      'margin-right': {},
      'margin-top': {},
      'margin-bottom': {},
      'font-family': {},
      'font-size': {}
    }
  }
}
