import { useDispatch } from 'react-redux'
import {
  closeElementModal,
  openElementModal,
  pushComplexElement,
  toggleElementsDrawer,
  useFormsBuilderState
} from 'state/slices/formsGenerator/builderSlice'
import { pushNewStyles } from 'state/slices/formsGenerator/formCssSlice'

export const useElementsDrawer = () => {
  const { showElementsDrawer, showElementModal, selectedType } =
    useFormsBuilderState()
  const dispatch = useDispatch()

  const toggleDrawer = (containerIndex) => {
    dispatch(toggleElementsDrawer(containerIndex))
  }

  const toggleModal = (type = '') => {
    if (type !== '') return dispatch(openElementModal(type))
    dispatch(closeElementModal(''))
  }

  const handlePushComplexElement = (element) => {
    dispatch(pushComplexElement(element))
  }

  const handlePushElementStyles = (styling) => {
    dispatch(pushNewStyles(styling))
  }

  return {
    openDrawer: showElementsDrawer,
    toggleDrawer,
    openModal: showElementModal,
    toggleModal,
    selectedType,
    handlePushComplexElement,
    handlePushElementStyles
  }
}
