import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useFormJs } from 'modules/forms-generator/hooks/useFormJs'

export default function FunctionsList () {
  const { functionsList, selectFunctionIndex, removeSelectedFunction } =
    useFormJs()
  const onSelectFunction = (functionIndex) => {
    selectFunctionIndex(functionIndex)
  }
  const onRemoveFunction = (functionIndex) => {
    removeSelectedFunction(functionIndex)
  }
  return (
    <TableContainer component={Paper} sx={{ m: '10px', width: '50%' }}>
      <Table sx={{ width: 650 }} aria-label='Functions Table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Function Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {functionsList.map((functionElement, idx) => (
            <TableRow
              key={functionElement.name + idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {functionElement.name}
              </TableCell>
              <TableCell align='right'>
                <IconButton
                  aria-label='edit'
                  onClick={() => {
                    onSelectFunction(idx)
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label='delete'
                  onClick={() => {
                    onRemoveFunction(idx)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
