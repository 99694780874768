import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { setPageConfig } from 'state/slices/formsGenerator/builderSlice'
import usePages from './usePagesEditor'

export const usePagesConfig = ({ keyPath, defaultValue }) => {
  const dispatch = useDispatch()
  const { pages, selectedPageIndex } = usePages()

  const handleChange = (newValue, pageIndex = selectedPageIndex) => {
    if (pageIndex === undefined || pageIndex === null) return
    dispatch(setPageConfig({ pageIndex, keyPath, value: newValue }))
  }

  const getCurrentValue = (pageIndex = selectedPageIndex) => {
    return get(pages[pageIndex], keyPath) || defaultValue
  }

  return [getCurrentValue, handleChange]
}
