export const WrapIcon = () => (
  <svg
    width='348'
    height='100'
    viewBox='0 0 348 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 10C0 4.47715 4.47715 0 10 0H90C95.5228 0 100 4.47715 100 10V90C100 95.5228 95.5228 100 90 100H10C4.47715 100 0 95.5228 0 90V10Z'
      fill='#D9D9D9'
    />
    <path
      d='M248 10C248 4.47715 252.477 0 258 0H338C343.523 0 348 4.47715 348 10V90C348 95.5228 343.523 100 338 100H258C252.477 100 248 95.5228 248 90V10Z'
      fill='#D9D9D9'
    />
    <path
      d='M124 10C124 4.47715 128.477 0 134 0H214C219.523 0 224 4.47715 224 10V90C224 95.5228 219.523 100 214 100H134C128.477 100 124 95.5228 124 90V10Z'
      fill='black'
    />
  </svg>
)

export const NoWrapIcon = () => (
  <svg
    width='348'
    height='223'
    viewBox='0 0 348 223'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 10C0 4.47715 4.47715 0 10 0H90C95.5228 0 100 4.47715 100 10V90C100 95.5228 95.5228 100 90 100H10C4.47715 100 0 95.5228 0 90V10Z'
      fill='#D9D9D9'
    />
    <path
      d='M248 10C248 4.47715 252.477 0 258 0H338C343.523 0 348 4.47715 348 10V90C348 95.5228 343.523 100 338 100H258C252.477 100 248 95.5228 248 90V10Z'
      fill='#D9D9D9'
    />
    <path
      d='M124 10C124 4.47715 128.477 0 134 0H214C219.523 0 224 4.47715 224 10V90C224 95.5228 219.523 100 214 100H134C128.477 100 124 95.5228 124 90V10Z'
      fill='black'
    />
    <path
      d='M0 133C0 127.477 4.47715 123 10 123H90C95.5228 123 100 127.477 100 133V213C100 218.523 95.5228 223 90 223H10C4.47715 223 0 218.523 0 213V133Z'
      fill='black'
    />
    <path
      d='M248 133C248 127.477 252.477 123 258 123H338C343.523 123 348 127.477 348 133V213C348 218.523 343.523 223 338 223H258C252.477 223 248 218.523 248 213V133Z'
      fill='black'
    />
    <path
      d='M124 133C124 127.477 128.477 123 134 123H214C219.523 123 224 127.477 224 133V213C224 218.523 219.523 223 214 223H134C128.477 223 124 218.523 124 213V133Z'
      fill='#D9D9D9'
    />
  </svg>
)

export const AlignCenterIcon = () => (
  <svg
    width='482'
    height='294'
    viewBox='0 0 482 294'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M63 204C63 198.477 67.4772 194 73 194H401C406.523 194 411 198.477 411 204V284C411 289.523 406.523 294 401 294H73C67.4772 294 63 289.523 63 284V204Z'
      fill='black'
    />
    <path
      d='M63 10C63 4.47715 67.4772 0 73 0H401C406.523 0 411 4.47715 411 10V90C411 95.5228 406.523 100 401 100H73C67.4772 100 63 95.5228 63 90V10Z'
      fill='black'
    />
    <path
      d='M0 139C0 133.477 4.47715 129 10 129H472C477.523 129 482 133.477 482 139V160C482 165.523 477.523 170 472 170H10C4.47715 170 0 165.523 0 160V139Z'
      fill='black'
    />
  </svg>
)

export const AlignTopIcon = () => (
  <svg
    width='482'
    height='288'
    viewBox='0 0 482 288'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M63 75C63 69.4772 67.4772 65 73 65H401C406.523 65 411 69.4772 411 75V155C411 160.523 406.523 165 401 165H73C67.4772 165 63 160.523 63 155V75Z'
      fill='black'
    />
    <path
      d='M63 198C63 192.477 67.4772 188 73 188H401C406.523 188 411 192.477 411 198V278C411 283.523 406.523 288 401 288H73C67.4772 288 63 283.523 63 278V198Z'
      fill='black'
    />
    <path
      d='M0 10C0 4.47715 4.47715 0 10 0H472C477.523 0 482 4.47715 482 10V31C482 36.5228 477.523 41 472 41H10C4.47715 41 0 36.5228 0 31V10Z'
      fill='black'
    />
  </svg>
)

export const AlignBottomIcon = () => (
  <svg
    width='482'
    height='288'
    viewBox='0 0 482 288'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M419 213C419 218.523 414.523 223 409 223L81 223C75.4772 223 71 218.523 71 213V133C71 127.477 75.4772 123 81 123L409 123C414.523 123 419 127.477 419 133V213Z'
      fill='black'
    />
    <path
      d='M419 90C419 95.5228 414.523 100 409 100L81 100C75.4772 100 71 95.5228 71 90L71 10C71 4.47715 75.4772 0 81 0L409 0C414.523 0 419 4.47715 419 10L419 90Z'
      fill='black'
    />
    <path
      d='M482 278C482 283.523 477.523 288 472 288L10 288C4.47715 288 0 283.523 0 278V257C0 251.477 4.47715 247 10 247L472 247C477.523 247 482 251.477 482 257V278Z'
      fill='black'
    />
  </svg>
)

export const SpaceAroundIcon = () => (
  <svg
    width='374'
    height='353'
    viewBox='0 0 374 353'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M325.116 289C325.116 294.523 321.642 299 317.357 299L62.8506 299C58.5653 299 55.0913 294.523 55.0913 289V209C55.0913 203.477 58.5653 199 62.8506 199L317.357 199C321.642 199 325.116 203.477 325.116 209V289Z'
      fill='black'
    />
    <path
      d='M325.116 144C325.116 149.523 321.642 154 317.357 154L62.8506 154C58.5653 154 55.0913 149.523 55.0913 144L55.0913 64C55.0913 58.4772 58.5653 54 62.8506 54L317.357 54C321.642 54 325.116 58.4772 325.116 64L325.116 144Z'
      fill='black'
    />
    <path
      d='M374 31C374 36.5228 370.526 41 366.241 41L7.75933 41C3.47397 41 -1.38748e-06 36.5228 -1.38748e-06 31V10C-1.38748e-06 4.47715 3.47397 0 7.75933 0L366.241 0C370.526 0 374 4.47715 374 10V31Z'
      fill='black'
    />
    <path
      d='M374 343C374 348.523 370.526 353 366.241 353L7.75933 353C3.47397 353 -1.38748e-06 348.523 -1.38748e-06 343V322C-1.38748e-06 316.477 3.47397 312 7.75933 312L366.241 312C370.526 312 374 316.477 374 322V343Z'
      fill='black'
    />
  </svg>
)

export const SpaceBetweenIcon = () => (
  <svg
    width='374'
    height='353'
    viewBox='0 0 374 353'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M325.116 343C325.116 348.523 321.642 353 317.357 353L62.8506 353C58.5653 353 55.0913 348.523 55.0913 343V263C55.0913 257.477 58.5653 253 62.8506 253L317.357 253C321.642 253 325.116 257.477 325.116 263V303V343Z'
      fill='black'
    />
    <path
      d='M325.116 91C325.116 96.5228 321.642 101 317.357 101L62.8506 101C58.5653 101 55.0913 96.5228 55.0913 91V11C55.0913 5.47715 58.5653 1 62.8506 1L317.357 1C321.642 1 325.116 5.47715 325.116 11V91Z'
      fill='black'
    />
    <path
      d='M374 31C374 36.5228 370.526 41 366.241 41L7.75934 41C3.47398 41 0 36.5228 0 31V10C0 4.47715 3.47398 0 7.75934 0L366.241 0C370.526 0 374 4.47715 374 10V31Z'
      fill='black'
    />
    <path
      d='M374 343C374 348.523 370.526 353 366.241 353L7.75934 353C3.47398 353 0 348.523 0 343V322C0 316.477 3.47398 312 7.75934 312L366.241 312C370.526 312 374 316.477 374 322V343Z'
      fill='black'
    />
  </svg>
)

export const AlignStretchIcon = () => (
  <svg
    width='374'
    height='353'
    viewBox='0 0 374 353'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M325.116 302C325.116 307.523 321.642 312 317.357 312L62.8506 312C58.5653 312 55.0913 307.523 55.0913 302V222C55.0913 216.477 58.5653 192 62.8506 192L317.357 192C321.642 192 325.116 216.477 325.116 222V302Z'
      fill='black'
    />
    <path
      d='M325.116 131C325.116 136.523 321.642 158 317.357 158L62.8506 158C58.5653 158 55.0913 136.523 55.0913 131L55.0913 51C55.0913 45.4772 58.5653 41 62.8506 41L317.357 41C321.642 41 325.116 45.4772 325.116 51L325.116 131Z'
      fill='black'
    />
    <path
      d='M374 31C374 36.5228 370.526 41 366.241 41L7.75934 41C3.47398 41 0 36.5228 0 31V10C0 4.47715 3.47398 0 7.75934 0L366.241 0C370.526 0 374 4.47715 374 10V31Z'
      fill='black'
    />
    <path
      d='M374 343C374 348.523 370.526 353 366.241 353L7.75934 353C3.47398 353 0 348.523 0 343V322C0 316.477 3.47398 312 7.75934 312L366.241 312C370.526 312 374 316.477 374 322V343Z'
      fill='black'
    />
  </svg>
)

export const JustifyCenterIcon = () => (
  <svg
    width='288'
    height='284'
    viewBox='0 0 288 284'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 63C0 57.4771 4.47715 53 10 53H90C95.5228 53 100 57.4772 100 63V221C100 226.523 95.5228 231 90 231H10C4.47715 231 0 226.523 0 221V63Z'
      fill='black'
    />
    <path
      d='M129 10C129 4.47715 133.477 0 139 0H149C154.523 0 159 4.47715 159 10V274C159 279.523 154.523 284 149 284H139C133.477 284 129 279.523 129 274V10Z'
      fill='black'
    />
    <path
      d='M188 63C188 57.4771 192.477 53 198 53H278C283.523 53 288 57.4772 288 63V221C288 226.523 283.523 231 278 231H198C192.477 231 188 226.523 188 221V63Z'
      fill='black'
    />
  </svg>
)

export const JustifyFlexStartIcon = () => (
  <svg
    width='288'
    height='284'
    viewBox='0 0 288 284'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M188 63.0002C188 57.4774 192.477 53.0002 198 53.0002H278C283.523 53.0002 288 57.4774 288 63.0002V221C288 226.523 283.523 231 278 231H198C192.477 231 188 226.523 188 221V63.0002Z'
      fill='black'
    />
    <path
      d='M0.000183105 10.0002C0.000183105 4.4774 4.47733 0.000244141 10.0002 0.000244141H20.0002C25.523 0.000244141 30.0002 4.4774 30.0002 10.0002V274C30.0002 279.523 25.523 284 20.0002 284H10.0002C4.47733 284 0.000183105 279.523 0.000183105 274V10.0002Z'
      fill='black'
    />
    <path
      d='M58.9999 63.0002C58.9999 57.4774 63.477 53.0002 68.9999 53.0002H149C154.523 53.0002 159 57.4774 159 63.0002V221C159 226.523 154.523 231 149 231H68.9999C63.477 231 58.9999 226.523 58.9999 221V63.0002Z'
      fill='black'
    />
  </svg>
)

export const JustifyFlexEndIcon = () => (
  <svg
    width='288'
    height='284'
    viewBox='0 0 288 284'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M100 221C100 226.523 95.5228 231 90 231H10C4.47715 231 0 226.523 0 221V63C0 57.4772 4.47715 53 10 53H90C95.5228 53 100 57.4772 100 63L100 221Z'
      fill='black'
    />
    <path
      d='M288 274C288 279.523 283.523 284 278 284H268C262.477 284 258 279.523 258 274L258 10.0001C258 4.47721 262.477 6.10352e-05 268 6.10352e-05H278C283.523 6.10352e-05 288 4.47721 288 10.0001L288 274Z'
      fill='black'
    />
    <path
      d='M229 221C229 226.523 224.523 231 219 231L139 231C133.477 231 129 226.523 129 221L129 63C129 57.4772 133.477 53 139 53L219 53C224.523 53 229 57.4772 229 63L229 221Z'
      fill='black'
    />
  </svg>
)

export const JustifySpaceBetweenIcon = () => (
  <svg
    width='289'
    height='290'
    viewBox='0 0 289 290'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M30 69C30 63.4772 34.4772 59 40 59H120C125.523 59 130 63.4772 130 69V227C130 232.523 125.523 237 120 237H40C34.4772 237 30 232.523 30 227V69Z'
      fill='black'
    />
    <path
      d='M0 10C0 4.47715 4.47715 0 10 0H20C25.5228 0 30 4.47715 30 10V274C30 279.523 25.5228 284 20 284H10C4.47715 284 0 279.523 0 274V10Z'
      fill='black'
    />
    <path
      d='M259 16C259 10.4772 263.477 6 269 6H279C284.523 6 289 10.4772 289 16V280C289 285.523 284.523 290 279 290H269C263.477 290 259 285.523 259 280V16Z'
      fill='black'
    />
    <path
      d='M159 69C159 63.4772 163.477 59 169 59H249C254.523 59 259 63.4772 259 69V227C259 232.523 254.523 237 249 237H169C163.477 237 159 232.523 159 227V69Z'
      fill='black'
    />
  </svg>
)

export const JustifySpaceAroundIcon = () => (
  <svg
    width='329'
    height='284'
    viewBox='0 0 329 284'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M41 63C41 57.4771 45.4772 53 51 53H131C136.523 53 141 57.4772 141 63V221C141 226.523 136.523 231 131 231H51C45.4772 231 41 226.523 41 221V63Z'
      fill='black'
    />
    <path
      d='M0 10C0 4.47715 4.47715 0 10 0H20C25.5228 0 30 4.47715 30 10V274C30 279.523 25.5228 284 20 284H10C4.47715 284 0 279.523 0 274V10Z'
      fill='black'
    />
    <path
      d='M299 10C299 4.47715 303.477 0 309 0H319C324.523 0 329 4.47715 329 10V274C329 279.523 324.523 284 319 284H309C303.477 284 299 279.523 299 274V10Z'
      fill='black'
    />
    <path
      d='M187 63C187 57.4771 191.477 53 197 53H277C282.523 53 287 57.4772 287 63V221C287 226.523 282.523 231 277 231H197C191.477 231 187 226.523 187 221V63Z'
      fill='black'
    />
  </svg>
)

export const JustifySpaceEvenlyIcon = () => (
  <svg
    width='347'
    height='285'
    viewBox='0 0 347 285'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M59 63C59 57.4771 63.4772 53 69 53H149C154.523 53 159 57.4772 159 63V221C159 226.523 154.523 231 149 231H69C63.4772 231 59 226.523 59 221V63Z'
      fill='black'
    />
    <path
      d='M0 10C0 4.47715 4.47715 0 10 0H20C25.5228 0 30 4.47715 30 10V274C30 279.523 25.5228 284 20 284H10C4.47715 284 0 279.523 0 274V10Z'
      fill='black'
    />
    <path
      d='M317 11C317 5.47715 321.477 1 327 1H337C342.523 1 347 5.47715 347 11V275C347 280.523 342.523 285 337 285H327C321.477 285 317 280.523 317 275V11Z'
      fill='black'
    />
    <path
      d='M188 63C188 57.4771 192.477 53 198 53H278C283.523 53 288 57.4772 288 63V221C288 226.523 283.523 231 278 231H198C192.477 231 188 226.523 188 221V63Z'
      fill='black'
    />
  </svg>
)
