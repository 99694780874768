import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  seletedElement: null,
  sideBarActiveElement: 'Insert',
  showCssModal: false,
  showJsModal: false,
  bullets: ['bullet-1', 'bullet-2', 'bullet-3', 'bullet-4']
}

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    activeElement: (state, action) => {
      const elementName = action.payload
      state.sideBarActiveElement = elementName
    },
    createBulletsList: (state, action) => {
      const bullets = action.payload
      state.bullets = bullets
    },
    addNewBullet: (state, action) => {
      const bullet = action.payload
      state.bullets.push(bullet)
    },
    editBullet: (state, action) => {
      const { bullet, bulletIndex } = action.payload
      const updatedBullet = [...state.bullets]
      updatedBullet[bulletIndex] = bullet
      state.bullets = updatedBullet
    },
    deleteBullet: (state, action) => {
      state.bullets = state.bullets.filter((bullet, index) => (index !== parseInt(action.payload.bulletIndex)))
    },
    showCssPopup: (state, action) => {
      state.showCssModal = !state.showCssModal
    },

    showJsPopup: (state, action) => {
      state.showJsModal = !state.showJsModal
    },
    addStyleCssEditor: (state, action) => {
      state.styleFromeditor = !state.showCssModal
    },
    selectElement: (state, action) => {
      state.seletedElement = action.payload.elementId
    }
  }
})

export const {
  addElement,
  selectElement,
  activeElement,
  loadDataFromLayout,
  addNewAdvertiser,
  addNewAdgroup,
  createBulletsList,
  addNewBullet,
  editBullet,
  deleteBullet,
  showCssPopup,
  showJsPopup
} = editorSlice.actions
export default editorSlice.reducer
