import { useDispatch } from 'react-redux'
import { changeStyle, changeDevice, addCustomClass, addCSSCode, pushlayoutstyle, changeSelectedClassName } from '../state/slices/stylesSlice'

export function useStylesHelpers () {
  const dispatch = useDispatch()

  const changestyle = (params) => {
    dispatch(changeStyle(params))
  }
  const pushLayoutStyle = (params) => {
    dispatch(pushlayoutstyle(params))
  }
  const changedevice = (params) => {
    dispatch(changeDevice(params))
  }

  const addNewClass = (params) => {
    dispatch(addCustomClass(params))
  }

  const addCssCode = (params) => {
    dispatch(addCSSCode(params))
  }

  const changeselectedclassname = (params) => {
    dispatch(changeSelectedClassName(params))
  }
  return {
    changestyle, changedevice, addNewClass, addCssCode, pushLayoutStyle, changeselectedclassname
  }
}
