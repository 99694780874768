import { Box, TextField } from '@mui/material'
import styled from 'styled-components'

export default function SpacingInput ({
  className,
  value,
  onChange,
  onAutoClick
}) {
  return (
    <SpacingInputContainer className={`field ${className || ''}`}>
      <TextField
        size='small'
        value={value}
        type='number'
        onChange={onChange}
        min='0'
        InputProps={{
          sx: {
            px: '0px',
            border: 'none',
            flexDirection: 'column-reverse'
          },
          inputMode: 'numeric',
          pattern: 'auto|[0-9]*',
          startAdornment: (
            <button onClick={onAutoClick} disabled={value === 'auto'}>
              auto
            </button>
          )
        }}
      />
    </SpacingInputContainer>
  )
}

const SpacingInputContainer = styled(Box)`
  margin: 10px 0;
  display: flex;
  align-items: center;
  fieldset {
    border: none;
  }
  button {
    border: 1px solid #c3c7cb;
    background: transparent;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    text-transform: uppercase;
    border-radius: 20px;
    font-size: xx-small;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    font-size: 1em;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    opacity: 0.5;
    padding: 10px 5px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
