import { ROOT_NODE, useEditor } from '@craftjs/core'
import { useStylesHelpers } from 'hooks/use-styles-helpers'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { HeaderContainer, Wrapper } from './dimensions-styled'
import { useSelector } from 'react-redux'

export default function FontColor () {
  const [fontColor, setFontColor] = useState('#000000')
  const isMountedRef = useRef(false)
  const { actions, active, Selectedstyles, classes } = useEditor(
    (state, query) => {
      const currentlySelectedNodeId = query.getEvent('selected').first()
      return {
        active: currentlySelectedNodeId,
        selectedNode: state.nodes[currentlySelectedNodeId]
          ? query.node(currentlySelectedNodeId)
          : undefined,
        related:
          currentlySelectedNodeId &&
          state.nodes[currentlySelectedNodeId].related,
        elementProps: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].data.props
          : undefined,
        dom: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].dom
          : undefined,
        Selectedstyles:
          state.nodes[currentlySelectedNodeId]?.data?.props?.styles,
        parent: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].data.parent
          : undefined,
        classes: state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName || [],
        rootProps: query.node(ROOT_NODE).get()?.data?.props,
        isRoot: ROOT_NODE === currentlySelectedNodeId
      }
    }
  )
  const { selectedClassName, selectedDevice, desktopStyle, tabletStyle, styles } = useSelector(
    state => state.styles
  )
  const { changestyle } = useStylesHelpers()
  useEffect(() => {
    if (isMountedRef.current) {
      const currentStyles = {}
      currentStyles.color = fontColor
      const cssKey = 'color'
      if (actions) {
        actions.setProp(active, (props) => {
          props.styles = { ...Selectedstyles, ...currentStyles }
        })
      }
      if (classes) {
        changestyle({ classes, Selectedstyles, cssKey, value: fontColor, selectedClassName })
      }
    } else {
      isMountedRef.current = true
    }
    // eslint-disable-next-line
  }, [fontColor])

  useEffect(() => {
    if (selectedClassName) {
      if (selectedDevice === 'tablet') {
        setFontColor(tabletStyle[selectedClassName]?.color ?? '#000000')
      } else if (selectedDevice === 'desktop') {
        setFontColor(desktopStyle[selectedClassName]?.color ?? '#000000')
      } else {
        setFontColor(styles[selectedClassName]?.color ?? '#000000')
      }
    }
  // eslint-disable-next-line
  }, [selectedClassName])

  return (
    <Wrapper>
      <HeaderContainer>
        <Title>font color</Title>
      </HeaderContainer>
      <Container>
        <input
          type='color'
          onChange={(e) => { setFontColor(e.target.value) }}
          value={fontColor}
        />
        <input type='text' value={fontColor} onChange={(e) => setFontColor(e.target.value)} />
      </Container>
    </Wrapper>
  )
}
const Title = styled.div`
  color: #636e72;
  font-weight: bold;
  padding: 10px;
`
const Container = styled.div`
  display: flex;
  justify-content: space-around;
`
