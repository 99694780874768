import { Box } from '@mui/system'
import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html'
import { Button, Paper, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { useCallback, useEffect, useState } from 'react'
import useLayouts from 'modules/forms-generator/hooks/useLayouts'

export default function Editor ({ layoutToEdit, onCancel, mode = 'add' }) {
  const { layouts, addEditManageLayout } = useLayouts()
  const [layoutName, setLayoutName] = useState('')
  const [layoutContent, setLayoutContent] = useState('')

  useEffect(() => {
    if (mode === 'edit' && layoutToEdit !== '') {
      setLayoutName(layoutToEdit)
      setLayoutContent(layouts[layoutToEdit])
    }
  }, [mode, layoutToEdit, layouts])

  const resetState = () => {
    setLayoutName('')
    setLayoutContent('')
    onCancel()
  }

  const addEditLayout = () => {
    addEditManageLayout(layoutName, layoutContent)
    resetState()
  }

  const onChangeEditor = useCallback((value, viewUpdate) => {
    setLayoutContent(value)
  }, [])
  return (
    <Box sx={{ width: '50%', m: '10px' }} component={Paper}>
      <Box
        sx={{
          m: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Button
          variant='outlined'
          startIcon={mode === 'add' ? <AddIcon /> : <EditIcon />}
          onClick={addEditLayout}
        >
          {mode === 'add' ? 'Save The Layout' : 'Edit The Layout'}
        </Button>
        {mode === 'edit' && (
          <Button onClick={resetState} variant='text'>
            Cancel
          </Button>
        )}
      </Box>
      <Box
        sx={{
          m: '10px'
        }}
      >
        <TextField
          sx={{ width: '100%' }}
          disabled={mode === 'edit'}
          label='Layout Name'
          variant='outlined'
          value={layoutName}
          onChange={({ target }) => setLayoutName(target.value)}
        />
      </Box>
      <Box>
        <CodeMirror
          value={layoutContent}
          height='100%'
          width='100%'
          extensions={[html()]}
          onChange={onChangeEditor}
        />
      </Box>
    </Box>
  )
}
