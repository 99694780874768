import { Box } from '@mui/system'
import Builder from '../builder'
import BuilderTabs from '../tabs/builder-tabs'

export default function BuilderLayout () {
  return (
    <Box
      sx={{
        borderTop: '1px solid #dedede',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '88vh'
      }}
    >
      <Builder />
      <BuilderTabs />
    </Box>
  )
}
