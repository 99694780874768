import { Autocomplete, TextField } from '@mui/material'
import { useDefaultValue } from 'modules/forms-generator/hooks/useDefaultValue'
import { useFormData } from 'modules/forms-generator/hooks/useFormData'

export default function DefaultValueKey ({ placeHolder }) {
  const { getKeysArray } = useFormData()
  const { changeKey, key } = useDefaultValue()

  const handleChange = (_, value) => {
    changeKey(value)
  }
  return (
    <Autocomplete
      disablePortal
      value={key}
      options={getKeysArray()}
      sx={{ ml: '5px', flex: 1 }}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={placeHolder} />}
    />
  )
}
