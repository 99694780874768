import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useEditor } from '@craftjs/core'
import { RemoveButton } from 'components/styledComponents'

export const AddCustomEvent = ({ setActiveToolOption, isActive }) => {
  const [isAddCustomSelected, setIsAddCustomSelected] = useState(false)
  const [hasError, setError] = useState(false)
  const [eventName, setEventName] = useState('')
  const [customEvent, setCusomEvent] = useState('')
  const showInputForm = () => {
    setActiveToolOption('AddCustomEventOption')
    setIsAddCustomSelected(!isAddCustomSelected)
  }

  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected
    }
  })

  const handleChange = e => {
    setEventName(e.target.value)
    setError(false)
  }

  const handleAddCusotmEvent = () => {
    if (!eventName) {
      setError(true)
      return
    }
    if (!hasError) {
      setCusomEvent(eventName)
      setEventName('')
    }
  }

  const handleRemoveCustomEvent = () => {
    setCusomEvent('')
  }

  useEffect(() => {
    actions.setProp(selected?.id, props => {
      props.customEvenet = {
        ...selected.customEvenet,
        eventType: customEvent
      }
    })
  // eslint-disable-next-line
  }, [customEvent])
  useEffect(() => {
    if (isActive === 'AddCustomEventOption') {
      setIsAddCustomSelected(true)
    } else {
      setIsAddCustomSelected(false)
    }
  }, [isActive])
  return (
    <AddCustomEventContainer data-cy='custom__event__button'>
      <img
        onClick={showInputForm}
        src='https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/64/000000/external-computer-back-to-school-xnimrodx-lineal-xnimrodx-2.png'
        alt='icon-customm-event'
      />
      <CustomEventInputContainer isAddCustomSelected={isAddCustomSelected}>
        <InputContainer>
          <img
            src='https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/64/000000/external-computer-back-to-school-xnimrodx-lineal-xnimrodx-2.png'
            alt='icon-customm-event'
          />
          <input
            placeholder='Enter Custom Event Name'
            onChange={handleChange}
            value={eventName}
            data-cy='custom__event__input'
          />
          <AddCusotmEvent onClick={handleAddCusotmEvent} data-cy='add__custom__event__button'>
            <img
              src='https://img.icons8.com/ios-filled/50/000000/plus-math.png'
              alt='add-icon'
            />
          </AddCusotmEvent>
        </InputContainer>
        <ListOfCusotmEvents data-cy='list__custom__event'>
          {customEvent && [customEvent].map((cusotmEvent, index) => (
            <CustomEvent key={index}>
              <div>{cusotmEvent}</div>
              <RemoveButton
                onClick={handleRemoveCustomEvent}
                id={cusotmEvent}
                src='https://img.icons8.com/external-becris-lineal-becris/64/000000/external-cancel-mintab-for-ios-becris-lineal-becris.png'
                alt='remove-tag-icon'
              />
            </CustomEvent>
          ))}
        </ListOfCusotmEvents>
      </CustomEventInputContainer>
    </AddCustomEventContainer>
  )
}

const AddCustomEventContainer = styled.div`
  font-size: 23px;
  padding: 5px;
  display: flex;
  border-right: 1px solid #aeaeae;
`
const CustomEventInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  position: absolute;
  display: ${props => (props.isAddCustomSelected ? 'flex' : 'none')};
  margin-left: -120px;
  padding: 3px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 5px 5px #e4e4e4;
  z-index: 100;
`
const AddCusotmEvent = styled.div``
const ListOfCusotmEvents = styled.div`
  display: flex;
  flex-direction: column;
`
const CustomEvent = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 5px #e4e4e4;
  padding: 5px;
  align-items: center;
`
const InputContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 5px;
  box-shadow: 0px 5px 5px #e4e4e4;
`
