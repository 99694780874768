import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import BorderRadiusSideInput from './sideInput'
import { BorderRadiusTopLeft } from 'modules/forms-generator/components/customIcons/borderRadiusTopLeft'
import styled from 'styled-components'
import { Borders } from 'modules/forms-generator/components/customIcons/borders'
import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'

export default function BorderRadius () {
  const [borderTopLeftRadius, setBorderTopLeftRadius] = useCssKeyValue({
    key: 'border-top-left-radius',
    value: '0',
    unit: 'px'
  })
  const [borderTopRightRadius, setBorderTopRightRadius] = useCssKeyValue({
    key: 'border-top-right-radius',
    value: '0',
    unit: 'px'
  })
  const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useCssKeyValue({
    key: 'border-bottom-left-radius',
    value: '0',
    unit: 'px'
  })
  const [borderBottomRightRadius, setBorderBottomRightRadius] = useCssKeyValue({
    key: 'border-bottom-right-radius',
    value: '0',
    unit: 'px'
  })

  const [bordersRadius, setBordersRadius] = useState(0)

  const handleChangeBorderRadius = (value) => {
    setBordersRadius(value)
    setBorderTopLeftRadius({ value: value })
    setBorderTopRightRadius({ value: value })
    setBorderBottomLeftRadius({ value: value })
    setBorderBottomRightRadius({ value: value })
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Border Raduis</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FieldsWrapper>
          <BorderRadiusSideInput
            value={bordersRadius}
            onChange={({ target }) => handleChangeBorderRadius(target.value)}
            className='field fullWidth'
            startIcon={<Borders viewBox='0 0 1024 1024' />}
          />
          <BorderRadiusSideInput
            value={borderTopLeftRadius}
            onChange={({ target }) =>
              setBorderTopLeftRadius({ value: target.value })}
            className='field'
            startIcon={<BorderRadiusTopLeft viewBox='0 0 15 15' />}
          />
          <BorderRadiusSideInput
            value={borderTopRightRadius}
            onChange={({ target }) =>
              setBorderTopRightRadius({ value: target.value })}
            className='field'
            startIcon={
              <BorderRadiusTopLeft
                viewBox='0 0 15 15'
                sx={{ transform: 'rotate(90deg)' }}
              />
            }
          />
          <BorderRadiusSideInput
            value={borderBottomLeftRadius}
            onChange={({ target }) =>
              setBorderBottomLeftRadius({ value: target.value })}
            className='field'
            startIcon={
              <BorderRadiusTopLeft
                viewBox='0 0 15 15'
                sx={{ transform: 'rotate(180deg)' }}
              />
            }
          />
          <BorderRadiusSideInput
            value={borderBottomRightRadius}
            onChange={({ target }) =>
              setBorderBottomRightRadius({ value: target.value })}
            className='field'
            startIcon={
              <BorderRadiusTopLeft
                viewBox='0 0 15 15'
                sx={{ transform: 'rotate(270deg)' }}
              />
            }
          />
        </FieldsWrapper>
      </AccordionDetails>
    </Accordion>
  )
}

const FieldsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .field {
    width: calc(50% - 5px);
  }
  .field.fullWidth {
    width: calc(100% - 5px);
  }
`
