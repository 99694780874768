export const getCurrentValue = (cssObject, currentSelector, key) => {
  return cssObject[currentSelector]
    ? cssObject[currentSelector][key.key] || {
        value: key.defaultValue,
        unit: key.defaultUnit
      }
    : {
        value: key.defaultValue,
        unit: key.defaultUnit
      }
}

export const getCurrentValueByKey = ({
  cssObject,
  currentSelector,
  key,
  defaultValue,
  defaultUnit
}) => {
  return cssObject[currentSelector]
    ? cssObject[currentSelector][key] || {
        value: defaultValue,
        unit: defaultUnit
      }
    : {
        value: defaultValue,
        unit: defaultUnit
      }
}

export const getCurrentBorderValue = (cssObject, currentSelector, key) => {
  if (
    cssObject[currentSelector] &&
    cssObject[currentSelector][key.key] &&
    cssObject[currentSelector][key.key].value !== 'none'
  ) {
    const borderSplited = cssObject[currentSelector][key.key].value.split(' ')
    return {
      value: {
        width: borderSplited[0].replace('px', ''),
        style: borderSplited[1],
        color: borderSplited[2]
      }
    }
  }

  return {
    value: { ...key.defaultValue }
  }
}

export const saveBorderValue = ({ width, style, color }) => {
  return `${width}px ${style} ${color} !important`
}
