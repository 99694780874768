import { Image } from '../Image'
import { Bullets } from '../Bullets'
import { Cta } from '../Cta'
import { Element } from '@craftjs/core'
import { AdContainer } from '../AdContainer'
import { ElementContainer } from '../ElementContainer'

const layout4DefaultStyles = {
  adContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imageStyle: {
    height: '200px',
    width: 'auto'
  },
  headlineStyle: {
    fontSize: '26px',
    width: 'fit-content'
  },
  bullesStyle: {
    fontSize: '16px'
  },
  ctaStyle: {
    display: 'flex',
    justifyContent: 'center'
  },
  elementContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}

export const Layout4 = () => {
  return (
    <Element id='ad-container' is={AdContainer} canvas styles={layout4DefaultStyles.adContainer}>
      <ElementContainer styles={layout4DefaultStyles.elementContainer}>
        <Image imgSource='' imgName='image-icon' styles={layout4DefaultStyles.imageStyle} />
        <Bullets bullets={['bullet-1', 'bullet-2', 'bullet-3', 'bullet-4']} styles={layout4DefaultStyles.bullesStyle} />
        <Cta styles={layout4DefaultStyles.ctaStyle} />
      </ElementContainer>
    </Element>
  )
}
