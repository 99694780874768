import { Drawer } from '@mui/material'
import { useState } from 'react'
import BasicElements from './basicElements'
import ComplexElements from './complexElements'
import DrawerSection from './section'

export default function ElementsDrawer ({ isOpen, onClose }) {
  const [activeSection, setActiveSection] = useState('')

  const toggleActiveSection = (newSection) => {
    setActiveSection((prev) => (prev === newSection ? '' : newSection))
  }

  const handleClose = () => {
    onClose()
    setActiveSection('')
  }
  return (
    <Drawer anchor='left' open={isOpen} onClose={handleClose}>
      <DrawerSection
        isActive={activeSection === 'basic'}
        label='Basic Elements'
        onToggle={() => toggleActiveSection('basic')}
        elements={<BasicElements />}
      />
      <DrawerSection
        isActive={activeSection === 'complex'}
        label='Form Elements'
        onToggle={() => toggleActiveSection('complex')}
        elements={<ComplexElements />}
      />
    </Drawer>
  )
}
