import { Button, TextField, Typography } from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useState, useEffect, useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box } from '@mui/system'
import OptionsGenerator from './generateOptions'
import SuggestionsList from './suggestionsList'

export default function OptionsHandler () {
  const [showOptionsGenerator, setShowOptionsGenerator] = useState(false)
  const {
    selectedElement,
    addSuggestion,
    removeSuggestion,
    updateSuggestion,
    errors,
    setErrorMessage,
    clearError
  } = useSelectedElements()
  const [suggestion, setSeggestion] = useState({
    text: '',
    value: ''
  })
  const [suggestionError, setSuggestionError] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const handleChangeSuggestion = (key, value) => {
    setSeggestion({ ...suggestion, [key]: value })
  }

  const isSuggestionAlreadyExist = useMemo(() => {
    for (const currentSuggestion of selectedElement.suggestions) {
      if (
        currentSuggestion.text.toLowerCase() ===
          suggestion.text.toLocaleLowerCase() ||
        currentSuggestion.value.toLowerCase() ===
          suggestion.value.toLocaleLowerCase()
      ) {
        return true
      }
    }
    return false
  }, [suggestion, selectedElement.suggestions])

  useEffect(() => {
    if (suggestion.text && suggestion.value && !isSuggestionAlreadyExist) {
      setSuggestionError(false)
    }
  }, [suggestion, isSuggestionAlreadyExist])

  useEffect(() => {
    if (selectedElement.suggestions.length === 0) {
      setErrorMessage({
        key: 'suggestions',
        message: 'Suggestions are required'
      })
    } else {
      clearError('suggestions')
    }
    return () => {
      clearError('suggestions')
    }
    // eslint-disable-next-line
  }, [selectedElement.suggestions])

  const handleDeleteOption = (index) => {
    removeSuggestion(index)
  }

  const handleSelectToEdit = (value, idx) => {
    setSeggestion(value)
    setSelectedIndex(idx)
  }

  const handleCancelEdit = () => {
    setSeggestion({
      text: '',
      value: ''
    })
    setSuggestionError(false)
    setSelectedIndex(null)
  }

  const handleUpdateSuggestion = () => {
    if (!suggestion.text || !suggestion.value || isSuggestionAlreadyExist) {
      return setSuggestionError(true)
    }
    updateSuggestion(selectedIndex, suggestion)
    setSeggestion({
      text: '',
      value: ''
    })
    setSelectedIndex(null)
  }

  const handleAddNewSuggestion = () => {
    if (!suggestion.text || !suggestion.value || isSuggestionAlreadyExist) {
      return setSuggestionError(true)
    }
    addSuggestion(suggestion)
    setSeggestion({
      text: '',
      value: ''
    })
  }
  return (
    <div>
      <Box
        sx={{
          height: '300px',
          width: '100%',
          overflowY: 'scroll',
          mt: '10px',
          backgroundColor: '#eeeeee',
          border:
            errors.suggestions !== true
              ? '1px solid #e74c3c'
              : '1px solid #eeeeee'
        }}
      >
        {selectedElement && selectedElement.suggestions && (
          <SuggestionsList
            data={selectedElement.suggestions}
            onDelete={handleDeleteOption}
            onEdit={handleSelectToEdit}
          />
        )}
      </Box>
      {errors.suggestions !== true && (
        <Typography
          sx={{ fontSize: '0.75rem', color: '#e74c3c', mx: '14px', mt: '3px' }}
        >
          {errors.suggestions}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <TextField
          sx={{ my: '10px' }}
          required
          id='outlined-required'
          label='Label Text'
          value={suggestion.text}
          error={suggestionError}
          helperText={
            suggestionError ? 'Field is required and should be unique' : ''
          }
          onChange={(e) => handleChangeSuggestion('text', e.target.value)}
        />
        <TextField
          sx={{ my: '10px' }}
          required
          id='outlined-required'
          label='Value'
          error={suggestionError}
          helperText={
            suggestionError ? 'Field is required and should be unique' : ''
          }
          value={suggestion.value}
          onChange={(e) => handleChangeSuggestion('value', e.target.value)}
        />
        {selectedIndex && (
          <Button variant='text' onClick={handleCancelEdit}>
            Cancel
          </Button>
        )}

        <Button
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={
            selectedIndex ? handleUpdateSuggestion : handleAddNewSuggestion
          }
        >
          {selectedIndex ? 'Edit Suggestion' : 'Add Suggestion'}
        </Button>
        <Button
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={() => setShowOptionsGenerator((prev) => !prev)}
        >
          {showOptionsGenerator
            ? 'Close Generate Options'
            : 'Open Generate Options'}
        </Button>
      </Box>
      <OptionsGenerator show={showOptionsGenerator} />
    </div>
  )
}
