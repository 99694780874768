import { Box } from '@mui/material'
import { useState } from 'react'
import FilterChips from './filtersChips'
import TemplatesList from './list'

export default function TemplatesPage () {
  const [selectedType, setSelectedType] = useState('all')
  const handleClick = (category) => {
    setSelectedType(category)
  }
  return (
    <Box sx={{ w: '100%', my: '20px' }}>
      <FilterChips
        onChange={handleClick}
        currentSelectedFilter={selectedType}
      />
      <TemplatesList />
    </Box>
  )
}
