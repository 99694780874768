import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useState, useEffect, useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box } from '@mui/system'
import RadioSuggestionsList from './radioSuggestionsList'

export default function Suggestions () {
  const {
    selectedElement,
    addSuggestion,
    errors,
    setErrorMessage,
    clearError
  } = useSelectedElements()
  const [suggestion, setSeggestion] = useState({
    text: '',
    value: '',
    className: '',
    shouldBeSubmitted: false
  })
  const [hasError, setHasError] = useState(false)
  const handleChangeSuggestion = (key, value) => {
    setSeggestion({ ...suggestion, [key]: value })
  }

  const isSuggestionAlreadyExist = useMemo(() => {
    if (!('text' in suggestion) || !('text' in suggestion)) return false
    for (const currentSuggestion of selectedElement.suggestions) {
      if (!('text' in currentSuggestion) || !('text' in currentSuggestion)) { return false }
      if (
        currentSuggestion.text.toLowerCase() ===
          suggestion.text.toLocaleLowerCase() ||
        currentSuggestion.value.toLowerCase() ===
          suggestion.value.toLocaleLowerCase()
      ) {
        return true
      }
    }
    return false
  }, [suggestion, selectedElement.suggestions])

  useEffect(() => {
    if (
      suggestion.className &&
      suggestion.text &&
      suggestion.value &&
      !isSuggestionAlreadyExist
    ) {
      setHasError(false)
    }
  }, [suggestion, isSuggestionAlreadyExist])

  useEffect(() => {
    if (selectedElement.suggestions.length === 0) {
      setErrorMessage({
        key: 'suggestions',
        message: 'Suggestions are required'
      })
    } else {
      clearError('suggestions')
    }
    return () => {
      clearError('suggestions')
    }
    // eslint-disable-next-line
  }, [selectedElement.suggestions])

  const handleAddNewSuggestion = () => {
    if (
      !suggestion.text ||
      !suggestion.value ||
      !suggestion.className ||
      isSuggestionAlreadyExist
    ) {
      return setHasError(true)
    }
    addSuggestion(suggestion)
    setSeggestion({
      text: '',
      value: '',
      className: '',
      shouldBeSubmitted: false
    })
  }
  return (
    <div>
      <Box
        sx={{
          height: '200px',
          width: '100%',
          mt: '5px',
          overflowY: 'scroll',
          backgroundColor: '#eeeeee',
          border:
            errors.suggestions !== true
              ? '1px solid #e74c3c'
              : '1px solid #eeeeee'
        }}
      >
        {selectedElement && selectedElement.suggestions && (
          <RadioSuggestionsList suggestions={selectedElement.suggestions} />
        )}
      </Box>
      {errors.suggestions !== true && (
        <Typography
          sx={{ fontSize: '0.75rem', color: '#e74c3c', mx: '14px', mt: '3px' }}
        >
          {errors.suggestions}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <TextField
          sx={{ my: '10px' }}
          required
          id='outlined-required'
          label='Label Text'
          value={suggestion.text}
          error={hasError}
          size='small'
          helperText={hasError ? 'Field is required and should be unique' : ''}
          onChange={(e) => handleChangeSuggestion('text', e.target.value)}
        />
        <TextField
          sx={{ my: '10px' }}
          required
          id='outlined-required'
          label='Value'
          value={suggestion.value}
          error={hasError}
          size='small'
          helperText={hasError ? 'Field is required and should be unique' : ''}
          onChange={(e) => handleChangeSuggestion('value', e.target.value)}
        />
        <TextField
          sx={{ my: '10px' }}
          required
          id='outlined-required'
          label='ClassNames'
          value={suggestion.className}
          error={hasError}
          size='small'
          helperText={hasError ? 'Field is required' : ''}
          onChange={(e) => handleChangeSuggestion('className', e.target.value)}
        />
        <FormControlLabel
          value={suggestion.shouldBeSubmitted}
          onChange={(_, checked) =>
            handleChangeSuggestion('shouldBeSubmitted', checked)}
          control={<Checkbox />}
          label='Should Submit Form'
        />
        <Button
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddNewSuggestion}
        >
          Add Suggestion
        </Button>
      </Box>
    </div>
  )
}
