import { Element, useEditor } from '@craftjs/core'
import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { useEffect, useState } from 'react'
import { Settings } from '../../settings'
import { ErrorSpanComponent } from '../errorSpan'
import { InputElement } from './input'

export const InputComponentSetting = () => {
  return <Settings typography dimensions colors border withError />
}

const getFirstValidationErrorMessage = (
  validations = { required: { value: true, message: 'enter email' } }
) => {
  const keys = Object.keys(validations)
  return validations[keys[0]].message
}

export const InputComponent = ({
  className,
  questionKey,
  type,
  placeholder,
  validations
}) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const { withError } = useEditor((state, _) => ({
    withError:
      state.nodes[id] && state.nodes[id].dom
        ? state.nodes[id].dom.classList.contains('with__error')
        : false
  }))

  useEffect(() => {
    setShowErrorMessage(withError)
  }, [withError])

  return (
    <div
      className={`${className}`}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      <Element
        canvas
        is={InputElement}
        questionKey={questionKey}
        type={type}
        placeholder={placeholder}
        id={`input__${questionKey}`}
      />
      {showErrorMessage && (
        <Element
          canvas
          is={ErrorSpanComponent}
          text={getFirstValidationErrorMessage(validations)}
          id={`error__span__${questionKey}`}
          className='error__span'
        />
      )}
    </div>
  )
}

InputComponent.craft = {
  displayName: 'Input Component',
  related: {
    toolbar: InputComponentSetting
  }
}
