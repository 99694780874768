import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DISPLAY_KEY, useDisplay } from './useDisplay'

export default function DisplaySetting () {
  const { display, updateAttribute, renderDisplayOptions } = useDisplay()

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Display</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <InputLabel id='display-settings'>Display</InputLabel>
          <Select
            labelId='display-settings'
            id='display-settings-select'
            value={display.value}
            label='Display'
            onChange={(changeEvent, child) => {
              updateAttribute({
                key: DISPLAY_KEY.DISPLAY.key,
                value: changeEvent.target.value
              })
            }}
          >
            {DISPLAY_KEY.DISPLAY.values.map((value) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {renderDisplayOptions()}
      </AccordionDetails>
    </Accordion>
  )
}
