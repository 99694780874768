export const zipCodePredefinedPage = {
  name: 'zipCodePage',
  className: 'zip_code_page',
  nextPage: {},
  layouts: {
    beforeElements: '',
    afterElements: ''
  },
  elements: [
    {
      kind: 'TEXT',
      type: 'PARAGRAPH',
      className: 'description zipcode_page_description',
      value: 'Enter your zip code to check for incentives in your area'
    },
    {
      kind: 'CONTAINER',
      className: 'zipcode_form_container',
      keys: [],
      children: [
        {
          kind: 'FORM',
          type: 'TEXT',
          className: 'zipcode_input',
          placeholder: 'Zip Code',
          key: 'zipCode',
          validations: {
            required: {
              value: true,
              message: 'Please enter a zip code'
            }
          }
        },
        {
          kind: 'BUTTON',
          className: 'next__button',
          value: 'NEXT',
          role: 'NEXT',
          onClick: []
        }
      ]
    }
  ]
}
