export const keyWords = [
  'auto',
  'fit-content',
  'inherit',
  'initial',
  'max-content',
  'min-content',
  'unset',
  '-web-kit-fill-available'
]
