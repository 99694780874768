import { createSvgIcon } from '@mui/material'

export const BorderRadiusTopLeft = createSvgIcon(
  <path
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    clipRule='evenodd'
    d='M5.5 2C3.567 2 2 3.567 2 5.5V8H1V5.5C1 3.01472 3.01472 1 5.5 1H8V2H5.5ZM11 2H10V1H11V2ZM14 2H13V1H14V2ZM14 5H13V4H14V5ZM14 8H13V7H14V8ZM2 11H1V10H2V11ZM14 11H13V10H14V11ZM2 14H1V13H2V14ZM5 14H4V13H5V14ZM8 14H7V13H8V14ZM11 14H10V13H11V14ZM14 14H13V13H14V14Z'
    fill='black'
  />,
  'BorderRadiusTopLeft'
)
