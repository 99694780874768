import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import CodeMirror from '@uiw/react-codemirror'
import { Button } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import copy from 'copy-to-clipboard'
import { html } from '@codemirror/lang-html'
import { css } from '@codemirror/lang-css'
import { useExport } from 'modules/forms-generator/hooks/useExport'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #dedede',
  boxShadow: 24,
  p: 4
}

export default function ExportedFormModal ({ open, onClose }) {
  const { exportedCode, exportedStyles } = useExport()

  const handleCopyToClipBoard = () => {
    copy(exportedCode)
  }

  const handleCopyCSSToClipBoard = () => {
    copy(exportedStyles)
  }
  return (
    <Modal
      aria-labelledby='new-page-modal-title'
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{ zIndex: 999999 }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '10px'
            }}
          >
            <Typography id='new-page-modal-title' variant='h6' component='h2'>
              Generated Form
            </Typography>
          </Box>
          <Box height='100%' display='flex' justifyContent='space-between'>
            <Box height='100%' width='50%' mr='5px'>
              <Button
                variant='outlined'
                startIcon={<ContentCopyIcon />}
                onClick={handleCopyToClipBoard}
                sx={{ marginBottom: '5px' }}
              >
                Copy HTML to Clipboard
              </Button>

              <Box sx={{ height: '90%', overflowY: 'scroll' }}>
                <CodeMirror
                  value={exportedCode}
                  height='100%'
                  extensions={[html()]}
                />
              </Box>
            </Box>
            <Box sx={{ height: '100%', width: '50%' }}>
              <Button
                variant='outlined'
                startIcon={<ContentCopyIcon />}
                onClick={handleCopyCSSToClipBoard}
                sx={{ marginBottom: '5px' }}
              >
                Copy CSS to Clipboard
              </Button>
              <Box sx={{ height: '90%', overflowY: 'scroll' }}>
                <CodeMirror
                  value={exportedStyles}
                  height='100%'
                  extensions={[css()]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}
