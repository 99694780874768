import { ELEMENTS_TYPES } from 'modules/utils/elementsTypes'
import TitleIcon from '@mui/icons-material/Title'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import SmartButtonIcon from '@mui/icons-material/SmartButton'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import PhoneIcon from '@mui/icons-material/Phone'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import AppsIcon from '@mui/icons-material/Apps'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export const ElementIcon = ({ elementType }) => {
  switch (elementType) {
    case ELEMENTS_TYPES.CONTAINER:
      return <AppsIcon />
    case ELEMENTS_TYPES.HEADLINE:
      return <TitleIcon />
    case ELEMENTS_TYPES.PARAGRAPH:
      return <LocalParkingIcon />
    case ELEMENTS_TYPES.BUTTON:
      return <SmartButtonIcon />
    case ELEMENTS_TYPES.RADIO_BUTTONS:
      return <RadioButtonCheckedIcon />
    case ELEMENTS_TYPES.DROPDOWN_SELECT:
      return <ArrowDropDownCircleIcon />
    case ELEMENTS_TYPES.RANGE_SLIDER:
      return <LinearScaleIcon />
    case ELEMENTS_TYPES.FORM_TEXT:
      return <TextFieldsIcon />
    case ELEMENTS_TYPES.FORM_PHONE:
      return <PhoneIcon />
    case ELEMENTS_TYPES.FORM_ERN_ADDRESS:
      return <EditLocationAltIcon />
    case ELEMENTS_TYPES.CHECKBOX:
      return <CheckBoxIcon />
    default:
      return null
  }
}
