import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  MarkerType
} from 'reactflow'

const initialState = {
  nodes: [],
  edges: [],
  openConnectionDialogue: false,
  connectionParams: null,
  dynamicConnections: null
}

export const pagesFlowSlice = createSlice({
  name: 'pagesFlow',
  initialState,
  reducers: {
    setNodes: (state, { payload }) => {
      state.nodes = payload
    },
    setEdges: (state, { payload }) => {
      state.edges = payload.map((entry) => ({
        ...entry,
        type: 'nextPageEdge',
        animated: true,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: '#1976d2'
        },
        style: {
          strokeWidth: 3,
          stroke: '#1976d2'
        }
      }))
    },
    onNodesChangesState: (state, { payload }) => {
      state.nodes = applyNodeChanges(payload, state.nodes)
    },
    onEdgesChangeState: (state, { payload }) => {
      state.edges = applyEdgeChanges(
        payload,
        state.edges.map((entry) => ({
          ...entry,
          type: 'nextPageEdge',
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#1976d2'
          },
          style: {
            strokeWidth: 3,
            stroke: '#1976d2'
          }
        }))
      )
    },
    onConnectState: (state, { payload }) => {
      state.edges = addEdge(
        {
          ...payload,
          type: 'nextPageEdge',
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#1976d2'
          },
          style: {
            strokeWidth: 3,
            stroke: '#1976d2'
          }
        },
        state.edges
      )
    },
    toggleConnectionDialogue: (state) => {
      state.openConnectionDialogue = !state.openConnectionDialogue
    },
    setConnectionParams: (state, { payload }) => {
      state.connectionParams = payload
    },
    setDynamicConnections: (state, { payload }) => {
      state.dynamicConnections = payload
    },
    importConfig: (state, { payload }) => {
      state.nodes = payload.nodes
      state.edges = payload.edges
    }
  }
})

export const {
  setEdges,
  setNodes,
  onNodesChangesState,
  onEdgesChangeState,
  onConnectState,
  toggleConnectionDialogue,
  setConnectionParams,
  setDynamicConnections,
  importConfig
} = pagesFlowSlice.actions
export const usePagesFlowState = () => useSelector((state) => state.pagesFlow)
export default pagesFlowSlice.reducer
