import { useDispatch } from 'react-redux'
import {
  changeDefaultValueFunction,
  changeDefaultValueKey,
  changeDefaultValueValue
} from 'state/slices/formsGenerator/builderSlice'
import { useSelectedElements } from './useSelectedElement'

export const useDefaultValue = () => {
  const { selectedElement } = useSelectedElements()
  const dispatch = useDispatch()
  const changeValue = (value) => {
    dispatch(changeDefaultValueValue(value))
  }

  const changeKey = (value) => {
    dispatch(changeDefaultValueKey(value))
  }

  const changeFunctionNameParams = (functionName, params) => {
    dispatch(changeDefaultValueFunction({ functionName, params }))
  }

  return {
    changeValue,
    changeKey,
    changeFunctionNameParams,
    value: selectedElement?.defaultValue?.value,
    key: selectedElement?.defaultValue?.key,
    functionName: selectedElement?.defaultValue?.functionName,
    params: selectedElement?.defaultValue?.params
  }
}
