import { useDispatch } from 'react-redux'
import { loadConfig, loadPage } from 'state/slices/formsGenerator/builderSlice'
import { loadCssConfig } from 'state/slices/formsGenerator/formCssSlice'
import { loadFunctionsList } from 'state/slices/formsGenerator/formJsSlice'
import { importConfig } from 'state/slices/formsGenerator/pagesFlowSlice'

export const useLoadConfig = () => {
  const dispatch = useDispatch()
  const loadFormData = ({ layouts, pages }) => {
    dispatch(loadConfig({ layouts, pages }))
  }

  const loadFunctions = (functionsList = []) => {
    dispatch(loadFunctionsList(functionsList))
  }
  const loadCssData = (cssData = {}) => {
    dispatch(loadCssConfig(cssData))
  }
  const loadPageData = (page = {}) => {
    dispatch(loadPage(page))
  }

  const loadPageFlowData = (config = { nodes: [], edges: [] }) => {
    dispatch(importConfig(config))
  }

  return {
    loadFormData,
    loadFunctions,
    loadCssData,
    loadPageData,
    loadPageFlowData
  }
}
