import { Box, Icon } from '@mui/material'
import { RxDashboard, RxChevronRight } from 'react-icons/rx'

export default function DrawerSection ({
  label,
  onToggle,
  isActive = false,
  elements
}) {
  return (
    <>
      <Box
        onClick={onToggle}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: '10px',
          width: '250px',
          py: '15px',
          cursor: 'pointer',
          color: isActive ? '#ffffff' : '#2B2B2B',
          backgroundColor: isActive ? '#2B2B2B' : 'transparent',
          mx: '10px',
          my: '5px',
          borderRadius: '10px',
          transition: 'all .4s ease',
          border: '1px solid rgba(68,68,68,.2)',
          ':hover': {
            backgroundColor: '#2B2B2B',
            color: '#ffffff'
          }
        }}
      >
        <Icon
          sx={{
            backgroundColor: '#444444',
            p: '4px',
            borderRadius: '4px',
            boxShadow: '0 4px 8px 0 rgba(68,68,68,.2)',
            color: '#ffffff'
          }}
        >
          <RxDashboard />
        </Icon>

        <span>{label}</span>
        <Icon
          sx={{
            backgroundColor: '#444444',
            p: '4px',
            borderRadius: '4px',
            boxShadow: '0 4px 8px 0 rgba(68,68,68,.2)',
            color: '#ffffff',
            transition: 'all .2s ease',
            transform: isActive ? 'rotate(90deg)' : ''
          }}
        >
          <RxChevronRight />
        </Icon>
      </Box>
      {isActive ? elements : null}
    </>
  )
}
