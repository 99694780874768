import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import InsertComponent from '../../components/sidebar/insertcomponent'
import DimensionsSection from '../../components/sidebar/dimensions/dimestionsSection'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import CreateIcon from '@mui/icons-material/Create'
import useEditorHelpers from 'hooks/useEditorHelpers'
import { LayerSection } from 'components/sidebar/layerSection'

export default function Sidebar () {
  const editor = useSelector(state => state.editor)
  const [activeElement, setActiveElement] = useState('Insert')
  const { ActiveSideBarElements } = useEditorHelpers()
  useEffect(() => {
    setActiveElement(editor.sideBarActiveElement)
  }, [editor])
  const renderSwitch = param => {
    switch (param) {
      case 'insert':
        return <InsertComponent />
      case 'Styles':
        return <DimensionsSection />
      default:
        return <InsertComponent />
    }
  }
  return (
    <Container data-cy='sidebar__container'>
      <BottomNavigation
        sx={{ borderColor: '#4285f4', border: 0.01 }}
        showLabels
        value={activeElement}
        onChange={(event, newValue) => {
          ActiveSideBarElements(newValue)
        }}
      >
        <BottomNavigationAction
          label='Insert'
          value='Insert'
          icon={<GridViewIcon />}
        />
        <BottomNavigationAction
          label='Styles'
          value='Styles'
          icon={<CreateIcon />}
        />
      </BottomNavigation>
      <ElementContainer>{renderSwitch(activeElement)}</ElementContainer>
      <LayerSection />
    </Container>
  )
}

const Container = styled.div`
  box-shadow: none;
  height: 100%;
  overflow: scroll;
  z-index: 98;
  width: 322px;
  flex-shrink: 0;
  position: relative;
  border-right: 1px solid #ddd;
`
const ElementContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
`
