import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const RangeInputBarSetting = () => {
  return <Settings typography dimensions colors border />
}

export const RangeInputBar = () => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <span
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      className='range__input--bar'
    />
  )
}

RangeInputBar.craft = {
  displayName: 'Range Input Bar',
  related: {
    toolbar: RangeInputBarSetting
  }
}
