import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useNode, useEditor } from '@craftjs/core'
import { Toolbar } from './Toolbar'
import useEditorHelpers from '../../hooks/useEditorHelpers'
import { useStyles } from '../../hooks/use-styles'
import { Resizable } from 're-resizable'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'

const imageIcon =
  'https://media.istockphoto.com/vectors/image-preview-icon-picture-placeholder-for-website-or-uiux-design-vector-id1222357475?k=20&m=1222357475&s=170667a&w=0&h=YGycIDbBRAWkZaSvdyUFvotdGfnKhkutJhMOZtIoUKY='
export const Image = ({
  mustacheTag,
  imgName = 'Image Name',
  classname,
  customEvenet,
  dataattribute,
  imgSource
}) => {
  const currentComponent = 'Image'
  const defaultTargets = ['src', 'alt', 'visibility', 'click link']
  const [isElementSelected, setIsElementSelected] = useState(false)
  const [elementHeight, setElementHeight] = useState()
  const [elementWidth, setElementWidth] = useState()
  const { ActiveSideBarElements } = useEditorHelpers()
  const {
    connectors: { connect, drag },
    actions: { setProp }
  } = useNode()
  const { changestyle } = useStylesHelpers()
  const { elementClassName } = useStyles()
  const { selectedDevice, styles, tabletStyle, desktopStyle } = useSelector(
    state => state.styles
  )
  const { selected, Selectedstyles } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected,
      classes: state.nodes[currentNodeId]?.dom?.classList,
      Selectedstyles: state.nodes[currentNodeId]?.data?.props?.styles
    }
  })

  const setStylesValue = (cssKey, value) => {
    if (elementClassName) {
      changestyle({
        classes: [elementClassName],
        Selectedstyles,
        cssKey,
        value
      })
    }
  }

  const handleClick = () => {
    ActiveSideBarElements('Styles')
    if (selected.name === currentComponent) {
      setIsElementSelected(true)
    }
  }

  const handleResize = (e, direction, ref, d) => {
    setStylesValue('height', ref.style.height)
    setStylesValue('width', ref.style.width)
    setElementHeight(ref.style.height)
    setElementWidth(ref.style.width)
    setProp(props => (props.elementClassName = [elementClassName]))
  }

  useEffect(() => {
    if (selected?.name === currentComponent) {
      setIsElementSelected(true)
    } else {
      setIsElementSelected(false)
    }
    // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    setProp(props => (props.elementClassName = [classname !== undefined ? classname : elementClassName]))
  })

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setElementHeight(tabletStyle[elementClassName]?.height)
      setElementWidth(tabletStyle[elementClassName]?.width)
    } else if (selectedDevice === 'desktop') {
      setElementHeight(desktopStyle[elementClassName]?.height)
      setElementWidth(desktopStyle[elementClassName]?.width)
    } else {
      setElementHeight(styles[elementClassName]?.height)
      setElementWidth(styles[elementClassName]?.width)
    }
    // eslint-disable-next-line
  }, [selectedDevice])

  return (
    <ImageContainer
      onClick={handleClick}
      ref={connect}
      isElementSelected={isElementSelected}
      data-ce-lincx-type={customEvenet?.type}
      data-cy='image__element'
      className={`${classname}--container`}
      style={{
        width: 'fit-content',
        height: 'fit-content'
      }}
    >
      <Toolbar
        isElementSelected={isElementSelected}
        defaultTargets={defaultTargets}
        mustacheTag={mustacheTag}
        drag={drag}
      />
      <Resizable
        size={{
          width: elementWidth,
          height: elementHeight
        }}
        onResizeStop={handleResize}
      >
        <ImageComponent
          src={imgSource || imageIcon}
          mustacheTag={mustacheTag}
          alt={imgName}
          className={`${elementClassName} ${classname}`}
          style={{ ...styles, height: elementHeight, width: elementWidth }}
          data-cy='image'
          customevent={customEvenet?.eventType}
          data_attribute={dataattribute}
          draggable='false'
          data-is-editor-element
        />
      </Resizable>
    </ImageContainer>
  )
}

const ImageContainer = styled.div`
  outline: ${props => (props.isElementSelected ? '1px dashed #5e5e5e' : 'none')};
  &:hover {
    outline: 1px dashed #5e5e5e;
  }
`
const ImageComponent = styled.img``
