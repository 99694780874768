import React, { useState } from 'react'
import styled from 'styled-components'
import { Layers } from '@craftjs/layers'

export const LayerSection = () => {
  const [expand, setExpand] = useState(true)
  const handleClick = () => {
    setExpand(!expand)
  }
  return (
    <LayerSectionContainer data-cy='layer'>
      <TitleContainer>
        <Title>Layer</Title>
        <div onClick={handleClick}>
          {expand
            ? (
              <img src='https://img.icons8.com/ios-glyphs/12/000000/double-up--v1.png' alt='icon-arrow-up' />
              )
            : (
              <img src='https://img.icons8.com/ios-glyphs/12/000000/double-down--v1.png' alt='icon-arrow-down' />
              )}
        </div>
      </TitleContainer>
      <LayerContainer expand={expand}>
        <Layers />
      </LayerContainer>
    </LayerSectionContainer>
  )
}

const LayerSectionContainer = styled.div`
  border: 1px solid rgba(0,0,0,0.12);
  display: flex;
  flex-direction: column;
  background: #ffffff;
  z-index: 1000;
}
`
const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
`
const Title = styled.div`
  margin: 6px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #5f6368;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  &:hover {
    color: #202124;
  }
`
const LayerContainer = styled.div`
  display: ${props => (props.expand ? 'block' : 'none')};
`
