import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SliderConfig from '../components/Slider'
import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'

export default function HeightWidthSetting () {
  const [height, setHeight, heighUnit] = useCssKeyValue({
    key: 'height',
    value: 'auto',
    unit: 'px'
  })
  const [width, setWidth, widthUnit] = useCssKeyValue({
    key: 'width',
    value: 'auto',
    unit: 'px'
  })
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Height/Width</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SliderConfig
          label='Height:'
          value={height}
          onChange={(value) => setHeight({ value })}
          max={2000}
          withAuto
          withUnit={heighUnit}
          onChangeUnit={(unit) => setHeight({ unit })}
        />
        <SliderConfig
          label='Width:'
          value={width}
          onChange={(value) => setWidth({ value })}
          max={2000}
          withAuto
          withUnit={widthUnit}
          onChangeUnit={(unit) => setWidth({ unit })}
        />
      </AccordionDetails>
    </Accordion>
  )
}
