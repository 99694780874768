import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const PageSetting = () => {
  return <Settings border colors dimensions display typography />
}

export default function PageComponent ({ children, className = '' }) {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()
  return (
    <div
      className={className}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {children}
    </div>
  )
}

PageComponent.craft = {
  displayName: 'page',
  props: {
    text: 'Text'
  },
  related: {
    toolbar: PageSetting
  }
}
