import { preDefinedPages } from '../components/pageSetting/predefinedPage'
import { useLoadConfig } from './useLoadConfig'
export const usePredefinedPage = () => {
  const { loadPageData } = useLoadConfig()

  const importPredefinedPage = (predefinedPage, pageName) => {
    loadPageData({ ...preDefinedPages[predefinedPage], name: pageName })
  }

  return { importPredefinedPage }
}
