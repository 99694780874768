import { useFormCss } from 'modules/forms-generator/hooks/useFormCss'
import {
  getCurrentValue,
  getCurrentValueByKey
} from 'modules/utils/getCssCurrentValue'
import { useState } from 'react'
import { useFormCssState } from 'state/slices/formsGenerator/formCssSlice'
import { NumberInput } from '../components/NumberInput'
import { SelectComponentOptions } from '../components/Select'
import { TextInput } from '../components/TextInput'
import { DISPLAY_VALUES } from './displayValues'

export const DISPLAY_KEY = {
  DISPLAY: {
    key: 'display',
    defaultValue: 'block',
    values: [
      'block',
      'inline',
      'flex',
      'flow-root',
      'grid',
      'table',
      'list-item',
      'contents',
      'none',
      'inline-block',
      'inline-flex',
      'inline-grid',
      'inline-table'
    ]
  }
}

export const useDisplay = () => {
  const { css, selectedSelector } = useFormCssState()
  const { changeSelectorKeyValue } = useFormCss()
  const [displayOptions, setDisplayOptions] = useState()

  const display = getCurrentValue(css, selectedSelector, DISPLAY_KEY.DISPLAY)

  const updateAttribute = ({ key, value }) => {
    changeSelectorKeyValue({ key, value, unit: undefined })
    if (key === 'display') {
      setDisplayOptions(DISPLAY_VALUES[value])
    }
  }

  const renderDisplayOptions = () => {
    if (!displayOptions) return
    return (
      <>
        {displayOptions.map((option, index) => {
          if (option.values) {
            return (
              <SelectComponentOptions
                key={`${index}_${option.optionName}`}
                label={option.optionName}
                value={
                  getCurrentValueByKey({
                    cssObject: css,
                    currentSelector: selectedSelector,
                    key: option.optionName,
                    defaultValue: option.values[0]
                  }).value
                }
                onChange={(changeEvent, child) => {
                  updateAttribute({
                    key: option.optionName,
                    value: changeEvent.target.value
                  })
                }}
                options={option.values}
              />
            )
          }
          if (option.unit) {
            return (
              <NumberInput
                key={`${index}_${option.optionName}`}
                label={option.optionName}
                value={
                  getCurrentValueByKey({
                    cssObject: css,
                    currentSelector: selectedSelector,
                    key: option.optionName,
                    defaultValue: 0
                  }).value
                }
                onChange={(changeEvent) => {
                  updateAttribute({
                    key: option.optionName,
                    value: changeEvent.target.value
                  })
                }}
              />
            )
          }
          return (
            <TextInput
              key={`${index}_${option.optionName}`}
              label={option.optionName}
              value={
                getCurrentValueByKey({
                  cssObject: css,
                  currentSelector: selectedSelector,
                  key: option.optionName,
                  defaultValue: ''
                }).value
              }
              onChange={(changeEvent) => {
                updateAttribute({
                  key: option.optionName,
                  value: changeEvent.target.value
                })
              }}
            />
          )
        })}
      </>
    )
  }

  return { display, displayOptions, updateAttribute, renderDisplayOptions }
}
