import { Box } from '@mui/material'
import FunctionEditor from './functionEditor'
import FunctionsList from './functionsList'

export default function JsManagement () {
  return (
    <Box
      sx={{
        borderTop: '1px solid #dedede',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }}
    >
      <Box
        sx={{
          height: '100%',
          backgroundColor: '#eeeeee',
          overflowY: 'scroll',
          position: 'relative',
          display: 'flex',
          p: '5px'
        }}
      >
        <FunctionsList />
        <FunctionEditor />
      </Box>
    </Box>
  )
}
