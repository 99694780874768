import { Element, useEditor } from '@craftjs/core'
import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { getFirstValidationErrorMessage } from 'modules/utils/getFirstValidationErrorMessage'
import { useEffect, useState } from 'react'
import { Settings } from '../../settings'
import { ErrorSpanComponent } from '../errorSpan'
import { SelectContainer } from './container'
import { SelectInput } from './selectInput'

export const SelectComponentSetting = () => {
  return <Settings typography dimensions colors border withError />
}

export const SelectComponent = ({
  className,
  suggestions,
  questionKey,
  validations
}) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const { withError } = useEditor((state, _) => ({
    withError:
      state.nodes[id] && state.nodes[id].dom
        ? state.nodes[id].dom.classList.contains('with__error')
        : false
  }))

  useEffect(() => {
    setShowErrorMessage(withError)
  }, [withError])

  return (
    <div
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      <Element
        canvas
        is={SelectContainer}
        className='dropdown__container'
        id={`container__${questionKey}`}
      >
        <Element
          canvas
          is={SelectInput}
          suggestions={suggestions}
          id={`select__${questionKey}`}
        />
      </Element>
      {showErrorMessage && (
        <Element
          canvas
          is={ErrorSpanComponent}
          text={getFirstValidationErrorMessage(validations)}
          id={`error__span__${questionKey}`}
          className='error__span'
        />
      )}
    </div>
  )
}

SelectComponent.craft = {
  displayName: 'Select Component',
  related: {
    toolbar: SelectComponentSetting
  }
}
