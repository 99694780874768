import { Box, IconButton, Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ELEMENTS_TYPES } from 'modules/utils/elementsTypes'
import { ElementIcon } from '../elements-drawer/elementIcon'
import AddElementButton from './addNewElementButton'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useDrag, useDrop } from 'react-dnd'
import { useRef } from 'react'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const getTextValue = (element) => {
  switch (element.kind) {
    case 'TEXT':
      return element.value

    case ELEMENTS_TYPES.BUTTON:
      return `${element.value} Role: ${element.role}`
    case ELEMENTS_TYPES.CONTAINER:
      return `${element.className}`

    case ELEMENTS_TYPES.RADIO_BUTTONS:
    case ELEMENTS_TYPES.DROPDOWN_SELECT:
    case ELEMENTS_TYPES.RANGE_SLIDER:
    case ELEMENTS_TYPES.CHECKBOX:
    case 'FORM':
      return `key = ${element.key}`

    default:
      return ''
  }
}

const ChildItem = ({ element, containerIndex, elementIndex }) => {
  const { type, kind } = element
  const { selectElementByIndex, deleteElementByIndex } = useSelectedElements()
  return (
    <Item
      sx={{
        mt: '10px',
        width: '350px',
        display: 'flex',
        alignSelf: 'end',
        alignItems: 'center',
        minHeight: '40px'
      }}
      elevation={2}
    >
      <ElementIcon elementType={type || kind} />
      <Typography sx={{ fontWeight: 'bold' }}>{type || kind}</Typography>:
      <Typography sx={{ fontWeight: 'bold', flex: 1 }}>
        {getTextValue(element)}
      </Typography>
      <IconButton
        aria-label='edit'
        onClick={() =>
          selectElementByIndex({
            elementIndex: elementIndex,
            containerIndex: containerIndex
          })}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        aria-label='delete'
        onClick={() => {
          deleteElementByIndex(containerIndex, elementIndex)
        }}
      >
        <ClearIcon />
      </IconButton>
    </Item>
  )
}

export default function ElementItem ({ element, idx, moveCard }) {
  const ref = useRef(null)
  const { selectElementByIndex, deleteElementByIndex } = useSelectedElements()
  const [{ handlerId }, drop] = useDrop({
    accept: 'item',
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover (item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = idx
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: () => {
      return { id: idx, index: idx }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  if (!element) return
  const { type, kind } = element
  return (
    <Box data-handler-id={handlerId} ref={ref}>
      <Item
        sx={{
          mt: '10px',
          width: '400px',
          display: 'flex',
          alignItems: 'center',
          minHeight: '40px',
          cursor: 'move',
          opacity: opacity
        }}
        elevation={4}
      >
        <ElementIcon elementType={type || kind} />
        <Typography sx={{ fontWeight: 'bold' }}>{type || kind}</Typography>:
        <Typography sx={{ fontWeight: 'bold', flex: 1 }}>
          {getTextValue(element)}
        </Typography>
        {kind === ELEMENTS_TYPES.CONTAINER && (
          <AddElementButton isContainer containerIndex={idx} />
        )}
        <IconButton
          aria-label='edit'
          onClick={() =>
            selectElementByIndex({ elementIndex: idx, containerIndex: null })}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label='delete'
          onClick={() => {
            deleteElementByIndex(idx)
          }}
        >
          <ClearIcon />
        </IconButton>
      </Item>
      {kind === ELEMENTS_TYPES.CONTAINER &&
        element.children.map((child, elemIndex) => (
          <ChildItem
            element={child}
            key={elemIndex}
            containerIndex={idx}
            elementIndex={elemIndex}
          />
        ))}
    </Box>
  )
}
