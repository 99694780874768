import { Box } from '@mui/material'
import ContactForm1 from './data/contact-1.json'
import FinanceForm from './data/finance-information.json'
import TemplateItem from './item'
const Templates = [
  { name: 'Contact Form v1', type: 'contact', data: ContactForm1 },
  { name: 'Finance Information', type: 'rent', data: FinanceForm }
]

export default function TemplatesList () {
  return (
    <Box>
      {Templates.map((item, index) => (
        <TemplateItem item={item} key={`template__${index}`} />
      ))}
    </Box>
  )
}
