export const firstLastNameCapturePreDefinedPage = {
  name: 'fullname',
  className: '',
  elements: [
    {
      kind: 'TEXT',
      type: 'TITLE',
      className: 'description fullname__description',
      value: 'What is your name?'
    },
    {
      kind: 'CONTAINER',
      className: 'first__name__container',
      keys: [],
      children: [
        {
          kind: 'TEXT',
          type: 'PARAGRAPH',
          className: 'label first__name__label',
          value: 'First Name'
        },
        {
          kind: 'FORM',
          type: 'TEXT',
          className: 'input first__name__input',
          placeholder: 'Please enter your first name',
          key: 'firstName',
          validations: {
            required: {
              value: true,
              message: 'First Name is required'
            }
          }
        }
      ]
    },
    {
      kind: 'CONTAINER',
      className: 'last__name__container',
      keys: [],
      children: [
        {
          kind: 'TEXT',
          type: 'PARAGRAPH',
          className: 'label last__name__label',
          value: 'Last Name'
        },
        {
          kind: 'FORM',
          type: 'TEXT',
          className: 'input last__name__input',
          placeholder: 'Please enter your last name',
          key: 'lastName',
          validations: {
            required: {
              value: true,
              message: 'Last Name is required'
            }
          }
        }
      ]
    },
    {
      kind: 'CONTAINER',
      className: 'navigation__container',
      keys: [],
      children: [
        {
          kind: 'BUTTON',
          className: 'next__button',
          value: 'NEXT',
          role: 'NEXT'
        },
        {
          kind: 'BUTTON',
          className: 'back__button',
          value: 'BACK',
          role: 'BACK'
        }
      ]
    }
  ],
  nextPage: {},
  layouts: {
    beforeElements: '',
    afterElements: ''
  }
}
