import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useEditor } from '@craftjs/core'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import MinPlusInput from './min-plus-input'
import styled from 'styled-components'
import { useStylesHelpers } from '../../../hooks/use-styles-helpers'
import { Container, Wrapper } from './dimensions-styled'

export default function WidthSection () {
  const [styleOption, setStyleOption] = useState('keywords')
  const [width, setWidth] = useState('')
  const { changestyle } = useStylesHelpers()

  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const { active, classes } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName ||
        []
    }
  })

  const handleClick = e => {
    setStyleOption(e.target.value)
  }

  const handleDropdownChange = e => {
    setWidth(e.target.value)
  }

  const setStylesValue = (cssKey, value) => {
    if (classes) {
      changestyle({ classes, styles, cssKey, value })
    }
  }

  useEffect(() => {
    setStylesValue('width', width)
    // eslint-disable-next-line
  }, [width])
  useEffect(() => {
    if (selectedDevice === 'tablet') {
      if (isNaN(parseInt(tabletStyle?.[selectedClassName]?.width))) {
        setStyleOption('keywords')
        setWidth(tabletStyle?.[selectedClassName]?.width || '')
      } else {
        setStyleOption('fixed')
      }
    } else if (selectedDevice === 'desktop') {
      if (isNaN(parseInt(desktopStyle?.[selectedClassName]?.width))) {
        setStyleOption('keywords')
        setWidth(desktopStyle?.[selectedClassName]?.width || '')
      } else {
        setStyleOption('fixed')
      }
    } else {
      if (isNaN(parseInt(styles?.[selectedClassName]?.width))) {
        setStyleOption('keywords')
        setWidth(styles?.[selectedClassName]?.width || '')
      } else {
        setStyleOption('fixed')
      }
    }
    // eslint-disable-next-line
  },[selectedClassName])
  useEffect(() => {
    if (selectedDevice === 'tablet') {
      if (isNaN(parseInt(tabletStyle?.[classes?.[0]]?.width))) {
        setStyleOption('keywords')
        setWidth(tabletStyle?.[classes?.[0]]?.width || '')
      } else {
        setStyleOption('fixed')
      }
    } else if (selectedDevice === 'desktop') {
      if (isNaN(parseInt(desktopStyle?.[classes?.[0]]?.width))) {
        setStyleOption('keywords')
        setWidth(desktopStyle?.[classes?.[0]]?.width || '')
      } else {
        setStyleOption('fixed')
      }
    } else {
      if (isNaN(parseInt(styles?.[classes?.[0]]?.width))) {
        setStyleOption('keywords')
        setWidth(styles?.[classes?.[0]]?.width || '')
      } else {
        setStyleOption('fixed')
      }
    }
    // eslint-disable-next-line
  }, [active, selectedDevice])

  return (
    <Wrapper>
      <Container center='center' data-cy='width__styling__section'>
        <FormControl>
          <RadioGroup row name='withStyleOption'>
            <FormControlLabel
              value='fixed'
              control={<Radio />}
              label='Fixed'
              checked={styleOption === 'fixed'}
              onChange={handleClick}
              data-cy='fixed__width'
            />
            <FormControlLabel
              value='keywords'
              control={<Radio />}
              label='Key Words'
              checked={styleOption === 'keywords'}
              onChange={handleClick}
              data-cy='keyword__width'
            />
          </RadioGroup>
        </FormControl>
        <OptionsContainer>
          {styleOption === 'keywords'
            ? (
              <FormControl sx={{ m: 1, minWidth: 200 }} size='small'>
                <Select
                  value={width}
                  onChange={handleDropdownChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  data-cy='width__keyword__values__select'
                >
                  <MenuItem value='auto'>auto</MenuItem>
                  <MenuItem value='fit-content'>fit-content</MenuItem>
                  <MenuItem value='inherit'>inherit</MenuItem>
                  <MenuItem value='initial'>initial</MenuItem>
                  <MenuItem value='auto'>auto</MenuItem>
                  <MenuItem value='max-content'>max-content</MenuItem>
                  <MenuItem value='min-content'>min-content</MenuItem>
                  <MenuItem value='unset'>unset</MenuItem>
                  <MenuItem value='-web-kit-fill-available'>-web-kit-fill-available</MenuItem>
                </Select>
              </FormControl>
              )
            : (
              <MinPlusInput title='' defaultValue='auto' cssKey='width' />
              )}
        </OptionsContainer>
      </Container>
    </Wrapper>
  )
}

const OptionsContainer = styled.div`
  margin-top: 20px;
`
