import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from './dimensions-styled'
import ShadowInput from './shadowInput'
import { ROOT_NODE, useEditor } from '@craftjs/core'
import { useSelector } from 'react-redux'
import { useStylesHelpers } from 'hooks/use-styles-helpers'

export default function ShadowSection () {
  const [shadow, setShadow] = useState({
    x: 0,
    y: 0,
    blur: 0,
    color: '#000000'
  })
  const [cssKey, setCssKey] = useState('textShadow')
  const { changestyle } = useStylesHelpers()

  const { classes, selected } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName ||
        [],
      selected: {
        id: currentlySelectedNodeId,
        name: state.nodes[currentlySelectedNodeId].data.name
      },
      rootProps: query.node(ROOT_NODE).get()?.data?.props,
      isRoot: ROOT_NODE === currentlySelectedNodeId
    }
  })

  const {
    selectedDevice,
    styles,
    tabletStyle,
    desktopStyle,
    selectedClassName
  } = useSelector(state => state.styles)

  useEffect(() => {
    setCssKey(selected.name === 'Cta' || selected.name === 'Image' ? 'boxShadow' : 'textShadow')
  }, [selected])

  useEffect(() => {
    if (selectedClassName) {
      let ShadowValue
      if (selectedDevice === 'tablet') {
        ShadowValue =
          tabletStyle[selectedClassName]?.[cssKey] ?? '0 0 0 #000000'
      } else if (selectedDevice === 'desktop') {
        ShadowValue =
          desktopStyle[selectedClassName]?.[cssKey] ?? '0 0 0 #000000'
      } else {
        ShadowValue = styles[selectedClassName]?.[cssKey] ?? '0 0 0 #000000'
      }
      const [x, y, blur, color] = ShadowValue.split(' ')
      setShadow({ x: parseInt(x), y: parseInt(y), blur: parseInt(blur), color })
    }
    // eslint-disable-next-line
  }, [selectedClassName])

  const handleShadowChange = (x, y, blur, color) => {
    setShadow({ x, y, blur, color })
    const value = `${x}px ${y}px ${blur}px ${color}`
    if (selectedClassName) {
      changestyle({ classes, styles, cssKey, value, selectedClassName })
      // selectedElement.style.boxShadow = ${x}px ${y}px ${blur}px ${color};
    }
  }
  return (
    <Wrapper>
      <Container center='space-around' data-cy='shadow__styling__section'>
        <ShadowInput {...shadow} onShadowChange={handleShadowChange} />
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  margin-top: 10px;
`
