import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useEditor, useNode } from '@craftjs/core'
import { useStyles } from '../../hooks/use-styles'
import useEditorHelpers from '../../hooks/useEditorHelpers'
import { Toolbar } from './Toolbar'

export const Text = ({ text, classname }) => {
  const { elementClassName } = useStyles()
  const [isElementSelected, setIsElementSelected] = useState(false)
  const defaultTargets = ['content', 'click link', 'visibility']
  const currentComponent = 'Text'
  const {
    connectors: { connect, drag },
    actions: { setProp }
  } = useNode()
  const { selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected
    }
  })
  useEffect(() => {
    if (selected?.name === currentComponent) {
      setIsElementSelected(true)
    } else {
      setIsElementSelected(false)
    }
    // eslint-disable-next-line
  }, [selected])
  const { ActiveSideBarElements } = useEditorHelpers()
  const handleClick = () => {
    ActiveSideBarElements('Styles')
    if (selected.name === currentComponent) {
      setIsElementSelected(true)
    }
  }
  useEffect(() => {
    setProp(props => (props.elementClassName = [classname !== undefined ? classname : elementClassName]))
  })
  return (
    <TextContainer className={`${elementClassName} ${classname}`} onClick={handleClick} ref={ref => connect(drag(ref))} data-is-editor-element>
      <Toolbar
        isElementSelected={isElementSelected}
        defaultTargets={defaultTargets}
        drag={drag}
      />
      <p>{text}</p>
    </TextContainer>
  )
}

const TextContainer = styled.div`
  width: 300px;
  text-align: center;
  &:hover {
    border: 1px dashed #5e5e5e;
  }
`
