import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useEditor } from '@craftjs/core'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import MinPlusInput from './min-plus-input'
import styled from 'styled-components'
import { useStylesHelpers } from '../../../hooks/use-styles-helpers'
import { Container } from './dimensions-styled'

export default function HeightSection () {
  const [styleOption, setStyleOption] = useState('keywords')
  const [height, setHeight] = useState('')
  const { changestyle } = useStylesHelpers()

  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const { active, classes } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: currentlySelectedNodeId,
      classes:
        state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName ||
        []
    }
  })

  const handleClick = e => {
    setStyleOption(e.target.value)
  }

  const handleDropdownChange = e => {
    setHeight(e.target.value)
  }

  const setStylesValue = (cssKey, value) => {
    if (classes) {
      changestyle({ classes, styles, cssKey, value })
    }
  }

  useEffect(() => {
    setStylesValue('height', height)
    // eslint-disable-next-line
  }, [height])
  useEffect(() => {
    if (selectedDevice === 'tablet') {
      if (isNaN(parseInt(tabletStyle?.[selectedClassName]?.height))) {
        setStyleOption('keywords')
        setHeight(tabletStyle?.[selectedClassName]?.height || '')
      } else {
        setStyleOption('fixed')
      }
    } else if (selectedDevice === 'desktop') {
      if (isNaN(parseInt(desktopStyle?.[selectedClassName]?.height))) {
        setStyleOption('keywords')
        setHeight(desktopStyle?.[selectedClassName]?.height || '')
      } else {
        setStyleOption('fixed')
      }
    } else {
      if (isNaN(parseInt(styles?.[selectedClassName]?.height))) {
        setStyleOption('keywords')
        setHeight(styles?.[selectedClassName]?.height || '')
      } else {
        setStyleOption('fixed')
      }
    }
    // eslint-disable-next-line
  },[selectedClassName])
  useEffect(() => {
    if (selectedDevice === 'tablet') {
      if (isNaN(parseInt(tabletStyle?.[classes?.[0]]?.height))) {
        setStyleOption('keywords')
        setHeight(tabletStyle?.[classes?.[0]]?.height || '')
      } else {
        setStyleOption('fixed')
      }
    } else if (selectedDevice === 'desktop') {
      if (isNaN(parseInt(desktopStyle?.[classes?.[0]]?.height))) {
        setStyleOption('keywords')
        setHeight(desktopStyle?.[classes?.[0]]?.height || '')
      } else {
        setStyleOption('fixed')
      }
    } else {
      if (isNaN(parseInt(styles?.[classes?.[0]]?.height))) {
        setStyleOption('keywords')
        setHeight(styles?.[classes?.[0]]?.height || '')
      } else {
        setStyleOption('fixed')
      }
    }
    // eslint-disable-next-line
  }, [active, selectedDevice])

  return (
    <Container data-cy='height__styling__section'>
      <FormControl>
        <RadioGroup row name='withStyleOption'>
          <FormControlLabel
            value='fixed'
            control={<Radio />}
            label='Fixed'
            checked={styleOption === 'fixed'}
            name='styleOption'
            id='heightValue'
            onChange={handleClick}
            data-cy='fixed__height'
          />
          <FormControlLabel
            value='keywords'
            control={<Radio />}
            label='Key Words'
            checked={styleOption === 'keywords'}
            onChange={handleClick}
            data-cy='keyword__height'
          />
        </RadioGroup>
      </FormControl>
      <OptionsContainer>
        {styleOption === 'keywords'
          ? (
            <FormControl sx={{ m: 1, minWidth: 200 }} size='small'>
              <Select
                value={height}
                onChange={handleDropdownChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                data-cy='height__keyword'
              >
                <MenuItem value='auto'>auto</MenuItem>
                <MenuItem value='fit-content'>fit-content</MenuItem>
                <MenuItem value='inherit'>inherit</MenuItem>
                <MenuItem value='initial'>initial</MenuItem>
                <MenuItem value='auto'>auto</MenuItem>
                <MenuItem value='max-content'>max-content</MenuItem>
                <MenuItem value='min-content'>min-content</MenuItem>
                <MenuItem value='unset'>unset</MenuItem>
                <MenuItem value='-web-kit-fill-available'>
                  -web-kit-fill-available
                </MenuItem>
              </Select>
            </FormControl>
            )
          : (
            <MinPlusInput title='' defaultValue='200' cssKey='height' />
            )}
      </OptionsContainer>
    </Container>
  )
}

const OptionsContainer = styled.div`
  margin-top: 20px;
`
