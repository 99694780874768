import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { JsEditor } from '../../components/sidebar/ExportHtml/JsEditor'
import useEditorHelpers from '../../hooks/useEditorHelpers'

export const JsModal = () => {
  const { showJsModal } = useSelector(state => state.editor)
  const { scripts } = useSelector(state => state.scripts)
  const { showJsModalonEditor } = useEditorHelpers()
  const closeDialogue = () => {
    showJsModalonEditor()
  }
  const addJavascriptCode = () => {
    const lincxScript = document.querySelector('script[lincx-script="true"]')
    const script = document.createElement('script')
    const code = document.createTextNode(scripts)
    script.setAttribute('lincx-script', true)
    script.appendChild(code)
    if (lincxScript) {
      const lincxZoneContainer = document.getElementById('__lincx_template__').parentElement
      lincxZoneContainer.removeChild(lincxScript)
      lincxZoneContainer.appendChild(script)
    } else {
      const lincxZoneContainer = document.getElementById('__lincx_template__').parentElement
      lincxZoneContainer.appendChild(script)
    }
    showJsModalonEditor()
  }
  return (
    <>
      <ExportModal openDialogue={showJsModal} data-cy='JS__Modal'>
        <ModalContainer>
          <DialogueCloseButton onClick={closeDialogue}>
            <img
              src='https://img.icons8.com/material-outlined/24/1A1A1A/delete-sign.png'
              alt='close-icon'
            />
          </DialogueCloseButton>
          <EditorContainer>
            <JsEditor />
          </EditorContainer>
          <SaveButtonContainer>
            <button onClick={addJavascriptCode} data-cy='save__button'>Save</button>
          </SaveButtonContainer>
        </ModalContainer>
      </ExportModal>
    </>
  )
}
const ExportModal = styled.div`
  display: ${props => (props.openDialogue ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: #0000009e;
`
const ModalContainer = styled.div`
  position: relative;
  padding: 10px;
  background: #ffff;
  z-index: 1000;
  margin: auto;
  border-radius: 10px;
`
const DialogueCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  img {
    width: 15px;
  }
`
const EditorContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`
const SaveButtonContainer = styled.div`
  display: felx;
  justify-content: flex-end;
  padding: 10px 0;
  button {
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    background: #28a745;
    &: hover {
      background: #1e7b34;
    }
  }
`
