import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const identityServer = 'https://ix-id.lincx.la'
const keyPrefix = '_aui_'
const ls = window.localStorage

const useAuth = () => {
  const get = (key) => {
    const lsKey = keyPrefix + key
    const str = ls.getItem(lsKey) || 'null'
    return JSON.parse(str)
  }

  const [authToken, setAuthToken] = useState(get('authToken'))
  const [email, setEmail] = useState(get('email'))
  const navigate = useNavigate()

  const set = (key, val) => {
    const lsKey = keyPrefix + key
    if (typeof val !== 'undefined' && val !== null) {
      ls.setItem(lsKey, JSON.stringify(val))
    } else {
      ls.removeItem(lsKey)
    }
    return val
  }

  useEffect(() => {
    set('authToken', authToken)
    set('email', email)
  }, [authToken, email])

  const isLoggedIn = () => {
    return Boolean(authToken)
  }

  const navigateIfNotLoggedIn = (to) => {
    if (!isLoggedIn()) {
      return navigate(to)
    }
  }

  const login = async (state, cb) => {
    try {
      const { data } = await axios.post(`${identityServer}/auth/login`, state)
      const { authToken } = data.data
      const { email } = state
      setAuthToken(authToken)
      setEmail(email)
      cb(null, data)
    } catch (error) {
      const {
        response: { data }
      } = error
      cb(data, null)
    }
  }

  const logout = (cb) => {
    setAuthToken(null)
    setEmail(null)
    cb && cb()
  }

  return {
    email,
    isLoggedIn,
    login,
    logout,
    set,
    get,
    navigateIfNotLoggedIn
  }
}

export default useAuth
