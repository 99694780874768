import { Box } from '@mui/system'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'
import SpeedDials from './speedDials'
import ElementsBuilder from './elements-builder'
import Design from '../design'
import ConnectionDialogue from './pages-flow/connectionDialogue'
import TemplatesPage from '../templates'

export default function Builder () {
  const { builderTabs } = useFormsBuilderState()
  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: '#eeeeee',
        overflowY: 'scroll',
        position: 'relative'
      }}
    >
      <Box sx={{ display: 'flex', height: '100%', overflowY: 'scroll' }}>
        <Box
          sx={{
            flex: 1
          }}
        >
          {builderTabs === 'edit' && <ElementsBuilder />}
          {builderTabs === 'design' && <Design />}
          {builderTabs === 'templates' && <TemplatesPage />}
        </Box>
      </Box>
      <SpeedDials />
      <ConnectionDialogue />
    </Box>
  )
}
