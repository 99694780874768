export const DISPLAY_VALUES = {
  flex: [
    {
      optionName: 'flex-direction',
      values: ['row', 'row-reverse', 'column', 'column-reverse']
    },
    {
      optionName: 'flex-wrap',
      values: ['nowrap', 'wrap', 'wrap-reverse']
    },
    {
      optionName: 'justify-content',
      values: [
        'flex-start',
        'flex-end',
        'center',
        'space-around',
        'space-between',
        'space-evenly'
      ]
    },
    {
      optionName: 'align-items',
      values: ['stretch', 'flex-start', 'flex-end', 'center']
    },
    {
      optionName: 'align-content',
      values: [
        'stretch',
        'flex-start',
        'flex-end',
        'center',
        'space-around',
        'space-between',
        'space-evenly'
      ]
    }
  ],
  grid: [
    {
      optionName: 'column-gap',
      unit: 'px'
    },
    {
      optionName: 'row-gap',
      unit: 'px'
    },
    {
      optionName: 'grid-template-columns'
    },
    {
      optionName: 'grid-template-rows'
    },
    {
      optionName: 'grid-template-areas'
    },
    {
      optionName: 'justify-items',
      values: ['start', 'end', 'center', 'stretch']
    },
    {
      optionName: 'align-items',
      values: ['start', 'end', 'center', 'stretch']
    },
    {
      optionName: 'justify-content',
      values: [
        'stretch',
        'start',
        'end',
        'center',
        'space-around',
        'space-between',
        'space-evenly'
      ]
    },
    {
      optionName: 'align-content',
      values: [
        'stretch',
        'start',
        'end',
        'center',
        'space-around',
        'space-between',
        'space-evenly'
      ]
    }
  ]
}
