const imageIcon =
  'https://media.istockphoto.com/vectors/image-preview-icon-picture-placeholder-for-website-or-uiux-design-vector-id1222357475?k=20&m=1222357475&s=170667a&w=0&h=YGycIDbBRAWkZaSvdyUFvotdGfnKhkutJhMOZtIoUKY='

export const getOpenTag = node => {
  const elementSelector = getClassList(node)
  switch (node.displayName) {
    case 'ZoneContainer':
      return '<div class=\'main__zone__container\'> \n{{#ads}}'
    case 'AdContainer':
      return `<div ${elementSelector} id='{{ adId }}'>`
    case 'Image':
      return getImageOpenningTag(node)
    case 'Text':
      return `<p ${elementSelector}>`
    case 'Bullets':
      return getFeaturesOpenningTag(node)
    case 'Cta':
      return getCtaOpenningTag(node)
    case 'Headline':
      return getHeadlineOpenningTag(node)
    case 'ElementContainer':
      return getElementContainerOpenningTag(node)
    case 'div':
      return getDivOpeningTag(node)
    default:
      break
  }
  return `<div class='${elementSelector}'>`
}

const getDivOpeningTag = (node) => {
  const element = `div ${getClassList(node)}`
  return `<${element}>${node.props?.children || node.props.children}`
}

export const getClosingTag = node => {
  switch (node.displayName) {
    case 'ZoneContainer':
      return '{{/ads}}\n</div>'
    case 'AdContainer':
      return '</div>'
    case 'Image':
      return getImageClosingTag(node)
    case 'Text':
      return '</p>'
    case 'Bullets':
      return getFeaturesClosingTag(node)
    case 'Cta':
      return getCtaClosignTag(node)
    case 'Headline':
      return getHeadlineClosingTag(node)
    case 'ElementContainer':
      return getElementContainerClosingTag(node)
    case 'div':
      return '</div>'
    default:
      break
  }
  return '</div>'
}

function getImageOpenningTag (node) {
  const imageSource = node.props?.mustacheTag?.filter(
    tag => tag.target === 'src'
  )

  let imageElement = `\n<img src='${imageSource?.[0]?.name || imageIcon}' alt='image-alt'`

  imageElement = imageElement + getClassList(node)

  if (node.props?.mustacheTag) {
    const onlyAttributeTags = node.props.mustacheTag?.filter(
      tag => tag.target !== 'visibility' && tag.target !== 'click link' && tag.target !== 'src'
    )
    imageElement = imageElement + `${onlyAttributeTags
      .map(tag => `${tag.target}='${tag.name}'`)
      .join('')}`
  }

  const clickLink = node.props?.mustacheTag?.filter(
    tag => tag.target === 'click link'
  )
  if (clickLink?.[0]) {
    imageElement = `\n <a href='${clickLink[0].name}'>${imageElement}`
  }

  if (node.props?.dataattribute) {
    const dataAttributes = [...node.props?.dataattribute]
      .map(tag => `${tag.attributeName} = '${tag.tagName}' `)
      .join('')
    imageElement = imageElement + dataAttributes
  }

  const customEvent = getCustomEvent(node)
  if (customEvent) {
    imageElement = imageElement + customEvent
  }

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    imageElement = `\n{{#${visibility[0].name}}}${imageElement}`
  }

  return imageElement
}

function getImageClosingTag (node) {
  let imageCloseTag = '/>'
  const clickLink = node.props?.mustacheTag?.filter(
    tag => tag.target === 'click link'
  )
  if (clickLink?.[0]) {
    imageCloseTag = `${imageCloseTag} \n</a>`
  }

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    imageCloseTag = `${imageCloseTag} \n{{/${visibility[0].name}}}`
  }

  return imageCloseTag
}

function getCtaOpenningTag (node) {
  let ctaOpenTag = '\n <a data-lincx-cta target="_blank" '

  ctaOpenTag = ctaOpenTag + getClassList(node)

  if (node.props?.mustacheTag) {
    const onlyAttributeTags = node.props.mustacheTag?.filter(
      tag => tag.target !== 'visibility' && tag.target !== 'content'
    )
    ctaOpenTag = ctaOpenTag + `${onlyAttributeTags
      .map(tag => `${tag.target}='${tag.name}'`)
      .join('')}`
  }

  if (node.props?.dataattribute) {
    const dataAttributes = [...node.props?.dataattribute]
      .map(tag => `${tag.attributeName} = '${tag.tagName}' `)
      .join('')
    ctaOpenTag = ctaOpenTag + dataAttributes
  }

  const customEvent = getCustomEvent(node)
  if (customEvent) {
    ctaOpenTag = ctaOpenTag + customEvent
  }

  ctaOpenTag = `${ctaOpenTag}>`

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    ctaOpenTag = `\n{{#${visibility[0].name}}}${ctaOpenTag} `
  }

  const content = node.props?.mustacheTag?.filter(
    tag => tag.target === 'content'
  )
  if (content?.[0]) {
    ctaOpenTag = ctaOpenTag + content[0].name
  } else {
    if (node.props.ctaText) {
      ctaOpenTag = `${ctaOpenTag} ${node.props.ctaText}`
    } else {
      ctaOpenTag = `${ctaOpenTag} Example CTA`
    }
  }

  return ctaOpenTag
}
function getCtaClosignTag (node) {
  let ctaClosingTag = '</a>'
  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    ctaClosingTag = `${ctaClosingTag} \n{{/${visibility[0].name}}}`
  }
  return ctaClosingTag
}

function getFeaturesOpenningTag (node) {
  let featuresOpenningTag = '<ul '
  featuresOpenningTag = featuresOpenningTag + getClassList(node)

  if (node.props?.dataattribute) {
    const dataAttributes = [...node.props?.dataattribute]
      .map(tag => `${tag.attributeName} = '${tag.tagName}' `)
      .join('')
    featuresOpenningTag = `${featuresOpenningTag} ${dataAttributes}`
  }

  const customEvent = getCustomEvent(node)
  if (customEvent) {
    featuresOpenningTag = `${featuresOpenningTag} ${customEvent}`
  }

  featuresOpenningTag = featuresOpenningTag + '>'

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    featuresOpenningTag = `\n{{#${visibility[0].name}}}${featuresOpenningTag} `
  }

  const listItems = node.props?.mustacheTag?.filter(
    tag => tag.target === 'list items'
  )
  if (listItems?.[0]) {
    featuresOpenningTag = `${featuresOpenningTag}\n{{#${listItems[0].name}}}\n<li> {{{.}}} </li>\n{{/${listItems[0].name}}}`
  } else {
    if (node.props.bullets) {
      featuresOpenningTag = `${featuresOpenningTag} ${node.props.bullets.map((feature) => (`\n<li>${feature}</li>`)).join('')}`
    }
  }

  return featuresOpenningTag
}

function getHeadlineOpenningTag (node) {
  let headlineOpenningTag = '<h1 '

  headlineOpenningTag = headlineOpenningTag + getClassList(node)

  if (node.props?.dataattribute) {
    const dataAttributes = [...node.props?.dataattribute]
      .map(tag => `${tag.attributeName} = '${tag.tagName}' `)
      .join('')
    headlineOpenningTag = `${headlineOpenningTag} ${dataAttributes}`
  }

  const customEvent = getCustomEvent(node)
  if (customEvent) {
    headlineOpenningTag = `${headlineOpenningTag} ${customEvent}`
  }

  if (node.props?.mustacheTag) {
    const onlyAttributeTags = node.props.mustacheTag?.filter(
      tag =>
        tag.target !== 'visibility' &&
        tag.target !== 'content' &&
        tag.target !== 'click link'
    )
    headlineOpenningTag = headlineOpenningTag + ` ${onlyAttributeTags
      .map(tag => `${tag.target}="${tag.name}"`)
      .join('')}`
  }

  headlineOpenningTag = `${headlineOpenningTag}>`

  const clickLink = node.props?.mustacheTag?.filter(
    tag => tag.target === 'click link'
  )
  if (clickLink?.[0]) {
    headlineOpenningTag = `\n <a href='${clickLink[0].name}'>${headlineOpenningTag}`
  }

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    headlineOpenningTag = `\n{{#${visibility[0].name}}}${headlineOpenningTag} `
  }

  const content = node.props?.mustacheTag?.filter(
    tag => tag.target === 'content'
  )
  if (content?.[0]) {
    headlineOpenningTag = `${headlineOpenningTag} {{ ${content[0].name} }}`
  } else {
    if (node.props.text) {
      headlineOpenningTag = `${headlineOpenningTag} ${node.props.text}`
    } else {
      headlineOpenningTag = `${headlineOpenningTag} Example Headline`
    }
  }

  return headlineOpenningTag
}

function getHeadlineClosingTag (node) {
  let headlineClosingTag = '</h1>'

  const clickLink = node.props?.mustacheTag?.filter(
    tag => tag.target === 'click link'
  )
  if (clickLink?.[0]) {
    headlineClosingTag = `${headlineClosingTag} \n</a>`
  }

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    headlineClosingTag = `${headlineClosingTag} \n{{/${visibility[0].name}}}`
  }

  return headlineClosingTag
}

function getFeaturesClosingTag (node) {
  let featureClosingTag = '</ul>'

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    featureClosingTag = `${featureClosingTag} \n{{/${visibility[0].name}}}`
  }

  return featureClosingTag
}

function getCustomEvent (node) {
  const customEvenetType = node.props?.customEvenet?.eventType || ''
  if (customEvenetType) {
    return `data-ce-lincx-type="${customEvenetType}" data-ce-lincx-template-id="{{templateId}}" data-ce-lincx-zone-id="{{zoneId}}" data-ce-lincx-click-id="{{clickId}}" data-ce-lincx-ad-id="{{adId}}"`
  }
}

function getClassList (node) {
  let classSelector = 'class=\''
  if (node.props?.elementClassName) {
    classSelector = classSelector + node.props?.elementClassName.join(' ')
  }
  if (node.props?.className) {
    classSelector = classSelector + ` ${node.props?.className?.name.join(' ')}`
  }
  if (node.props?.classname) {
    classSelector = classSelector + ` ${node.props?.classname}`
  }
  classSelector = `${classSelector}'`
  if (classSelector === "class=''") {
    return ''
  }
  return classSelector
}

function getElementContainerOpenningTag (node) {
  let containerTag = '<div '
  containerTag = containerTag + getClassList(node)
  const customEvent = getCustomEvent(node)
  if (customEvent) {
    containerTag = containerTag + customEvent
  }
  const dataAttributes = getDataAttributes(node)
  if (dataAttributes) {
    containerTag = containerTag + dataAttributes
  }
  containerTag = containerTag + '>'
  const clickLink = node.props?.mustacheTag?.filter(
    tag => tag.target === 'click link'
  )
  if (clickLink?.[0]) {
    containerTag = `\n <a href='${clickLink[0].name}'>${containerTag}`
  }
  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    containerTag = `\n{{#${visibility[0].name}}}${containerTag} `
  }
  return containerTag
}

function getElementContainerClosingTag (node) {
  let containerCloseTag = '</div>'
  const clickLink = node.props?.mustacheTag?.filter(
    tag => tag.target === 'click link'
  )
  if (clickLink?.[0]) {
    containerCloseTag = `${containerCloseTag} \n</a>`
  }

  const visibility = node.props?.mustacheTag?.filter(
    tag => tag.target === 'visibility'
  )
  if (visibility?.[0]) {
    containerCloseTag = `${containerCloseTag} \n{{/${visibility[0].name}}}`
  }

  return containerCloseTag
}

function getDataAttributes (node) {
  let dataAttributes
  if (node.props?.dataattribute) {
    dataAttributes = [...node.props?.dataattribute]
      .map(tag => `${tag.attributeName} = '${tag.tagName}' `)
      .join('')
  }
  return dataAttributes
}
