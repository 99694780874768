import React from 'react'
import styled from 'styled-components'
import { useNode } from '@craftjs/core'
import { Image } from './Image'
import { Text } from './Text'
import { Headline } from './Headline'
import { Bullets } from './Bullets'
import { Cta } from './Cta'
import { ElementContainer } from './ElementContainer'

const allowedComponentToMoveIn = [Image, Text, Headline, Cta, ElementContainer, Bullets]

export const AdContainer = ({ children, classname }) => {
  const {
    connectors: { connect, drag }
  } = useNode()
  return (
    <ContainerWrapper ref={dom => connect(drag(dom))} id='__lincx_template__' data-cy='ads__container' className={classname}>
      <Label>Ad</Label>
      {children}
    </ContainerWrapper>
  )
}
const ContainerWrapper = styled.div`
  border: 1px dashed #5e5e5e;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content:space-evenly;
  flex-wrap: wrap;
  &:hover {
    border: 1px dashed #5e5e5e;
  }
`
const Label = styled.span`
  color: #ffffff;
  background: #000000;
  align-self:flex-start;
  position: absolute;
  margin-left: 50px;
  padding: 0 10px;
  margin-top: -20px;    
`
AdContainer.craft = {
  rules: {
    canMoveIn: (nodes) => nodes.every((node) => allowedComponentToMoveIn.includes(node.data.type))
  }
}
