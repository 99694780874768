import { createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

const initialState = {
  open: false,
  vertical: 'top',
  horizontal: 'center',
  message: '',
  severity: 'error'
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, { payload }) => {
      state.open = true
      state.message = payload
    },
    hideToast: (state) => {
      state.open = false
      state.message = ''
    }
  }
})

export const { showToast, hideToast } = toastSlice.actions

export const useToast = () => {
  const dispatch = useDispatch()
  const { open, vertical, horizontal, message, severity } = useSelector(
    (state) => state.toast
  )

  const openToast = (toastMessage) => dispatch(showToast(toastMessage))
  const closeToast = () => dispatch(hideToast())

  return {
    open,
    vertical,
    horizontal,
    message,
    severity,
    openToast,
    closeToast
  }
}
export default toastSlice.reducer
