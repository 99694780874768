import { Button, Divider, Modal } from '@mui/material'
import { Box } from '@mui/system'
import { useElementsDrawer } from 'modules/forms-generator/hooks/useElementsDrawer'
import { ELEMENTS_SETTINGS } from '..'
import AddIcon from '@mui/icons-material/Add'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useEffect } from 'react'
import { renderHandler } from './render-handlers'
import ValidationsHandler from './validationsHandler'
import { useValidations } from 'modules/forms-generator/hooks/useValidations'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'

export default function ElementModal () {
  const { openModal, toggleModal, selectedType } = useElementsDrawer()
  const { createElementKeys, addElement, isFormValid } = useSelectedElements()
  const { haveValidations } = useValidations()
  const { selectedElementIndex } = useFormsBuilderState()

  useEffect(() => {
    createElementKeys(selectedType)
    // eslint-disable-next-line
  }, [selectedType])

  const handleAddElementClick = () => {
    toggleModal()
    addElement()
  }
  return (
    <Modal open={openModal} onClose={() => toggleModal()}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '60%',
          maxHeight: '800px',
          overflowY: 'scroll',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#ffffff',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
          pt: 2,
          px: 4,
          pb: 3,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {ELEMENTS_SETTINGS[selectedType] &&
          ELEMENTS_SETTINGS[selectedType].map((setting, idx) =>
            renderHandler(setting, idx)
          )}
        <Divider />
        {haveValidations && <ValidationsHandler elementType={selectedType} />}

        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={handleAddElementClick}
            disabled={!isFormValid}
          >
            {selectedElementIndex !== null ? 'Edit Element' : 'Add Element'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
