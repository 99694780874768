import { Box } from '@mui/material'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add'
import { useState, useMemo, useEffect } from 'react'
import DynamicSelectInput from './dynamicSelectInput'
import { useFormData } from 'modules/forms-generator/hooks/useFormData'
import DynamicConnectionHandler from './dynamicConnectionHandler'
import { usePagesConnections } from 'modules/forms-generator/hooks/usePagesConnections'
import { usePagesFlow } from 'modules/forms-generator/hooks/usePagesFlow'

const validateUpdatedRelationShip = (updatedArray = []) => {
  if (updatedArray.length === 0) return false
  for (const entry of updatedArray) {
    if (entry.data.key === '' || entry.data.selectedValue === '') return false
  }
  return true
}

export default function DynamicPagesConnection ({
  sourceName,
  currentTargetsNames,
  newTargetName
}) {
  const [selectedKey, setSelectedKey] = useState('')
  const { getAllKeysOptions } = useFormData()
  const { connectionParams } = usePagesConnections()
  const { changeDynamicConnections } = usePagesFlow()
  const [currentConnections, setCurrentConnections] = useState([
    ...currentTargetsNames.map((entry) => ({ value: '', ...entry })),
    { value: '', name: newTargetName, index: connectionParams.target }
  ])

  const formKeysOptions = Object.keys(getAllKeysOptions()).map((entry) => ({
    key: entry,
    value: entry
  }))

  const suggestions = useMemo(() => {
    if (!selectedKey) return []
    return getAllKeysOptions(selectedKey).map((entry) => ({
      key: entry,
      value: entry
    }))
    // eslint-disable-next-line
  }, [selectedKey])

  useEffect(() => {
    const updatedRelations = currentConnections.reduce((acc, entry) => {
      return [
        ...acc,
        {
          ...connectionParams,
          target: `${entry.index}`,
          id: `specific__${connectionParams.source}->${entry.index}`,
          label: `${selectedKey} = ${entry.value}`,
          data: {
            key: selectedKey,
            selectedValue: entry.value,
            pageName: entry.name
          }
        }
      ]
    }, [])

    if (validateUpdatedRelationShip(updatedRelations)) {
      changeDynamicConnections(updatedRelations)
    } else {
      changeDynamicConnections([])
    }
    // eslint-disable-next-line
  }, [currentConnections, connectionParams, selectedKey])

  const handleChangeConnectedValue = (newValue, index) => {
    const isAlreadyChoosen = currentConnections.find(
      (entry) => entry.value === newValue && newValue !== ''
    )
    if (isAlreadyChoosen) return
    setCurrentConnections((prevConnections) =>
      prevConnections.map((entry, idx) => {
        if (idx === index) return { ...entry, value: newValue }
        return entry
      })
    )
  }

  const handleChangeSelectedKey = (value) => {
    setSelectedKey(value)
    if (value === '') {
      setCurrentConnections([
        ...currentTargetsNames.map((entry) => ({ value: '', ...entry })),
        { value: '', name: newTargetName, index: connectionParams.target }
      ])
    }
  }

  return (
    <DynamicPagesConnectionWrapper>
      <DynamicSelectInput
        label='lookup key'
        id='lookupKey'
        value={selectedKey}
        onChange={handleChangeSelectedKey}
        options={formKeysOptions}
      />

      {currentTargetsNames.map((entry, index) => (
        <DynamicConnectionHandler
          key={`connection__${index}`}
          sourceName={sourceName}
          targetName={entry.name}
          suggestions={suggestions}
          connectedValue={currentConnections[index].value}
          onChange={(newVal) => handleChangeConnectedValue(newVal, index)}
        />
      ))}

      <div className='newConnectionIcon'>
        <AddIcon sx={{ fontSize: 60 }} />
      </div>

      <DynamicConnectionHandler
        sourceName={sourceName}
        targetName={newTargetName}
        suggestions={suggestions}
        connectedValue={currentConnections[currentConnections.length - 1].value}
        onChange={(newVal) =>
          handleChangeConnectedValue(newVal, currentConnections.length - 1)}
      />
    </DynamicPagesConnectionWrapper>
  )
}

const DynamicPagesConnectionWrapper = styled(Box)`
  .newConnectionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
    .pageName {
      background-color: #3f51b5;
      border-radius: 5px;
      font-weight: bold;
      padding: 10px 20px;
      position: relative;
      color: #ffffff;
      min-width: 150px;
      text-align: center;
    }
    .arrow {
      height: 1px;
      background: #002984;
      flex: 1;
      top: 50%;
      position: relative;
    }
    .arrow:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      border-style: solid;
      border-width: 7px 0 7px 20px;
      right: 0;
      border-color: transparent transparent transparent #002984;
      transform: translateY(-50%);
    }
  }
`
