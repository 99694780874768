import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CodeMirror from '@uiw/react-codemirror'
import jsBeautify from 'js-beautify'
import copy from 'copy-to-clipboard'
import { css } from '@codemirror/lang-css'
import {
  CodeMirrorContainer,
  EditorContainer,
  EditorHeader,
  CopyButton,
  CopyMessage
} from '../../styles-elements/styled-elements'
export const CssEditor = (props) => {
  const [style, setStyle] = useState(props.exportedcss)
  const [copyAnimation, setCopyAnimation] = useState(false)
  const handleChange = React.useCallback((value) => {
    setStyle(value)
    if (props.setCurrentCss !== undefined) props.setCurrentCss(value)
  }, [props])

  const handleCopy = () => {
    setCopyAnimation(true)
    copy(style)
  }

  useEffect(() => {
    const formatedStyle = jsBeautify.css(props.exportedcss, { indent_size: 2 })
    setStyle(formatedStyle)
  }, [props.exportedcss])

  return (
    <EditorContainer>
      <EditorHeader>
        <Label>CSS</Label>
        <CopyButton onClick={handleCopy}>
          <CopyMessage copied={copyAnimation}>CSS Copied to clipboard</CopyMessage>
          <img
            src='https://img.icons8.com/material-rounded/24/000000/copy.png'
            alt='copy-icon'
          />
        </CopyButton>
      </EditorHeader>
      <CodeMirrorContainer>
        <CodeMirror
          value={style}
          height='100%'
          width='100%'
          extensions={[css()]}
          onChange={handleChange}
          data-cy='css__editor'
        />
      </CodeMirrorContainer>
    </EditorContainer>
  )
}

const Label = styled.div`
  font-weight: bold;
  padding: 5px;
`
