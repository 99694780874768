import { Image } from '../Image'
import { Bullets } from '../Bullets'
import { Cta } from '../Cta'
import { Element } from '@craftjs/core'
import { AdContainer } from '../AdContainer'

const layout2DefaultStyles = {
  adContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imageStyle: {
    height: '200px',
    width: 'auto'
  },
  headlineStyle: {
    fontSize: '26px',
    width: 'fit-content'
  },
  bullesStyle: {
    fontSize: '16px'
  },
  ctaStyle: {
    display: 'flex',
    justifyContent: 'center'
  },
  ctaContainer1: {
  }

}

export const Layout2 = () => {
  return (
    <Element id='ad-container' is={AdContainer} canvas styles={layout2DefaultStyles.adContainer}>
      <Image imgSource='' imgName='image-icon' styles={layout2DefaultStyles.imageStyle} />
      <Bullets bullets={['bullet-1', 'bullet-2', 'bullet-3', 'bullet-4']} styles={layout2DefaultStyles.bullesStyle} />
      <Cta styles={layout2DefaultStyles.ctaStyle} />
    </Element>
  )
}
