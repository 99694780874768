import { TextField } from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useEffect } from 'react'

export default function TextValue ({
  elementKey,
  placeHolder,
  isRequired = true
}) {
  const {
    selectedElement,
    changeElement,
    setErrorMessage,
    clearError,
    errors
  } = useSelectedElements()

  useEffect(() => {
    if (!isRequired) return
    if (!selectedElement[elementKey]) {
      setErrorMessage({
        key: elementKey,
        message: `${elementKey} is Required`
      })
    } else clearError(elementKey)

    return () => {
      clearError(elementKey)
    }
    // eslint-disable-next-line
  }, [selectedElement, elementKey, isRequired])
  return (
    <TextField
      sx={{ my: '10px' }}
      required
      helperText={errors[elementKey]}
      error={typeof errors[elementKey] === 'string'}
      id={elementKey}
      label={placeHolder}
      size='small'
      value={selectedElement[elementKey]}
      onChange={(e) => changeElement(elementKey, e.target.value)}
    />
  )
}
