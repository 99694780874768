import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

const ErrorSpanSetting = () => {
  return <Settings border colors dimensions display typography />
}
export const ErrorSpanComponent = ({ text, className = 'error__span' }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  return (
    <span
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {text}
    </span>
  )
}

ErrorSpanComponent.craft = {
  displayName: 'Error Span',
  props: {
    text: 'Error Text'
  },
  related: {
    toolbar: ErrorSpanSetting
  }
}
