import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ContentEditable from 'react-contenteditable'
import { Resizable } from 're-resizable'
import { useNode, useEditor } from '@craftjs/core'
import { useSelector } from 'react-redux'
import { Toolbar } from './Toolbar'
import { useStyles } from '../../hooks/use-styles'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'
import useEditorHelpers from '../../hooks/useEditorHelpers'

export const Bullets = ({ customEvenet, dataattribute, classname, mustacheTag }) => {
  const currentComponent = 'Bullets'
  const defaultTargets = ['list items', 'visibility']
  const [isEnter, setIsEnter] = useState(false)
  const [isBackSpace, setIsBackSpace] = useState(false)
  const [isElementSelected, setIsElementSelected] = useState(false)
  const [elementHeight, setElementHeight] = useState()
  const [elementWidth, setElementWidth] = useState()
  const { changestyle } = useStylesHelpers()
  const { ActiveSideBarElements, addBullet, updateBullet, removeBullet } =
    useEditorHelpers()
  const { elementClassName } = useStyles()
  const editor = useSelector(state => state.editor)
  const {
    connectors: { connect, drag },
    actions: { setProp }
  } = useNode()
  const { selectedDevice, styles, tabletStyle, desktopStyle } = useSelector(
    state => state.styles
  )
  const { selected, Selectedstyles } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected,
      classes: state.nodes[currentNodeId]?.dom?.classList,
      Selectedstyles: state.nodes[currentNodeId]?.data?.props?.styles
    }
  })

  const handleClick = () => {
    ActiveSideBarElements('Styles')
    if (selected.name === currentComponent) {
      setIsElementSelected(true)
    }
  }

  const handlechange = e => {
    const bulletIndex = e.currentTarget.dataset.key
    const bullet = e.target.value
    if (!isEnter) {
      updateBullet(bullet, bulletIndex)
    }
    if (isBackSpace && bullet === '') {
      removeBullet(bulletIndex)
    }
    setIsEnter(false)
    setIsBackSpace(false)
  }

  const handleResize = (e, direction, ref, d) => {
    setStylesValue('height', ref.style.height)
    setStylesValue('width', ref.style.width)
    setElementHeight(ref.style.height)
    setElementWidth(ref.style.width)
    setProp(props => (props.elementClassName = [elementClassName]))
  }

  const setStylesValue = (cssKey, value) => {
    if (elementClassName) {
      changestyle({
        classes: [elementClassName],
        Selectedstyles,
        cssKey,
        value
      })
    }
  }

  const handleHitEnter = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      addBullet('Edit feature here')
      setIsEnter(true)
    }
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setIsBackSpace(true)
    }
  }

  useEffect(() => {
    if (selected?.name === currentComponent) {
      setIsElementSelected(true)
    } else {
      setIsElementSelected(false)
    }
    // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    setProp(props => (props.elementClassName = [elementClassName]))
  })

  useEffect(() => {
    setProp(
      props =>
        (props.styles = {
          ...props.styles,
          height: elementHeight,
          width: elementWidth
        })
    )
  }, [elementHeight, elementWidth, setProp])

  useEffect(() => {
    setProp(props => (props.bullets = editor.bullets))
  }, [editor.bullets, setProp])

  useEffect(() => {
    if (selectedDevice === 'tablet') {
      setElementHeight(tabletStyle[elementClassName]?.height)
      setElementWidth(tabletStyle[elementClassName]?.width)
    } else if (selectedDevice === 'desktop') {
      setElementHeight(desktopStyle[elementClassName]?.height)
      setElementWidth(desktopStyle[elementClassName]?.width)
    } else {
      setElementHeight(styles[elementClassName]?.height)
      setElementWidth(styles[elementClassName]?.width)
    }
    // eslint-disable-next-line
  }, [selectedDevice])

  return (
    <FeaturesContainer
      ref={connect}
      onClick={handleClick}
      isElementSelected={isElementSelected}
      className={`${classname}--container`}
      dataattribute={dataattribute}
      data-ce-lincx-type={customEvenet?.type}
      style={{ height: elementHeight, width: elementWidth }}
      bullets={editor.bullets}
      data-cy='bullets__component'
    >
      <Toolbar
        isElementSelected={isElementSelected}
        defaultTargets={defaultTargets}
        drag={drag}
        mustacheTag={mustacheTag}
      />
      <Resizable
        style={{ height: elementHeight, width: elementWidth }}
        data-cy='bullet__resizer'
        size={{ elementWidth, elementHeight }}
        onResizeStop={handleResize}
      >
        <ul className={`${elementClassName} ${classname}`} data-cy='bullets_items'>
          {editor.bullets.map((bullet, index) => (
            <ContentEditable
              html={bullet}
              data-key={index}
              tagName='li'
              key={index}
              onChange={handlechange}
              onKeyDown={handleHitEnter}
              customevent={customEvenet?.eventType}
            />
          ))}
        </ul>
      </Resizable>
    </FeaturesContainer>
  )
}

const FeaturesContainer = styled.div`
  outline: ${props => (props.isElementSelected ? '1px dashed #5e5e5e' : 'none')};
  &:hover {
    outline: 1px dashed #5e5e5e;
  }
`
