import { Box, Icon } from '@mui/material'
import { useElementsDrawer } from 'modules/forms-generator/hooks/useElementsDrawer'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'

export default function DrawerElement ({
  icon,
  label,
  type,
  complexElement,
  elementStyles
}) {
  const { containerIndex } = useFormsBuilderState()
  const {
    toggleDrawer,
    toggleModal,
    handlePushComplexElement,
    handlePushElementStyles
  } = useElementsDrawer()

  const handleClick = () => {
    if (complexElement) {
      handlePushComplexElement(complexElement)
      handlePushElementStyles(elementStyles)
      toggleDrawer(containerIndex)
    } else {
      toggleDrawer(containerIndex)
      toggleModal(type)
    }
  }
  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
        width: 'calc(50% - 10px)',
        height: '60px',
        py: '10px',
        borderRadius: '10px',
        cursor: 'pointer',
        transition: 'all .4s ease',
        textAlign: 'center',
        ':hover': {
          backgroundColor: '#2B2B2B',
          color: '#ffffff'
        }
      }}
    >
      <Icon>{icon}</Icon>
      <span>{label}</span>
    </Box>
  )
}
