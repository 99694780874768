import {
  Box,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'

const BordersAllIcon = () => (
  <svg width='10' height='10' fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 1.5a1 1 0 00-1 1v5c0 .6.4 1 1 1h5c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1h-5zm0-1.5A2.5 2.5 0 000 2.5v5C0 8.9 1.1 10 2.5 10h5C8.9 10 10 8.9 10 7.5v-5C10 1.1 8.9 0 7.5 0h-5z'
    />
  </svg>
)

const BorderSidesIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12'>
    <path
      d='M 3 0.75 C 3 0.336 3.336 0 3.75 0 L 8.25 0 C 8.664 0 9 0.336 9 0.75 L 9 0.75 C 9 1.164 8.664 1.5 8.25 1.5 L 3.75 1.5 C 3.336 1.5 3 1.164 3 0.75 Z'
      fill='currentColor'
      opacity='1'
    />
    <path
      d='M 10.5 3.75 C 10.5 3.336 10.836 3 11.25 3 L 11.25 3 C 11.664 3 12 3.336 12 3.75 L 12 8.25 C 12 8.664 11.664 9 11.25 9 L 11.25 9 C 10.836 9 10.5 8.664 10.5 8.25 Z'
      fill='currentColor'
      opacity='1'
    />
    <path
      d='M 3 11.25 C 3 10.836 3.336 10.5 3.75 10.5 L 8.25 10.5 C 8.664 10.5 9 10.836 9 11.25 L 9 11.25 C 9 11.664 8.664 12 8.25 12 L 3.75 12 C 3.336 12 3 11.664 3 11.25 Z'
      fill='currentColor'
      opacity='1'
    />
    <path
      d='M 0 3.75 C 0 3.336 0.336 3 0.75 3 L 0.75 3 C 1.164 3 1.5 3.336 1.5 3.75 L 1.5 8.25 C 1.5 8.664 1.164 9 0.75 9 L 0.75 9 C 0.336 9 0 8.664 0 8.25 Z'
      fill='currentColor'
      opacity='1'
    />
  </svg>
)

export default function BordersHandlers () {
  const [borderType, setBorderType] = useState('all')
  const [borderTopWidth, setBorderTopWidth] = useCssKeyValue({
    key: 'border-top-width',
    value: '0',
    unit: 'px'
  })
  const [borderBottomWidth, setBorderBottomWidth] = useCssKeyValue({
    key: 'border-bottom-width',
    value: '0',
    unit: 'px'
  })
  const [borderLeftWidth, setBorderLeftWidth] = useCssKeyValue({
    key: 'border-left-width',
    value: '0',
    unit: 'px'
  })
  const [borderRightWidth, setBorderRightWidth] = useCssKeyValue({
    key: 'border-right-width',
    value: '0',
    unit: 'px'
  })
  const [borderWidth, setBorderWidth] = useCssKeyValue({
    key: 'border-width',
    value: '0',
    unit: 'px'
  })
  const [borderColor, setBorderColor] = useCssKeyValue({
    key: 'border-color',
    value: '#000000'
  })
  const [borderStyle, setBorderStyle] = useCssKeyValue({
    key: 'border-style',
    value: 'solid'
  })

  useEffect(() => {
    if (
      borderWidth === 'none' &&
      (borderTopWidth !== 'none' ||
        borderLeftWidth !== 'none' ||
        borderBottomWidth !== 'none' ||
        borderRightWidth !== 'none')
    ) {
      setBorderType('sides')
    }
    // eslint-disable-next-line
  }, [])
  const handleChange = (_, newValue) => {
    if (newValue) {
      setBorderType(newValue)
      if (newValue === 'all') {
        setBorderTopWidth({ value: 'none' })
        setBorderLeftWidth({ value: 'none' })
        setBorderBottomWidth({ value: 'none' })
        setBorderRightWidth({ value: 'none' })
        setBorderWidth({ value: 1 })
      } else {
        setBorderWidth({ value: 'none' })
        setBorderTopWidth({ value: borderWidth })
        setBorderLeftWidth({ value: borderWidth })
        setBorderBottomWidth({ value: borderWidth })
        setBorderRightWidth({ value: borderWidth })
      }
    }
  }
  return (
    <Box>
      <AttribueContainer>
        <span>Color</span>
        <div className='handlers'>
          <input
            type='color'
            value={borderColor}
            onChange={({ target }) => setBorderColor({ value: target.value })}
          />
        </div>
      </AttribueContainer>
      <AttribueContainer>
        <span>Width</span>
        <div className='handlers'>
          <TextField
            label='Size'
            id='all'
            size='small'
            value={borderWidth}
            onChange={({ target }) => setBorderWidth({ value: target.value })}
            disabled={borderType !== 'all'}
          />
          <ToggleButtonGroup
            size='small'
            className='toggle'
            color='primary'
            value={borderType}
            exclusive
            onChange={handleChange}
            aria-label='Border Type'
          >
            <ToggleButton value='all'>
              <BordersAllIcon />
            </ToggleButton>
            <ToggleButton value='sides'>
              <BorderSidesIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {borderType === 'sides' && (
          <div className='sides__border'>
            <TextField
              label='Top'
              id='top'
              size='small'
              value={borderTopWidth}
              onChange={({ target }) =>
                setBorderTopWidth({ value: target.value })}
            />
            <TextField
              label='Left'
              id='left'
              size='small'
              value={borderLeftWidth}
              onChange={({ target }) =>
                setBorderLeftWidth({ value: target.value })}
            />
            <TextField
              label='Bottom'
              id='bottom'
              size='small'
              value={borderBottomWidth}
              onChange={({ target }) =>
                setBorderBottomWidth({ value: target.value })}
            />
            <TextField
              label='Right'
              id='right'
              size='small'
              value={borderRightWidth}
              onChange={({ target }) =>
                setBorderRightWidth({ value: target.value })}
            />
          </div>
        )}
      </AttribueContainer>
      <AttribueContainer>
        <span>Style</span>
        <div className='handlers'>
          <Select
            value={borderStyle}
            label='Border Style:'
            onChange={({ target }, _) =>
              setBorderStyle({ value: target.value })}
            sx={{ marginRight: '2px', flex: 1 }}
            size='small'
          >
            <MenuItem value='solid'>Solid</MenuItem>
            <MenuItem value='dotted'>Dotted</MenuItem>
            <MenuItem value='double'>Double</MenuItem>
            <MenuItem value='dashed'>Dashed</MenuItem>
          </Select>
        </div>
      </AttribueContainer>
    </Box>
  )
}
const AttribueContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  span {
    width: 20%;
  }
  .handlers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }
  input[type='text'] {
    width: 50px;
  }
  input[type='color'] {
    width: 30px;
    height: 30px;
    border: none;
    padding: 0;
    border-radius: 30px;
    overflow: hidden;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
  }
  .sides__border {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    input[type='text'] {
      width: 30px;
    }
  }
`
