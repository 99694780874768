import { getClosingTag, getOpenTag } from './mapReactComponentToHtml'

const _ = require('lodash')
let html = ''

export const jsonToHtmlParser = json => {
  html = ''
  const jsonToArray = []
  json = JSON.parse(json)
  Object.keys(json).forEach(component => jsonToArray.push({ ...json[component], id: component }))
  const treeStructure = getTreeStructure(jsonToArray)
  renderHtml(treeStructure[1])
  return html
}
function getTreeStructure (data = [], parentComponentId = null) {
  const groupedElementsByParent = getGroupedByParents(data)

  const unflattenedComponents = Object.keys(groupedElementsByParent).map(element => {
    if (element === 'null') return groupedElementsByParent[element]
    const foundComponent = data.find(currentElement => currentElement?.id === element)
    if (foundComponent) {
      foundComponent.children = groupedElementsByParent[element]
    }
    return foundComponent
  })

  const flattenedComponents = _.flatten(unflattenedComponents)

  const rootParentComponentsTree = flattenedComponents.filter(
    component => (component && !component?.parent) || component?.id === parentComponentId?.toString()
  )

  return _.uniqBy(rootParentComponentsTree, 'id')
}

function getGroupedByParents (data = []) {
  const groupedByParents = _.groupBy(data, 'parent')
  return groupedByParents
}
function renderHtml (node) {
  if (!node) return
  html += getOpenTag(node)
  for (const childComponent of node?.children || []) {
    renderHtml(childComponent)
  }
  html += getClosingTag(node)
}
