import { Box } from '@mui/material'
import styled from 'styled-components'

export const ContainerWrapper = styled(Box)`
  position: relative;
  &.margin {
    border: 2px dashed #c3c7cb;
  }
  &.padding {
    border: 2px solid #2081e2;
  }
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  .padding-label {
    position: absolute;
    font-size: 14px;
    bottom: 5px;
    right: 10px;
    width: auto;
    text-transform: uppercase;
    color: #9da6ba;
  }
  .margin-label {
    position: absolute;
    font-size: 14px;
    top: 5px;
    left: 10px;
    width: auto;
    text-transform: uppercase;
    color: #9da6ba;
  }
  div {
    width: 100%;
    .field {
      width: 20%;
    }
    input {
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
  .top,
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .point {
    position: absolute;
    border: 2px solid #2081e2;
    height: 5px;
    width: 5px;
    background-color: #ffffff;
  }
  .point-top{
    top: -5px;
    left: 50%;
    transform: translateX(-50%)
  }
  .point-bottom{
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%)
  }
  .point-left{
    left: -5px;
    top: 50%;
    transform: translateY(-50%)
  }
  .point-right{
    right: -5px;
    top: 50%;
    transform: translateY(-50%)
  }
`
