import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useCssKeyValue } from 'modules/forms-generator/hooks/useCssKeyValue'
import { useFormCss } from 'modules/forms-generator/hooks/useFormCss'
import { getGoogleFonts } from 'modules/utils/googleFont'
import { useEffect, useState } from 'react'
import { useFormCssState } from 'state/slices/formsGenerator/formCssSlice'

export const useFontFamily = () => {
  const { googleFonts } = useFormCssState()
  const { newGoogleFont } = useFormCss()

  const [options, setOptions] = useState([])
  const loading = options.length === 0
  const [currentFontFamily, changeCurrentFontFamily] = useCssKeyValue({
    key: 'font-family',
    value: ''
  })
  const [fontFamily, setFontFamily] = useState(
    googleFonts.find((font) => font.family === currentFontFamily) || null
  )
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    let active = true
    if (!loading) return undefined
    ;(async () => {
      const data = await getGoogleFonts()

      if (active) {
        setOptions([...data])
      }
    })()
    return () => {
      active = false
    }
  }, [loading])

  const handleChange = (googleFont) => {
    setFontFamily(googleFont)
    newGoogleFont(googleFont)
    changeCurrentFontFamily({ value: googleFont.family })
  }

  const AutoCompleteFontFamily = () => {
    return (
      <Autocomplete
        id='google-fonts'
        sx={{ width: 300, mb: 2 }}
        isOptionEqualToValue={(option, value) => option.family === value.family}
        getOptionLabel={(option) => option.family}
        size='small'
        options={options}
        loading={loading}
        value={fontFamily}
        onChange={(_, val) => handleChange(val)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          console.log({ newInputValue })
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Font Family'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading
                    ? (
                      <CircularProgress color='inherit' size={20} />
                      )
                    : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    )
  }
  return { AutoCompleteFontFamily }
}
