export const appendStyleFromEditor = cssText => {
  const oldStyleTage = document.querySelector('[data-lxeditorstyle]')
  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  style.dataset.lxeditorstyle = 'lxeditorstyle'
  if (oldStyleTage) head.removeChild(oldStyleTage)
  head.appendChild(style)

  if (style.styleSheet) {
    style.styleSheet.cssText = cssText
  } else {
    style.appendChild(document.createTextNode(cssText))
  }
}
export const appendStyle = (styles, css) => {
  let cssString = ''
  const classNames = Object.keys(styles)
  classNames.forEach(className => {
    if (Object.keys(styles[className]).length !== undefined) {
      cssString += '.' + className + '{'
      Object.keys(styles[className]).forEach(key => {
        const cssKey = key
          .split(/(?=[A-Z])/)
          .join('-')
          .toLowerCase()
        cssString += cssKey + ':' + styles[className][key] + ';'
      })
      cssString += '} '
    }
  })
  const oldStyleTage = document.querySelector('[data-lxstyle]')

  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  style.dataset.lxstyle = 'lxstyle'
  if (oldStyleTage) head.removeChild(oldStyleTage)
  head.appendChild(style)

  cssString = cssString + css

  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = cssString
  } else {
    style.appendChild(document.createTextNode(cssString))
  }
}

export const styleMerge = (style1, style2) => {
  const mergedStyle = {}
  const classNames1 = Object.keys(style1 || {})
  const classNames2 = Object.keys(style2 || {})

  classNames1.forEach(className => {
    if (!mergedStyle[className]) {
      mergedStyle[className] = {}
    }
    const stylesKeys = Object.keys(style1[className] || {})
    stylesKeys.forEach((styleKey) => {
      mergedStyle[className][styleKey] = style1[className][styleKey]
    })
  })

  classNames2.forEach(className => {
    if (!mergedStyle[className]) {
      mergedStyle[className] = {}
    }
    const stylesKeys = Object.keys(style2[className] || {})
    stylesKeys.forEach((styleKey) => {
      mergedStyle[className][styleKey] = style2[className][styleKey]
    })
  })

  return mergedStyle
}
