import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useEditor } from '@craftjs/core'
import { useStylesHelpers } from '../../hooks/use-styles-helpers'
import { useStyles } from 'hooks/use-styles'
export const ClassListAdder = ({ setActiveToolOption, isActive }) => {
  const [singleClass, setSingleClass] = useState('')
  const [imageClassList, setImageClassList] = useState([])
  const [isClassInputSelected, setIsClassInputSelected] = useState(false)
  const { getEditorElementFromTheElementSelected } = useStyles()
  const [elementDom, setElementDom] = useState()
  const htmlTag = '</>'
  const { addNewClass } = useStylesHelpers()
  const { seletedElement } = useSelector(
    state => state.editor
  )
  const { dom } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      dom: state.nodes[currentlySelectedNodeId]
        ? state.nodes[currentlySelectedNodeId].dom
        : undefined
    }
  })

  const handleAddClassName = e => {
    if (e.key === 'Enter') {
      setImageClassList([...imageClassList, singleClass])
      clearInputField(e)
      addNewClass({ elementID: seletedElement, className: singleClass })
      if (elementDom) {
        elementDom.classList.add(singleClass)
      }
    }
  }
  const clearInputField = e => {
    e.target.value = ''
  }
  const handleClassNameChange = e => {
    setSingleClass(e.target.value)
  }
  useEffect(() => {
    actions.setProp(selected?.id, props => {
      props.className = { ...selected.className, name: imageClassList }
    })
    // eslint-disable-next-line
  }, [imageClassList])

  useEffect(() => {
    if (isActive === 'AddClassOption') {
      setIsClassInputSelected(true)
    } else {
      setIsClassInputSelected(false)
    }
    if (dom) {
      const selectedElementDom = getEditorElementFromTheElementSelected(dom)
      if (selectedElementDom) { setElementDom(selectedElementDom) }
    }
  // eslint-disable-next-line
  }, [isActive])

  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      }
    }

    return {
      selected
    }
  })

  const handleShowInput = () => {
    setActiveToolOption('AddClassOption')
    setIsClassInputSelected(!isClassInputSelected)
  }

  const removeClassName = (e) => {
    const selectedClassName = e.target.getAttribute('data-class-name')
    setImageClassList(imageClassList.filter((className) => (className !== selectedClassName)))
    if (elementDom) {
      elementDom.classList.remove(selectedClassName)
    }
  }

  return (
    <ClassListAdderContainer>
      <span onClick={handleShowInput} data-cy='add_class__name__button'>{htmlTag}</span>
      <ClassNameInputContainer
        isClassInputSelected={isClassInputSelected}
      >
        <InputContainer>
          <div>{htmlTag}</div>
          <input
            onKeyDown={handleAddClassName}
            onChange={handleClassNameChange}
            placeholder='Enter Class names'
            data-cy='add__class__name__input'
          />
        </InputContainer>
        <AddedClassListContainer data-cy='list__class__name'>
          {
            imageClassList.map((className, index) => (
              <ClassName key={index}>
                {className}
                <img src='https://img.icons8.com/external-becris-lineal-becris/64/000000/external-cancel-mintab-for-ios-becris-lineal-becris.png' alt='remove-class-icon' data-class-name={className} onClick={removeClassName} />
              </ClassName>
            ))
          }
        </AddedClassListContainer>
      </ClassNameInputContainer>
    </ClassListAdderContainer>
  )
}
const ClassListAdderContainer = styled.div`
  font-size: 23px;
  padding: 5px;
  display: flex;
  border-right: 1px solid #aeaeae;
`
const ClassNameInputContainer = styled.div`
  margin-top: 35px;
  position: absolute;
  display: ${props => (props.isClassInputSelected ? 'flex' : 'none')};
  flex-direction: column;
`
const AddedClassListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 3px;
  z-index: 100;
`
const InputContainer = styled.div`
  display: flex;
  padding: 3px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 5px 5px #e4e4e4;
  div {
    border-right: 1px solid #aeaeae;
    z-index: 2;
    margin-top: -3px;
    margin-bottom: -3px;
    padding: 3px;
  }
  input{
    border: none;
  }
  z-index: 100;
`
const ClassName = styled.div`
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 5px 5px #e4e4e4;
  margin-top: 3px;
  padding: 5px;
  display: flex;
  font-size: 20px;
  img{
    height: 10px !important;
    width: 10px !important;
  }
`
