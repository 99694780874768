import { IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useElementsDrawer } from 'modules/forms-generator/hooks/useElementsDrawer'

export default function AddElementButton ({
  isContainer = false,
  containerIndex
}) {
  const { toggleDrawer } = useElementsDrawer()
  return (
    <IconButton
      onClick={() => toggleDrawer(containerIndex)}
      aria-label='add'
      sx={{
        backgroundColor: '#0a3d62',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#60a3bc'
        },
        my: isContainer ? '0px' : '10px'
      }}
    >
      <AddIcon />
    </IconButton>
  )
}
