import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEditor } from '@craftjs/core'
import { useFormCss } from 'modules/forms-generator/hooks/useFormCss'
import { useState, useEffect } from 'react'

export default function WithErrorState () {
  const { elementSelected } = useFormCss()
  const { dom, props, withError } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      dom: state.nodes[currentlySelectedNodeId]
        ? state.nodes[currentlySelectedNodeId].dom
        : undefined,
      props: state.nodes[currentlySelectedNodeId].data.props,
      withError:
        state.nodes[currentlySelectedNodeId] &&
        state.nodes[currentlySelectedNodeId].dom
          ? state.nodes[currentlySelectedNodeId].dom.classList.contains(
              'with__error'
            )
          : false
    }
  })

  const [withErrorChecked, setWithErrorChecked] = useState(false)

  useEffect(() => {
    setWithErrorChecked(withError)
    // eslint-disable-next-line
  }, [])

  const onChangeCheck = (checked) => {
    setWithErrorChecked(checked)
    if (checked) {
      dom.classList.add('with__error')
      elementSelected({
        className: `${props.className} with__error`,
        elementTag: dom?.localName
      })
    } else {
      dom.classList.remove('with__error')
      elementSelected({
        className: props.className,
        elementTag: dom?.localName
      })
    }
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>With Error</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={withErrorChecked}
                onChange={(_, checked) => onChangeCheck(checked)}
                inputProps={{ 'aria-label': 'With Error' }}
              />
            }
            label='With Error'
          />
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )
}
