import { createSlice } from '@reduxjs/toolkit'
import { appendStyle, styleMerge } from '../../utils/styles-utils'
const initialState = {
  styles: {},
  tabletStyle: {},
  desktopStyle: {},
  customCssCode: '',
  className: {},
  selectedDevice: 'mobile',
  selectedClassNAme: ''
}
export const stylesSlice = createSlice({
  name: 'styles',
  initialState,
  reducers: {
    addClassName: (state, action) => {
      const className = action.payload
      if (state.selectedDevice === 'tablet') {
        state.tabletStyle = { ...state.tabletStyle, [className]: {} }
      } else if (state.selectedDevice === 'desktop') {
        state.desktopStyle = { ...state.desktopStyle, [className]: {} }
      } else {
        state.styles = { ...state.styles, [className]: {} }
      }
    },
    pushlayoutstyle: (state, action) => {
      const { styles } = action.payload
      state.desktopStyle = styles.desktopStyle
      state.styles = styles.styles
      state.tabletStyle = styles.tabletStyle

      const tabletStyle = styleMerge(state.styles, state.tabletStyle)
      const desktopStyle = styleMerge(tabletStyle, state.desktopStyle)

      switch (state.selectedDevice) {
        case 'mobile':
          appendStyle(state.styles)
          break
        case 'tablet':
          appendStyle(tabletStyle)
          break
        case 'desktop':
          appendStyle(desktopStyle)
          break
        default:
          appendStyle(desktopStyle)
          break
      }
    },
    changeStyle: (state, action) => {
      const { cssKey, value, selectedClassName } = action.payload
      let className
      const classes = Array.from(action.payload.classes)
      classes.forEach((element, index) => {
        if (element.substring(0, 3) === 'lx_') {
          className = element
        } else {
          if (index === classes.length - 1) {
            className = element
          }
        }
      })
      if (selectedClassName) {
        className = selectedClassName
      } else {
        classes.forEach(element => {
          if (element.substring(0, 3) === 'lx_') className = element
        })
      }
      if (className && cssKey && value) {
        let selectedClassName
        if (state.selectedDevice === 'tablet') {
          selectedClassName = state.tabletStyle[className]
        } else if (state.selectedDevice === 'desktop') {
          selectedClassName = state.desktopStyle[className]
        } else {
          selectedClassName = state.styles[className]
        }
        if (!selectedClassName) {
          if (value) {
            if (state.selectedDevice === 'tablet') {
              state.tabletStyle = { ...state.tabletStyle, [className]: { [cssKey]: value } }
              const tabletStyle = styleMerge(state.styles, state.tabletStyle)
              appendStyle({ ...tabletStyle, [className]: { [cssKey]: value } }, state.customCssCode)
            } else if (state.selectedDevice === 'desktop') {
              const tabletStyle = styleMerge(state.styles, state.tabletStyle)
              const desktopStyle = styleMerge(tabletStyle, state.desktopStyle)
              state.desktopStyle = { ...state.desktopStyle, [className]: { [cssKey]: value } }
              appendStyle({ ...desktopStyle, [className]: { [cssKey]: value } }, state.customCssCode)
            } else {
              state.styles = { ...state.styles, [className]: { [cssKey]: value } }
              appendStyle({ ...state.styles, [className]: { [cssKey]: value } }, state.customCssCode)
            }
          }
        } else {
          if (value) {
            const updatedClassName = { ...selectedClassName, [cssKey]: value }
            if (state.selectedDevice === 'tablet') {
              state.tabletStyle = { ...state.tabletStyle, [className]: updatedClassName }
              const tabletStyle = styleMerge(state.styles, state.tabletStyle)
              appendStyle(tabletStyle, state.customCssCode)
            } else if (state.selectedDevice === 'desktop') {
              state.desktopStyle = { ...state.desktopStyle, [className]: updatedClassName }
              const tabletStyle = styleMerge(state.styles, state.tabletStyle)
              const desktopStyle = styleMerge(tabletStyle, state.desktopStyle)
              appendStyle(desktopStyle, state.customCssCode)
            } else {
              state.styles = { ...state.styles, [className]: updatedClassName }
              appendStyle(state.styles, state.customCssCode)
            }
          }
        }
      }
    },
    addCSSCode: (state, action) => {
      const cssCode = action.payload
      state.customCssCode = cssCode
    },
    addCustomClass: (state, action) => {
      const { elementID, className } = action.payload
      if (!state.className?.[elementID]) {
        state.className[elementID] = [className]
      } else {
        state.className[elementID] = [...state.className[elementID], className]
      }
      if (!state.customCssCode.includes(className)) {
        state.customCssCode = state.customCssCode + `.${className} {}`
      }
    },
    changeDevice: (state, action) => {
      const selectedDevice = action.payload
      state.selectedDevice = selectedDevice
      const tabletStyle = styleMerge(state.styles, state.tabletStyle)
      const desktopStyle = styleMerge(tabletStyle, state.desktopStyle)
      switch (selectedDevice) {
        case 'mobile':
          appendStyle(state.styles, state.customCssCode)
          break
        case 'tablet':
          appendStyle(tabletStyle, state.customCssCode)
          break
        case 'desktop':
          appendStyle(desktopStyle, state.customCssCode)
          break
        default:
          appendStyle(desktopStyle, state.customCssCode)
          break
      }
      state.selectedDevice = action.payload
    },
    changeSelectedClassName: (state, action) => {
      state.selectedClassName = action.payload
    }
  }
})
export const { addClassName, changeStyle, changeDevice, addCustomClass, addCSSCode, pushlayoutstyle, changeSelectedClassName } = stylesSlice.actions
export default stylesSlice.reducer
