import { Box, Button, Paper, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import CodeMirror from '@uiw/react-codemirror'
import { useFormJs } from 'modules/forms-generator/hooks/useFormJs'
import { javascript } from '@codemirror/lang-javascript'
import { useCallback } from 'react'

export const getFunctionName = (content) => {
  // eslint-disable-next-line no-useless-escape
  const result = /^function\s+([\w\$]+)\s*\(/.exec(content.toString())
  return result ? result[1] : ''
}

export default function FunctionEditor () {
  const {
    currentFunction,
    mode,
    saveCurrentFunction,
    clearSelectedFunction,
    editFunction
  } = useFormJs()
  const onChangeEditor = useCallback(
    (value, viewUpdate) => {
      editFunction({ key: 'name', value: getFunctionName(value) })
      editFunction({ key: 'content', value })
    },
    [editFunction]
  )
  return (
    <Box sx={{ width: '50%', m: '10px' }} component={Paper}>
      <Box
        sx={{
          m: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Button
          variant='outlined'
          startIcon={mode === 'add' ? <AddIcon /> : <EditIcon />}
          onClick={saveCurrentFunction}
        >
          {mode === 'add' ? 'Save The Function' : 'Edit The Function'}
        </Button>
        {mode === 'edit' && (
          <Button onClick={clearSelectedFunction} variant='text'>
            Cancel
          </Button>
        )}
      </Box>
      <Box
        sx={{
          m: '10px'
        }}
      >
        <TextField
          sx={{ width: '100%' }}
          disabled
          label='Function Name'
          variant='outlined'
          value={currentFunction.name}
        />
      </Box>
      <Box>
        <CodeMirror
          value={currentFunction.content}
          height='100%'
          width='100%'
          extensions={[javascript()]}
          onChange={onChangeEditor}
        />
      </Box>
    </Box>
  )
}
