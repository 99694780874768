import { useDispatch } from 'react-redux'
import {
  addElement,
  selectElement,
  activeElement,
  addNewAdvertiser,
  addNewAdgroup,
  createBulletsList,
  addNewBullet,
  editBullet,
  deleteBullet,
  showCssPopup,
  showJsPopup
} from '../state/slices/editorSlice'

export default function useEditorHelpers () {
  const dispatch = useDispatch()

  const addElementToEditor = (containerId, element) =>
    dispatch(addElement({ containerId, element }))

  const selectElementOfEditor = elementId => {
    dispatch(selectElement({ elementId }))
  }
  const ActiveSideBarElements = elementName => {
    dispatch(activeElement(elementName, elementName))
  }
  const addNewAdvertiserFunction = advertiserName => {
    dispatch(addNewAdvertiser(advertiserName))
  }
  const addNewAdgroupToSelect = addGroupName => {
    dispatch(addNewAdgroup(addGroupName))
  }
  const createListOfBullets = bullets => {
    dispatch(createBulletsList(bullets))
  }
  const addBullet = bullet => {
    dispatch(addNewBullet(bullet))
  }
  const updateBullet = (bullet, bulletIndex) => {
    dispatch(editBullet({ bullet, bulletIndex }))
  }
  const removeBullet = (bulletIndex) => {
    dispatch(deleteBullet({ bulletIndex }))
  }
  const showCssModalonEditor = () => {
    dispatch(showCssPopup())
  }
  const showJsModalonEditor = () => {
    dispatch(showJsPopup())
  }
  return {
    addElementToEditor,
    selectElementOfEditor,
    ActiveSideBarElements,
    addNewAdvertiserFunction,
    addNewAdgroupToSelect,
    addBullet,
    updateBullet,
    removeBullet,
    showCssModalonEditor,
    showJsModalonEditor,
    createListOfBullets
  }
}
