import React from 'react'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'

const ShadowInput = ({ x, y, blur, color, onShadowChange }) => {
  const handleXChange = event => {
    onShadowChange(parseInt(event.target.value), y, blur, color)
  }

  const handleYChange = event => {
    onShadowChange(x, parseInt(event.target.value), blur, color)
  }

  const handleBlurChange = event => {
    onShadowChange(x, y, parseInt(event.target.value), color)
  }

  const handleColorChange = event => {
    onShadowChange(x, y, blur, event.target.value)
  }

  return (
    <Container>
      <TextField
        label='X'
        type='number'
        value={x}
        onChange={handleXChange}
        sx={{ width: 60 }}
      />
      <TextField
        label='Y'
        type='number'
        value={y}
        onChange={handleYChange}
        sx={{ width: 60 }}
      />
      <TextField
        label='Blur'
        type='number'
        value={blur}
        onChange={handleBlurChange}
        sx={{ width: 60 }}
      />
      <TextField
        label='Color'
        type='color'
        value={color}
        onChange={handleColorChange}
        sx={{ width: 60 }}
      />
    </Container>
  )
}

export default ShadowInput
const Container = styled.div`
  padding: 5px 0px;
`
