import { useDispatch } from 'react-redux'
import {
  createNewPage,
  removePageByIndex,
  setPageKeysChange,
  setSelectedPage,
  useFormsBuilderState
} from 'state/slices/formsGenerator/builderSlice'

export default function usePages () {
  const { pages, selectedPageIndex } = useFormsBuilderState()
  const dispatch = useDispatch()

  const addPage = (pageName) => {
    dispatch(createNewPage(pageName))
  }

  const selectPage = (page, index) => {
    dispatch(setSelectedPage({ page, index }))
  }

  const removePage = (index = selectedPageIndex) => {
    dispatch(removePageByIndex(index))
  }

  const isPageNameExist = (pageName) => {
    const exist = pages.find(
      (page) => page.name.toLowerCase() === pageName.toLowerCase()
    )
    return !!exist
  }

  const changePageValues = ({ pageIndex, values }) => {
    dispatch(setPageKeysChange({ pageIndex, values }))
  }

  return {
    addPage,
    selectPage,
    isPageNameExist,
    removePage,
    changePageValues,
    pages,
    selectedPageIndex
  }
}
