import { findIndex, isEqual } from 'lodash'
import { useReducer, useCallback, useMemo, useEffect, useState } from 'react'
import usePages from './usePagesEditor'
import { usePagesFlow } from './usePagesFlow'

function reducer (state, { type, payload }) {
  if (type === 'change') {
    const { key, value } = payload
    return {
      ...state,
      [key]: value
    }
  }
  if (type === 'setAllValues') {
    return {
      ...state,
      ...payload
    }
  }
  throw Error('Unknown action.')
}
const initialArg = {
  className: '',
  onLoad: '',
  animation: '',
  autoMoveNext: 0
}

export const usePageNode = ({ id, selected }) => {
  const { pages, changePageValues, removePage } = usePages()
  const { edges, removeNode } = usePagesFlow()
  const [openEdit, setOpenEdit] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [isAutoMode, setIsAutoMove] = useState(false)
  const [formState, dispatch] = useReducer(reducer, initialArg)
  const { className, onLoad, animation, autoMoveNext } = formState
  const handleChange = useCallback(
    ({ key, value }) => dispatch({ type: 'change', payload: { key, value } }),
    []
  )

  const toggleEditPageSetting = () => setOpenEdit((prev) => !prev)

  useEffect(() => {
    if (!selected) setOpenEdit(false)
  }, [selected])

  const currentPage = useMemo(() => {
    return pages[id]
  }, [pages, id])

  useEffect(() => {
    if (!currentPage) return
    const { className, customEvents, animation, autoMoveNext } = currentPage
    dispatch({
      type: 'setAllValues',
      payload: {
        className,
        onLoad: customEvents?.onLoad || '',
        animation: animation || '',
        autoMoveNext: autoMoveNext || 0
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!currentPage) return setIsChanged(false)
    const {
      className: pageClassName,
      customEvents,
      animation: pageAnimation,
      autoMoveNext: pageAutoMoveNext
    } = currentPage
    if (
      !isEqual(
        { className, onLoad, animation, autoMoveNext },
        {
          className: pageClassName,
          onLoad: customEvents?.onLoad || '',
          animation: pageAnimation || '',
          autoMoveNext: pageAutoMoveNext || 0
        }
      )
    ) {
      setIsChanged(true)
    } else {
      setIsChanged(false)
    }
  }, [className, onLoad, animation, autoMoveNext, currentPage])

  const handleSaveClick = () => {
    changePageValues({
      pageIndex: id,
      values: {
        className,
        animation,
        autoMoveNext,
        customEvents: { ...currentPage.customEvents, onLoad }
      }
    })
  }

  const isNodeConnected = useMemo(() => {
    const isConnected = findIndex(
      edges,
      (entry) => entry.source === id || entry.target === id
    )
    return isConnected > -1
  }, [edges, id])

  const handleDeleteNode = () => {
    removeNode(id)
    removePage(Number(id))
  }

  return {
    isChanged,
    toggleEditPageSetting,
    isNodeConnected,
    handleDeleteNode,
    openEdit,
    formState,
    isAutoMode,
    setIsAutoMove,
    handleSaveClick,
    handleChange
  }
}
