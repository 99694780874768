import { Box } from '@mui/material'
import { ELEMENTS_TYPES } from 'modules/utils/elementsTypes'
import { RxBoxModel, RxButton, RxSlider } from 'react-icons/rx'
import { FaHeading } from 'react-icons/fa'
import { TfiParagraph } from 'react-icons/tfi'
import {
  BsInputCursorText,
  BsTelephone,
  BsUiChecksGrid,
  BsUiRadiosGrid
} from 'react-icons/bs'
import { MdOutlineArrowDropDownCircle } from 'react-icons/md'
import DrawerElement from './element'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'

export default function BasicElements () {
  const { containerIndex } = useFormsBuilderState()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '10px',
        m: '10px',
        transition: 'all .4s ease',
        width: '270px'
      }}
    >
      {(containerIndex === null || containerIndex === undefined) && (
        <DrawerElement
          icon={<RxBoxModel />}
          label='Container'
          type={ELEMENTS_TYPES.CONTAINER}
        />
      )}

      <DrawerElement
        icon={<FaHeading />}
        label='Headline'
        type={ELEMENTS_TYPES.HEADLINE}
      />
      <DrawerElement
        icon={<TfiParagraph />}
        label='Paragraph'
        type={ELEMENTS_TYPES.PARAGRAPH}
      />
      <DrawerElement
        icon={<RxButton />}
        label='Button'
        type={ELEMENTS_TYPES.BUTTON}
      />
      <DrawerElement
        icon={<BsInputCursorText />}
        label='Input Text'
        type={ELEMENTS_TYPES.FORM_TEXT}
      />
      <DrawerElement
        icon={<BsUiRadiosGrid />}
        label='Radio Buttons'
        type={ELEMENTS_TYPES.RADIO_BUTTONS}
      />
      <DrawerElement
        icon={<BsUiChecksGrid />}
        label='CheckBox Option'
        type={ELEMENTS_TYPES.CHECKBOX}
      />
      <DrawerElement
        icon={<MdOutlineArrowDropDownCircle />}
        label='Dropdown Options'
        type={ELEMENTS_TYPES.DROPDOWN_SELECT}
      />
      <DrawerElement
        icon={<RxSlider />}
        label='Range Slider'
        type={ELEMENTS_TYPES.RANGE_SLIDER}
      />
      <DrawerElement
        icon={<BsTelephone />}
        label='Input Phone'
        type={ELEMENTS_TYPES.FORM_PHONE}
      />
    </Box>
  )
}
