import { Element, useEditor } from '@craftjs/core'
import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { getFirstValidationErrorMessage } from 'modules/utils/getFirstValidationErrorMessage'
import { useEffect, useState } from 'react'
import { Settings } from '../../settings'
import { ErrorSpanComponent } from '../errorSpan'
import { RangeInputBar } from './bar'
import { RangeInput } from './input'
import { RangeLabel } from './label'
import { RangeInputLabels } from './labels'
import { RangeInputSlide } from './slide'

export const RangeComponentSetting = () => {
  return <Settings typography dimensions colors border withError />
}

export const RangeComponent = ({
  questionKey,
  className,
  suggestions,
  initialIndex,
  minLabel,
  maxLabel,
  validations
}) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const { withError } = useEditor((state, _) => ({
    withError:
      state.nodes[id] && state.nodes[id].dom
        ? state.nodes[id].dom.classList.contains('with__error')
        : false
  }))

  useEffect(() => {
    setShowErrorMessage(withError)
  }, [withError])

  const [labelTextValue, setLabelTextValue] = useState(
    suggestions[initialIndex].text
  )
  const [rangeValue, setRangeValue] = useState(initialIndex)

  useEffect(() => {
    setLabelTextValue(suggestions[rangeValue].text)
  }, [rangeValue, suggestions])
  return (
    <div
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      className={`${className} range__input--container`}
    >
      <Element
        canvas
        is={RangeLabel}
        text={labelTextValue}
        id={`range__label__${questionKey}`}
      />
      <Element
        canvas
        is={RangeInputBar}
        id={`range__input__bar__${questionKey}`}
      />
      <Element
        canvas
        is={RangeInputSlide}
        id={`range__input__slide__${questionKey}`}
      />
      <Element
        canvas
        is={RangeInput}
        id={`range__input__${questionKey}`}
        value={rangeValue}
        handleChange={({ target }) => {
          setRangeValue(target.value)
        }}
        suggestions={suggestions}
      />
      <Element
        canvas
        is={RangeInputLabels}
        minLabel={minLabel}
        maxLabel={maxLabel}
        id={`range__input__labels__${questionKey}`}
      />
      {showErrorMessage && (
        <Element
          canvas
          is={ErrorSpanComponent}
          text={getFirstValidationErrorMessage(validations)}
          id={`error__span__${questionKey}`}
          className='error__span'
        />
      )}
    </div>
  )
}

RangeComponent.craft = {
  displayName: 'Range Component',
  related: {
    toolbar: RangeComponentSetting
  }
}
