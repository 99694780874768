import { ValidationsOptions } from './elementsValidations'
import MoreThanValidation from './moreThan'
import RegexValidations from './regexValidation'
import RequiredValidation from './requiredValidation'
import USAZipCodeValidation from './usaZipCodeValidation'

export const renderValidation = (validationType, idx) => {
  switch (validationType) {
    case ValidationsOptions.REQUIRED:
      return <RequiredValidation key={idx} />
    case ValidationsOptions.USA_ZIP_CODE:
      return <USAZipCodeValidation key={idx} />
    case ValidationsOptions.REGEX:
      return <RegexValidations key={idx} />
    case ValidationsOptions.MORE_THAN:
      return <MoreThanValidation key={idx} />
    default:
      return null
  }
}
