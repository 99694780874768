import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const RangeLabelSetting = () => {
  return <Settings typography dimensions colors border />
}

export const RangeLabel = ({ text }) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  return (
    <label
      className='range__label'
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
    >
      {text}
    </label>
  )
}

RangeLabel.craft = {
  displayName: 'Range Label',
  related: {
    toolbar: RangeLabelSetting
  }
}
