import { useCraftNode } from 'modules/forms-generator/hooks/useCraftNode'
import { Settings } from '../../settings'

export const LabelSetting = () => {
  return (
    <Settings typography dimensions colors border display />
  )
}

export const LabelComponent = ({
  text,
  className = '',
  questionKey,
  value
}) => {
  const {
    connectors: { connect, drag },
    id
  } = useCraftNode()

  return (
    <label
      className={className}
      style={{ cursor: 'move', position: 'relative' }}
      ref={(ref) => connect(drag(ref))}
      data-element-id={id}
      htmlFor={`${questionKey}_${value}`}
    >
      {text}
    </label>
  )
}

LabelComponent.craft = {
  displayName: 'label',
  props: {
    text: 'Text'
  },
  related: {
    toolbar: LabelSetting
  }
}
