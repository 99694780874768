import { FormControl, TextField } from '@mui/material'

export const NumberInput = ({ label, value, onChange }) => {
  return (
    <FormControl fullWidth sx={{ my: '10px' }}>
      <TextField
        fullWidth
        label={label}
        type='number'
        value={value}
        onChange={onChange}
      />
    </FormControl>
  )
}
