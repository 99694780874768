import { useEffect, useState } from 'react'
import { useFormCssState } from 'state/slices/formsGenerator/formCssSlice'
import { useFormCss } from './useFormCss'

const getCurrentValue = (cssObject, selector, { key, value, unit }) => {
  const currentSelector = cssObject[selector]
  if (currentSelector === undefined) return { value, unit }
  if (currentSelector[key]) {
    return { value, unit, ...currentSelector[key] }
  }
  return { value, unit }
}

const getValuePerUnit = (unit, value) => {
  if (unit !== '%') return value
  if (value === 'auto') return value
  if (Number(value) >= 100) return 100
  return value
}

export const useCssKeyValue = ({ key, value, unit }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [currentUnit, setCurrentUnit] = useState(unit)

  const { css, device, tabletCss, desktopCss, selectedSelector } =
    useFormCssState()
  const { changeSelectorKeyValue, getCurrentUsedCssObject } = useFormCss()

  useEffect(() => {
    const currentSelectorValue = getCurrentValue(
      getCurrentUsedCssObject(),
      selectedSelector,
      {
        key,
        value,
        unit
      }
    )
    setCurrentValue(currentSelectorValue.value)
    setCurrentUnit(currentSelectorValue.unit)
    // eslint-disable-next-line
  }, [selectedSelector, css, device, tabletCss, desktopCss, key, unit, value])

  const updateValue = (updatedValue) => {
    changeSelectorKeyValue({
      key,
      value: getValuePerUnit(currentUnit, currentValue),
      unit: currentUnit,
      ...updatedValue
    })
  }

  return [currentValue, updateValue, currentUnit]
}
