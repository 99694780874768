import { Box } from '@mui/material'
import styled from 'styled-components'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SourceTargetPagesContainer from './sourceTargetPagesContainer'

export default function SpecificPageConnection ({
  sourceName,
  currentTargetName,
  newTargetName
}) {
  return (
    <StaticPageConnectionWrapper>
      <SourceTargetPagesContainer
        source={sourceName}
        target={currentTargetName}
      />
      <div className='newConnectionIcon'>
        <ImportExportIcon sx={{ fontSize: 60 }} />
      </div>

      <SourceTargetPagesContainer source={sourceName} target={newTargetName} />
    </StaticPageConnectionWrapper>
  )
}

const StaticPageConnectionWrapper = styled(Box)`
  .newConnectionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pageName {
      background-color: #3f51b5;
      border-radius: 5px;
      font-weight: bold;
      padding: 10px 20px;
      position: relative;
      color: #ffffff;
    }
    .arrow {
      height: 1px;
      background: #002984;
      flex: 1;
      top: 50%;
      position: relative;
    }
    .arrow:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      border-style: solid;
      border-width: 7px 0 7px 20px;
      right: 0;
      border-color: transparent transparent transparent #002984;
      transform: translateY(-50%);
    }
  }
`
