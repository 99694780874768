import { Editor, Frame, Element } from '@craftjs/core'
import { Box } from '@mui/material'
import {
  convertCssObjectToString,
  injectGoogleFonts,
  injectStylesToHead
} from 'modules/utils/convertCssObjectToString'
import { ELEMENTS_TYPES } from 'modules/utils/elementsTypes'
import { useEffect } from 'react'
import { useFormsBuilderState } from 'state/slices/formsGenerator/builderSlice'
import { useFormCssState } from 'state/slices/formsGenerator/formCssSlice'
import { ButtonComponent } from './elements/button'
import PageComponent from './elements/page'
import RadioButtonComponent from './elements/radioButtons'
import { ParagraphComponent } from './elements/paragraph'
import { RenderNode } from './elements/renderNode'
import { TitleComponent } from './elements/title'
import { InputContainerComponent } from './elements/radioButtons/inputContainer'
import { LabelComponent } from './elements/label'
import { RadioInputComponent } from './elements/radioInput'
import Toolbar from './toolbar'
import { SelectComponent } from './elements/select'
import { SelectContainer } from './elements/select/container'
import { SelectInput } from './elements/select/selectInput'
import { RangeComponent } from './elements/range'
import { RangeInputBar } from './elements/range/bar'
import { RangeInput } from './elements/range/input'
import { RangeLabel } from './elements/range/label'
import { RangeInputLabels } from './elements/range/labels'
import { RangeInputSlide } from './elements/range/slide'
import { InputComponent } from './elements/input'
import { InputElement } from './elements/input/input'
import { ContainerComponent } from './elements/container'
import { ErrorSpanComponent } from './elements/errorSpan'
import EditorWrapper from './wrapper'
import { DevicesWidth } from './devicesWidth'
import { CheckBoxComponent } from './elements/checkbox'
import useLayouts from 'modules/forms-generator/hooks/useLayouts'

export const renderElement = (elements, container = '') => {
  return (
    elements &&
    elements.map((element, idx) => {
      if (element.kind === 'TEXT' && element.type === ELEMENTS_TYPES.HEADLINE) {
        return (
          <Element
            canvas
            customId={element.id}
            is={TitleComponent}
            className={element.className}
            text={element.value}
            key={`element__${idx}__${container}`}
          />
        )
      }
      if (
        element.kind === 'TEXT' &&
        element.type === ELEMENTS_TYPES.PARAGRAPH
      ) {
        return (
          <Element
            canvas
            is={ParagraphComponent}
            customId={element.id}
            className={element.className}
            text={element.value}
            key={`element__${idx}__${container}`}
          />
        )
      }
      if (element.kind === ELEMENTS_TYPES.BUTTON) {
        return (
          <Element
            canvas
            is={ButtonComponent}
            customId={element.id}
            className={element.className}
            text={element.value}
            key={`element__${idx}__${container}`}
          />
        )
      }

      if (
        element.kind === 'FORM' &&
        element.type === ELEMENTS_TYPES.RADIO_BUTTONS
      ) {
        return (
          <Element
            canvas
            is={RadioButtonComponent}
            customId={element.id}
            className={element.className}
            text={element.value}
            suggestions={element.suggestions}
            questionKey={element.key}
            validations={element.validations}
            key={`element__${idx}__${container}`}
          />
        )
      }

      if (element.kind === 'FORM' && element.type === ELEMENTS_TYPES.CHECKBOX) {
        return (
          <Element
            canvas
            is={CheckBoxComponent}
            customId={element.id}
            className={element.className}
            content={element.content}
            questionKey={element.key}
            key={`element__${idx}__${container}`}
          />
        )
      }

      if (
        element.kind === 'FORM' &&
        element.type === ELEMENTS_TYPES.DROPDOWN_SELECT
      ) {
        return (
          <Element
            canvas
            is={SelectComponent}
            customId={element.id}
            className={element.className}
            suggestions={element.suggestions}
            questionKey={element.key}
            validations={element.validations}
            key={`element__${idx}__${container}`}
          />
        )
      }
      if (
        element.kind === 'FORM' &&
        element.type === ELEMENTS_TYPES.RANGE_SLIDER
      ) {
        return (
          <Element
            canvas
            is={RangeComponent}
            customId={element.id}
            className={element.className}
            suggestions={element.suggestions}
            questionKey={element.key}
            initialIndex={element.initialIndex}
            minLabel={element.minLabel}
            maxLabel={element.maxLabel}
            validations={element.validations}
            key={`element__${idx}__${container}`}
          />
        )
      }

      if (
        element.kind === 'FORM' &&
        (element.type === ELEMENTS_TYPES.FORM_TEXT ||
          element.type === ELEMENTS_TYPES.FORM_PHONE)
      ) {
        return (
          <Element
            canvas
            is={InputComponent}
            customId={element.id}
            className={element.className}
            placeholder={element.placeholder}
            questionKey={element.key}
            validations={element.validations}
            key={`element__${idx}__${container}`}
          />
        )
      }
      if (element.kind === 'CONTAINER') {
        return (
          <Element
            canvas
            is={ContainerComponent}
            customId={element.id}
            className={element.className}
            key={`element__${idx}`}
          >
            {renderElement(element.children, `container__${idx}`)}
          </Element>
        )
      }

      return null
    })
  )
}

export default function Design () {
  const { selectedPage } = useFormsBuilderState()
  const { layouts } = useLayouts()
  const { css, plainCss, desktopCss, tabletCss, googleFonts } =
    useFormCssState()

  useEffect(() => {
    injectStylesToHead(
      `${injectGoogleFonts(googleFonts)} \n \n
      ${convertCssObjectToString(css)}\n\n
      @media only screen and (min-width: ${DevicesWidth.tablet}) {
        ${convertCssObjectToString(tabletCss)}
      }
      @media only screen and (min-width: ${DevicesWidth.desktop}) {
        ${convertCssObjectToString(desktopCss)}
      }
      \n\n
      ${plainCss}`
    )
  }, [css, plainCss, googleFonts, tabletCss, desktopCss])

  if (selectedPage && selectedPage.name) {
    return (
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          p: '5px'
        }}
      >
        <Editor
          resolver={{
            TitleComponent,
            PageComponent,
            ParagraphComponent,
            ButtonComponent,
            RadioButtonComponent,
            InputContainerComponent,
            LabelComponent,
            RadioInputComponent,
            SelectComponent,
            SelectContainer,
            SelectInput,
            RangeComponent,
            RangeInputBar,
            RangeInput,
            RangeLabel,
            RangeInputLabels,
            RangeInputSlide,
            InputComponent,
            InputElement,
            ContainerComponent,
            ErrorSpanComponent,
            CheckBoxComponent
          }}
          canvas
          onRender={RenderNode}
          key={selectedPage.name}
        >
          <EditorWrapper>
            {selectedPage.layouts.beforeElements && (
              <div
                dangerouslySetInnerHTML={{
                  __html: layouts[selectedPage.layouts.beforeElements]
                }}
              />
            )}
            <Frame>
              <Element
                canvas
                is={PageComponent}
                className={`${selectedPage.className}`}
              >
                {renderElement(selectedPage.elements)}
              </Element>
            </Frame>
            {selectedPage.layouts.afterElements && (
              <div
                dangerouslySetInnerHTML={{
                  __html: layouts[selectedPage.layouts.afterElements]
                }}
              />
            )}
          </EditorWrapper>
          <Box
            sx={{
              width: '30%',
              height: '100%',
              backgroundColor: '#ffffff',
              maxHeight: '80vh',
              overflow: 'scroll',
              maxWidth: '500px'
            }}
          >
            <Toolbar />
          </Box>
        </Editor>
      </Box>
    )
  }
}
