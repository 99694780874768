import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'
import { useEffect, useState } from 'react'
import DefaultWithFunction from './defaultWithFunction'
import DefaultValueKey from './keys'
import DefaultValueByValue from './value'

export default function DefaultValueSelector () {
  const { selectedElement } = useSelectedElements()
  const [defaultValueType, setDefaultValueType] = useState('')
  useEffect(() => {
    if (!selectedElement) return
    const { defaultValue } = selectedElement
    if (!defaultValue) return
    if ('key' in defaultValue) setDefaultValueType('key')
    if ('value' in defaultValue) setDefaultValueType('value')
    if ('functionName' in defaultValue) setDefaultValueType('function')
    // eslint-disable-next-line
  }, [])
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <FormControl>
        <FormLabel id='default-value'>Default Value:</FormLabel>
        <RadioGroup
          value={defaultValueType}
          aria-labelledby='default-value'
          defaultValue='value'
          name='radio-buttons-group'
          onChange={(_, value) => setDefaultValueType(value)}
        >
          <FormControlLabel
            value='value'
            control={<Radio />}
            label='Specific Value'
          />
          <FormControlLabel
            value='key'
            control={<Radio />}
            label='Previous Key'
          />
          <FormControlLabel
            value='function'
            control={<Radio />}
            label='Using Function'
          />
        </RadioGroup>
      </FormControl>
      {defaultValueType === 'key' && (
        <DefaultValueKey placeHolder='Select the Key to get Default Value From' />
      )}
      {defaultValueType === 'value' && (
        <DefaultValueByValue placeHolder='Enter the value to be the default value' />
      )}
      {defaultValueType === 'function' && <DefaultWithFunction />}
    </Box>
  )
}
