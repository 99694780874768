import { Box } from '@mui/material'
import { useExport } from 'modules/forms-generator/hooks/useExport'
import { useEffect, useRef } from 'react'

export default function PreviewLayout () {
  const { exportedCode, exportedStyles } = useExport()
  const previewRef = useRef()

  useEffect(() => {
    if (previewRef) {
      previewRef.current.srcdoc = `<style>${exportedStyles}</style>\n\n${exportedCode}`
    }
  }, [exportedStyles, exportedCode])
  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: '#eeeeee',
        overflowY: 'scroll',
        position: 'relative'
      }}
    >
      <Box sx={{ backgroundColor: '#ffffff', margin: '20px' }}>
        <iframe
          ref={previewRef}
          frameBorder='0'
          title='previewForm'
          style={{ display: 'block', height: '100%', width: '100%' }}
        />
      </Box>
    </Box>
  )
}
