import { useDispatch } from 'react-redux'
import { changeMode, changeBuilderTab } from 'state/slices/formsGenerator/builderSlice'

export default function useFormsLayout () {
  const dispatch = useDispatch()

  const changeBuilderMode = (newMode) => {
    dispatch(changeMode(newMode))
  }

  const changeBuilderSelectedTab = (newTab) => {
    dispatch(changeBuilderTab(newTab))
  }

  return { changeBuilderMode, changeBuilderSelectedTab }
}
