import { ROOT_NODE, useEditor } from '@craftjs/core'
import { FormControl, TextField } from '@mui/material'
import { useStylesHelpers } from 'hooks/use-styles-helpers'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Wrapper } from './dimensions-styled'

export default function BackgroundColor () {
  const [background, setBackground] = useState('')
  const { changestyle } = useStylesHelpers()
  const { selectedDevice, styles, tabletStyle, desktopStyle, selectedClassName } = useSelector(
    state => state.styles
  )
  const isMountedRef = useRef(false)
  function handleChange (e) {
    setBackground(e.target.value)
  }
  const { actions, active, Selectedstyles, classes } = useEditor(
    (state, query) => {
      const currentlySelectedNodeId = query.getEvent('selected').first()
      return {
        active: currentlySelectedNodeId,
        selectedNode: state.nodes[currentlySelectedNodeId]
          ? query.node(currentlySelectedNodeId)
          : undefined,
        related:
          currentlySelectedNodeId &&
          state.nodes[currentlySelectedNodeId].related,
        elementProps: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].data.props
          : undefined,
        dom: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].dom
          : undefined,
        Selectedstyles:
          state.nodes[currentlySelectedNodeId]?.data?.props?.styles,
        parent: state.nodes[currentlySelectedNodeId]
          ? state.nodes[currentlySelectedNodeId].data.parent
          : undefined,
        classes: state.nodes[currentlySelectedNodeId]?.data?.props?.elementClassName || [],
        rootProps: query.node(ROOT_NODE).get()?.data?.props,
        isRoot: ROOT_NODE === currentlySelectedNodeId
      }
    }
  )
  useEffect(() => {
    if (selectedClassName) {
      if (selectedDevice === 'tablet') {
        setBackground(tabletStyle[selectedClassName]?.backgroundColor ?? '#FFFFFF')
      } else if (selectedDevice === 'desktop') {
        setBackground(desktopStyle[selectedClassName]?.backgroundColor ?? '#FFFFFF')
      } else {
        setBackground(styles[selectedClassName]?.backgroundColor ?? '#FFFFFF')
      }
    }
    // eslint-disable-next-line
  }, [selectedClassName])
  useEffect(() => {
    if (isMountedRef.current) {
      const currentStyles = {}
      currentStyles.backgroundColor = background
      const cssKey = 'backgroundColor'
      if (actions) {
        actions.setProp(active, (props) => {
          props.styles = { ...Selectedstyles, ...currentStyles }
        })
      }
      if (classes) {
        changestyle({ classes, Selectedstyles, cssKey, value: background, selectedClassName })
      }
    } else {
      isMountedRef.current = true
    }
    // eslint-disable-next-line
  }, [background])

  return (
    <Wrapper>
      <Container>
        <input
          type='color'
          value={background} onChange={handleChange}
        />
        <FormControl sx={{ width: '12ch' }}>
          <TextField
            size='normal'
            id='filled-hidden-label-small'
            value={background} onChange={handleChange}
          />
        </FormControl>
      </Container>
    </Wrapper>
  )
}

const Container = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-around;
  align-items:center
`
