import { IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useFormJs } from 'modules/forms-generator/hooks/useFormJs'
import { useState, useEffect } from 'react'
import Add from '@mui/icons-material/Add'
import ElementCustomFunctionForm from './functionForm'
import { useFormData } from 'modules/forms-generator/hooks/useFormData'
import { useSelectedElements } from 'modules/forms-generator/hooks/useSelectedElement'

export default function ElementCustomFunctionHandler ({ handlerKey, label }) {
  const { changeElementOnClickFunctions, selectedElement } =
    useSelectedElements()
  const [customFunctions, setCustomFunctions] = useState([])
  const { functionsList } = useFormJs()
  const { getKeysArray } = useFormData()
  const handleChange = (index, value, key) => {
    setCustomFunctions((prev) =>
      prev.map((curr, idx) =>
        idx === index ? { ...curr, [key]: value } : curr
      )
    )
    changeElementOnClickFunctions(
      customFunctions.map((curr, idx) =>
        idx === index ? { ...curr, [key]: value } : curr
      )
    )
  }
  const handleAddNewFunction = () => {
    setCustomFunctions([
      ...customFunctions,
      { name: '', params: [], shouldBeAwaited: false }
    ])
    changeElementOnClickFunctions([
      ...customFunctions,
      { name: '', params: [], shouldBeAwaited: false }
    ])
  }
  const handleRemove = (index) => {
    setCustomFunctions((prev) =>
      prev.filter((curr, idx) => idx !== index && curr)
    )
    changeElementOnClickFunctions(
      customFunctions.filter((curr, idx) => idx !== index && curr)
    )
  }

  useEffect(() => {
    setCustomFunctions(selectedElement[handlerKey] || [])
    // eslint-disable-next-line
  }, [handlerKey, selectedElement])
  return (
    <Box>
      <Typography>{label}</Typography>
      {customFunctions.map((currentFunction, idx) => (
        <ElementCustomFunctionForm
          functionsList={functionsList}
          keysArray={getKeysArray()}
          onChange={handleChange}
          onRemove={() => handleRemove(idx)}
          currentValue={currentFunction}
          idx={idx}
          key={`form_${idx}`}
        />
      ))}
      <IconButton
        color='primary'
        aria-label='add new Function'
        onClick={handleAddNewFunction}
      >
        <Add />
      </IconButton>
    </Box>
  )
}
