import { ELEMENTS_TYPES } from 'modules/utils/elementsTypes'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  addElementToSelectedPage,
  addKeysToSelectedElement,
  changeSelectedElementValue,
  useFormsBuilderState,
  addSuggestionToSelectedElement,
  changeSelectedElement,
  removeSuggestionsFromSelectedElement,
  updateSuggestionValueInSelectedElement,
  updateElementsOrderWithinPage,
  removeElementFromPage,
  updateError,
  updateSelectedElementCustomEvent,
  updateSelectedElementOnClickFunctions
} from 'state/slices/formsGenerator/builderSlice'

export const useSelectedElements = () => {
  const dispatch = useDispatch()
  const { selectedElement, selectedElementIndex, errors } =
    useFormsBuilderState()

  const setErrorMessage = ({ key, message }) => {
    dispatch(updateError({ [key]: message }))
  }

  const clearError = (key) => {
    dispatch(updateError({ [key]: true }))
  }

  const isFormValid = useMemo(() => {
    for (const key in errors) {
      if (Object.hasOwnProperty.call(errors, key) && errors[key] !== true) {
        return false
      }
    }
    return true
  }, [errors])

  const createElementKeys = (elementType) => {
    if (selectedElementIndex !== null) return
    switch (elementType) {
      case ELEMENTS_TYPES.HEADLINE:
      case ELEMENTS_TYPES.PARAGRAPH:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'TEXT',
            type: elementType,
            className: '',
            value: ''
          })
        )
      case ELEMENTS_TYPES.BUTTON:
        return dispatch(
          addKeysToSelectedElement({
            kind: elementType,
            className: '',
            value: '',
            role: ''
          })
        )
      case ELEMENTS_TYPES.CONTAINER:
        return dispatch(
          addKeysToSelectedElement({
            kind: elementType,
            className: '',
            keys: [],
            children: []
          })
        )
      case ELEMENTS_TYPES.RADIO_BUTTONS:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'FORM',
            type: elementType,
            className: '',
            key: '',
            role: null,
            suggestions: [],
            validations: {}
          })
        )
      case ELEMENTS_TYPES.CHECKBOX:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'FORM',
            type: elementType,
            className: '',
            key: '',
            content: '',
            checkedValue: '',
            uncheckedValue: '',
            validations: {}
          })
        )
      case ELEMENTS_TYPES.DROPDOWN_SELECT:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'FORM',
            type: elementType,
            className: '',
            key: '',
            suggestions: [],
            validations: {}
          })
        )
      case ELEMENTS_TYPES.RANGE_SLIDER:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'FORM',
            type: elementType,
            className: '',
            initialIndex: null,
            minLabel: '',
            maxLabel: '',
            key: '',
            suggestions: [],
            validations: {}
          })
        )
      case ELEMENTS_TYPES.FORM_TEXT:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'FORM',
            type: 'TEXT',
            className: '',
            placeholder: '',
            key: '',
            validations: {}
          })
        )

      case ELEMENTS_TYPES.FORM_PHONE:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'FORM',
            type: 'PHONE',
            className: '',
            placeholder: '',
            key: '',
            validations: {}
          })
        )
      case ELEMENTS_TYPES.FORM_ERN_ADDRESS:
        return dispatch(
          addKeysToSelectedElement({
            kind: 'FORM',
            type: 'ERN_ADDRESS',
            className: '',
            placeholder: '',
            key: '',
            validations: {}
          })
        )
      default:
        break
    }
  }

  const changeElement = (key, value) => {
    dispatch(changeSelectedElementValue({ key, value }))
  }

  const addSuggestion = (value) => {
    dispatch(addSuggestionToSelectedElement(value))
  }

  const addElement = () => {
    if (!isFormValid) return
    dispatch(addElementToSelectedPage())
  }

  const selectElementByIndex = (index) => {
    dispatch(changeSelectedElement(index))
  }

  const removeSuggestion = (index) => {
    dispatch(removeSuggestionsFromSelectedElement(index))
  }

  const updateSuggestion = (index, value) => {
    dispatch(updateSuggestionValueInSelectedElement({ index, value }))
  }

  const updateElementsOrder = (newElementsList) => {
    dispatch(updateElementsOrderWithinPage(newElementsList))
  }

  const deleteElementByIndex = (index, childIndex) => {
    dispatch(removeElementFromPage({ index, childIndex }))
  }

  const changeElementCustomEvent = ({ key, eventName }) => {
    dispatch(updateSelectedElementCustomEvent({ key, eventName }))
  }

  const changeElementOnClickFunctions = (functions = []) => {
    dispatch(updateSelectedElementOnClickFunctions(functions))
  }

  return {
    selectedElement,
    errors,
    isFormValid,
    customEvents: selectedElement?.customEvents || {},
    changeElementCustomEvent,
    setErrorMessage,
    clearError,
    createElementKeys,
    changeElement,
    addElement,
    addSuggestion,
    selectElementByIndex,
    removeSuggestion,
    updateSuggestion,
    updateElementsOrder,
    deleteElementByIndex,
    changeElementOnClickFunctions
  }
}
