/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import { LayoutsSvg } from './layoutsSvg'
import { ListingLayoutFolder } from './listingLayoutFolder'

export default function Layouts () {
  const [toggle, setStoggle] = useState(true)
  return (
    <Container data-cy='sidebar__layouts__section'>
      <LayoutTitle>
        <Icon>
          <svg
            version='1.1'
            id='GM2'
            xmlns='http://www.w3.org/2000/svg'
            className='cwIsqe'
            xlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            width='24px'
            height='24px'
            viewBox='0 0 24 24'
            focusable='false'
            enableBackground='new 0 0 24 24'
            space='preserve'
          >
            <path d='M4,5v13h17V5H4z M6,16V7h3v9H6z M11,16v-3.5h3V16H11z M19,16h-3v-3.5h3V16z M11,10.5V7h8v3.5H11z' />
            <path fill='none' d='M0,0h24v24H0V0z' />
          </svg>
        </Icon>
        <Title>Layouts</Title>
        <ToggleUp
          onClick={() => {
            setStoggle(!toggle)
          }}
          toggle={toggle}
        >
          <img src='https://img.icons8.com/ios-glyphs/12/000000/double-up--v1.png' alt='icon-arrow-up' />
        </ToggleUp>
        <ToggleDown
          onClick={() => {
            setStoggle(!toggle)
          }}
          toggle={!toggle}
        >
          <img src='https://img.icons8.com/ios-glyphs/12/000000/double-down--v1.png' alt='icon-arrow-down' />
        </ToggleDown>
      </LayoutTitle>
      <Wrapper toggle={toggle}>
        <ListingLayoutFolder />
        <LayoutsSvg />
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 14px 0px;
`
const LayoutTitle = styled.div`
  display: flex;
  justify-content: space-around;
  color: #5f6368;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
  text-align: left;
`
const Icon = styled.div``
const Title = styled.div``
const ToggleUp = styled.div`
  cursor: pointer;
  display: ${props => (props.toggle ? 'block' : 'none')};
`
const ToggleDown = styled.div`
  display: ${props => (props.toggle ? 'block' : 'none')};
`
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: ${props => (props.toggle ? 'flex' : 'none')};
  svg {
    margin-bottom: 5px;
    cursor:pointer;
  }
`
const Layout = styled.div`
  width: 110px;
  margin: 2px;
  padding: 0;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.12);

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  img {
    width: 110px;
    height: 60px;
  }
`
