import { Box } from '@mui/material'
import { MdOutlineAlternateEmail } from 'react-icons/md'
import { BsInputCursorText } from 'react-icons/bs'
import { EmailElementData, EmailElementStyles } from './complex-data/email'
import {
  LabelInputElementData,
  LabelInputElementStyles
} from './complex-data/label-input'
import DrawerElement from './element'

export default function ComplexElements () {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '10px',
        m: '10px',
        transition: 'all .4s ease',
        width: '270px'
      }}
    >
      <DrawerElement
        icon={<MdOutlineAlternateEmail />}
        label='Email Form'
        complexElement={EmailElementData}
        elementStyles={EmailElementStyles}
      />
      <DrawerElement
        icon={<BsInputCursorText />}
        label='Label + Input'
        complexElement={LabelInputElementData}
        elementStyles={LabelInputElementStyles}
      />
    </Box>
  )
}
